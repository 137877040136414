import { LineString, Position } from "geojson";
import {
  along,
  booleanPointOnLine,
  distance,
  lineString,
  nearestPointOnLine,
  point,
  length,
} from "@turf/turf";
import { formatDuration } from "date-fns";
import { enUS, ko, zhTW } from "date-fns/locale";
import i18n from "../../app/locales/i18n";
import { GeoLocation } from "../geolocation/geoLocationSlice";
import { Path, PointIdxForGuide } from "../route/routeSlice";
import { CarPositionState } from "../map/position/carPositionSlice";
import { GuidePositionState } from "../map/position/guidePositionSlice";
import { type Guidance, type WayDetailInfo } from "./guideSlice";
import { BASE_URL } from "../../app/config/const";
import Logger from "../../app/logger";

export const getNearbyPoint = (point1: GeoLocation, lstring: LineString) => {
  const nearest = nearestPointOnLine(lstring, [point1.longitude, point1.latitude], {
    units: "meters",
  });
  return nearest;
};

export const calculateDistance = (point1: Position, point2: Position) => {
  return distance(point1, point2, { units: "meters" });
};

export const checkPointOnLine = (lineStirng: LineString, point1: Position) => {
  if (
    lineStirng.coordinates.length < 2 ||
    lineStirng.coordinates[0] === undefined ||
    lineStirng.coordinates[1] === undefined
  ) {
    return false;
  }

  return booleanPointOnLine(point1, lineStirng, {
    epsilon: 0.0000000000001,
    ignoreEndVertices: false,
  });
};

export const generatePointsBetween = (
  startPoint: Position,
  endPoint: Position,
  meterPerSecond: number,
) => {
  // km/h -> m/s

  const lineDistance = distance(point(startPoint), point(endPoint), { units: "meters" });

  if (lineDistance < meterPerSecond) {
    return [startPoint, endPoint];
  }

  const points: Position[] = [];

  for (let i = 0; i <= lineDistance; i += meterPerSecond) {
    const segment = along(lineString([startPoint, endPoint]), i, {
      units: "meters",
    });
    points.push(segment.geometry.coordinates);
  }

  if (
    points[points.length - 1][0] !== endPoint[0] ||
    points[points.length - 1][1] !== endPoint[1]
  ) {
    points.push(endPoint);
  }

  return points;
};

export const getFormattedTime = (ms: number) => {
  const minutes = Math.floor(ms / 60000);
  const days = Math.floor(minutes / (60 * 24));
  const hours = Math.floor((minutes - days * 60 * 24) / 60);
  const mins = Math.floor(minutes - days * 60 * 24 - hours * 60);

  if (i18n.language === "ko") {
    return formatDuration({ days, hours, minutes: mins }, { locale: ko });
  }
  if (i18n.language === "zhtw") {
    return formatDuration({ days, hours, minutes: mins }, { locale: zhTW });
  }

  return formatDuration({ days, hours, minutes: mins }, { locale: enUS });
};

export const getFormattedDistance = (totDistance: number, units: boolean = true) => {
  if (totDistance < 1000) {
    return units ? `${totDistance.toFixed(0)} ${i18n.t("m")}` : totDistance.toFixed(0);
  }

  return units ? `${(totDistance / 1000).toFixed(1)} ${i18n.t("km")}` : totDistance.toFixed(0);
};

/**
 * Calculates the difference between two angles.
 * @param sourceAngle - The source angle in degrees. (0 <= angle < 360)
 * @param targetAngle - The target angle in degrees. (0 <= angle < 360)
 * @returns The difference between the target angle and the source angle. (-180 <= angle <= 180)
 *          If the difference is positive, the target angle is clockwise from the source angle.
 */
export const getAngleDiff = (sourceAngle: number, targetAngle: number) => {
  const diff = targetAngle - sourceAngle;
  if (diff > 180) return diff - 360;
  if (diff < -180) return diff + 360;
  return diff;
};

export const binarySearchForWayIds = (arr: WayDetailInfo[], target: number): number => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (arr[mid].wayId === target) {
      // 중복시 가장 먼저 나오는 인덱스를 리턴
      let ret = mid;
      while (ret > 0 && arr[ret - 1].wayId === target) {
        ret -= 1;
      }

      return ret;
    }

    if (arr[mid].wayId < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
};

export const binarySearchForWayIdAndDir = (
  arr: WayDetailInfo[],
  target: number,
  direction: boolean,
): number => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (arr[mid].wayId === target && arr[mid].direction === direction) {
      // 중복시 가장 먼저 나오는 인덱스를 리턴
      let ret = mid;
      while (ret > 0 && arr[ret - 1].wayId === target && arr[ret - 1].direction === direction) {
        ret -= 1;
      }

      return ret;
    }

    if (arr[mid].wayId < target) {
      left = mid + 1;
    } else if (arr[mid].wayId === target && arr[mid].direction < direction) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
};

export const binarySearchForGuideIdxByInterval = (
  arr: Guidance[],
  startIdx: number,
  endIdx: number,
): number => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (arr[mid].startInterval <= startIdx && arr[mid].endInterval >= endIdx) {
      return mid;
    }

    if (arr[mid].endInterval < endIdx) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
};

export const getGuidePosFromCarpos = (
  path: Path | null,
  guidance: Guidance[],
  sortedWayIds: WayDetailInfo[],
  carpos: CarPositionState,
  prevGuidePosIdx: number,
  prevGuidanceIdx: number | null,
): GuidePositionState | null => {
  if (
    path === null ||
    path === undefined ||
    guidance === null ||
    guidance === undefined ||
    guidance.length === 0
  ) {
    return null;
  }

  if (
    carpos === null ||
    carpos === undefined ||
    carpos.wayId === null ||
    carpos.wayId === undefined ||
    carpos.distanceForward === null ||
    carpos.distanceForward === undefined ||
    carpos.distanceBackward === null ||
    carpos.distanceBackward === undefined
  ) {
    // carpos는 없는데, 안내정보는 있으면 첫번째 안내를 뿌릴 수 있도록 수정. 단 이전 guideindex가 있으면 null리턴하여 마지막 안내 계속 뿌리도록.
    if (sortedWayIds.length > 0 && guidance.length > 0 && prevGuidanceIdx === null) {
      return {
        position: null,
        linkPositionIndex: guidance[0].startInterval,
        distanceInLink: 0,
        distanceFromStart: 0,
        wayDetailInfo: sortedWayIds[0],
        guidanceIndex: 0,
      };
    }
    return null;
  }

  let wayIdIdx = -1;
  if (carpos.direction === null) wayIdIdx = binarySearchForWayIds(sortedWayIds, carpos.wayId);
  else {
    wayIdIdx = binarySearchForWayIdAndDir(sortedWayIds, carpos.wayId, carpos.direction);

    //    if (wayIdIdx === -1)
    //      wayIdIdx = binarySearchForWayIds(sortedWayIds, carpos.wayId);
  }

  if (wayIdIdx === -1) {
    return null;
  }

  // 중복된 wayId가 있을수 있으므로 방향성체크 필요.
  // 반대 방향에 대한 체크는 현재 안됨.
  // 아래 식으로는 유턴같이 바로 같은 wayid가 오는 경우를 체크하지 못함.

  // 같은 방향으로 빙글 빙글 도는 경로일때 이미 지나간 곳이면?????
  if (
    prevGuidePosIdx !== null &&
    prevGuidePosIdx !== undefined &&
    prevGuidePosIdx !== -1 &&
    sortedWayIds[wayIdIdx].pointIdx[1] < prevGuidePosIdx // 지나간 곳인지 확인
  ) {
    let ii = 0;
    while (
      sortedWayIds.length > wayIdIdx + 1 &&
      sortedWayIds[wayIdIdx] === sortedWayIds[wayIdIdx + 1]
    ) {
      if (sortedWayIds[wayIdIdx + 1].pointIdx[1] < prevGuidePosIdx) {
        // 통과전인지 확인.
        ii += 1;
      }
    }

    wayIdIdx += ii;
  }

  const linkPositionIndex: number | null = sortedWayIds[wayIdIdx].pointIdx[0];
  const distanceInLink: number | null = carpos.distanceBackward;

  if (linkPositionIndex < 0) {
    // fixme : 재탐중 linkPositionIndex가 -1로 오는 경우가 발생. 원인파악 필요.
    Logger.error("linkPositionIndex out of range", linkPositionIndex);
    return null;
  }

  // guidance index 체크
  let guidanceIndex = null;
  const tempGuideIndex: number = prevGuidanceIdx || 0;

  if (
    sortedWayIds[wayIdIdx].pointIdx[0] >= guidance[tempGuideIndex].startInterval &&
    sortedWayIds[wayIdIdx].pointIdx[1] <= guidance[tempGuideIndex].endInterval
  ) {
    guidanceIndex = tempGuideIndex; // 현재 안내 인덱스 유지.
  } else if (sortedWayIds[wayIdIdx].pointIdx[1] <= guidance[tempGuideIndex].startInterval) {
    // 이전 링크보다 앞에 있는 경우
    let offset = 1;
    // 이전것 5개까지 정도 찾아본다.
    while (
      offset < 6 &&
      sortedWayIds[wayIdIdx].pointIdx[1] <= guidance[tempGuideIndex - offset].startInterval
    ) {
      offset += 1;
    }

    if (
      sortedWayIds[wayIdIdx].pointIdx[0] >= guidance[tempGuideIndex - offset].startInterval &&
      sortedWayIds[wayIdIdx].pointIdx[1] <= guidance[tempGuideIndex - offset].endInterval
    ) {
      guidanceIndex = tempGuideIndex - offset;
    }
  } else if (sortedWayIds[wayIdIdx].pointIdx[0] >= guidance[tempGuideIndex].endInterval) {
    // 이전 링크보다 뒤에 있는 경우
    let offset = 1;
    // 다음것 5개까지 정도 찾아본다.
    while (
      offset < 6 &&
      sortedWayIds[wayIdIdx].pointIdx[0] >= guidance[tempGuideIndex + offset].endInterval
    ) {
      offset += 1;
    }

    if (
      sortedWayIds[wayIdIdx].pointIdx[0] >= guidance[tempGuideIndex + offset].startInterval &&
      sortedWayIds[wayIdIdx].pointIdx[1] <= guidance[tempGuideIndex + offset].endInterval
    ) {
      guidanceIndex = tempGuideIndex + offset;
    }
  }

  // 그래도 +-5개 범위 밖이면..... 찾는수밖에...
  if (guidanceIndex === null) {
    guidanceIndex = binarySearchForGuideIdxByInterval(
      guidance,
      sortedWayIds[wayIdIdx].pointIdx[0],
      sortedWayIds[wayIdIdx].pointIdx[1],
    );

    if (guidanceIndex === -1) {
      guidanceIndex = null;
    }
  }

  if (guidanceIndex === null) {
    // 못찾음!
    return null;
  }

  let distanceFromStart = distanceInLink;
  if (guidanceIndex === 0 && sortedWayIds[wayIdIdx].pointIdx[0] === 0) {
    // distanceInLink 거리는 총 링크 길이이므로, 처음 출발지에서의 길이와는 맞지 않아 안내지점거리가 마이너스 거리가 됨.
    // 주로 경부 고속도로에서 맵매칭이 튀면 발생.
    // 첫 way는 링크 진행방향 거리가 아닌 안내용 way길에서 반대방향 진행거리(남은 링크거리)를 빼도록 처리
    const startWayLength =
      length(
        lineString(
          path.points.coordinates.slice(
            sortedWayIds[wayIdIdx].pointIdx[0],
            sortedWayIds[wayIdIdx].pointIdx[1] + 1,
          ),
        ),
        {
          units: "meters",
        },
      ) * guidance[guidanceIndex].lengthRatio;
    distanceFromStart = startWayLength - carpos.distanceForward;
    if (distanceFromStart < 0) {
      // 링크 길이와도 약간의 차이가 발생할수 있어서 -0.xxxxx로 나올때가 있음.
      distanceFromStart = 0;
    }
  }

  if (guidanceIndex > 0) distanceFromStart += guidance[guidanceIndex - 1].accdist;
  if (linkPositionIndex > guidance[guidanceIndex].startInterval) {
    const line: Position[] = path.points.coordinates.slice(
      guidance[guidanceIndex].startInterval,
      linkPositionIndex + 1,
    );
    if (line.length > 1) {
      distanceFromStart +=
        length(lineString(line), { units: "meters" }) * guidance[guidanceIndex].lengthRatio;
    }
  }

  // distanceFromStart까지 나왔으면 다음/이전 거리 계산 해보기.
  while (
    guidance.length - 1 > guidanceIndex &&
    guidance[guidanceIndex].accdist - distanceFromStart < 0
  ) {
    // 가이드 거리가 음수인 경우는 건너뛰고 다음으로 넘어간다.
    // 경유지처럼 안내지점이 node가 아닌 way중간일 경우에 발생.
    guidanceIndex += 1;
  }

  // 위와 반대의 경우로, 모의 주행일때 뒤로 돌리면 이전 안내가 양수로 나올테니 그 안내를 뿌려주는게 타당할듯.
  // 일반적으로는 안나오고, 한 way내에 경유지가 2개 이상 존재할경우 해당됨.
  while (
    guidanceIndex > 0 &&
    guidance[guidanceIndex - 1].accdist - distanceFromStart >= 0 &&
    sortedWayIds[wayIdIdx].pointIdx[0] >= guidance[guidanceIndex - 1].startInterval &&
    sortedWayIds[wayIdIdx].pointIdx[1] <= guidance[guidanceIndex - 1].endInterval
  ) {
    guidanceIndex -= 1;
  }

  const retGuidePos: GuidePositionState = {
    position: carpos.carPosition,
    linkPositionIndex,
    distanceInLink,
    distanceFromStart,
    wayDetailInfo: sortedWayIds[wayIdIdx],
    guidanceIndex,
  };

  return retGuidePos;
};

export const binarySearchForPointIdx = (arr: PointIdxForGuide[], target: number): number => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (arr[mid].point_idx === target) {
      // 중복시 가장 먼저 나오는 인덱스를 리턴
      let ret = mid;
      while (ret > 0 && arr[ret - 1].point_idx === target) {
        ret -= 1;
      }

      return ret;
    }

    if (arr[mid].point_idx < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
};

export const getDirNameWithTurnInfo = (dirName: string, turnInfo: number): string => {
  let ret = dirName;

  switch (turnInfo) {
    case -98:
    case -8:
    case 8:
      ret = i18n.t("AAA U-turn", { dir: dirName });
      break;
    case -7:
      ret = i18n.t("AAA Keep left", { dir: dirName });
      break;
    case -3:
    case -1:
      ret = i18n.t("AAA Bear left", { dir: dirName });
      break;
    case -2:
      ret = i18n.t("AAA Turn left", { dir: dirName });
      break;
    case 0:
      ret = i18n.t("AAA Continue", { dir: dirName });
      break;
    case 1:
    case 3:
      ret = i18n.t("AAA Bear right", { dir: dirName });
      break;
    case 2:
      ret = i18n.t("AAA Turn right", { dir: dirName });
      break;
    case 6:
      ret = i18n.t("AAA Roundabout", { dir: dirName });
      break;
    case 7:
      ret = i18n.t("AAA Keep right", { dir: dirName });
      break;
    default:
      ret = i18n.t("AAA toward", { dir: dirName });
  }
  return ret;
};

export const getGuideSignImageUrl = (guide?: Guidance): string => {
  if (!guide) return "";
  if (guide.sign === 6) {
    // round about
    // 출구방향은 별도로 계산이 필요한데, 일단은 넘어간다.
    return `${BASE_URL}/guide_arrow/sign_${guide.sign}_${guide.subsign}.png`;
  }

  return `${BASE_URL}/guide_arrow/sign_${guide.sign}.png`;
};
