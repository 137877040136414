import { enqueueSnackbar } from "notistack";
import baseApi, { ApiResponse, ApiErrorResponse } from "../baseApi";

// 유저 세팅 정보
export interface Settings {
  language: "KO" | "EN" | "CN";
  unit: "KILOMETER" | "MILE";
  map_display: string;
  dark_mode: "ON" | "OFF" | "SYSTEM";
  debug_mode: boolean;
  volume: number;
  normal_guide: string[];
  highway_guide: string[];
  normal_safe_info: string[];
  highway_safe_info: string[];
}

export interface GetUserSettingsResponse extends Settings {}
export interface UpdateUserSettingsRequest extends Settings {}

const settingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // 개인 설정 불러오기
    getUserSettings: builder.mutation<GetUserSettingsResponse, void>({
      query: () => ({
        url: "setting",
        method: "get",
      }),
    }),
    // 개인 설정 업데이트
    updateUserSettings: builder.mutation<void, UpdateUserSettingsRequest>({
      query: (settings) => ({
        url: "setting",
        method: "post",
        body: settings,
      }),
      invalidatesTags: ["settings"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
  }),
});

export const { useGetUserSettingsMutation, useUpdateUserSettingsMutation } = settingApi;
export default settingApi;
