import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Chip,
  useTheme,
  CardActions,
  CardActionArea,
} from "@mui/material";
import RouteTwoToneIcon from "@mui/icons-material/RouteTwoTone";
import { useTranslation } from "react-i18next";
import { getFormattedDistance, getFormattedTime } from "../guide/guideUtils";
import { useAppSelector } from "../../app/hooks";
import { Path, selectRoute } from "../route/routeSlice";

interface SummaryRouteCardProps {
  path: Path;
  index: number;
  selected?: boolean;
  onButtonClick?: (index: number) => void;
  onCardClick?: (path: Path, index: number) => void;
}

const SummaryRouteCard = ({
  path,
  index,
  selected = false,
  onButtonClick,
  onCardClick,
}: SummaryRouteCardProps) => {
  const { i18n } = useTranslation();
  const { activePathIndex, activePath } = useAppSelector(selectRoute);
  const theme = useTheme();

  // 예상 도착 시간 TODO: date-fns 적용
  const arrivalTime = useMemo(() => {
    return (totalRouteTime: number) => {
      let datelocale = "en-us";
      if (i18n.language === "ko") {
        datelocale = "ko-kr";
      } else if (i18n.language === "zhtw") {
        datelocale = "zh-tw";
      }

      return new Date(Date.now() + totalRouteTime).toLocaleTimeString(datelocale, {
        hour: "2-digit",
        minute: "2-digit",
      });
    };
  }, [i18n.language]);

  // 도로명별 길이 계산
  const routeDesc = useMemo(() => {
    const roadNames = path.rousen_details?.road_names ?? [];
    const wayInfo = path.rousen_details?.way_info ?? [];

    // key: 도로명, value: 총 길이
    const roadLengths: { [key: string]: number } = {};

    let temp = 0;
    roadNames.forEach((road) => {
      // null 처리
      if (!road.text) return;
      // 도로명이 없는 도로
      if (!roadLengths[road.text]) {
        roadLengths[road.text] = 0;
      }

      wayInfo.forEach((way) => {
        // way_info 의 end_idx 가 roadname 의 last_idx 와 일치 할때까지 더함
        if (temp < way.end_idx && way.end_idx <= road.last_idx) {
          // 해당 도로명에 길이를 더함
          roadLengths[road.text] += way.length;
        }
      });
      temp = road.last_idx;
    });

    // 총 거리의 5% 이상인 도로만 필터
    const threshold = activePath ? activePath.distance * 0.05 : 0;

    // roadLengths 컬렉션에서 상위 1개 추출
    const topRoads: { [key: string]: number } = Object.entries(roadLengths)
      .filter(([, length]) => length >= threshold) // threshold 이상인 도로만 필터
      .sort((a, b) => b[1] - a[1]) // 내림차순 정렬
      .slice(0, 1) // 1개 추출
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return Object.keys(topRoads).join(", ");
  }, [path, activePath]);

  const handleCardClick = () => {
    if (onButtonClick) {
      onButtonClick(index);
    } else if (onCardClick) {
      onCardClick(path, index);
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: 260,
        boxSizing: "content-box",
        border: selected ? `2px solid ${theme.palette.error.main}` : null,
      }}
    >
      <CardActionArea onClick={handleCardClick}>
        <CardContent sx={{ p: 2 }}>
          <Box>
            {/* 남은 시간 */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" sx={{ paddingRight: 1 }}>
                {getFormattedTime(path.time)}
              </Typography>
              <Chip
                label="안내중"
                variant="outlined"
                color="error"
                sx={{ visibility: index === activePathIndex ? "visible" : "hidden" }}
              />
            </Box>

            {/* 도착 시간 */}
            <Typography variant="body1" pt={1}>
              {arrivalTime(path.time)}&nbsp;도착
            </Typography>
            {/* 경유 도로 */}
            <Typography sx={{ paddingTop: 1 }}>{routeDesc}</Typography>
            {/* 남은 거리 */}
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {getFormattedDistance(path.distance)}
            </Typography>
            {onButtonClick && (
              <Box sx={{ position: "absolute", bottom: "10px", right: "30px" }}>
                <RouteTwoToneIcon style={{ color: theme.palette.text.primary }} />
              </Box>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ padding: 0 }} />
    </Card>
  );
};

export default SummaryRouteCard;
