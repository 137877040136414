import { playBrowserTTS, isPlayingBrowserTTS, playBrowserWav } from "./ttsTestWithBrowser";
import { isAndroid, playNativeTTS, isPlayingNativeTTS } from "../geolocation/native/nativeUtils";

export interface SoundData {
  wav: string;
  tts: string[];
}

export enum TurnInfoCode {
  NET_TURN_INFO_NONE = Number.MIN_VALUE,
  NET_TURN_INFO_AT_DEST = 4,					// the destination 목적지 입니다.
  NET_TURN_INFO_AT_MID = 5,					// the via location 경유지 입니다.
  NET_TURN_INFO_TO_ROUNDABOUT_OUTER = 6,		// enter roundabout 로터리 진입입니다. dir_code에 시계방향 저장, extra에 몇번째 출구 저장, (외부링)

  /*
  NET_TURN_INFO_TO_OVERPASS,				// enter overpass road 고가차도로 가십시오
  NET_TURN_INFO_NOT_TO_OVERPASS,			// do not enter overpass road 고가 옆길로 가십시오.

  NET_TURN_INFO_TO_UNDERPASS,				// enter underpass road 지하차도로 가십시오.
  NET_TURN_INFO_NOT_TO_UNDERPASS,			// do not enter underpass road 지하 옆길로 가십시오.

  NET_TURN_INFO_TO_TUNNEL,				// enter tunnel 터널입니다.
  NET_TURN_INFO_TO_BRIDGE,				// enter bridge 다리입니다. (100m 이상의 긴 다리에만 해당 ?)
  NET_TURN_INFO_ENTER_HIGHWAY,			// enter highway 고속도로로 진입하십시오.
  NET_TURN_INFO_EXIT_HIGHWAY,				// exit highway 고속도로에서 빠져나가십시오.
  NET_TURN_INFO_ENTER_CITY_EXPRESSWAY,	// enter expressway 도시고속도로로 진입하십시오.
  NET_TURN_INFO_EXIT_CITY_EXPRESSWAY,		// exit expressway 도시고속도로를 빠져나가십시오.

  NET_TURN_INFO_GO_FAST_LANE,				// goto fast lane 고속차선(fast lane)으로 이동하십시오.
  NET_TURN_INFO_GO_SLOW_LANE,				// goto slow lane 저속차선(slow lane)으로 이동하십시오.

  NET_TURN_INFO_TO_TOLL,					// the tollgate / etc 요금소 입니다.
  NET_TURN_INFO_TO_FERRY,					// the ferry terminal 선착장(페리도로) 입니다.

  NET_TURN_INFO_TO_ROUNDABOUT_INNER,			// enter inner roundabout 로터리 진입입니다. dir_code에 시계방향 저장, extra에 몇번째 출구 저장, (내부링, KOR에서는 사용안함)

  NET_TURN_INFO_TO_IC,					// (not used) EXIT_HIGHWAY 대신에, xxx방면으로 우측/좌측입니다. (우측/좌측은 info_dir로 처리함)
  NET_TURN_INFO_TO_JC,					// (not used) EXIT_HIGHWAY 대신에, xxx방면으로 우측/좌측입니다. (우측/좌측은 info_dir로 처리함)

  NET_TURN_INFO_HIGHWAY_INFO,				// highway information, dir_code = NET_TURN_DIR_KEEP_GO_12
  NET_TURN_INFO_ETC,						// etc information (didn't play voice guidance)
  NET_TURN_INFO_SPEED_CAM,				// speed cam information (didn't play voice guidance)

  NET_TURN_INFO_TO_FERRY_IN,				// ferry in  페리항로 진입
  NET_TURN_INFO_TO_FERRY_OUT,				// ferry out 페리항로 진출

  NET_TURN_INFO_MAX,
  */
}

export enum TurnDirCode {
  NET_TURN_DIR_NONE = Number.MIN_VALUE,
  NET_TURN_DIR_RTURN = 2,		  	// turn right    우회전
  NET_TURN_DIR_LTURN = -2,			// turn left     좌회전
  NET_TURN_DIR_UTURN = -98,			// take uturn    유턴 (좌측으로)
  NET_TURN_DIR_RIGHT_UTURN = 98,	// take right uturn 우측으로 유턴
  NET_TURN_DIR_KEEP_GO_12 = 0,	// keep go straight 직진차선(NET_TURN_DIR_GO_12)을 유지 또는 촤측차선 이용(NET_TURN_DIR_LEFT_LANE)
  // entry가 있을 경우에 안내 정보를 생성함.

  NET_TURN_DIR_GO_12 = 0,		  	// go straight 직진 방향
  NET_TURN_DIR_GO_1 = 1,		  	// go right 1 o'clock direction 오른쪽 1시 방향
  NET_TURN_DIR_GO_2 = 1,		  	// go right 2 o'clock direction 오록쪽 2시 방향
  NET_TURN_DIR_GO_3 = 2,		  	// go right 3 o'clock direction 오른쪽 3시 방향
  NET_TURN_DIR_GO_4 = 3,		  	// go right 4 o'clock direction 오른쪽 4시 방향
  NET_TURN_DIR_GO_5 = 3,		  	// go right 5 o'clock direction 오른쪽 5시 방향
  NET_TURN_DIR_GO_6 = -8,		  	// take u-turn                  6시 방향
  NET_TURN_DIR_GO_7 = -3,		  	// go left  7 o'clock direction 왼쪽   7시 방향
  NET_TURN_DIR_GO_8 = -3,		  	// go left  8 o'clock direction 왼쪽   8시 방향
  NET_TURN_DIR_GO_9 = -2,		  	// go left  9 o'clock direction 왼쪽   9시 방향
  NET_TURN_DIR_GO_10 = -1,	  	// go left 10 o'clock direction 왼쪽  10시 방향
  NET_TURN_DIR_GO_11 = -1,	  	// go left 11 o'clock direction 왼쪽  11시 방향


  NET_TURN_DIR_LEFT_SIDE = -1,		// take left side road  왼쪽 방향
  NET_TURN_DIR_RIGHT_SIDE = 1,	// take right side road 오른쪽 방향

  NET_TURN_DIR_LEFT_LANE = -7,		// take left lane  왼쪽 차선을 이용
  NET_TURN_DIR_RIGHT_LANE = 7,	// take right lane 오른쪽 차선을 이용

  /*
  NET_TURN_DIR_PTURN,			// p-turn P턴
  NET_TURN_DIR_HOOK_LTURN,	// hook left turn  HOOK턴 / 좌회전
  NET_TURN_DIR_HOOK_RTURN,	// hook right turn HOOK턴 / 우회전
  
  NET_TURN_DIR_MAX,
  */
}

export enum WavType {
  WV_EFF_DDING = "100013",        	//	띵
  WV_EFF_SPEED_OVER_ALM = "speedover",		// (file play) 속도 초과 
  WV_EFF_SPEED_END_ALM = "speedover2",		  // (file play) 카메라 종료 지점 
  WV_EFF_DDINGDDAENG = "100008",	//	띵땡

  /*
#define			WV_EFF_URGENT			1002		// (file play) 띠리링 
#define			WV_EFF_TURN				1003		// (file play) 디리링  
#if _IS_LINUX
#define			WV_EFF_LINUX			1004		// (file play) 리눅스용  
#define			WV_ZAU_KEY_SND			1005		// key sound
#endif

#define		WV_EFF_START_APPL			100000	//	찰칵
#define		WV_EFF_DINGDONG				100001	//	띵동
#define		WV_EFF_DDANG				100002	//	땅그랑
#define		WV_EFF_GIGIGING				100003	//	지지지징
#define		WV_EFF_DIDONGDAEDONG		100004	//	딩동댕동
#define		WV_EFF_BBIBIG				100005	//	삐빅
#define		WV_EFF_BBIRIRIC				100006	//	삐리릭
#define		WV_EFF_DDIRING				100007	//	띠링
#define		WV_EFF_BBING				100009	//	삐잉
#define		WV_EFF_GIGING				100010	//	지징
#define		WV_EFF_PPO					100011	// 뽀
#define		WV_EFF_DDI					100012	// 띠
#define		WV_EFF_BIRIRIC				100014  //  귀뚜라미소리
#define		WV_EFF_DINGDONG2			100015  // 띵뚱2 
#define		WV_MUTE_01 					101001	//	묵음 0.1초 
#define		WV_MUTE_02 					101002	//	묵음 0.2초 
#define		WV_MUTE_03 					101003	//	묵음 0.3초 
#define		WV_MUTE_04 					101004	//	묵음 0.4초 
#define		WV_MUTE_05 					101005	//	묵음 0.5초 
  */
}

export enum SoundType {
  STS_SOUND_ALL = 0,

  // effect sound
  STS_SOUND_EFFECT_TOUCH,					// [x] touch sound,	터치음 (not used)
  STS_SOUND_EFFECT_ATTENTION_TONE,			// [x] attention tone, 음성안내 시작시 상기음
  STS_SOUND_EFFECT_END_OF_ROUTE_PLAN,		// end of route plan, 경로탐색 완료
  STS_SOUND_EFFECT_END_OF_SPEED_CAMERA,	// end of camera, speed 카메라 통과음
  STS_SOUND_EFFECT_END_OF_CAUTION_INFO,	// [x]
  STS_SOUND_EFFECT_SPEED_OVER,			// speed over, 속도 초과음
  STS_SOUND_EFFECT_DETECT_SPEED_CAMERA,	// [x]
  STS_SOUND_EFFECT_TURN,					// [x] turn guide sound, 턴 직전 음..

  STS_SOUND_EFFECT_ALL,					// STS_SOUND_EFFECT_MAX

  // normal guidance
  STS_SOUND_TURN,						// turn guidance
  STS_SOUND_CAMERA,					// camera guidance
  STS_SOUND_ADMIN_CHANGED,			// KOR
  STS_SOUND_HIPASS_LANE,				// KOR
  STS_SOUND_OUT_OF_ROUTE,				// KOR
  STS_SOUND_AUTO_REROUTE,				// KOR
  STS_SOUND_LANE_GUIDE,				// KOR
  STS_SOUND_REMAIN_TURN_COUNT,		// KOR
  STS_SOUND_ARRIVE_DEST,				//
  STS_SOUND_ARRIVE_VIA,				//
  STS_SOUND_MAP_COLOR_CHANGED,		//
  STS_SOUND_NEAR_MYPOI,				// KOR, 주변에 등록지가 있습니다.
  STS_SOUND_REST_AREA,				// rest area
  STS_SOUND_DROWSY_SHELTER,			// KOR, 졸음쉼터
  STS_SOUND_INTERS_NAME,				// cross name
  STS_SOUND_ROAD_NAME,				// roadname on/off
  STS_SOUND_DIRECTION,				// direction name
  // STS_SOUND_TURN_COUNT_DOWN,
  // STS_SOUND_SPEED_CAMERA_COUNT_DOWN,
  STS_SOUND_NORMAL,					// 일반 안내, 안내시작, 등등
  // STS_SOUND_SPEED_OVER,				// 속도가 초과되었을 때 1회만, 내려갔다 올라가면 또한번
  STS_SOUND_CAUTION,                  // 주의구간안내 가이드

  STS_SOUND_MAX, // (engine use)
}

export enum SoundPriority {
  STS_PRIORITY_PLAY_NOW = 0,	// 카메라 통과음 처럼, 바로 출력할 것들.
  STS_PRIORITY_EFFECT_SOUND,	// 과속경고음 처럼, 효과음으로만 되어있는 것.
  STS_PRIORITY_MUTE_SOUND,    // in iOS for keep-running
  STS_PRIORITY_GUIDANCE,		// 일반안내
  STS_PRIORITY_OTHER,			// 기타 등등
};

export const IS_DIST_WITHIN = (dist: number, target: number, gap: number) => {
  return Math.abs(dist - target) < gap;
}

export const playSound = (sndType: SoundType, snd: SoundData, priority: SoundPriority) => {
  let fullstr = "";
  snd.tts.forEach(text => {
    if (fullstr.length > 0) {
      fullstr += " ";
    }
    fullstr += text;
  });
  if (isAndroid()) {
    if (snd.wav.length > 0) {
      playNativeTTS("wav", snd.wav, priority);
    }

    if (fullstr.length > 0) {
      playNativeTTS("tts", fullstr, priority);
    }

  } else {
    playBrowserWav(snd.wav);
    playBrowserTTS(fullstr);
  }
}

export const isPlayingSound = (): boolean => {
  if (isAndroid()) {
    return isPlayingNativeTTS();
  }

  return isPlayingBrowserTTS();

}
