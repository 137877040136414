import { GeoLocation } from "./geoLocationSlice";
import IGeoLocationProvider from "./interface/IGeoLocationProvider";

export default class WebGeoLocationProvider implements IGeoLocationProvider {
  getLocation(): Promise<GeoLocation> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              timestamp: position.timestamp,
              accuracy: position.coords.accuracy,
              altitude: position.coords.altitude ?? 0,
              heading: position.coords.heading ?? 0,
              altitudeAccuracy: position.coords.altitudeAccuracy,
              speed: position.coords.speed,
            });
          },
          (error) => {
            reject(error);
          },
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }
}
