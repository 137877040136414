import * as turf from '@turf/turf';
import { Place } from '../search/searchSlice';
import { GeoLocation } from '../geolocation/geoLocationSlice';

export const getRightSidePoint = (lat: number, lon: number, heading: number, distance: number): {newLat: number, newLon: number} => {
  const point = turf.point([lon, lat]);
  const rightSideAngle = (heading + 90) % 360;

  const destination = turf.destination(point, distance, rightSideAngle, { units: "meters" });
  const [newLon, newLat] = destination.geometry.coordinates;

  return { newLat, newLon };
}

export const getReRouteStartPoint = (carPosition: GeoLocation | null) => {
  if (!carPosition) return null;

  const { newLat, newLon } = getRightSidePoint(carPosition.latitude, carPosition.longitude, carPosition.heading, 1);
  const place: Place = {
    name: "현위치",
    point: {
      lng: newLon,
      lat: newLat,
    },
    id: "",
    type: "",
    roadAddress: "",
    jibunAddress: "",
    distance: 0
  };
  
  return place;
}