import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectGuide } from "../guide/guideSlice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { selectDebug } from "../debug/debugSlice";
import { setPlayedSound, resetPlayData, selectSound, type PlaySoundType } from "./soundSlice";
import { TurnDirCode, TurnInfoCode, IS_DIST_WITHIN, type SoundData, WavType, playSound, SoundType, SoundPriority } from "./soundUtils";
import * as TTSText from "./TTSText";

// 일반도로의 경우 1.5km > 700m > 300m > soon
// 고속도로의 경우 2.0km > 1.0km > 500m > soon
const SND_PLAYED_FLAG_0 = 0;// 0x0001;	// soon	/ soon
const SND_PLAYED_FLAG_1 = 1;// 0x0002;	// 300m / 500m
const SND_PLAYED_FLAG_2 = 2; // 0x0004;	// 700m / 1km
const SND_PLAYED_FLAG_3 = 3; // 0x0008;	// 1.5km / 2km
const SND_PLAYED_FLAG_4 = 4; // 0x0010;	// 다음안래까지 쭉~~

const TTS_LANG = 'ko';  // 일단은 한국어만 지원하도록 처리.

const useGuideSound = () => {
  enum SNDOK {
    IS_SND_OK_TURN = 1,
    IS_SND_OK_DEST = 2,
    IS_SND_OK_GO_STRAIGHT = 3,
  }

  const isMuted: boolean = false;
  const useAttentionTone: boolean = true;
  const DEF_SND_DIST_DIFF: number = 20;

  const dispatch = useAppDispatch();
  const { played } = useAppSelector(selectSound);
  const { position, wayDetailInfo, guidanceIndex } = useAppSelector(selectGuidePosition);
  const { guidances } = useAppSelector(selectGuide);
  const { mode } = useAppSelector(selectDebug);

  useEffect(() => {
    const makeDistanceSoundCode = (dist: number, turnCode: number): { distCode: string, sndDist: number } => {
      const isTurnSoundAndDoSomething = !(turnCode === TurnInfoCode.NET_TURN_INFO_AT_DEST || turnCode === TurnInfoCode.NET_TURN_INFO_AT_MID);
      let sndDist: number = 0;
      let distCode: string = "";
      if (dist <= 100) // 10m 단위로 정리함.
      {
        sndDist = ((dist + 5) / 10) * 10;
        switch (sndDist) {
          case 10: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_10M : TTSText.WV_D2_10M), TTS_LANG); break;
          case 20: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_20M : TTSText.WV_D2_20M), TTS_LANG); break;
          case 30: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_30M : TTSText.WV_D2_30M), TTS_LANG); break;
          case 40: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_40M : TTSText.WV_D2_40M), TTS_LANG); break;
          case 50: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_50M : TTSText.WV_D2_50M), TTS_LANG); break;
          case 60: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_60M : TTSText.WV_D2_60M), TTS_LANG); break;
          case 70: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_70M : TTSText.WV_D2_70M), TTS_LANG); break;
          case 80: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_80M : TTSText.WV_D2_80M), TTS_LANG); break;
          case 90: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_90M : TTSText.WV_D2_90M), TTS_LANG); break;
          case 100: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_100M : TTSText.WV_D2_100M), TTS_LANG); break;
          default:
        };

      }
      else if (dist <= 200) {
        sndDist = ((dist + 10) / 20) * 20; // 20m 단위로 정리함.
        switch (sndDist) {
          case 100: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_100M : TTSText.WV_D2_100M), TTS_LANG); break;
          case 120: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_120M : TTSText.WV_D2_120M), TTS_LANG); break;
          case 140: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_140M : TTSText.WV_D2_140M), TTS_LANG); break;
          case 160: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_160M : TTSText.WV_D2_160M), TTS_LANG); break;
          case 180: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_180M : TTSText.WV_D2_180M), TTS_LANG); break;
          case 200: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_200M : TTSText.WV_D2_200M), TTS_LANG); break;
          default:
        }
      }
      else if (dist <= 1000) {
        sndDist = ((dist + 25) / 50) * 50; // 50m 단위로 정리함.
        switch (sndDist) {
          case 100: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_100M : TTSText.WV_D2_100M), TTS_LANG); break;
          case 150: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_150M : TTSText.WV_D2_150M), TTS_LANG); break;
          case 200: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_200M : TTSText.WV_D2_200M), TTS_LANG); break;
          case 250: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_250M : TTSText.WV_D2_250M), TTS_LANG); break;
          case 300: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_300M : TTSText.WV_D2_300M), TTS_LANG); break;
          case 350: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_350M : TTSText.WV_D2_350M), TTS_LANG); break;
          case 400: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_400M : TTSText.WV_D2_400M), TTS_LANG); break;
          case 450: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_450M : TTSText.WV_D2_450M), TTS_LANG); break;
          case 500: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_500M : TTSText.WV_D2_500M), TTS_LANG); break;
          case 550: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_550M : TTSText.WV_D2_550M), TTS_LANG); break;
          case 600: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_600M : TTSText.WV_D2_600M), TTS_LANG); break;
          case 650: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_650M : TTSText.WV_D2_650M), TTS_LANG); break;
          case 700: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_700M : TTSText.WV_D2_700M), TTS_LANG); break;
          case 750: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_750M : TTSText.WV_D2_750M), TTS_LANG); break;
          case 800: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_800M : TTSText.WV_D2_800M), TTS_LANG); break;
          case 850: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_850M : TTSText.WV_D2_850M), TTS_LANG); break;
          case 900: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_900M : TTSText.WV_D2_900M), TTS_LANG); break;
          case 950: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_950M : TTSText.WV_D2_950M), TTS_LANG); break;
          case 1000: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1KM : TTSText.WV_D2_1KM), TTS_LANG); break;
          default:
        }
      }
      else {
        sndDist = ((dist + 50) / 100) * 100; // 100m 단위로 정리함.
        switch (sndDist) {
          case 1000: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1KM : TTSText.WV_D2_1KM), TTS_LANG); break;
          case 1100: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_1KM : TTSText.WV_D2_1_1KM), TTS_LANG); break;
          case 1200: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_2KM : TTSText.WV_D2_1_2KM), TTS_LANG); break;
          case 1300: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_3KM : TTSText.WV_D2_1_3KM), TTS_LANG); break;
          case 1400: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_5KM : TTSText.WV_D2_1_5KM), TTS_LANG); break;
          case 1500: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_5KM : TTSText.WV_D2_1_5KM), TTS_LANG); break;
          case 1600: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_6KM : TTSText.WV_D2_1_6KM), TTS_LANG); break;
          case 1700: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_7KM : TTSText.WV_D2_1_7KM), TTS_LANG); break;
          case 1800: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_8KM : TTSText.WV_D2_1_8KM), TTS_LANG); break;
          case 1900: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_1_9KM : TTSText.WV_D2_1_9KM), TTS_LANG); break;
          case 2000: distCode = TTSText.getTTSString((isTurnSoundAndDoSomething ? TTSText.WV_D_2KM : TTSText.WV_D2_2KM), TTS_LANG); break;
          default:
        }
      }

      /*
      if (distCode === "" && sndDist > 0 && sndDist < 2000) {
        console.error("Make Distance Wave Code: ", sndDist, " no wave code");

      }
      */
      return { distCode, sndDist };

    }
    const addRemainDistSndCode = (roadClass: string, remainDist: number, snd: SoundData, turnCode: number, distanceCheckGap: number, carSpeed: number, _playedCurrent: PlaySoundType): { isSndOk: number, guideDistStage: number, isSoonSound: boolean, playedCurrent: PlaySoundType } => {

      const playedCurrent = JSON.parse(JSON.stringify(_playedCurrent));
      // return {isSndOk:number, guideDistStage:numner, isSoonSound:number}
      let isSndOk: number = 0;
      let guideDistStage: number = 0;
      let isSoonSound: boolean = false;

      const isDestSound = (turnCode === TurnInfoCode.NET_TURN_INFO_AT_DEST || turnCode === TurnInfoCode.NET_TURN_INFO_AT_MID);

      // 잠시후 안내에 대한 정의
      // 주행속도  30km/h 이하의 경우  50m에서 잠시후
      // 주행속도 100km/h 이상의 경우 120m에서 잠시후
      let soonDist: number = 50;
      if (carSpeed > 100) { soonDist = 120; }
      else if (carSpeed > 30) {
        // 목적지 / 경유지 도착의 경우 거리를 조금 더 넣어준다.
        // 이유는 "목적지 주변에 도착했습니다" -> 이 후 터치다운이 바로 되기 때문에 안내를 조금 당겨주는 센스.
        if (isDestSound) {
          soonDist = 70 + (120 - 70) * (carSpeed - 30) / (100 - 30);
        } else {
          soonDist = 50 + (120 - 50) * (carSpeed - 30) / (100 - 30);
        }
      }
      if (soonDist < 0) { soonDist = 0; }
      else if (soonDist > 149) { soonDist = 149; }

      let maxGuideDist: number = 1500;
      if (roadClass === "highway" || roadClass === "urban_expressway") {
        maxGuideDist = 2000;
        if (remainDist < soonDist) // soon
        {
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_0]) === false) {
            playedCurrent.played_flag = [true, true, true, true, true];
            if (isDestSound === false) snd.tts.push(TTSText.getTTSString(TTSText.WV_D_SOON, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 1;
            isSoonSound = true;
          }
        }
        else if (remainDist < (500 - distanceCheckGap)) {
          // skip
        }
        else if (remainDist < (500 + distanceCheckGap)) {
          // 500m 안내하고,
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_1]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], true, true, true, true];
            snd.tts.push(TTSText.getTTSString(isDestSound ? TTSText.WV_D2_500M : TTSText.WV_D_500M, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 2;
          }
        }
        else if (remainDist < (1000 - distanceCheckGap)) {
          // skip
        }
        else if (remainDist < (1000 + distanceCheckGap)) {
          // 1km 안내하고,
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_2]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], true, true, true];
            snd.tts.push(TTSText.getTTSString(isDestSound ? TTSText.WV_D2_1KM : TTSText.WV_D_1KM, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 3;
          }
        }
        else if (remainDist < (2000 - distanceCheckGap)) {
          // skip
        }
        else if (remainDist < (2000 + distanceCheckGap)) {
          // 2km 안내하고,
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_3]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], playedCurrent.played_flag[2], true, true];
            snd.tts.push(TTSText.getTTSString(isDestSound ? TTSText.WV_D2_2KM : TTSText.WV_D_2KM, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 4;
          }
        }
        else // 매우 크면 직인 안내하고.
        {
          // eslint-disable-next-line no-lonely-if
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_4]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], playedCurrent.played_flag[2], playedCurrent.played_flag[3], true];
          }
        }
      } else {   // 일반도로 주행
        maxGuideDist = 1500;
        if (remainDist < soonDist) // soon
        {
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_0]) === false) {
            playedCurrent.played_flag = [true, true, true, true, true];
            if (isDestSound === false) snd.tts.push(TTSText.getTTSString(TTSText.WV_D_SOON, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 1;
            isSoonSound = true;
          }
        }
        else if (remainDist < (300 - distanceCheckGap)) {
          // skip
        }
        else if (remainDist < (300 + distanceCheckGap)) {
          // 300m 안내하고,
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_1]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], true, true, true, true];
            snd.tts.push(TTSText.getTTSString(isDestSound ? TTSText.WV_D2_300M : TTSText.WV_D_300M, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 2;
          }
        }
        else if (remainDist < (700 - distanceCheckGap)) {
          // skip
        }
        else if (remainDist < (700 + distanceCheckGap)) {
          // 700km 안내하고,
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_2]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], true, true, true];
            snd.tts.push(TTSText.getTTSString(isDestSound ? TTSText.WV_D2_700M : TTSText.WV_D_700M, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 3;
          }
        }
        else if (remainDist < (1500 - distanceCheckGap)) {
          // skip
        }
        else if (remainDist < (1500 + distanceCheckGap)) {
          // 1.5km 안내하고,
          if ((playedCurrent.played_flag[SND_PLAYED_FLAG_3]) === false) {
            playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], playedCurrent.played_flag[2], true, true];
            snd.tts.push(TTSText.getTTSString(isDestSound ? TTSText.WV_D2_1_5KM : TTSText.WV_D_1_5KM, TTS_LANG));
            isSndOk = SNDOK.IS_SND_OK_TURN;
            guideDistStage = 4;
          }
        }
      }

      if (isSndOk === 0 && playedCurrent.played_flag[0] === false && playedCurrent.played_flag[1] === false && playedCurrent.played_flag[2] === false && playedCurrent.played_flag[3] === false && playedCurrent.played_flag[4] === false ) {
        // 처음 안내하는 경우임.
        if (remainDist > 60 && remainDist < maxGuideDist) {
          const distWaveCode = makeDistanceSoundCode(remainDist, turnCode); // 거리를 조합하여 만든다.
          if (distWaveCode.distCode !== "") {
            snd.tts.push(distWaveCode.distCode);
            isSndOk = SNDOK.IS_SND_OK_TURN;

            // 안내하는 거리가 기준 거리와 유사한 경우에는 기준 거리안내를 하지 않도록 처리한다.
            if (Math.abs(distWaveCode.sndDist - 1500) < 200)
              playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], playedCurrent.played_flag[2], true, true];
            else if (Math.abs(distWaveCode.sndDist - 700) < 200)
              playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], true, true, true];
            else if (distWaveCode.sndDist < 350)
              playedCurrent.played_flag = [playedCurrent.played_flag[0], true, true, true, true];
            else
              playedCurrent.played_flag = [playedCurrent.played_flag[0], playedCurrent.played_flag[1], playedCurrent.played_flag[2], playedCurrent.played_flag[3], true]; // 반복해서 들어오는 것을 막기 위해서
          }
          else {
            // isSndOk = remainDist < 2000 ? SNDOK.IS_SND_OK_TURN:_IS_SND_OK_GO_STRAIGHT;

          }
        }

        // 매우 크면 직인 안내하고.
        //			if ((m_pplayed.played_flag&SND_PLAYED_FLAG_4) == 0)
        //			{
        //				m_pplayed.played_flag |= (SND_PLAYED_FLAG_4);
        //			}
      }

      // Add a return statement here
      return { isSndOk, guideDistStage, isSoonSound, playedCurrent };
    }

    const getDirWaveCode = (turnCode: number, isRoundabout: boolean): string => {
      let wavCode = "";

      if (isRoundabout) {
        switch (turnCode) {
          case TurnDirCode.NET_TURN_DIR_RTURN:
          case TurnDirCode.NET_TURN_DIR_GO_3:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_R03_DIR, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_LTURN:
          case TurnDirCode.NET_TURN_DIR_GO_9:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_L09_DIR, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_UTURN:
          case TurnDirCode.NET_TURN_DIR_GO_6:
            wavCode = TTSText.getTTSString(TTSText.WV_R_U_TURN, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_RIGHT_UTURN:
            wavCode = TTSText.getTTSString(TTSText.WV_R_U_TURN, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_KEEP_GO_12:
          case TurnDirCode.NET_TURN_DIR_GO_12:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_12_DIR, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_GO_1:
          case TurnDirCode.NET_TURN_DIR_GO_2:
          case TurnDirCode.NET_TURN_DIR_RIGHT_SIDE:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_R02_DIR, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_GO_4:
          case TurnDirCode.NET_TURN_DIR_GO_5:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_R05_DIR, TTS_LANG);
            break;

          case TurnDirCode.NET_TURN_DIR_GO_7:
          case TurnDirCode.NET_TURN_DIR_GO_8:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_L07_DIR, TTS_LANG);
            break;

          case TurnDirCode.NET_TURN_DIR_GO_10:
          case TurnDirCode.NET_TURN_DIR_GO_11:
          case TurnDirCode.NET_TURN_DIR_LEFT_SIDE:
            wavCode = TTSText.getTTSString(TTSText.WV_R_L11, TTS_LANG);
            break;

          case TurnDirCode.NET_TURN_DIR_LEFT_LANE:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_LEFT_LANE, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_RIGHT_LANE:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_RIGHT_LANE, TTS_LANG);
            break;
          default:
        }
      } else {
        switch (turnCode) {
          case TurnDirCode.NET_TURN_DIR_RTURN:
          case TurnDirCode.NET_TURN_DIR_GO_3:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_R_TURN, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_LTURN:
          case TurnDirCode.NET_TURN_DIR_GO_9:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_L_TURN, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_UTURN:
          case TurnDirCode.NET_TURN_DIR_GO_6:
            wavCode = TTSText.getTTSString(TTSText.WV_R_U_TURN, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_RIGHT_UTURN:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_R_TURN, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_KEEP_GO_12:
          case TurnDirCode.NET_TURN_DIR_GO_12:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_12_DIR, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_GO_1:
          case TurnDirCode.NET_TURN_DIR_GO_2:
          case TurnDirCode.NET_TURN_DIR_RIGHT_SIDE:
            wavCode = TTSText.getTTSString(TTSText.WV_R_R, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_GO_4:
          case TurnDirCode.NET_TURN_DIR_GO_5:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_R05_TURN, TTS_LANG);
            break;

          case TurnDirCode.NET_TURN_DIR_GO_7:
          case TurnDirCode.NET_TURN_DIR_GO_8:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_L07_TURN, TTS_LANG);
            break;

          case TurnDirCode.NET_TURN_DIR_GO_10:
          case TurnDirCode.NET_TURN_DIR_GO_11:
          case TurnDirCode.NET_TURN_DIR_LEFT_SIDE:
            wavCode = TTSText.getTTSString(TTSText.WV_R_L, TTS_LANG);
            break;

          case TurnDirCode.NET_TURN_DIR_LEFT_LANE:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_LEFT_LANE, TTS_LANG);
            break;
          case TurnDirCode.NET_TURN_DIR_RIGHT_LANE:
            wavCode = TTSText.getTTSString(TTSText.WV_ETC_RIGHT_LANE, TTS_LANG);
            break;
          default:
        }
      }

      return wavCode;
    }

    const getInfoWaveCode = (infoCode: number, isSoonSound: boolean): string => {
      let wavCode = "";
      switch (infoCode) {
        case TurnInfoCode.NET_TURN_INFO_NONE:
          break;
        case TurnInfoCode.NET_TURN_INFO_AT_DEST:
          wavCode = TTSText.getTTSString(TTSText.WV_ETC_AT_DEST, TTS_LANG);
          break;
        case TurnInfoCode.NET_TURN_INFO_AT_MID:
          wavCode = TTSText.getTTSString(TTSText.WV_ETC_AT_MID, TTS_LANG);
          break;
        case TurnInfoCode.NET_TURN_INFO_TO_ROUNDABOUT_OUTER:
          wavCode = TTSText.getTTSString(TTSText.WV_FR_ROTARY_OUTSIDE, TTS_LANG);
          break;
        default:
      }

      // 목적지 / 경유지의 경우
      // SOON = 목적지 주변이 도착했습니다.
      // 1.5km ~ SOON = 목적지가 있습니다.
      if (infoCode === TurnInfoCode.NET_TURN_INFO_AT_DEST || infoCode === TurnInfoCode.NET_TURN_INFO_AT_MID) {
        if (isSoonSound === false) {  // SOON이 아닌 경우에는, xxxm앞에 목적지가 있습니다.
          wavCode = TTSText.getTTSString(infoCode === TurnInfoCode.NET_TURN_INFO_AT_DEST ? TTSText.WV_ETC_IT_IS_DEST : TTSText.WV_ETC_IT_IS_MID, TTS_LANG);
        } else {  // SOON인 경우에는 목적지 주변에 도착했습니다.
          wavCode = TTSText.getTTSString(infoCode === TurnInfoCode.NET_TURN_INFO_AT_DEST ? TTSText.WV_ETC_NEAR_DEST : TTSText.WV_ETC_NEAR_MID, TTS_LANG);
        }
      }

      return wavCode;
    }


    if (position === null) {
      return;
    }

    if (guidances === null || guidances.length === 0) {
      return;
    }

    if (guidanceIndex === null)
      return;

    let isSimulmode = false;
    let playedCurrent = JSON.parse(JSON.stringify((mode === "route" && played.length >= 1) ? played[1] : played[0]));

    if (mode === "route" && played.length >= 1) {
      isSimulmode = true;
    } else {
      // 실주행 중 guide mute 상태이면 TTS를 출력하지 않는다.
      // eslint-disable-next-line no-lonely-if
      if (isMuted) {
        // eslint-disable-next-line no-useless-return
        return;
      }
    }

    if (guidances.length <= guidanceIndex || guidanceIndex < 0) {
      return;
    }
    
    const sndIdx = guidances.length - guidanceIndex - 1;
    const guideCurrent = guidances[guidanceIndex];

    if (guideCurrent === null || guideCurrent === undefined) {
      return;
    }

    if (playedCurrent.played_snd_idx !== sndIdx) {
      dispatch(resetPlayData(isSimulmode ? 1 : 0));
      let sec = 3;
      if (playedCurrent.pause_play_until_tick === 0 && guideCurrent.guidedist < 150) { sec = 2; }
      playedCurrent.pause_play_until_tick = new Date().getTime() + sec * 1000;
      playedCurrent.played_snd_idx = sndIdx;
//      dispatch(setPlayedSound({ played: playedCurrent, idx: isSimulmode ? 1 : 0 }));

    } else {
      playedCurrent.pause_play_until_tick = 0;
//      dispatch(setPlayedSound({ played: playedCurrent, idx: isSimulmode ? 1 : 0 }));
    }

    const snd: SoundData = { wav: "", tts: [] };
    if (useAttentionTone) {
      snd.wav = WavType.WV_EFF_DDING;
    }

    let remainDist = guideCurrent.guidedist;
    remainDist -= DEF_SND_DIST_DIFF;

    if (remainDist < 0) { remainDist = 0; }

    const distanceCheckGap = (wayDetailInfo?.roadClass === "highway" ? 75 : 30);
    let isSndOK = 0; 	// 1:sound turn, 2:dest, 3:go straight
    const isHighwayOrExpressway: boolean = wayDetailInfo?.roadClass === "highway" || wayDetailInfo?.roadClass === "urban_expressway";


    if (guideCurrent.sign === TurnInfoCode.NET_TURN_INFO_AT_DEST || guideCurrent.sign === TurnInfoCode.NET_TURN_INFO_AT_MID) {
      if (IS_DIST_WITHIN(remainDist, 200, distanceCheckGap)) {
        if ((playedCurrent.played_flag[SND_PLAYED_FLAG_1]) === false) {
          playedCurrent.played_flag = [playedCurrent.played_flag[0], true, true, true, true];
          dispatch(setPlayedSound({ played: playedCurrent, idx: isSimulmode ? 1 : 0 }));
          isSndOK = SNDOK.IS_SND_OK_TURN;

          if (guideCurrent.sign === TurnInfoCode.NET_TURN_INFO_AT_DEST) {
            snd.tts.push(TTSText.getTTSString(TTSText.WV_ETC_200_AT_DEST, TTS_LANG));
            playSound(SoundType.STS_SOUND_TURN, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
            return;
          }

          snd.tts.push(TTSText.getTTSString(TTSText.WV_ETC_NEAR_MID, TTS_LANG));

        }
      }
    }

    if (isSndOK !== 0) {
      playSound(SoundType.STS_SOUND_TURN, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
      return;
    }

    // ////////////////////////////////////////////////////////////////////////
    // 이 아래는 모두 남은 거리 안내가 필요함.

    // 고속도로/고속화도로 일때 1: soon, 2: 500m, 3: 1km, 4: 2km
    // 일반도로 일때 1: soon, 2: 300m, 3: 700m, 4: 1.5km
    const isFirstSound = (playedCurrent.played_flag[0] === false && playedCurrent.played_flag[1] === false && playedCurrent.played_flag[2] === false && playedCurrent.played_flag[3] === false && playedCurrent.played_flag[4] === false) // 최초 음성안내인 경우 1
    const { isSndOk, guideDistStage, isSoonSound, playedCurrent:playedCurrent2 } = addRemainDistSndCode(wayDetailInfo?.roadClass || "", guideCurrent.guidedist, snd, guideCurrent.sign, distanceCheckGap, (position.speed || 0), playedCurrent);
    playedCurrent = playedCurrent2;
    isSndOK = isSndOk;
    const isRoundabout = (/* guideCurrent.sign === TurnInfoCode.NET_TURN_INFO_TO_ROUNDABOUT_INNER || */ guideCurrent.sign === TurnInfoCode.NET_TURN_INFO_TO_ROUNDABOUT_OUTER);

    // 방면 안내 정보 : TODO 현재 방면 정보 없음
    const dirStr: string = (TTS_LANG === "ko") ? guideCurrent.dirname||"" : "";

    if (isSndOK === SNDOK.IS_SND_OK_TURN) {
      const dirWavCode = getDirWaveCode(!isRoundabout ? guideCurrent.sign : guideCurrent.subsign, isRoundabout);
      const infoWavCode = getInfoWaveCode(guideCurrent.sign, isSoonSound);

        /* TODO : 현재 안내정보에 고속도로 진출입정보 없어서 처리 불가.
        if (guideCurrent.sign == NET_TURN_INFO_ENTER_HIGHWAY)
        {
          // 고속도로 진입인 경우
          if (dirWavCode)
          {
            // 방면 안내 추가
            if (dir_cnt > 0 && (isFirstSound || guideDistStage != 1)) // 잠시후 안내에서는 방면 안내를 하지 않는다. 단, 최초 안내시에는 한다.
            {
              snd.add(WV_TTS_STR1, dir_str);
              snd.add(WV_TTS_STR1, _T(" 방면 "));
            }
    
            // 고속도로 입구 진입 시 우회전, 좌회전에 대한 TTS 코드 추가
            if (guideCurrent.sign == NET_TURN_DIR_RTURN)
              snd.add(WV_TTS_STR1, _T("고속도로 입구로 우회전 하십시오  "));
            else if (guideCurrent.sign == NET_TURN_DIR_LTURN)
              snd.add(WV_TTS_STR1, _T("고속도로 입구로 좌회전 하십시오  "));
            else
              snd.add(dirWavCode);
          }
          else
          {
            // 방면 안내 추가
            if (dir_cnt > 0 && (isFirstSound || guideDistStage != 1)) // 잠시후 안내에서는 방면 안내를 하지 않는다. 단, 최초 안내시에는 한다.
            {
              snd.add(WV_TTS_STR1, dir_str);
              snd.add(WV_TTS_STR1, _T(" 방면 "));
            }
    
            snd.add(infoWavCode);
          }
        }
        */

        /* TODO : 현재 페리 진출입 정보 없어서 처리 불가.
        else if (guideCurrent.sign == NET_TURN_INFO_TO_FERRY_IN ||guideCurrent.sign ==  NET_TURN_INFO_TO_FERRY_OUT)
        {
                // 페리 node_name 안내 정보
                TCHAR ferry_dir_str[512]={0,};
                int ferry_dir_cnt = get_ferry_direction_string(&m_current.guide_info.turn[0], ferry_dir_str, countof(ferry_dir_str)-1);
    
          // 페리 진출입시 node_name에 대한 TTS 코드 추가
          snd.add(WV_TTS_STR1, ferry_dir_str);
          snd.add(WV_TTS_STR1, _T("입니다"));
    
        }
        */

        /* TODO : 현재 안내정보에 고속도로 진출입정보 없어서 처리 불가.
        else if (guideCurrent.sign == NET_TURN_INFO_EXIT_HIGHWAY)
        {
          // 고속도로 진출인 경우
          if (infoWavCode)
          {
            // 방면 안내 추가
            if (dir_cnt > 0 && (isFirstSound || guideDistStage != 1)) // 잠시후 안내에서는 방면 안내를 하지 않는다. 단, 최초 안내시에는 한다.
            {
              snd.add(WV_TTS_STR1, dir_str);
              snd.add(WV_TTS_STR1, _T(" 방면 "));
            }
    
            // WV_IC_EXIT_HIGHWAY 고속도로 출구로 진입하십시오
            snd.add(infoWavCode);
          }
        }
        */

        /* TODO : 현재 안내정보에 톨게이트 정보가 없어서 처리 불가
        else if (guideCurrent.sign == NET_TURN_INFO_TO_TOLL)
        {
    
                LOGD("NET_TURN_INFO_TO_TOLL _current->link_id=%d", _current->link_id);
    
    
                // 톨게이트 안내
          snd.add(WV_POI_TOLL); // 요금소가
          snd.add(WV_ETC_IS); // 있습니다.
    
    
          // 요금 안내
          int toll_charge = (int)(curr_snd->part_toll_charge+.5);
          if (toll_charge > 0)
          {
            add_toll_charge_snd_code(toll_charge, snd);
          }
    
          // 하이패스 안내
          if (guideDistStage != 1) // 잠시후 안내일때는 하이패스 차선 안내를 하지 않는다.
          {
            dgserver& dgsvr = dgs_engine();
            if (dgsvr.m_opt.use_lane_voice_guide)
            {
              HipassGuideManager* pGM = (HipassGuideManager*)GuideService::Instance()->GM(GS_HIPASS_SERVICE);
              pGM->PlayHipassLaneGuide(snd);
            }
          }
        }
        else */ if (guideCurrent.sign !== Number.MIN_VALUE && dirWavCode) {
        // dir_code 에 따른 방향 안내

        // 방면 안내 추가
        if (dirStr.length > 0 && (isFirstSound || guideDistStage !== 1)) // 잠시후 안내에서는 방면 안내를 하지 않는다. 단, 최초 안내시에는 한다.
        {
          snd.tts.push(dirStr);
          snd.tts.push(" 방면으로 "); // fixme : KT에서는 " 방면으로 "가 하드코딩되어 있는데, 나중에 다국어 지원되도록 수정 필요.
        }

        // 루센온라인에서는 rotary안내가 IN/OUT이 구분이 안되기 때문에, 예전 구형 제품처럼 "로터리에서"를 추가해줌.
        if (guideCurrent.sign === TurnInfoCode.NET_TURN_INFO_TO_ROUNDABOUT_OUTER) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_FR_ROTARY2, TTS_LANG));
        }
        
        snd.tts.push(dirWavCode);
      }
      else if (guideCurrent.sign !== Number.MIN_VALUE && infoWavCode) {
        // info_code 에 따른 정보 안내

        snd.tts.push(infoWavCode);
      }

      dispatch(setPlayedSound({ played: playedCurrent, idx: isSimulmode ? 1 : 0 }));
      playSound(SoundType.STS_SOUND_TURN, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guidances, guidanceIndex]);
};
export default useGuideSound;