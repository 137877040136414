import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../../app/store";
import { RouteType } from "../routeSlice";

interface State {
  isAwaitingWaypointsUpdate: boolean;
  reRouteType: RouteType;
}

const initialState: State = {
  isAwaitingWaypointsUpdate: false,
  reRouteType: RouteType.ReRoute,
};

const reRouteSlice = createSlice({
  name: "reRoute",
  initialState,
  reducers: {
    setIsAwaitingWaypointsUpdate: (state, action: PayloadAction<boolean>) => {
      state.isAwaitingWaypointsUpdate = action.payload;
    },
    setReRouteType: (state, action: PayloadAction<RouteType>) => {
      state.reRouteType = action.payload;
    },
  },
});

export const selectReRoute = (state: RootState) => state.reRoute;
export const { setIsAwaitingWaypointsUpdate, setReRouteType } = reRouteSlice.actions;
export default reRouteSlice;
