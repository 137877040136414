import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { AccountBox, Lock } from "@mui/icons-material";
import React, { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../components/LogoIcon";
import { CreateUserRequest, useCreateUserMutation } from "../features/token/userApi";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const navigate = useNavigate();
  const [createUser, { isLoading, isError }] = useCreateUserMutation();
  const [values, setValues] = useState<CreateUserRequest>({
    user_id: "",
    password: "",
    join_type: "LOCAL",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const { user_id, password } = values;

  const { t } = useTranslation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
    await createUser(values).unwrap();
    navigate("/signin");
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <Box
      py={10}
      px={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      component="form"
      onSubmit={handleSubmit}
      overflow="hidden"
    >
      <Box textAlign="center">
        <LogoIcon color="primary" sx={{ fontSize: "8rem" }} />
        <Typography variant="h5">Rousen Navigation</Typography>
      </Box>
      <Stack spacing={2}>
        <TextField
          label={t("user id")}
          name="user_id"
          value={user_id}
          onChange={handleChange}
          fullWidth
          disabled={isLoading}
          required
          error={isError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountBox />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("password")}
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          fullWidth
          disabled={isLoading}
          required
          error={isError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("verify password")}
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          fullWidth
          disabled={isLoading}
          required
          error={passwordError}
          helperText={passwordError ? "비밀번호가 일치하지 않습니다." : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" fullWidth variant="contained" size="large" disabled={isLoading}>
          회원가입
        </Button>
      </Stack>
    </Box>
  );
};

export default SignUp;
