import React, { memo, useEffect, useMemo, useState } from "react";
import { Box, BoxProps, Paper, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { SafeType, selectGuide } from "./guideSlice";
import {
  safetyExpwayDistance,
  safetyExpwayGap,
  safetyGeneralDistance,
  safetyGeneralGap,
  selectGuideSetting,
} from "./guideSettingSlice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { getFormattedDistance } from "./guideUtils";
import { mapCamera01ImgUrl, mapCamera03ImgUrl } from "../../app/config/images";

interface Props extends BoxProps {}

const CameraDistance = memo(() => {
  const { safeDrives, currentSafeIndex } = useAppSelector(selectGuide);

  const cameraDistance = useMemo(() => {
    return getFormattedDistance(safeDrives[currentSafeIndex]?.guidedist || 0);
  }, [currentSafeIndex, safeDrives]);

  return (
    <Paper
      sx={{
        bgcolor: (theme) => theme.palette.primary.main,
        textAlign: "center",
        p: 1,
        position: "absolute",
        top: "calc(100% - 20px)",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <Typography variant="h6" noWrap color="background.paper">
        {cameraDistance}
      </Typography>
    </Paper>
  );
});

const Camera = ({ ...restProps }: Props) => {
  const { safeDrives, currentSafeIndex } = useAppSelector(selectGuide);
  const { wayDetailInfo } = useAppSelector(selectGuidePosition);
  const [isShowTrafficSignByTimer, setShowTrafficSignByTimer] = useState(false);
  const { safetyGeneralDistanceCheck, safetyExpwayDistanceCheck } =
    useAppSelector(selectGuideSetting);

  // 이하 함수들은 카메라가 있어야 처리가 가능하기 때문에 임시로 더미 UI와 함께 생성
  // <<<<
  const isShowCameraInfo = useMemo(() => {
    if (safeDrives === null || safeDrives === undefined || safeDrives.length === 0) return false;

    const isHiway =
      safeDrives[currentSafeIndex].isHighway &&
      wayDetailInfo?.roadClass ===
        "highway"; /* || wayDetailInfo?.roadClass === "national_highway" */ // national_highway가 실제 고속도로인지 확인이 먼저 필요.

    let checkDistance = 0;
    if (isHiway) {
      for (let ii = 0; ii < safetyExpwayDistanceCheck.length; ii += 1) {
        if (safetyExpwayDistanceCheck[ii]) {
          checkDistance = safetyExpwayDistance[ii];
          break;
        }
      }

      if (checkDistance === 0)
        checkDistance = safetyExpwayDistance[safetyExpwayDistance.length - 1];

      checkDistance += safetyExpwayGap;
    } else {
      for (let ii = 0; ii < safetyGeneralDistanceCheck.length; ii += 1) {
        if (safetyGeneralDistanceCheck[ii]) {
          checkDistance = safetyGeneralDistance[ii];
          break;
        }
      }

      if (checkDistance === 0)
        checkDistance = safetyGeneralDistance[safetyGeneralDistance.length - 1];

      checkDistance += safetyGeneralGap;
    }

    if (
      safeDrives[currentSafeIndex].guidedist <= checkDistance &&
      safeDrives[currentSafeIndex].guidedist > 0
    )
      return true;

    return false;
  }, [
    safeDrives,
    currentSafeIndex,
    wayDetailInfo,
    safetyExpwayDistanceCheck,
    safetyGeneralDistanceCheck,
  ]);

  const cameraSpeed = useMemo(
    () => safeDrives[currentSafeIndex]?.speed,
    [currentSafeIndex, safeDrives],
  );

  const isTrafficSignCamera = useMemo(() => {
    return (
      (isShowTrafficSignByTimer &&
        safeDrives[currentSafeIndex]?.type === SafeType.SAFE_INFO_SPEED_TRAFFIC_LIGHT) ||
      safeDrives[currentSafeIndex]?.type === SafeType.SAFE_INFO_TRAFFIC_LIGHT
    );
  }, [isShowTrafficSignByTimer, safeDrives, currentSafeIndex]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowTrafficSignByTimer((prev) => !prev);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (!isShowCameraInfo) {
    return null;
  }

  return (
    <Box position="relative" {...restProps}>
      <img src={mapCamera01ImgUrl} alt="Map Camera" />
      {isTrafficSignCamera ? (
        <img
          src={mapCamera03ImgUrl}
          alt="Map Camera"
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <Typography
          variant="h3"
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "black" /* 색상 테마색으로 바꾸지 말것 */,
          }}
        >
          {cameraSpeed}
        </Typography>
      )}

      <CameraDistance />
    </Box>
  );
};

export default memo(Camera);
