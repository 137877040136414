// 하단 메뉴 팝업 - 개요
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Slide,
  DialogContent,
  useTheme,
  DialogActions,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MapLibre, { Marker, Popup } from "react-map-gl/maplibre";
import { FormatListBulleted, NearMe } from "@mui/icons-material";
import { MapLibreEvent } from "maplibre-gl";
import Slider from "react-slick";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Path, selectRoute, setActivePathIndex } from "../route/routeSlice";
import DialogTitle from "../../components/DialogTitle";
import { selectWaypoint, waypointTypeImages } from "../waypoint/waypointSlice";
import { selectCarPosition } from "../map/position/carPositionSlice";
import { selectGuide } from "../guide/guideSlice";
import SummaryGuideListDialog from "./SummaryGuideListDialog";
import SummaryRouteCard from "./SummaryRouteCard";
import { useDialog } from "../../components/Dialog";
import LineString from "../map/source/line/LineString";
import { carImgUrl } from "../../app/config/images";
import { MAP_STYLES } from "../../app/config/const";

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement }>(
  (props, ref) => <Slide direction="up" ref={ref} {...props} />,
);

interface SummaryRouteMapDialogProps {
  open: boolean;
  onClose: () => void;
}

const SummaryRouteMapDialog = ({ open, onClose }: SummaryRouteMapDialogProps) => {
  const { guideRemainInfo } = useAppSelector(selectGuide);
  const { paths, activePathIndex, activePath } = useAppSelector(selectRoute);
  const { carPosition } = useAppSelector(selectCarPosition);
  const { waypoints } = useAppSelector(selectWaypoint);

  const [selectedPathIndex, setSelectedPathIndex] = useState<number>(activePathIndex);
  const [selectedPath, setSelectedPath] = useState<Path | null>(null);
  const [mapInstance, setMapInstance] = useState<MapLibreEvent["target"] | null>(null);
  const guideListDialog = useDialog();

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleMapLoad = useCallback((event: MapLibreEvent) => {
    setMapInstance(event.target);
  }, []);

  useEffect(() => {
    setSelectedPath(paths[selectedPathIndex]);
  }, [paths, selectedPathIndex]);

  // 경로 개요 open 할때 / close 할때
  useEffect(() => {
    if (open) {
      setSelectedPathIndex(activePathIndex);
    } else {
      setSelectedPathIndex(-1);
    }
  }, [open, activePathIndex]);

  // 도로명별 길이 계산
  const getRouteDesc = (path: Path) => {
    const roadNames = path.rousen_details?.road_names ?? [];
    const wayInfo = path.rousen_details?.way_info ?? [];

    // key: 도로명, value: 총 길이
    const roadLengths: { [key: string]: number } = {};

    let temp = 0;
    roadNames.forEach((road) => {
      // null 처리
      if (!road.text) return;
      // 도로명이 없는 도로
      if (!roadLengths[road.text]) {
        roadLengths[road.text] = 0;
      }

      wayInfo.forEach((way) => {
        // way_info 의 end_idx 가 roadname 의 last_idx 와 일치 할때까지 더함
        if (temp < way.end_idx && way.end_idx <= road.last_idx) {
          // 해당 도로명에 길이를 더함
          roadLengths[road.text] += way.length;
        }
      });
      temp = road.last_idx;
    });

    // 총 거리의 5% 이상인 도로만 필터
    const threshold = paths[activePathIndex].distance * 0.05;
    const filteredRoadLengths = Object.fromEntries(
      Object.entries(roadLengths).filter(([, length]) => length >= threshold),
    );

    // roadLengths 컬렉션에서 상위 1개 추출
    const topRoad = Object.entries(filteredRoadLengths)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 1)[0];

    if (topRoad) {
      const [roadName] = topRoad;
      return `${roadName}`;
    }

    return "";
  };

  // 상세 경로 리스트 클릭 시 해당 안내 좌표로 이동
  const moveToCoordinate = (longitude: number, latitude: number) => {
    guideListDialog.onClose();
    if (mapInstance) {
      mapInstance.easeTo({
        center: [longitude, latitude],
        zoom: 19,
      });
    }
  };

  // summary 최초 진입 시 지도 정렬
  useEffect(() => {
    if (mapInstance && activePath) {
      mapInstance.setPadding({ top: 0, right: 0, left: 0, bottom: 0 });
      mapInstance.fitBounds(activePath.bbox!, {
        padding: { top: 100, right: 100, left: 100, bottom: 200 },
        duration: 2500,
        animate: false,
      });
    }
  }, [mapInstance, activePath]);

  const sliderRef = useRef<Slider>(null);
  // 다른 경로 card 클릭 시
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(selectedPathIndex);
    }
  }, [selectedPathIndex]);

  const handleClickSwitchRoute = (path: Path, index: number) => {
    setSelectedPathIndex(index);
    setSelectedPath(path);
  };

  // 경로 변경
  const handleClickChangeRoute = () => {
    dispatch(setActivePathIndex(selectedPathIndex));
    enqueueSnackbar(`${t("Route recalculation.")}`, { variant: "info" });
  };

  // 스와이프 요소 설정 코드(지속적 수정 필요)
  const swipeSetting = {
    dots: false, // 슬라이더 하단 위치 점
    infinite: false, // 무한 반복
    speed: 600, // 이동 속도
    variableWidth: true, // 가변 너비 설정
    centerMode: true, // 중앙 focus
    focusOnSelect: true, // 클릭 시 focus
    centerPadding: "10px",
    swipeToSlide: true, // 드래그 이동
  };
  //

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle onClose={onClose}>
        <Typography
          noWrap
          sx={{ flexGrow: 1, textAlign: "center", py: 3 }}
          variant="h6"
          component="div"
        >
          {t("Summary")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0, position: "relative" }}>
        <MapLibre
          attributionControl={false}
          onLoad={handleMapLoad}
          initialViewState={{
            latitude: carPosition?.latitude,
            longitude: carPosition?.longitude,
            zoom: 18,
            bearing: 0,
            pitch: 0,
            padding: { top: 0, bottom: 0, left: 0, right: 0 },
          }}
          // dragRotate={false}
          style={{ width: "100%", height: "100%" }}
          mapStyle={MAP_STYLES[theme.palette.mode]}
        >
          {paths.map((path, index) => (
            <LineString
              type="summary"
              key={path.time}
              points={path.points}
              lineWidth={15}
              layerId={`layer-${index}`}
              beforeId={index !== selectedPathIndex ? `layer-${selectedPathIndex}` : undefined}
              selected={index === selectedPathIndex}
            />
          ))}
          {/* 경로 팝업 */}
          {paths.map(
            (path, index) =>
              index === selectedPathIndex && (
                <Popup
                  key={path.time}
                  latitude={
                    path.points.coordinates[Math.floor(path.points.coordinates.length / 2)][1]
                  }
                  longitude={
                    path.points.coordinates[Math.floor(path.points.coordinates.length / 2)][0]
                  }
                  closeButton={false}
                  closeOnClick={false}
                >
                  <div>{getRouteDesc(path)}</div>
                </Popup>
              ),
          )}
          {/* 출도착지 마커 */}
          {waypoints.map(
            (waypoint) =>
              waypoint.place && (
                <Marker
                  key={waypoint.place.id}
                  latitude={waypoint.place.point.lat}
                  longitude={waypoint.place.point.lng}
                  anchor="bottom-left"
                  offset={[-3, 3]}
                >
                  <img src={waypointTypeImages[waypoint.type]} alt="마커" />
                </Marker>
              ),
          )}
          {/* 현재 위치 마커 */}
          {carPosition && (
            <Marker
              latitude={carPosition.latitude}
              longitude={carPosition.longitude}
              offset={[0, -20]}
            >
              <img src={carImgUrl} alt="Marker" width="60" height="60" />
            </Marker>
          )}
          {/* 하단 추천 경로 리스트 */}
          <Box
            display="flex"
            flexDirection="column"
            sx={{ position: "absolute", bottom: 0, right: 0, width: "100%", zIndex: 1 }}
          >
            <Slider ref={sliderRef} {...swipeSetting}>
              {paths.map((path, index) => (
                <Box
                  key={`${path.time}`}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: theme.spacing(1),
                  }}
                >
                  <SummaryRouteCard
                    path={path}
                    index={index}
                    selected={index === selectedPathIndex}
                    onCardClick={() => handleClickSwitchRoute(path, index)}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </MapLibre>
      </DialogContent>

      {/* buttom */}
      <DialogActions
        sx={{ paddingBottom: 2, paddingTop: 2, display: "flex", justifyContent: "space-between" }}
      >
        {/* 현위치/재탐색 버튼 */}
        <Box>
          <Button
            onClick={handleClickChangeRoute}
            color="primary"
            variant="contained"
            startIcon={<NearMe />}
            sx={{ width: "100%" }}
          >
            {selectedPathIndex === activePathIndex ? t("Current") : t("Guidance Start")}
          </Button>
        </Box>
        {/* 현재위치 정보 */}
        <Box display="flex" flexDirection="column" alignItems="start" sx={{ paddingRight: 3 }}>
          <Typography variant="h5">{t("Current Location")}</Typography>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Box
              key={activePath?.time}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography noWrap>{guideRemainInfo.remainTime}</Typography>
              <Typography sx={{ marginRight: 1, marginLeft: 1, fontSize: "10px", opacity: 0.5 }}>
                /
              </Typography>
              <Typography noWrap>{guideRemainInfo.remainDist}</Typography>
            </Box>
          </Box>
        </Box>
        {/* TBT 안내 리스트 버튼 */}
        <IconButton onClick={() => guideListDialog.onOpen()} color="primary">
          <FormatListBulleted />
        </IconButton>
      </DialogActions>

      {/* 상세 경로 리스트 */}
      <SummaryGuideListDialog
        open={guideListDialog.open}
        onClose={guideListDialog.onClose}
        moveToCoordinate={moveToCoordinate}
        selectedPath={selectedPath}
      />
    </Dialog>
  );
};

export default SummaryRouteMapDialog;
