// 하단 메뉴 팝업 - 진행 상황 슬라이더
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { selectRoute } from "../route/routeSlice";
import { useAppSelector } from "../../app/hooks";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import ProgressBar from "../../components/ProgressBar";
import WaypointIcon from "../waypoint/WaypointIcon";
import { selectWaypoint } from "../waypoint/waypointSlice";

const SummaryProgressCard = () => {
  const { activePath } = useAppSelector(selectRoute);
  const { distanceFromStart } = useAppSelector(selectGuidePosition);
  const { waypoints } = useAppSelector(selectWaypoint);

  const theme = useTheme();

  // 경로 point 총 개수(slider 용)
  const pointsCount = useMemo(() => activePath?.distance || 0, [activePath]);

  // 진행 상황
  const completionPercentage = distanceFromStart ? Math.min(distanceFromStart / pointsCount, 1) : 0;

  // 경유 도로
  const routeDesc = useMemo(() => {
    const roadNames = activePath?.rousen_details?.road_names ?? [];
    const wayInfo = activePath?.rousen_details?.way_info ?? [];

    // key: 도로명, value: 총 길이
    const roadLengths: { [key: string]: number } = {};

    let temp = 0;
    roadNames.forEach((road) => {
      // null 처리
      if (!road.text) return;
      // 도로명이 없는 도로
      if (!roadLengths[road.text]) {
        roadLengths[road.text] = 0;
      }

      wayInfo.forEach((way) => {
        // way_info 의 end_idx 가 roadname 의 last_idx 와 일치 할때까지 더함
        if (temp < way.end_idx && way.end_idx <= road.last_idx) {
          // 해당 도로명에 길이를 더함
          roadLengths[road.text] += way.length;
        }
      });
      temp = road.last_idx;
    });

    // 총 거리의 5% 이상인 도로만 필터
    const threshold = activePath ? activePath.distance * 0.05 : 0;

    // roadLengths 컬렉션에서 상위 1개 추출
    let topRoads: { [key: string]: number } = Object.entries(roadLengths)
      .filter(([, length]) => length >= threshold) // threshold 이상인 도로만 필터
      .sort((a, b) => b[1] - a[1]) // 내림차순 정렬
      .slice(0, 3) // 3개 추출
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    // topRoads를 last_index 오름차순으로 정렬
    topRoads = Object.keys(topRoads)
      .sort((a, b) => {
        const aLastIndex = roadNames.find((road) => road.text === a)?.last_idx || 0;
        const bLastIndex = roadNames.find((road) => road.text === b)?.last_idx || 0;
        return aLastIndex - bLastIndex;
      })
      .reduce((obj, key) => ({ ...obj, [key]: topRoads[key] }), {});

    return topRoads;
  }, [activePath]);

  // 도착지 명칭
  const destName = useMemo(() => {
    if (waypoints.length < 2) return "";
    if (waypoints[1].place === null) return "";
    return waypoints[1].place.name;
  }, [waypoints]);

  // 도착지 주소
  const destAddr = useMemo(() => {
    if (waypoints.length < 2) return "";
    if (waypoints[1].place === null) return "";
    return waypoints[1].place.roadAddress || waypoints[1].place.jibunAddress;
  }, [waypoints]);

  return (
    <Card variant="outlined" sx={{ margin: 2, boxShadow: 3 }}>
      <CardContent sx={{ textAlign: "center" }}>
        {/* 목적지 */}
        <Box display="flex" justifyContent="center">
          <Typography variant="h5" sx={{ color: theme.palette.primary.light }}>
            {destName}
          </Typography>
        </Box>
        {/* 주소 */}
        <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
          {destAddr}
        </Typography>
        {/* 경유 도로 */}
        <Box display="flex" justifyContent="center" sx={{ paddingTop: 3, paddingBottom: 3 }}>
          {activePath && (
            <List>
              {Object.keys(routeDesc).map((key) => (
                <ListItem
                  key={key}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {key}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Divider />
        {/* 주행 상황 */}
        <Box display="flex" flexDirection="column" sx={{ paddingTop: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ paddingLeft: 1, paddingRight: 1 }}
          >
            <WaypointIcon type="origin" />
            <WaypointIcon type="destination" />
          </Box>
          <Box sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <ProgressBar value={Math.round(completionPercentage * 100)} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SummaryProgressCard;
