import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ko from "./ko.json";
import en from "./en.json";
import zhtw from "./zh-tw.json";

const resources = {
  ko: {
    translation: ko,
  },
  en: {
    translation: en,
  },
  zhtw: {
    translation: zhtw,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["ko", "en", "zhtw"],
    resources,
    // debug: true,
    fallbackLng: ["en"],
    detection: {
      order: ["path", "navigator"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ".",
    },
  });

export default i18n;
