// useMatcher.ts
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectGeoLocation } from "../geolocation/geoLocationSlice";
import { selectOsmTile, setOsmTileId } from "./osmSlice";
import { getTileCoordinates, tileKey } from "./osmUtils";
import { INITIAL_GEOLOCATION, OSM_PBF_TILE_LEVEL } from "../../app/config/const";
import { selectCarPosition } from "../map/position/carPositionSlice";

let first = true;
interface PrevPosT {
  geoLon: number;
  geoLat: number;
  carLon: number;
  carLat: number;
};

const useOsm = () => {
  const dispatch = useAppDispatch();
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { tileId } = useAppSelector(selectOsmTile);
  const { carPosition } = useAppSelector(selectCarPosition);
  const workerRef = useRef<Worker | null>(null);
  const prevPos = useRef<PrevPosT>({ geoLon: 0, geoLat: 0, carLon: 0, carLat: 0 });
  const prevTick = useRef<number>(0);

  useEffect(() => {
    // Worker를 모듈로 생성
    workerRef.current = new Worker(new URL("./osmWorker.ts", import.meta.url), {
      type: "module",
    });

    workerRef.current.onmessage = (event) => {
      const { type, ...data } = event.data;
      if (type === "error") {
        dispatch(setOsmTileId({ x: -1, y: -1, z: -1 }));
        console.error("Error in osmWorker:", data.error);
      }
    };

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (workerRef.current) {
      workerRef.current.postMessage({ tileId });
    }
  }, [dispatch, tileId]);

  useEffect(() => {
    if (!geolocation) return;
    let lon = INITIAL_GEOLOCATION.coordinates[0];
    let lat = INITIAL_GEOLOCATION.coordinates[1];

    const tick = new Date().getTime();
    if (prevTick.current !== 0 && tick - prevTick.current < 1000) return;  // 1초 미만으로 갱신하려고하면 무시(geopos, carpos둘 다 사용하다보니...)
    prevTick.current = tick;

    if (geolocation.longitude === 0 || geolocation.latitude === 0) {
      if (first) {
        first = false;
      }
    } else if (prevPos.current.geoLon === geolocation.longitude &&
      prevPos.current.geoLat === geolocation.latitude &&
      carPosition !== null &&
      carPosition?.longitude !== 0 &&
      carPosition?.latitude !== 0) {
      lon = carPosition.longitude;
      lat = carPosition.latitude;
    } else {
      lon = geolocation.longitude;
      lat = geolocation.latitude;
    }

    prevPos.current = { geoLon: geolocation.longitude, geoLat: geolocation.latitude, carLon: carPosition?.longitude ?? 0, carLat: carPosition?.latitude ?? 0 };

    const { x, y, z } = getTileCoordinates(lon, lat, OSM_PBF_TILE_LEVEL);
    const newTileId = tileKey(x, y, z);
    const oldTileId = tileKey(tileId.x, tileId.y, tileId.z);
    if (newTileId !== oldTileId) {
      dispatch(setOsmTileId({ x, y, z }));
    }
  }, [dispatch, geolocation, carPosition, tileId]);
};

export default useOsm;
