/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-continue */
/* eslint-disable no-bitwise */
/// <reference lib="webworker" />
import * as turf from "@turf/turf";
import { IWay, OsmCache, osmCacheDB } from "../db/OsmCache";
import { binarySearchForWayIdAndDir } from "../guide/guideUtils";
import { MAPMATCH_DIR } from "../match2/MapMatchStructs";
import Logger from "../../app/logger";

export enum LANE_TYPE_RAW {
  U_TURN = 0,
  TURN_LEFT,
  BEAR_LEFT,
  STRAIGHT,
  BEAR_RIGHT,
  TURN_RIGHT,
  POCKET_INFO,
  EXTRA_INFO,
}

export enum LANE_TYPE {
  UNKNOWN = 0x00,
  U_TURN = 0x01,
  TURN_LEFT = 0x02,
  BEAR_LEFT = 0x04,
  STRAIGHT = 0x08,
  BEAR_RIGHT = 0x10,
  TURN_RIGHT = 0x20,
  LEFT_POCKET = 0x40,
  RIGHT_POCKET = 0x80,
  OVERPASS = 0x100,
  UNDERPASS = 0x200,
  ROTARY = 0x400,
  P_TURN = 0x800,
  ACTIVE = 0x1000,
}

let sync = false;
self.onmessage = async (event) => {
  if (sync) {
    console.error("LaneOsm : previous mathcing task is not finished yet. just return.");
    return;
  }
  sync = true;
  const { wayId, carPosDir, currentGuide, sortedWayIds } = event.data;

  // fixme : clink를 찾는 함수가 별도로 osmcache에 없기 때문에 만듬.
  // 코드 작성 당시 기준(2024/07/29) node 정보에도 연결정보가 없기 때문에 링크끼리 뭉친걸로 판단한다.
  // 나중에 꼭 검증할것.
  const getClinkForward = async (way: IWay, dir: MAPMATCH_DIR): Promise<IWay[]> => {
    const pt =
      dir === MAPMATCH_DIR.ES
        ? [way.feature.geometry.coordinates[0][0], way.feature.geometry.coordinates[0][1]]
        : [
            way.feature.geometry.coordinates[way.feature.geometry.coordinates.length - 1][0],
            way.feature.geometry.coordinates[way.feature.geometry.coordinates.length - 1][1],
          ];
    const bbox = OsmCache.BufferedBBoxOf({ type: "Point", coordinates: pt }, 10); // 일단 5m로 잡아보자. -> 안나오는 경우가 있어서 10m로 수정.
    const ways = await osmCacheDB.waysOn(bbox);

    const clinks: IWay[] = [];
    for (const w of ways) {
      if (w.id === way.id) continue; // 자기 자신은 제외
      if (w.feature.geometry.coordinates.length < 2) continue; // 노드가 2개 이하인건 제외
      if (w.feature.geometry.coordinates.length === 2) {
        if (
          w.feature.geometry.coordinates[0][0] === w.feature.geometry.coordinates[1][0] &&
          w.feature.geometry.coordinates[0][1] === w.feature.geometry.coordinates[1][1]
        )
          continue; // 5000000번대 way중 버택스가 2개인데, 같은 좌표인 경우가 있음. 이런건 제외. 아무론 속성이 없어서 터널밀기에 방해가 된다.
      }

      if (
        w.feature.properties?.oneway !== "-1" &&
        w.feature.geometry.coordinates[0][0] === pt[0] &&
        w.feature.geometry.coordinates[0][1] === pt[1]
      ) {
        clinks.push(w); // SE을때 시작점과 자기자신의 연결점이 다르면 제외
        continue;
      }

      if (
        w.feature.properties?.oneway !== "yes" &&
        w.feature.geometry.coordinates[w.feature.geometry.coordinates.length - 1][0] === pt[0] &&
        w.feature.geometry.coordinates[w.feature.geometry.coordinates.length - 1][1] === pt[1]
      ) {
        clinks.push(w); // ES을때 끝점과 자기자신의 연결점이 다르면 제외
      }
    }

    return clinks;
  };

  const checkClinkSE = (fromWay: IWay, fromDir: MAPMATCH_DIR, toWay: IWay): boolean | null => {
    const pt =
      fromDir === MAPMATCH_DIR.ES
        ? [fromWay.feature.geometry.coordinates[0][0], fromWay.feature.geometry.coordinates[0][1]]
        : [
            fromWay.feature.geometry.coordinates[
              fromWay.feature.geometry.coordinates.length - 1
            ][0],
            fromWay.feature.geometry.coordinates[
              fromWay.feature.geometry.coordinates.length - 1
            ][1],
          ];

    if (
      toWay.feature.geometry.coordinates[0][0] === pt[0] &&
      toWay.feature.geometry.coordinates[0][1] === pt[1]
    ) {
      return true; // SE
    }
    if (
      toWay.feature.geometry.coordinates[toWay.feature.geometry.coordinates.length - 1][0] ===
        pt[0] &&
      toWay.feature.geometry.coordinates[toWay.feature.geometry.coordinates.length - 1][1] === pt[1]
    ) {
      return false; // ES
    }

    // 여기 오면 안될거 같은데 빼먹었을수 있으니 체크필요.
    return null;
  };

  const checkActiveLane = (
    targetWayId: number,
    targetDir: boolean,
  ): { isGuidancePoint: boolean; isDeactiveLane: boolean } => {
    let isGuidancePoint = false;
    let isDeactiveLane = false;
    if (currentGuide !== null && sortedWayIds !== null && sortedWayIds.length > 0) {
      // 현재 매칭된 링크의 끝점하고 비교를 해본다.
      const idx = binarySearchForWayIdAndDir(sortedWayIds, targetWayId, targetDir);
      if (idx !== -1) {
        if (sortedWayIds[idx].pointIdx[1] === currentGuide.endInterval) {
          isGuidancePoint = true;
        } else {
          isGuidancePoint = false;
        }
      } else {
        isDeactiveLane = true;
      }
    }

    return { isGuidancePoint, isDeactiveLane };
  };

  // start
  // 1. wayId에 대한 way정보 가져오기.
  let extraDistance: number = 0;
  const way: IWay | undefined = await osmCacheDB.getWay(wayId);
  if (!way || !way.feature.properties) {
    console.error("LaneOsm : way is not found in the db.");
    sync = false;
    self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
    return;
  }

  try {
    let laneInfoRaw: string | null = null;
    if (carPosDir === true) {
      // SE
      laneInfoRaw = way.feature.properties.guideInfo1;
    } else {
      // ES
      laneInfoRaw = way.feature.properties.guideInfo2;
    }

    let isGuidancePoint: boolean = false;
    let isDeactiveLane: boolean = false;
    if (laneInfoRaw !== null && laneInfoRaw !== "") {
      const { isGuidancePoint: ret1, isDeactiveLane: ret2 } = checkActiveLane(wayId, carPosDir);
      isGuidancePoint = ret1;
      isDeactiveLane = ret2;
    }

    let crossNameWayId: number = wayId;
    if (!laneInfoRaw || laneInfoRaw === "") {
      if (isGuidancePoint) {
        // 다음 지점이 안내지점이면 섣불리 다음 링크를 찾으면 안된다.
        // console.error("LaneOsm : guide point has'nt laneInfo.");
        sync = false;
        self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
        return;
      }

      // 없으면 Clink를 찾아서 결과가 하나밖에 없으면 그걸로 쓰자.
      const clinks: IWay[] = await getClinkForward(
        way,
        carPosDir ? MAPMATCH_DIR.SE : MAPMATCH_DIR.ES,
      );
      let targetWay: IWay | null = null;
      if (clinks.length > 1) {
        const getToHeading = (w: IWay, dir: boolean): number => {
          if (w.feature.properties?.dir === undefined) return Number.NaN;
          const dirValues: string[] = w.feature.properties?.dir.split("|");
          if (dirValues === null || dirValues === undefined || dirValues.length !== 2)
            return Number.NaN;

          if (dir === true) {
            // ES
            return parseInt(dirValues[1], 10) + 180;
          }

          return parseInt(dirValues[0], 10) + 180; // SE
        };

        const fromHeading: number = getToHeading(way, carPosDir);
        const headingCheck = clinks.filter((w) => {
          if (w === undefined) return false;
          const dir = checkClinkSE(way, carPosDir ? MAPMATCH_DIR.SE : MAPMATCH_DIR.ES, w);
          if (dir === null) return false;

          const toHeading: number = getToHeading(w, !dir); // 진행방향과 반대방향을 봐야하므로 dir에 !를 붙여준다.
          if (Number.isNaN(fromHeading) || Number.isNaN(toHeading)) return false;
          let diff = Math.abs(fromHeading - (toHeading + 180)); // 방향이 반대이므로 180도를 더해준다.
          while (diff >= 360) {
            diff -= 360;
          }
          if (diff < 20) return true;
          return false;
        });

        if (headingCheck.length === 1) {
          [targetWay] = headingCheck;
        }
      } else if (clinks.length === 1) {
        [targetWay] = clinks;
      } else {
        targetWay = null;
      }

      if (targetWay === null) {
        sync = false;
        self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
        return;
      }

      const nextDir = checkClinkSE(way, carPosDir ? MAPMATCH_DIR.SE : MAPMATCH_DIR.ES, targetWay);
      if (nextDir === null) {
        // console.error("LaneOsm : nextLink is null.");
        sync = false;
        self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
        return;
      }

      if (nextDir === true) {
        laneInfoRaw = targetWay.feature.properties?.guideInfo1;
      } else {
        laneInfoRaw = targetWay.feature.properties?.guideInfo2;
      }

      if (!laneInfoRaw || laneInfoRaw === "") {
        // console.error("LaneOsm : laneInfo is null.");
        sync = false;
        self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
        return;
      }

      const { isGuidancePoint: ret1, isDeactiveLane: ret2 } = checkActiveLane(
        targetWay.id,
        nextDir,
      );
      isGuidancePoint = ret1;
      isDeactiveLane = ret2;

      crossNameWayId = targetWay.id;
      extraDistance = turf.length(turf.lineString(targetWay.feature.geometry.coordinates), {
        units: "meters",
      });
    }

    // 2. laneInfoRaw를 파싱해서 laneInfo로 만들기
    const parts = laneInfoRaw.trim().split("/");
    if (parts.length === 0) {
      console.error("LaneOsm : laneInfo is invalid.");
      sync = false;
      self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
      return;
    }

    const laneInfoTemp: number[] = [];
    let leftPocketCnt: number = 0;
    let rightPocketCnt: number = 0;
    parts.forEach((part, index) => {
      const singleLane = part.split(",");
      if (singleLane.length === 0 || (singleLane[0] === "0" && index <= LANE_TYPE_RAW.TURN_RIGHT)) {
        // 0인 경우는 무시.
        return;
      }

      singleLane.forEach((lane, index2) => {
        const iLane = parseInt(lane, 10);
        switch (index) {
          case LANE_TYPE_RAW.U_TURN:
            if (laneInfoTemp.length < iLane) laneInfoTemp.push(LANE_TYPE.U_TURN);
            else laneInfoTemp[iLane - 1] |= LANE_TYPE.U_TURN;
            break;
          case LANE_TYPE_RAW.TURN_LEFT:
            if (laneInfoTemp.length < iLane) laneInfoTemp.push(LANE_TYPE.TURN_LEFT);
            else laneInfoTemp[iLane - 1] |= LANE_TYPE.TURN_LEFT;
            break;
          case LANE_TYPE_RAW.BEAR_LEFT:
            if (laneInfoTemp.length < iLane) laneInfoTemp.push(LANE_TYPE.BEAR_LEFT);
            else laneInfoTemp[iLane - 1] |= LANE_TYPE.BEAR_LEFT;
            break;
          case LANE_TYPE_RAW.STRAIGHT:
            if (laneInfoTemp.length < iLane) laneInfoTemp.push(LANE_TYPE.STRAIGHT);
            else laneInfoTemp[iLane - 1] |= LANE_TYPE.STRAIGHT;
            break;
          case LANE_TYPE_RAW.BEAR_RIGHT:
            if (laneInfoTemp.length < iLane) laneInfoTemp.push(LANE_TYPE.BEAR_RIGHT);
            else laneInfoTemp[iLane - 1] |= LANE_TYPE.BEAR_RIGHT;
            break;
          case LANE_TYPE_RAW.TURN_RIGHT:
            if (laneInfoTemp.length < iLane) laneInfoTemp.push(LANE_TYPE.TURN_RIGHT);
            else laneInfoTemp[iLane - 1] |= LANE_TYPE.TURN_RIGHT;
            break;
          case LANE_TYPE_RAW.POCKET_INFO:
            if (index2 === 0) {
              // L-POCKET
              leftPocketCnt = iLane;
              for (let iii = 0; iii < iLane; iii += 1) {
                laneInfoTemp[iii] |= LANE_TYPE.LEFT_POCKET;
              }
            } else {
              // R-POCKET
              rightPocketCnt = iLane;
              for (let iii = 0; iii < iLane; iii += 1) {
                if (laneInfoTemp.length - 1 - iii < 0) continue;
                laneInfoTemp[laneInfoTemp.length - 1 - iii] |= LANE_TYPE.RIGHT_POCKET;
              }
            }
            break;
          case LANE_TYPE_RAW.EXTRA_INFO:
            // 여기오면 iLane은 NaN이므로 다시 체크할 필요있음.
            if (lane.length >= 2) {
              const type = lane.substring(0, 1);
              const cnt = parseInt(lane.substring(1), 10);
              let laneType = 0;
              switch (type) {
                case "H": // overpass
                  laneType = LANE_TYPE.OVERPASS;
                  break;
                case "L": // underpass
                  laneType = LANE_TYPE.UNDERPASS;
                  break;
                case "R": // rotary
                  laneType = LANE_TYPE.ROTARY;
                  break;
                case "P": // P-turn
                  laneType = LANE_TYPE.P_TURN;
                  break;
                default:
                  break;
              }
              // R,P를 쓸일은 없을거 같고, H,L은 직진 왼쪽부터 채우면 될것 같음.
              for (
                let iii = 0, jjj = 0;
                iii < laneInfoTemp.length && !isNaN(cnt) && jjj < cnt;
                iii += 1
              ) {
                if (laneInfoTemp[iii] & LANE_TYPE.STRAIGHT) {
                  laneInfoTemp[iii] |= laneType;
                  jjj += 1;
                }
              }
            }
            break;
          default:
            break;
        }
      });
    });

    // active check
    if (sortedWayIds === null || sortedWayIds === undefined || sortedWayIds.length === 0) {
      // activeLane을 사용하지 않으면 모두 active로 셋팅
      laneInfoTemp.forEach((lane, index) => {
        laneInfoTemp[index] |= LANE_TYPE.ACTIVE;
      });
    } else if (isGuidancePoint) {
      let laneType = LANE_TYPE.UNKNOWN;
      switch (currentGuide.sign) {
        case 1:
        case 7:
          laneType = LANE_TYPE.BEAR_RIGHT;
          break;
        case -1:
        case -7:
          laneType = LANE_TYPE.BEAR_LEFT;
          break;
        case 2: // 우회전
        case 3:
          laneType = LANE_TYPE.TURN_RIGHT;
          break;
        case -2: // 좌회전
        case -3:
          laneType = LANE_TYPE.TURN_LEFT;
          break;
        case -98: // 유턴
        case 98:
        case 8:
        case -8:
          laneType = LANE_TYPE.U_TURN;
          break;
        case 0: // 직진
          laneType = LANE_TYPE.STRAIGHT;
          break;
        default:
          break;
      }

      // let changeCnt:number = 0;
      laneInfoTemp.forEach((lane, index) => {
        if (lane & laneType) {
          laneInfoTemp[index] |= LANE_TYPE.ACTIVE;
          // changeCnt += 1;
        }
      });
    } else {
      // fixme :직진 활성화. 직진이 없으면??? 일단 모두비 활성화 시켜 버리자.
      // eslint-disable-next-line no-lonely-if
      if (parts.length < LANE_TYPE_RAW.STRAIGHT || parts[LANE_TYPE_RAW.STRAIGHT] === "0") {
        laneInfoTemp.forEach((lane, index) => {
          laneInfoTemp[index] &= ~LANE_TYPE.ACTIVE;
        });
      } else {
        laneInfoTemp.forEach((lane, index) => {
          if (lane & LANE_TYPE.STRAIGHT) laneInfoTemp[index] |= LANE_TYPE.ACTIVE;
        });
      }
    }

    // test 로그
    let strTestLog: string = "|";
    laneInfoTemp.forEach((lane, index) => {
      if (lane & LANE_TYPE.ACTIVE) strTestLog += `*`;

      if (lane & LANE_TYPE.LEFT_POCKET || lane & LANE_TYPE.RIGHT_POCKET) strTestLog += `포`;

      if (lane & LANE_TYPE.U_TURN) strTestLog += `U`;

      if (lane & LANE_TYPE.STRAIGHT) strTestLog += `직`;

      if (lane & LANE_TYPE.TURN_LEFT || lane & LANE_TYPE.BEAR_LEFT) strTestLog += `좌`;

      if (lane & LANE_TYPE.TURN_RIGHT || lane & LANE_TYPE.BEAR_RIGHT) strTestLog += `우`;

      if (lane & LANE_TYPE.OVERPASS) strTestLog += `고`;

      if (lane & LANE_TYPE.UNDERPASS) strTestLog += `지`;

      strTestLog += "|";
    });

    // Logger.log(`LaneOsm : laneInfoTemp : ${strTestLog} wayId : ${wayId}(${crossNameWayId}) carPosDir : ${carPosDir}`);
    // 안내지점이 아닌 보통의 다음 교차로의 차선 정보면 직진을 active 시켜보자.
    // 아직은 테스트니 all active
    // 직진이 차선이 없으면.... 어떻게 할지 고민해봐야함.

    // 결과물을 내보내기
    const retWays: number[] = [wayId];
    if (crossNameWayId !== wayId) {
      retWays.push(crossNameWayId);
    }

    self.postMessage({ type: "ok", ...{ laneInfo: laneInfoTemp, extraDistance, ways: retWays } });
  } catch (error) {
    console.error("LaneOsm : error in worker => ", error);
    self.postMessage({ type: "none", ...{ laneInfo: null, extraDistance: 0, ways: [] } });
  } finally {
    sync = false;
  }
};
