import { Box, styled } from "@mui/material";
import React, { useMemo } from "react";

interface Props {
  value: number;
  dots?: number;
}

const Dot = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.background.default,
  transition: "background-color 0.3s",
  ...(active && {
    backgroundColor: theme.palette.success.main,
  }),
}));

const ProgressBar = ({ value, dots = 20 }: Props) => {
  const array = useMemo(() => new Array(dots).fill(0).map((n, i) => (i * 100) / dots), [dots]);
  return (
    <Box display="flex" justifyContent="space-between">
      {array.map((dot) => (
        <Dot key={dot} active={value > dot} />
      ))}
    </Box>
  );
};

export default ProgressBar;
