import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { Settings } from "./settingApi";

interface SettingState extends Settings {}

const initialState: SettingState = {
  language: "KO",
  unit: "KILOMETER",
  map_display: "_2D",
  dark_mode: "SYSTEM",
  debug_mode: false,
  volume: 11,
  normal_guide: ["_1_5km"],
  highway_guide: ["_2km"],
  normal_safe_info: ["_800m"],
  highway_safe_info: ["_1_5km"],
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    syncSettings: (state, { payload }: PayloadAction<Settings>) => {
      return { ...state, ...payload };
    },
    setSetting: (state, action: PayloadAction<{ key: keyof SettingState; value: any }>) => {
      return { ...state, [action.payload.key]: action.payload.value };
    },
    setEnableDebug: (state, { payload }) => {
      state.debug_mode = payload;
    },
    resetSettings: () => {
      return initialState;
    },
  },
});

export default settingSlice;
export const selectSetting = (state: RootState) => state.setting;
export const { setSetting, setEnableDebug, resetSettings, syncSettings } = settingSlice.actions;
