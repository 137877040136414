import React, { memo, useMemo } from "react";
import { Marker } from "react-map-gl/maplibre";
import { useAppSelector } from "../../../app/hooks";
import { selectGuide } from "../../guide/guideSlice";
import { selectGuidePosition } from "../position/guidePositionSlice";
import { directionTargetImgUrl } from "../../../app/config/images";

const GuidePointMarker = () => {
  const { guidances } = useAppSelector(selectGuide);
  const { guidanceIndex } = useAppSelector(selectGuidePosition);

  const currentGuide = useMemo(() => {
    if (
      !guidances.length ||
      guidanceIndex === null ||
      guidanceIndex > guidances.length - 1 ||
      guidanceIndex === -1
    )
      return null;
    return guidances[guidanceIndex];
  }, [guidances, guidanceIndex]);

  if (!currentGuide) return null;

  return (
    <Marker
      key={currentGuide.accdist}
      latitude={currentGuide.guidePoint.coordinates[1]}
      longitude={currentGuide.guidePoint.coordinates[0]}
    >
      <img src={directionTargetImgUrl} alt="안내지점" />
    </Marker>
  );
};

export default memo(GuidePointMarker);
