import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCarPosition } from "../map/position/carPositionSlice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { Guidance, selectGuide, WayDetailInfo } from "../guide/guideSlice";
import { setLaneInfo } from "./laneOsmSlice";
import { selectDebug } from "../debug/debugSlice";

const useLaneOsm = () => {
  const dispatch = useAppDispatch();
  // 현재 carpos의 wayid를 이용함. offroad상태라서 wayid가 없으면 어차피 차선정보도 없음.
  const { wayId, direction:carPosDir } = useAppSelector(selectCarPosition);

  // 안내 정보가 있으면, 다음 안내 지점에 대한 차선 정보를 가져오기 위해 가이드 포스도 가져오자
  const workerRef = useRef<Worker | null>(null);

  const { guidances, sortedWayIds } = useAppSelector(selectGuide);
  const { guidanceIndex } = useAppSelector(selectGuidePosition);
  const { showLaneTest } = useAppSelector(selectDebug);
  
  const showLaneTestRef = useRef<boolean>(showLaneTest);
  const currentGuide = useRef<Guidance | null>(null);
  const sortedWayIdsRef = useRef<WayDetailInfo[]>(sortedWayIds);
  const wayIdRef = useRef<number | null>(null);
  const carPosDirRef = useRef<boolean | null>(null);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  

  useEffect(() => {
    showLaneTestRef.current = showLaneTest;
  }, [showLaneTest]);

  useEffect(() => {
    if (
      !guidances.length ||
      guidanceIndex === null ||
      guidanceIndex > guidances.length - 1 ||
      guidanceIndex === -1
    ) {
      currentGuide.current = null; 
      sortedWayIdsRef.current = [];
    } else {
      currentGuide.current = guidances[guidanceIndex];
      sortedWayIdsRef.current = sortedWayIds;
    }
      
      wayIdRef.current = wayId;
      carPosDirRef.current = carPosDir;
      setDataChanged(b => !b);
  }, [guidances, guidanceIndex, sortedWayIds, wayId, carPosDir]);
  
  useEffect(() => {
    // Worker를 모듈로 생성
    workerRef.current = new Worker(new URL("./laneOsmWorker.ts", import.meta.url), {
      type: "module",
    });
    
    workerRef.current.onmessage = (event) => {
      const { type, ...data } = event.data;
      // if (type === "none") {
        // console.error("Lane Info None");
      // } 
      
      dispatch(setLaneInfo({laneInfo:data.laneInfo, extraDistance:data.extraDistance, wayIds:data.ways}));
    };

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (!showLaneTestRef.current) return;
    if (workerRef.current && wayIdRef.current !== null && wayIdRef.current !== -1 && carPosDirRef.current !== null) {
      workerRef.current.postMessage({ wayId:wayIdRef.current, carPosDir:carPosDirRef.current, currentGuide: currentGuide.current, sortedWayIds: sortedWayIdsRef.current });
    }
  }, [dataChanged]);
}

export default useLaneOsm;