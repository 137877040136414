import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../../app/store";

interface State {
  isAutoReRouteEnabled: boolean;  // 경로 이탈 재탐색 가능 여부
  autoReRouteCounting: number;
  autoReRouteDelayDist: number;   // 경로 이탈 후 누적이동거리
}

const initialState: State = {
  isAutoReRouteEnabled: false,
  autoReRouteCounting: 0,
  autoReRouteDelayDist: 0,
};

const autoReRouteSlice = createSlice({
  name: "autoReRoute",
  initialState,
  reducers: {
    setIsAutoReRouteEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAutoReRouteEnabled = action.payload;
    },
    plusAutoReRouteCounting: (state) => {
      const counting = state.autoReRouteCounting + 1
      state.autoReRouteCounting = counting;
    },
    plusAutoReRouteDelayDist: (state, action: PayloadAction<number>) => {
      const dist = state.autoReRouteDelayDist + action.payload;
      state.autoReRouteDelayDist = dist;
    },
    clearAutoReRoute: (state) => {
      state.isAutoReRouteEnabled = false;
      state.autoReRouteCounting = 0;
      state.autoReRouteDelayDist = 0;
    },
    clearAutoReRouteCounting: (state) => {
      state.autoReRouteCounting = 0;
    },
    clearAutoReRouteDelayDist: (state) => {
      state.autoReRouteDelayDist = 0;
    },
  },
});

export const selectAutoReRoute = (state: RootState) => state.autoReRoute;
export const { setIsAutoReRouteEnabled, plusAutoReRouteCounting, plusAutoReRouteDelayDist, clearAutoReRoute, clearAutoReRouteCounting, clearAutoReRouteDelayDist } = autoReRouteSlice.actions;
export default autoReRouteSlice;
