import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export interface GeoLocation {
  latitude: number;
  longitude: number;
  accuracy: number;
  speed: number | null;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number;
  timestamp: number; // unix time in milliseconds.
}

export interface GeoLocationState {
  geolocation: GeoLocation | null;
}

const initialState: GeoLocationState = {
  geolocation: null,
};

const geoLocationSlice = createSlice({
  name: "geolocation",
  initialState,
  reducers: {
    setGeoLocation: (state, { payload }: PayloadAction<GeoLocation>) => {
      state.geolocation = payload;
    },
    resetGeoLocation: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export default geoLocationSlice;
export const selectGeoLocation = (state: RootState) => state.geolocation;
export const { setGeoLocation, resetGeoLocation } = geoLocationSlice.actions;
