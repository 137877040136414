import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { AccountBox, Lock } from "@mui/icons-material";
import React, { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoIcon from "../components/LogoIcon";
import { GetUserTokenRequest, useGetUserTokenMutation } from "../features/token/userApi";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectToken, setUserToken } from "../features/token/tokenSlice";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAppSelector(selectToken);
  const [getUserToken, { isLoading, isError }] = useGetUserTokenMutation();
  const [values, setValues] = useState<GetUserTokenRequest>({
    user_id: "",
    password: "",
  });
  const { user_id: userId, password } = values;

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const { token: newToken } = await getUserToken(values).unwrap();
    dispatch(setUserToken(newToken));
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    navigate(location.state?.pathname ? location.state?.pathname : "/", { replace: true });
  }, [token, navigate, location]);

  return (
    <Box
      py={10}
      px={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      component="form"
      onSubmit={handleSubmit}
      overflow="hidden"
    >
      <Box textAlign="center">
        <LogoIcon color="primary" sx={{ fontSize: "8rem" }} />
        <Typography variant="h5" color="primary">
          Rousen Navigation
        </Typography>
      </Box>
      <Stack spacing={2}>
        <TextField
          label="아이디"
          name="user_id"
          value={userId}
          onChange={handleChange}
          fullWidth
          disabled={isLoading}
          required
          error={isError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountBox />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="비밀번호"
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          fullWidth
          disabled={isLoading}
          required
          error={isError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" fullWidth variant="contained" size="large" disabled={isLoading}>
          로그인
        </Button>
        <Typography
          sx={{ textAlign: "center" }}
          variant="subtitle2"
          onClick={() => navigate("/signup")}
        >
          회원가입
        </Typography>
        {/* <Box display="flex" justifyContent="space-between" paddingX={8}>
          <Typography variant="subtitle2">아이디 찾기</Typography>
          <Divider orientation="vertical" sx={{ borderColor: theme.palette.primary.main }} />
          <Typography variant="subtitle2">비밀번호 찾기</Typography>
          <Divider orientation="vertical" sx={{ borderColor: theme.palette.primary.main }} />
          <Typography variant="subtitle2" onClick={() => navigate("/signup")}>
            회원가입
          </Typography>
        </Box> */}
        {/* <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Divider sx={{ borderColor: theme.palette.secondary.dark }} />
          </Box>
          <Typography variant="subtitle2" sx={{ mx: 2 }}>
            다른 서비스 계정으로 로그인
          </Typography>
          <Box flexGrow={1}>
            <Divider sx={{ borderColor: theme.palette.secondary.dark }} />
          </Box>
        </Box> */}
        {/* <Box display="flex" justifyContent="space-between" paddingX={10}>
          <Avatar src="/social/kakao_talk_circle.png" />
          <Avatar src="/social/naver_circle.png" />
          <Avatar src="/social/google_circle.png" />
          <Avatar src="/social/apple_circle.png" />
        </Box> */}
      </Stack>
    </Box>
  );
};

export default SignIn;
