import React from "react";
import { Box, Button } from "@mui/material";
import { AltRoute } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { changeCandidate, selectMapMatch2 } from "../match2/MapMatch2Slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectMap } from "./mapSlice";

const PositionCorrectionButton = () => {
  const { t } = useTranslation();
  const { competition } = useAppSelector(selectMapMatch2);
  const { isCenterChanged, isZoomChanged } = useAppSelector(selectMap);
  const dispatch = useAppDispatch();

  if (!competition) return null;

  return (
    <Box
      position="absolute"
      right={8}
      bottom={isCenterChanged || isZoomChanged ? 64 : 16}
      zIndex={1}
    >
      <Button
        color="secondary"
        variant="contained"
        startIcon={<AltRoute />}
        onClick={() => dispatch(changeCandidate())}
      >
        {t("Position correction")}
      </Button>
    </Box>
  );
};

export default PositionCorrectionButton;
