/** GPS observation count  */
export const GEOLOCATION_OBSERVATION_COUNT = 5;

/** 1sec */
export const GEOLOCATION_UPDATE_INTERVAL = 1000;

/** 30m */
export const GEOLOCATION_MAX_ACCURACY_THRESHOLD = 30; // 30m

/** 2m */
export const GEOLOCATION_MIN_ACCURACY_THRESHOLD = 2;

/** 2m */
export const GEOLOCATION_DISTANCE_STAY_THRESHOLD = 2;
export const GEOLOCATION_ILLEGAL_DISTANCE_THRESHOLD = 1000; // 1km
export const GEOLOCATION_ILLEGAL_TIME_THRESHOLD = GEOLOCATION_OBSERVATION_COUNT * 1000; // sec
export const GEOLOCATION_PREDICTION_COUNT = 1;
export const GEOLOCATION_PREDICTION_INTERVAL = 3; // sec
export const GEOLOCAITON_PREDICTION_SPEED = 19.44; // 70km/h
export const GEOLOCATION_DIRECTION_ANGLE_THRESHOLD = 180; // 180 degree
export const GEOLOCATION_MAX_MATCHING_PT_COUNT =
  GEOLOCATION_OBSERVATION_COUNT + GEOLOCATION_PREDICTION_COUNT + 1;

export const SNAPPING_DISTANCE_THRESHOLD = GEOLOCATION_MAX_ACCURACY_THRESHOLD;
