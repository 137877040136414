import { List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { memo } from "react";

interface Props {
  items: { label: string; value: string | number | undefined }[];
}

const ListView = ({ items }: Props) => {
  return (
    <List dense>
      {items.map((item) => (
        <ListItem key={item.label}>
          <ListItemText primary={item.label} />
          <Typography>{item.value ?? "-"}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default memo(ListView);
