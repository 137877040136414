import React from "react";
import { Box } from "@mui/material";
import { Marker } from "react-map-gl/maplibre";
import { selectMapMatch2 } from "../../../match2/MapMatch2Slice";
import { useAppSelector } from "../../../../app/hooks";
import { selectDebug } from "../../../debug/debugSlice";

const GPSPushMarker = () => {
  const { showGPSLocation, gpsPush1sec } = useAppSelector(selectDebug);
  const { gps: mmgps } = useAppSelector(selectMapMatch2);
  if (!showGPSLocation || !gpsPush1sec || !mmgps) return null;
  return (
    <Marker latitude={mmgps.latitude} longitude={mmgps.longitude} pitchAlignment="map">
      <Box width={20} height={20} borderRadius="50%" bgcolor="orange" />
    </Marker>
  );
};

export default GPSPushMarker;
