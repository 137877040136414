const {
  VITE_INIT_GEOLOCATION_LATITUDE,
  VITE_INIT_GEOLOCATION_LONGITUDE,
  VITE_MAP_STATIC_FILE_URL,
  VITE_SEARCH_SERVER_API_URL,
  VITE_SERVER_API_URL,
} = import.meta.env;
export const BASE_URL = import.meta.env.BASE_URL === "/" ? "" : import.meta.env.BASE_URL;
export const SERVER_API_URL = VITE_SERVER_API_URL;
export const SEARCH_API_URL = VITE_SEARCH_SERVER_API_URL;
export const MAP_DEFAULT_STYLE = `${VITE_MAP_STATIC_FILE_URL}/maps/style_default.json`;
export const MAP_DARK_STYLE = `${VITE_MAP_STATIC_FILE_URL}/maps/style_dark.json`;
export const ROUTE_SERVER_URL = SERVER_API_URL;
export const ROUSEN_WFS_SERVER_URL = `${SERVER_API_URL}/wfs/ways`;
export const ROUSEN_WFS_MAX_FEATURES = 1500;
export const ROUSEN_OSM_SERVER_URL = `${VITE_MAP_STATIC_FILE_URL}/net`;

export const MAP_STYLES = { dark: MAP_DARK_STYLE, light: MAP_DEFAULT_STYLE };

/** 초기 지도 좌표 */
export const INITIAL_GEOLOCATION = {
  type: "Point",
  coordinates: [Number(VITE_INIT_GEOLOCATION_LONGITUDE), Number(VITE_INIT_GEOLOCATION_LATITUDE)],
};

export const OSM_PBF_TILE_LEVEL = 15;

/** 사용자의 지도 움직임 초기화 대기시간 10초 */
export const USER_ACTION_CLEAR_TIMEOUT = 10000;

export const USER_LONG_PRESS_THRESHOLD = 500; // 500ms
