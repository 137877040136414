import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectWaypoint } from "../waypoint/waypointSlice";
import { useAppSelector } from "../../app/hooks";
import { selectGuide } from "./guideSlice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";

const Arrivals = () => {
  const { totalRouteDist, guidances } = useAppSelector(selectGuide);
  const { distanceFromStart } = useAppSelector(selectGuidePosition);
  const { t } = useTranslation();
  const { waypoints } = useAppSelector(selectWaypoint);

  const isArrived = useMemo(
    () => totalRouteDist - (distanceFromStart === null ? 0 : distanceFromStart) <= 250,
    [distanceFromStart, totalRouteDist],
  );

  const destName = useMemo(() => {
    if (waypoints.length < 2) return "";
    if (waypoints[1].place === null) return "";
    return waypoints[1].place.name;
  }, [waypoints]);

  const destAddr = useMemo(() => {
    if (waypoints.length < 2) return "";
    if (waypoints[1].place === null) return "";
    return waypoints[1].place.roadAddress || waypoints[1].place.jibunAddress;
  }, [waypoints]);

  if (!isArrived || !guidances.length) return null;

  return (
    <Box px={2} py={0.5}>
      <Typography variant="h5" py={0.5}>
        {t("Current destination")}
      </Typography>
      <Typography variant="h6" py={0.5}>
        {destName}
      </Typography>
      <Typography variant="h6" py={0.5}>
        {destAddr}
      </Typography>
    </Box>
  );
};

export default Arrivals;
