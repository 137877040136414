import React from "react";
import {
  Box,
  Button,
  Typography,
  Slide,
  BottomNavigationAction,
  BottomNavigation,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import { RouteTwoTone, ForkLeft } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import SummaryRouteListDialog from "./SummaryRouteListDialog";
import DialogTitle from "../../components/DialogTitle";
import SummaryRouteMapDialog from "./SummaryRouteMapDialog";
import SummaryProgressCard from "./SummaryProgressCard";
import { selectGuideRemainInfo } from "../guide/guideSlice";
import Dialog, { useDialog } from "../../components/Dialog";

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement }>(
  (props, ref) => <Slide direction="up" ref={ref} {...props} />,
);

interface SummaryDialogProps {
  open: boolean;
  onClose: () => void;
}

const SummaryDialog = ({ open, onClose }: SummaryDialogProps) => {
  const navigate = useNavigate();
  const routeListDialog = useDialog();
  const routeSummaryDialog = useDialog();
  const guideRemainInfo = useAppSelector(selectGuideRemainInfo);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        {/* 상단 타이틀 */}
        <DialogTitle onClose={onClose}>
          <Box textAlign="center" py={1}>
            <Typography variant="h6" gutterBottom>
              {guideRemainInfo.etaTime}
            </Typography>
            <Typography variant="body1">
              {guideRemainInfo.remainTime} ({guideRemainInfo.remainDist})
            </Typography>
          </Box>
        </DialogTitle>
        {/* 중앙 card */}
        <DialogContent sx={{ p: 0, bgcolor: theme.palette.background.default }}>
          <SummaryProgressCard />
        </DialogContent>
        {/* 하단 경로/개요 navigation */}
        <BottomNavigation
          showLabels
          sx={{ "& .MuiBottomNavigationAction-label": { color: theme.palette.text.primary } }}
        >
          <BottomNavigationAction
            onClick={routeListDialog.onOpen}
            label={t("All Routes")}
            icon={<ForkLeft sx={{ color: theme.palette.text.primary }} />}
          />
          <BottomNavigationAction
            onClick={routeSummaryDialog.onOpen}
            label={t("Summary")}
            icon={<RouteTwoTone sx={{ color: theme.palette.text.primary }} />}
          />
        </BottomNavigation>
        {/* 하단 경로 취소 버튼 */}
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => navigate("/")} fullWidth>
            {t("Cancel Path")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Right 개요 */}
      <SummaryRouteMapDialog open={routeSummaryDialog.open} onClose={routeSummaryDialog.onClose} />
      {/* Left 경로 */}
      <SummaryRouteListDialog open={routeListDialog.open} onClose={routeListDialog.onClose} />
    </>
  );
};

export default SummaryDialog;
