import { ArrowBackIos, VolumeDown, VolumeUp, ChevronRight } from "@mui/icons-material";
import {
  AppBar,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Slider,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectSetting, setEnableDebug, setSetting } from "../features/setting/settingSlice";
import { Settings, useUpdateUserSettingsMutation } from "../features/setting/settingApi";
import { selectToken } from "../features/token/tokenSlice";

const Setting = () => {
  const settings = useAppSelector(selectSetting) as Settings;
  const { t, i18n } = useTranslation();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // 현재 로그인 토큰 정보
  const { token } = useAppSelector(selectToken);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value, type } = event.target;
    let newValue: any = type === "checkbox" ? checked : value;

    if (name === "debug_mode") {
      newValue = !settings.debug_mode;
      dispatch(setEnableDebug(newValue));
    } else {
      dispatch(setSetting({ key: name as keyof Settings, value: newValue }));
    }

    if (name === "language") {
      if (newValue === "CN") {
        i18n.changeLanguage("zhtw");
      } else {
        i18n.changeLanguage(newValue.toLowerCase());
      }
    }

    if (token) {
      updateUserSettings({ ...settings, [name]: newValue });
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    dispatch(setSetting({ key: "volume", value: newValue as number }));
    if (token) {
      updateUserSettings({ ...settings, volume: newValue as number });
    }
  };

  return (
    <Stack spacing={2}>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <IconButton onClick={() => navigate("/")} sx={{ mr: 2 }}>
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6" flexGrow={1}>
            {t("Settings")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Stack spacing={2}>
          <Paper sx={{ overflow: "hidden" }}>
            <List subheader={<ListSubheader>지도 설정</ListSubheader>}>
              {/* 언어 */}
              <ListItem
                divider
                secondaryAction={
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    name="language"
                    // value={i18n.resolvedLanguage}
                    // onChange={(event) => i18n.changeLanguage(event.target.value)}
                    value={settings.language}
                    onChange={handleChange}
                  >
                    <MenuItem value="KO">한국어</MenuItem>
                    <MenuItem value="EN">English</MenuItem>
                    <MenuItem value="CN">中文（繁體）</MenuItem>
                  </TextField>
                }
              >
                <ListItemButton>
                  <ListItemText primary={t("Language")} />
                </ListItemButton>
              </ListItem>
              {/* 표기 단위 */}
              <ListItem
                divider
                secondaryAction={
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    value={settings.unit}
                    onChange={handleChange}
                    name="unit"
                  >
                    <MenuItem value="KILOMETER">{t("km")}</MenuItem>
                    <MenuItem value="MILE">{t("mile")}</MenuItem>
                  </TextField>
                }
              >
                <ListItemButton>
                  <ListItemText primary={t("Unit")} />
                </ListItemButton>
              </ListItem>
              {/* 지도 표시 방식 */}
              <ListItem
                divider
                secondaryAction={
                  <TextField
                    name="map_display"
                    select
                    size="small"
                    variant="outlined"
                    value={settings.map_display}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value="default">{t("Default")}</MenuItem> */}
                    <MenuItem value="_2D">2D</MenuItem>
                    <MenuItem value="_3D">3D</MenuItem>
                  </TextField>
                }
              >
                <ListItemButton>
                  <ListItemText primary={t("Map View type")} />
                </ListItemButton>
              </ListItem>
              {/* 다크 모드 */}
              <ListItem
                divider
                secondaryAction={
                  <TextField
                    name="dark_mode"
                    select
                    size="small"
                    variant="outlined"
                    value={settings.dark_mode}
                    onChange={handleChange}
                  >
                    <MenuItem value="SYSTEM">{t("System Default")}</MenuItem>
                    <MenuItem value="ON">{t("Dark")}</MenuItem>
                    <MenuItem value="OFF">{t("Light")}</MenuItem>
                  </TextField>
                }
              >
                <ListItemButton>
                  <ListItemText primary={t("Dark Mode")} />
                </ListItemButton>
              </ListItem>
              {/* Debug mode */}
              <ListItem divider>
                <ListItemButton>
                  <ListItemText primary={t("Debug mode")} />
                  {/* <Switch checked={userSettings?.debug_mode} onChange={handleChangeDebug} /> */}
                  <Switch name="debug_mode" checked={settings.debug_mode} onChange={handleChange} />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
          <Paper sx={{ overflow: "hidden" }}>
            {/* 소리 */}
            <List subheader={<ListSubheader>{t("Guide Voice")}</ListSubheader>}>
              <ListItem
                divider
                secondaryAction={
                  <Stack direction="row" alignItems="center" width={200} spacing={2}>
                    <VolumeDown />
                    <Slider value={settings.volume} onChange={handleSliderChange} />
                    <VolumeUp />
                  </Stack>
                }
              >
                <ListItemButton>
                  <ListItemText primary={t("Volume")} />
                </ListItemButton>
              </ListItem>
              {/* 음성안내거리 */}
              <ListItem divider>
                <ListItemButton>
                  <ListItemText
                    primary={t("Voice Guidance Dist")}
                    onClick={() => navigate("/settings/voice-guidance-dist")}
                  />
                </ListItemButton>
                <ChevronRight />
              </ListItem>
            </List>
          </Paper>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Setting;
