import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface State {
  value: string;
}

export interface Place {
  id: string;
  name: string;
  point: {
    lng: number;
    lat: number;
  };
  type: string;
  roadAddress: string;
  jibunAddress: string;
  distance: number;
}

const initialState: State = {
  value: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<State["value"]>) => {
      state.value = action.payload;
    },
  },
});
export const { setValue } = searchSlice.actions;
export const selectSearch = (state: RootState) => state.search;
export default searchSlice;
