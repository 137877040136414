/* eslint-disable react/no-array-index-key */
/* eslint-disable no-bitwise */
// 현재 속도 출력
import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { memo, useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCarPosition } from "./position/carPositionSlice";
import { selectLaneOsm } from "../LaneOsm/laneOsmSlice";
import { LANE_TYPE } from "../LaneOsm/laneOsmWorker";
import { getFormattedDistance } from "../guide/guideUtils";
import { selectDebug } from "../debug/debugSlice";
import useLaneOsm from "../LaneOsm/useLaneOsm";

const BottomLane = () => {
  const { distanceForward, wayId: carWayId } = useAppSelector(selectCarPosition);
  const { laneInfo, extraDistance, wayIds } = useAppSelector(selectLaneOsm);
  const laneInfoRef = useRef<number[] | null>(laneInfo);
  const { showLaneTest } = useAppSelector(selectDebug);

  const laneDistance = useMemo(() => {
    if (distanceForward == null) return "";
    return getFormattedDistance(distanceForward + extraDistance);
  }, [distanceForward, extraDistance]);

  const checkWayId = useMemo(() => {
    if (carWayId === null || wayIds === null) return false;
    
    const ret = wayIds.includes(carWayId);
    if (ret) laneInfoRef.current = laneInfo;
    else laneInfoRef.current = null;

    return ret
  }, [carWayId, wayIds, laneInfo]);

  const getLaneImage = (singleLane: number): string => {
    let status: string = 'd';
    let laneType = LANE_TYPE.UNKNOWN;
    if (singleLane & LANE_TYPE.ACTIVE) status = 'a';
    if (singleLane & LANE_TYPE.OVERPASS) laneType = LANE_TYPE.OVERPASS;
    else if (singleLane & LANE_TYPE.UNDERPASS) laneType = LANE_TYPE.UNDERPASS;
    else {
      laneType = singleLane &
        ~LANE_TYPE.LEFT_POCKET &
        ~LANE_TYPE.RIGHT_POCKET &
        ~LANE_TYPE.OVERPASS &
        ~LANE_TYPE.UNDERPASS &
        ~LANE_TYPE.ROTARY &
        ~LANE_TYPE.P_TURN &
        ~LANE_TYPE.ACTIVE;

      if (laneType & LANE_TYPE.BEAR_LEFT) {
        laneType &= ~LANE_TYPE.BEAR_LEFT;
        laneType |= LANE_TYPE.TURN_LEFT;
      } else if (laneType & LANE_TYPE.BEAR_RIGHT) {
        laneType &= ~LANE_TYPE.BEAR_RIGHT;
        laneType |= LANE_TYPE.TURN_RIGHT;
      }
    }
    return `/guide_lane/carline_${status}_${laneType}.png`;
  };

  const getPocketLine = (singleLane: number) => {
    if (singleLane & LANE_TYPE.LEFT_POCKET) return { borderRadius: "30%", borderLeft: 2, borderBottom: 2, borderTopLeftRadius: 0, borderBottomRightRadius: 0 };
    if (singleLane & LANE_TYPE.RIGHT_POCKET) return { borderRadius: "30%", borderRight: 2, borderBottom: 2, borderTopRightRadius: 0, borderBottomLeftRadius: 0 };
    return {};
  }

  useLaneOsm();
  if (!showLaneTest || laneInfoRef.current === null || !checkWayId) return null;

  return (
    <Box position="absolute" bottom={10} zIndex={1}
      sx={{
        left: "50%",
        transform: "translate(-50%, 0)",
      }}>
      <Paper sx={{ p: 0 }}>
        <Box display="flex" alignItems="center" p={1}>
          {laneInfoRef.current.map((lane, idx) => (
            <Box key={idx} display="flex" sx={getPocketLine(lane)}>
              {idx > 0 && <img src="/guide_lane/map_vbtn_dot_vline.png" alt="" />}
              <img src={getLaneImage(lane)} alt="" />
            </Box>
          ))}

          <Typography variant="h6" ml={0.5} whiteSpace="nowrap">
            {laneDistance}
          </Typography>
        </Box>

      </Paper>
    </Box>
  );
};

export default memo(BottomLane);
