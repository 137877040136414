import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../app/store";
import { IWay } from "../../db/OsmCache";

interface LinkRouteState {
  links: IWay[];
}

const initialState: LinkRouteState = {
  links: [],
};

const linkRouteSlice = createSlice({
  name: "linkRoute",
  initialState,
  reducers: {
    setLinkRoute: (state, action: PayloadAction<{ links: IWay[] }>) => {
      state.links = action.payload.links;
    },
  },
});

export default linkRouteSlice;
export const { setLinkRoute } = linkRouteSlice.actions;
export const selectLinkRoute = (state: RootState) => state.linkRoute;
