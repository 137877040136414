import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectZoom } from "./view/viewSlice";
import { getFormattedDistance } from "../guide/guideUtils";

const zoomScales: { [key: number]: number } = {
  0: 59959.436,
  1: 29979.718,
  2: 14989.859,
  3: 7494.929,
  4: 3747.464,
  5: 1873.732,
  6: 936.866,
  7: 468.433,
  8: 234.217,
  9: 117.108,
  10: 58.554,
  11: 29.277,
  12: 14.638,
  13: 7.319,
  14: 3.66,
  15: 1.83,
  16: 0.915,
  17: 0.458,
  18: 0.229,
  19: 0.114,
  20: 0.057,
  21: 0.028,
  22: 0.014,
};

const width = 60;

const Scale = () => {
  const zoom = useAppSelector(selectZoom);
  const [scale, setScale] = useState<number>(0);

  useEffect(() => {
    const newScale = zoomScales[Math.floor(zoom)];
    setScale(Math.floor(newScale * width));
  }, [zoom]);

  return (
    <Box bottom={2} right={8} position="absolute" display="flex" alignItems="center">
      <Box height={5} width={width} border="2px solid black" mr={1} mt={1} borderTop={0} />
      <Typography variant="caption">{getFormattedDistance(scale)}</Typography>
    </Box>
  );
};

export default Scale;
