import { TypographyOptions } from "@mui/material/styles/createTypography";
import "@fontsource/noto-sans-kr";

const createTypography = (): TypographyOptions => {
  return {
    fontFamily:
      '"Noto Sans KR", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: "17px",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    body2: {
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    button: {
      fontWeight: 600,
    },
    caption: {
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: 1.43,
      letterSpacing: "-0.56px",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.2,
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: 1.2,
    },
  };
};

export default createTypography;
