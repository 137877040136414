import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export interface PredictPositionsState {
  predictPositions: number[][] | null;
}

const initialState: PredictPositionsState = {
  predictPositions: [],
};

const prePositionsSlice = createSlice({
  name: "predictPositions",
  initialState,
  reducers: {
    setPrePositions: (state, { payload }: PayloadAction<PredictPositionsState>) => {
      state.predictPositions = payload.predictPositions;
    },
    clearCarPosition: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setPrePositions, clearCarPosition } = prePositionsSlice.actions;
export const selectPrePositions = (state: RootState) => state.predictPositions;
export default prePositionsSlice;