import { BASE_URL } from "../../app/config/const";

let voices: SpeechSynthesisVoice[] | null = null;

const setVoiceList = () => {
  if (voices !== null && voices.length > 0) return;
  voices = window.speechSynthesis.getVoices();
};

const speech = (txt: string) => {
  if (voices === null) return;
  const lang = "ko-KR"; // "zh-TW";
  const utterThis = new SpeechSynthesisUtterance(txt);

  utterThis.lang = lang;

  /* 한국어 vocie 찾기
     디바이스 별로 한국어는 ko-KR 또는 ko_KR로 voice가 정의되어 있다.
  */
  const korVoice = voices.find(
    (elem) => elem.lang === lang || elem.lang === lang.replace("-", "_"),
  );

  // 한국어 voice가 있다면 ? utterance에 목소리를 설정한다 : 리턴하여 목소리가 나오지 않도록 한다.
  if (korVoice) {
    utterThis.voice = korVoice;
    //    if (lang === "zh-TW")
    //      utterThis.rate = 1;
    //    else
    utterThis.rate = 1.35;
  } else {
    return;
  }

  // utterance를 재생(speak)한다.
  window.speechSynthesis.speak(utterThis);
};

export const playBrowserTTS = (txt: string): void => {
  setVoiceList();
  speech(txt);
};

export const isPlayingBrowserTTS = (): boolean => {
  return window.speechSynthesis.speaking;
};

export const playBrowserWav = (wavfile: string): void => {
  if (wavfile === "") return;
  const wav = new Audio(`${BASE_URL}/sound/${wavfile}.mp3`);
  wav.play();
};
