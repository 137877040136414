import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, index, value }: Props) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index ? children : null}
    </div>
  );
};

export default TabPanel;
