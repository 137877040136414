import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import localStorage from "redux-persist/es/storage";
import sessionStorage from "redux-persist/es/storage/session";
import historySlice from "../features/search/history/historySlice";
import settingSlice from "../features/setting/settingSlice";
import tokenSlice from "../features/token/tokenSlice";
import searchSlice from "../features/search/searchSlice";
import viewSlice from "../features/map/view/viewSlice";
import waypointSlice from "../features/waypoint/waypointSlice";
import routeSlice from "../features/route/routeSlice";
import baseApi from "../features/baseApi";
import searchApi from "../features/search/searchApi";
import geoLocationSlice from "../features/geolocation/geoLocationSlice";
import simulatorSlice from "../features/debug/simulator/simulatorSlice";
import mapSlice from "../features/map/mapSlice";
import carPositionSlice from "../features/map/position/carPositionSlice";
import prePositionsSlice from "../features/match/prePosSlice";
import osmTileSlice from "../features/osm/osmSlice";
import guideSlice from "../features/guide/guideSlice";
import guideSettingSlice from "../features/guide/guideSettingSlice";
import viewTransitionSlice from "../features/map/view/transition/viewTransitionSlice";
import guidePositionSlice from "../features/map/position/guidePositionSlice";
import debugSlice from "../features/debug/debugSlice";
import soundSlice from "../features/sound/soundSlice";
import autoReRouteSlice from "../features/route/reroute/autoReRouteSlice";
import reRouteSlice from "../features/route/reroute/reRouteSlice";
import linkRouteSlice from "../features/route/link/linkRouteSlice";
import mapMatch2Slice from "../features/match2/MapMatch2Slice";
import laneOsmSlice from "../features/LaneOsm/laneOsmSlice";

const persistHistoryReducer = persistReducer(
  {
    key: "histories",
    storage: localStorage,
  },
  historySlice.reducer,
);
const persistSettingReducer = persistReducer(
  {
    key: "setting",
    storage: localStorage,
  },
  settingSlice.reducer,
);
const persistTokenReducer = persistReducer(
  {
    key: "token",
    storage: localStorage,
  },
  tokenSlice.reducer,
);

const rootReducer = combineReducers({
  [tokenSlice.name]: persistTokenReducer,
  [searchSlice.name]: searchSlice.reducer,
  [viewSlice.name]: viewSlice.reducer,
  [waypointSlice.name]: waypointSlice.reducer,
  [routeSlice.name]: routeSlice.reducer,
  [historySlice.name]: persistHistoryReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [geoLocationSlice.reducerPath]: geoLocationSlice.reducer,
  [simulatorSlice.name]: simulatorSlice.reducer,
  [mapSlice.name]: mapSlice.reducer,
  [carPositionSlice.name]: carPositionSlice.reducer,
  [prePositionsSlice.name]: prePositionsSlice.reducer,
  [osmTileSlice.name]: osmTileSlice.reducer,
  [guideSlice.name]: guideSlice.reducer,
  [guideSettingSlice.name]: guideSettingSlice.reducer,
  [viewTransitionSlice.name]: viewTransitionSlice.reducer,
  [guidePositionSlice.name]: guidePositionSlice.reducer,
  [debugSlice.name]: debugSlice.reducer,
  [settingSlice.name]: persistSettingReducer,
  [soundSlice.name]: soundSlice.reducer,
  [reRouteSlice.name]: reRouteSlice.reducer,
  [autoReRouteSlice.name]: autoReRouteSlice.reducer,
  [linkRouteSlice.name]: linkRouteSlice.reducer,
  [mapMatch2Slice.name]: mapMatch2Slice.reducer,
  [laneOsmSlice.name]: laneOsmSlice.reducer,
});

export default rootReducer;
