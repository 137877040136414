import { PaletteMode, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import createComponents from "../../app/theme/createComponents";
import createShadow from "../../app/theme/createShadow";
import createTypography from "../../app/theme/createTypography";
import createPalette, { createDarkPalette } from "../../app/theme/createPalette";
import { useAppSelector } from "../../app/hooks";
import { selectSetting } from "../setting/settingSlice";
import { Settings } from "../setting/settingApi";

export type ColorMode = "default" | PaletteMode;

interface Props {
  children: ReactNode;
}

const ColorContext = createContext<{ mode: ColorMode; setMode: (newMode: ColorMode) => void }>({
  mode: "light",
  setMode: () => {},
});

export const ColorThemeProvider = ({ children }: Props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<ColorMode>("default");
  const settings = useAppSelector(selectSetting) as Settings;

  useEffect(() => {
    if (settings.dark_mode === "ON") {
      setMode("dark");
    } else if (settings.dark_mode === "OFF") {
      setMode("light");
    } else if (settings.dark_mode === "SYSTEM") {
      setMode("default");
    }
  }, [settings.dark_mode]);

  const palette = useMemo(() => {
    if (mode === "light") {
      return createPalette();
    }

    if (mode === "dark") {
      return createDarkPalette();
    }

    return prefersDarkMode ? createDarkPalette() : createPalette();
  }, [prefersDarkMode, mode]);

  const theme = useMemo(() => {
    const shadows = createShadow();
    const typography = createTypography();
    const components = createComponents(palette);
    return createTheme({
      components,
      palette,
      shape: {
        borderRadius: 8,
      },
      shadows,
      typography,
    });
  }, [palette]);

  const value = useMemo(() => {
    return {
      mode,
      setMode: (newMode: ColorMode) => setMode(newMode),
    };
  }, [mode]);

  return (
    <ColorContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorContext.Provider>
  );
};

const useColorTheme = () => {
  return useContext(ColorContext);
};

export default useColorTheme;
