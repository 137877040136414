import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ViewState } from "react-map-gl/dist/esm/types";
import type { RootState } from "../../../app/store";
import { INITIAL_GEOLOCATION } from "../../../app/config/const";

export interface State extends ViewState {}

const initialState: State = {
  latitude: INITIAL_GEOLOCATION.coordinates[1],
  longitude: INITIAL_GEOLOCATION.coordinates[0],
  zoom: 18,
  bearing: 0,
  pitch: 90,
  padding: { top: 0, bottom: 0, left: 0, right: 0 },
};

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setBearing: (state, action: PayloadAction<number>) => {
      state.bearing = action.payload;
    },
    setViewState: (state, action: PayloadAction<ViewState>) => {
      return action.payload;
    },
    setPartialViewState: (state, { payload }: PayloadAction<Partial<ViewState>>) => {
      if (payload.latitude) {
        state.latitude = payload.latitude;
      }
      if (payload.longitude) {
        state.longitude = payload.longitude;
      }
      if (payload.zoom) {
        state.zoom = payload.zoom;
      }
      if (payload.bearing) {
        state.bearing = payload.bearing;
      }
      if (payload.pitch) {
        state.pitch = payload.pitch;
      }
      if (payload.padding) {
        state.padding = payload.padding;
      }
    },
    clearViewState: () => {
      return initialState;
    },
  },
});
export const { setViewState, setPartialViewState, setBearing, clearViewState } = viewSlice.actions;
export const selectView = (state: RootState) => state.view;
export const selectBearing = (state: RootState) => state.view.bearing;
export const selectZoom = (state: RootState) => state.view.zoom;
export default viewSlice;
