import { buildGPX, GarminBuilder } from "gpx-builder";
import { Position } from "geojson";
import { GeoLocation } from "./geoLocationSlice";
import { geoLocationManager } from "./GeoLocationManager";

const builder = new GarminBuilder();

export const geolocationsToGPXString = (locations: GeoLocation[]): string => {
  const points = locations.map(
    (location) =>
      new GarminBuilder.MODELS.Point(location.latitude, location.longitude, {
        ele: location.altitude || undefined,
        time: new Date(location.timestamp),
        hr: location.accuracy,
      }),
  );
  builder.setSegmentPoints(points);
  return buildGPX(builder.toObject());
};

export const getCurrentGeolocation = async (): Promise<GeoLocation> => {
  const geolocation = await geoLocationManager.getLocation();
  return geolocation;
};

export const calculateDegreeByPoints = (point1: Position, point2: Position) => {
  const lon1 = point1[0];
  const lat1 = point1[1];
  const lon2 = point2[0];
  const lat2 = point2[1];

  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;

  const y = Math.sin(dLon) * Math.cos(lat2Rad);
  const x =
    Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLon);
  let brng = (Math.atan2(y, x) * 180) / Math.PI;

  // Normalize to 0-360
  brng = (brng + 360) % 360;

  return brng;
};
