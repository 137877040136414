import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from "../../app/store";

export interface PlaySoundType {
  played_snd_idx: number;
  pause_play_until_tick:number;
  played_dist:number;
  played_flag:boolean[];
}


interface SoundState {
  played: PlaySoundType[];
  cameraOverFirst:number;
}

const initialState: SoundState = {
  played: [
    {played_snd_idx: 0, pause_play_until_tick: 0, played_dist: 0, played_flag: [false, false, false, false, false]},
    {played_snd_idx: 0, pause_play_until_tick: 0, played_dist: 0, played_flag: [false, false, false, false, false]}
  ],  // played[0] : 드라이브 모드, played[1] : 모의주행모드
  cameraOverFirst: 0,
};

const soundSlice = createSlice({
  name: 'sound',
  initialState,
  reducers: {
    resetPlayData: (state, action: PayloadAction<number>) => {  
      if (action.payload !== 0 && action.payload !== 1) {
        return;
      }

      state.played[action.payload].played_dist = 0;
      state.played[action.payload].played_flag = [false, false, false, false, false];
    },
    setPlayedSound: (state, action: PayloadAction<{played:PlaySoundType, idx:number}>) => {
      state.played[action.payload.idx] = action.payload.played;
    },
    setCameraOverFirst: (state, action: PayloadAction<number>) => {
      state.cameraOverFirst = action.payload;    
    }
  },
});

export const { resetPlayData, setPlayedSound, setCameraOverFirst } = soundSlice.actions;
export const selectSound = (state: RootState) => state.sound;
export default soundSlice;