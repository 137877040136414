import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IWay } from "../db/OsmCache";

export type DebugMode = "route" | "log" | "shift" | "off";

interface DebugState {
  mode: DebugMode;
  disableAnimation: boolean;
  isExpanded1: boolean; // 디버그 모드 섹션
  isExpanded2: boolean; // 디스플레이 설정 섹션
  isExpanded3: boolean; // GPS 및 경로 섹션
  isHidden: boolean;
  showLinks: boolean;
  showRoute: boolean;
  showGPSLocation: boolean;
  debugLinks: IWay[];
  debugActiveLinkId: IWay["id"] | null;
  useMMV4: boolean;
  gpsPush1sec: boolean;
  autoResetZoom: boolean;
  showLaneTest: boolean;
}

const initialState: DebugState = {
  mode: "off",
  disableAnimation: false,
  isExpanded1: false,
  isExpanded2: false,
  isExpanded3: false,
  isHidden: false,
  showLinks: false,
  showRoute: false,
  showGPSLocation: false,
  debugLinks: [],
  debugActiveLinkId: null,
  useMMV4: true,
  gpsPush1sec: false,
  autoResetZoom: false,
  showLaneTest: false,
};

const debugSlice = createSlice({
  name: "debug",
  initialState,
  reducers: {
    setDebugMode: (state, { payload }: PayloadAction<DebugMode>) => {
      state.mode = payload;
    },
    setDisableAnimation: (state, { payload }: PayloadAction<boolean>) => {
      state.disableAnimation = payload;
    },
    toggleExpanded1: (state) => {
      state.isExpanded1 = !state.isExpanded1;
    },
    toggleExpanded2: (state) => {
      state.isExpanded2 = !state.isExpanded2;
    },
    toggleExpanded3: (state) => {
      state.isExpanded3 = !state.isExpanded3;
    },
    toggleHidden: (state) => {
      state.isHidden = !state.isHidden;
    },
    toggleShowLinks: (state) => {
      state.showLinks = !state.showLinks;
    },
    toggleShowGPSLocation: (state) => {
      state.showGPSLocation = !state.showGPSLocation;
    },
    toggleShowRoute: (state) => {
      state.showRoute = !state.showRoute;
    },
    setDebugLinks: (state, action: PayloadAction<IWay[]>) => {
      state.debugLinks = action.payload;
    },
    setDebugActiveLink: (state, action: PayloadAction<number | null>) => {
      state.debugActiveLinkId = action.payload;
    },
    toggleUseMMV4: (state) => {
      state.useMMV4 = !state.useMMV4;
    },
    toggleGpsPush1Sec: (state) => {
      state.gpsPush1sec = !state.gpsPush1sec;
    },
    toggleAutoResetZoom: (state) => {
      state.autoResetZoom = !state.autoResetZoom;
    },
    toggleShowLaneTest: (state) => {
      state.showLaneTest = !state.showLaneTest;
    },
  },
});

export default debugSlice;
export const {
  setDebugMode,
  setDisableAnimation,
  toggleExpanded1,
  toggleExpanded2,
  toggleExpanded3,
  toggleHidden,
  toggleShowLinks,
  toggleShowGPSLocation,
  toggleShowRoute,
  setDebugLinks,
  setDebugActiveLink,
  toggleUseMMV4,
  toggleGpsPush1Sec,
  toggleAutoResetZoom,
  toggleShowLaneTest,
} = debugSlice.actions;
export const selectDebug = (state: RootState) => state.debug;
