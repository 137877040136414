type RequestType = "geolocation" | "carPosition";

declare global {
  interface Window {
    HybridApp?: {
      WebToAndroid: (type: string, json: string) => void;
      playTts: (type: string, contents: string, priority: number) => void;
      stopTts: () => void;
      clearTts: () => void;
      isPlayingTts: () => boolean;
    };
  }
}

interface RequestPayload {
  type: RequestType;
  payload: any;
}

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

export const requestToNative = ({ type, payload }: RequestPayload) => {
  if (isAndroid() && window.HybridApp) {
    window.HybridApp.WebToAndroid(type, JSON.stringify(payload));
  }
};

export const playNativeTTS = (type: string, contents: string, priority: number) => {
  if (isAndroid() && window.HybridApp) {
    window.HybridApp.playTts(type, contents, priority);
  }
};

export const stopNativeTTS = () => {
  if (isAndroid() && window.HybridApp) {
    window.HybridApp.stopTts();
  }
};

export const clearNativeTTS = () => {
  if (isAndroid() && window.HybridApp) {
    window.HybridApp.clearTts();
  }
};

export const isPlayingNativeTTS = () => {
  if (isAndroid() && window.HybridApp) {
    return window.HybridApp.isPlayingTts();
  }
  return false;
};

export default {};
