import React, { useMemo, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { selectCarPosition } from "../../map/position/carPositionSlice";
import { selectView } from "../../map/view/viewSlice";
import TabPanel from "../../../components/TabPanel";
import ListView from "../../../components/ListView";
import { selectGuidePosition } from "../../map/position/guidePositionSlice";
import { WayProperties } from "../../db/OsmCache";
import { selectGeoLocation } from "../../geolocation/geoLocationSlice";
import { selectDebug } from "../debugSlice";
import { getTileCoordinates } from "../../osm/osmUtils";
import { OSM_PBF_TILE_LEVEL, ROUSEN_OSM_SERVER_URL } from "../../../app/config/const";

const MapStatus = () => {
  const { zoom, pitch, bearing, latitude, longitude } = useAppSelector(selectView);
  const { debugActiveLinkId, debugLinks } = useAppSelector(selectDebug);

  const activeLink = useMemo(() => {
    return debugLinks.find((link) => link.id === debugActiveLinkId);
  }, [debugActiveLinkId, debugLinks]);

  const netDownloadLink = useMemo(() => {
    const { x, y, z } = getTileCoordinates(longitude, latitude, OSM_PBF_TILE_LEVEL);
    const url = `${ROUSEN_OSM_SERVER_URL}/${z}/${x}/${y}.pbf`;
    return <a href={url}>{`${z}/${x}/${y}`}</a>;
  }, [longitude, latitude]);

  const mapStatus = useMemo(() => {
    const generalStatus = [
      { label: "Lon", value: longitude?.toFixed(6) },
      { label: "Lat", value: latitude?.toFixed(6) },
      { label: "Zoom", value: zoom?.toFixed(2) },
      { label: "Pitch", value: pitch?.toFixed(2) },
      { label: "Bearing", value: bearing?.toFixed(2) },
      { label: "OsmNet", value: netDownloadLink },
    ];

    if (activeLink === undefined) return generalStatus;

    const linkStatus = ["maxspeed", "length", "lanes", "road_level", "highway", "oneway"].map(
      (key) => ({
        label: key,
        value: activeLink.feature.properties![key as keyof WayProperties],
      }),
    );
    return [...generalStatus, { label: "Way Id", value: activeLink.id }, ...linkStatus];
  }, [zoom, pitch, bearing, latitude, longitude, activeLink, netDownloadLink]);

  return <ListView items={mapStatus} />;
};

const CarStatus = () => {
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { carPosition, indexOfLinkSegment, wayId } = useAppSelector(selectCarPosition);
  const carStatus = useMemo(() => {
    return [
      { label: "GPS Lon", value: geolocation?.longitude?.toFixed(6) },
      { label: "GPS Lat", value: geolocation?.latitude?.toFixed(6) },
      { label: "Car Lon", value: carPosition?.longitude?.toFixed(6) },
      { label: "Car Lat", value: carPosition?.latitude?.toFixed(6) },
      { label: "Accuracy", value: carPosition?.accuracy.toFixed(2) },
      { label: "Speed", value: carPosition?.speed?.toString() },
      { label: "Heading", value: carPosition?.heading?.toString() },
      { label: "Link Index Seg", value: indexOfLinkSegment?.toString() },
      { label: "Way Id", value: wayId?.toString() },
    ];
  }, [carPosition, indexOfLinkSegment, wayId, geolocation]);
  return <ListView items={carStatus} />;
};

const GuideStatus = () => {
  const { distanceFromStart, distanceInLink, position, linkPositionIndex } =
    useAppSelector(selectGuidePosition);

  const guideStatus = useMemo(() => {
    return [
      { label: "Lon", value: position?.longitude?.toFixed(6) },
      { label: "Lat", value: position?.latitude?.toFixed(6) },
      { label: "Distance From Start", value: distanceFromStart?.toFixed(2) },
      { label: "Distance In Link", value: distanceInLink?.toFixed(2) },
      { label: "Link Start Index", value: linkPositionIndex?.toString() },
    ];
  }, [position, distanceFromStart, distanceInLink, linkPositionIndex]);

  return <ListView items={guideStatus} />;
};

const StatusMonitor = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeActiveTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}
        onChange={handleChangeActiveTab}
      >
        <Tab label="지도" value={0} />
        <Tab label="차량" value={1} />
        <Tab label="가이드" value={2} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <MapStatus />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <CarStatus />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <GuideStatus />
      </TabPanel>
    </>
  );
};

export default StatusMonitor;
