import React from "react";
import { Marker } from "react-map-gl/maplibre";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import { selectDebug } from "../../../debug/debugSlice";
import { selectPrePositions } from "../../../match/prePosSlice";

const PredictLocationMarker = () => {
  const { showGPSLocation } = useAppSelector(selectDebug);
  const { predictPositions } = useAppSelector(selectPrePositions);

  if (!showGPSLocation || !predictPositions) return null;

  return predictPositions.map((predictPosition, index) => (
    <Marker
      // eslint-disable-next-line react/no-array-index-key
      key={`${index}-${predictPosition[0]}-${predictPosition[1]}`}
      latitude={predictPosition[1]}
      longitude={predictPosition[0]}
      pitchAlignment="map"
    >
      <Box width={15} height={15} borderRadius="50%" bgcolor="blue" />
    </Marker>
  ));
};

export default PredictLocationMarker;
