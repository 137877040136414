// routing 결과 card
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  ListItemAvatar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ItemIndexAvatar from "../../components/ItemIndex";
import { Path, selectRoute, setActivePathIndex } from "./routeSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getFormattedDistance, getFormattedTime } from "../guide/guideUtils";

// 소요시간 | 거리
const getRouteTitle = (path: Path) => {
  const time: string = getFormattedTime(path.time);
  const distance: string = path.distance ? ` ${getFormattedDistance(path.distance)}` : "";

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h6">{time}</Typography>
      <Box px={1} display="flex" alignItems="center">
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "24px",
            borderWidth: "0.8px",
            // borderColor: "",
          }}
        />
      </Box>
      <Typography variant="body2">{distance}</Typography>
    </Box>
  );
};

interface RouteListItemProps {
  path: Path;
  index: number;
}

// 주요 경유 도로 계산
const RouteListItem = ({ path, index }: RouteListItemProps) => {
  const { activePath } = useAppSelector(selectRoute);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const handleClickPath = (index1: number) => {
    dispatch(setActivePathIndex(index1));
  };

  // 예상 도착 시간 TODO: date-fns 적용
  const arrivalTime = useMemo(() => {
    return (totalRouteTime: number) => {
      let datelocale = "en-us";
      if (i18n.language === "ko") {
        datelocale = "ko-kr";
      } else if (i18n.language === "zhtw") {
        datelocale = "zh-tw";
      }

      return new Date(Date.now() + totalRouteTime).toLocaleTimeString(datelocale, {
        hour: "2-digit",
        minute: "2-digit",
      });
    };
  }, [i18n.language]);

  const routeRoadName: string = useMemo(() => {
    const roadNames = path.rousen_details?.road_names ?? [];
    const wayInfo = path.rousen_details?.way_info ?? [];

    // key: 도로명, value: 총 길이
    const roadLengths: { [key: string]: number } = {};

    let temp = 0;
    roadNames.forEach((road) => {
      // null 처리
      if (!road.text) return;
      // 도로명이 없는 도로
      if (!roadLengths[road.text]) {
        roadLengths[road.text] = 0;
      }

      wayInfo.forEach((way) => {
        // way_info 의 end_idx 가 roadname 의 last_idx 와 일치 할때까지 더함
        if (temp < way.end_idx && way.end_idx <= road.last_idx) {
          // 해당 도로명에 길이를 더함
          roadLengths[road.text] += way.length;
        }
      });
      temp = road.last_idx;
    });

    // 총 거리의 5% 이상인 도로만 필터
    const threshold = path.distance * 0.05;

    // roadLengths 컬렉션에서 상위 1개 추출
    const topRoads: { [key: string]: number } = Object.entries(roadLengths)
      .filter(([, length]) => length >= threshold) // threshold 이상인 도로만 필터
      .sort((a, b) => b[1] - a[1]) // 내림차순 정렬
      .slice(0, 1) // 1개 추출
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    let newRoadName = "";
    Object.keys(topRoads).forEach((key, i, array) => {
      newRoadName += key;
      if (i === array.length - 1) return;
      newRoadName += ", ";
    });
    return newRoadName;
  }, [path]);

  const getRouteDesc2 = (path_: Path) => {
    // 페리, 유료도로 사용 체크
    let useFerry = false;
    let usePay = false;
    let desc = "";
    path_.rousen_details?.road_details?.forEach((info) => {
      if (info.road_class === "ferry") {
        useFerry = true; // 페리는 유료도로 체크 별도 안함.
        return;
      }

      info.properties.forEach((prop) => {
        if (prop === "pay") {
          usePay = true;
        }
      });
    });

    if (useFerry) desc = t("Ferry");

    if (usePay) {
      if (desc.length > 0) desc += ", ";
      desc += t("Toll Road");
    }

    if (desc.length > 0) desc = t("Include AAA", { prop: desc });
    else desc = t("Free");
    return desc;
  };

  return (
    <Card
      key={path.time}
      sx={{
        height: "95px",
        padding: 0,
        display: "flex",
        position: "relative",
        border:
          activePath?.time === path.time
            ? `2px solid ${theme.palette.error.main}`
            : "2px solid transparent",
      }}
    >
      <CardActionArea onClick={() => handleClickPath(index)}>
        <CardContent
          sx={{
            textAlign: "left",
            height: "100%",
            width: "100%",
            padding: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ ml: "10px" }}>
            <ListItemAvatar>
              <ItemIndexAvatar active={activePath?.time === path.time}>{index + 1}</ItemIndexAvatar>
            </ListItemAvatar>
          </Box>
          <Box>
            <Box>
              <Typography variant="h6">{getRouteTitle(path)}</Typography>
            </Box>
            <Box paddingTop="4px" style={{ display: "flex", overflow: "hidden" }}>
              <Typography
                variant="body2"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "150px",
                }}
              >
                {routeRoadName}&nbsp;
                <span style={{ opacity: 0.5 }}>{getRouteDesc2(path)}</span>
              </Typography>
              <Typography>{arrivalTime(path.time)}&nbsp;도착</Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RouteListItem;
