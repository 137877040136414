import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { Place } from "../searchSlice";
import type { RootState } from "../../../app/store";

export interface HistoryItem {
  place: Place;
  created_at: string;
}

interface State {
  histories: HistoryItem[];
}

const initialState: State = {
  histories: [] as HistoryItem[],
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addHistory: (state, { payload }: PayloadAction<Place>) => {
      // 중복 확인
      const existingHistoryIndex = state.histories.findIndex(
        (history) => history.place.id === payload.id,
      );

      const formattedDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");

      if (existingHistoryIndex !== -1) {
        // 중복이면 created_at만 업데이트
        state.histories[existingHistoryIndex].created_at = formattedDate;
      } else {
        // 새로운 항목이면
        state.histories.unshift({
          place: payload,
          created_at: formattedDate,
        });
      }
    },
    removeHistory: (state, action: PayloadAction<Place>) => {
      state.histories = state.histories.filter((history) => history.place.id !== action.payload.id);
    },
    setHistories: (state, action: PayloadAction<State["histories"]>) => {
      state.histories = action.payload;
    },
  },
});

export const { addHistory, removeHistory, setHistories } = historySlice.actions;
export const selectHistory = (state: RootState) => state.history;
export default historySlice;
