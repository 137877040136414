
export function tileKey(x: number, y: number, z: number): string {
  return `${z}/${x}/${y}`;
}

export function getTileCoordinates(lon: number, lat: number, zoom: number): { x: number, y: number, z: number } {
  const z = zoom;
  const x = Math.floor((lon + 180) / 360 * 2**z);
  const y = Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * 2**z);
  return { x, y, z };
}

export function tileToBBox(x: number, y: number, z: number): GeoJSON.BBox {
  const n = 2**z;
  const west = x / n * 360 - 180;
  const east = (x + 1) / n * 360 - 180;
  const north = Math.atan(Math.sinh(Math.PI * (1 - 2 * y / n))) * 180 / Math.PI;
  const south = Math.atan(Math.sinh(Math.PI * (1 - 2 * (y + 1) / n))) * 180 / Math.PI;
  return [west, south, east, north];
}

export function tilesOnBBox(bbox: GeoJSON.BBox, z: number): Array<{x: number, y: number, z: number}> {
  const [minLon, minLat, maxLon, maxLat] = bbox;

  const minX = Math.floor((minLon + 180) / 360 * 2**z);
  const maxX = Math.floor((maxLon + 180) / 360 * 2**z);
  const minY = Math.floor((1 - Math.log(Math.tan(maxLat * Math.PI / 180) + 1 / Math.cos(maxLat * Math.PI / 180)) / Math.PI) / 2 * 2**z);
  const maxY = Math.floor((1 - Math.log(Math.tan(minLat * Math.PI / 180) + 1 / Math.cos(minLat * Math.PI / 180)) / Math.PI) / 2 * 2**z);

  const tiles: Array<{x: number, y: number, z: number}> = [];
  for (let x = minX; x <= maxX; x += 1) {
    for (let y = minY; y <= maxY; y += 1) {
      tiles.push({ x, y, z });
    }
  }
  return tiles;
}

