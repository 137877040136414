import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GeoLocation } from "../../../geolocation/geoLocationSlice";
import type { RootState } from "../../../../app/store";

export interface TransitionQueueItem {
  location: GeoLocation;
  index: number;
  wayId: number;
  // direction: boolean;
  isOnRoute: boolean;
  direction: boolean;
}

interface State {
  status: "idle" | "fulfilled" | "pending";
  transition: ViewTrasition | null;
}

interface ViewTrasition {
  prev: TransitionQueueItem | null;
  current: TransitionQueueItem;
  next: TransitionQueueItem | null;
}

const initialState: State = {
  transition: null,
  status: "idle",
};

const viewTransitionSlice = createSlice({
  name: "viewTransition",
  initialState,
  reducers: {
    setTransition: (state, action: PayloadAction<ViewTrasition>) => {
      state.transition = action.payload;
    },
    setStatus: (state, action: PayloadAction<State["status"]>) => {
      state.status = action.payload;
    },
    clearTransition: (state) => {
      state.transition = null;
      state.status = "idle";
    },
  },
});

export const { setTransition, setStatus, clearTransition } = viewTransitionSlice.actions;
export const selectViewTransition = (state: RootState) => state.viewTransition.transition;
export const selectViewTransitionStatus = (state: RootState) => state.viewTransition.status;
export default viewTransitionSlice;
