import React from "react";
import { Box, styled } from "@mui/material";
import { WaypointType } from "./waypointSlice";

interface Props {
  type: WaypointType;
  text?: string | number;
}

const StyledBox = styled(Box)<{ type: WaypointType }>(({ theme, type }) => {
  let bgColor = theme.palette.background.paper;
  if (type === "origin") {
    bgColor = theme.palette.success.main;
  } else if (type === "destination") {
    bgColor = theme.palette.primary.light;
  } else if (type === "waypoint") {
    bgColor = theme.palette.warning.main;
  }

  return {
    position: "relative",
    width: 32,
    height: 32,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: bgColor,
    fontSize: 12,
    marginBottom: 12,
    color: theme.palette.background.paper,
    "::after": {
      content: '""',
      position: "absolute",
      top: "calc(100% - 5px)",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderLeft: "12px solid transparent",
      borderRight: "12px solid transparent",
      borderTop: "13px solid",
      borderTopColor: bgColor,
    },
  };
});
const WaypointIcon = ({ type, text }: Props) => {
  let displayText;

  if (type === "origin") {
    displayText = "출발";
  } else if (type === "destination") {
    displayText = "도착";
  } else if (type === "waypoint") {
    displayText = `경유${text}`;
  }

  return <StyledBox type={type}>{displayText}</StyledBox>;
};

export default WaypointIcon;
