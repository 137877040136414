import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { MapLayerMouseEvent, MapLayerTouchEvent, MapLibreEvent } from "maplibre-gl";
import { useTranslation } from "react-i18next";
import { Position } from "geojson";
import MapLibre, { Marker, ViewStateChangeEvent } from "react-map-gl/maplibre";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectView, setViewState } from "./view/viewSlice";
import {
  clearUserAction,
  setCenterChanged,
  setContainerHeight,
  setZoomChanged,
  clearUserDragAction,
} from "./mapSlice";
import "maplibre-gl/dist/maplibre-gl.css";
import "./map.css";
import {
  USER_ACTION_CLEAR_TIMEOUT,
  USER_LONG_PRESS_THRESHOLD,
  MAP_STYLES,
} from "../../app/config/const";
import { selectDebug, setDebugActiveLink } from "../debug/debugSlice";
import { setGeoLocation } from "../geolocation/geoLocationSlice";
import NavigationControl from "../../components/NavigationControl";
import DebugLinkLine from "../debug/DebugLinkLine";
import LongPressContextMenu from "./LongPressContextMenu";
import Scale from "./Scale";

export type MapInstance = MapLibreEvent["target"];

interface MapProps {
  navigationControl?: boolean;
  catchUserAction?: boolean;
  children: ReactNode;
  onLoad?: (event: MapInstance) => void;
}

const Map = ({ children, catchUserAction, navigationControl = true, onLoad }: MapProps) => {
  const dispatch = useAppDispatch();
  const viewState = useAppSelector(selectView);
  const [mapInstance, setMapInstance] = useState<MapInstance | null>(null);
  const theme = useTheme();
  const { i18n } = useTranslation();
  const { mode, showLinks } = useAppSelector(selectDebug);
  const { autoResetZoom } = useAppSelector(selectDebug);

  // 지도 언어 변경
  useEffect(() => {
    if (!mapInstance) return;
    const { layers } = mapInstance.getStyle();
    layers.forEach((layer) => {
      if (layer.id.endsWith("labels") && layer.id !== "Road labels") {
        mapInstance.setLayoutProperty(layer.id, "text-field", [
          "coalesce",
          ["get", `name:${i18n.resolvedLanguage}`],
        ]);
      }
    });
  }, [mapInstance, i18n.resolvedLanguage]);

  const handleMapLoad = useCallback(
    (event: MapLibreEvent) => {
      setMapInstance(event.target);
      dispatch(setContainerHeight(event.target.getContainer().clientHeight));
      onLoad?.(event.target);
    },
    [onLoad, dispatch],
  );

  const longPressTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (event: ViewStateChangeEvent) => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }

    dispatch(setViewState(event.viewState));
  };

  const handleResize = (event: MapLibreEvent) => {
    dispatch(setContainerHeight(event.target.getContainer().clientHeight));
  };

  const handleClick = (event: MapLayerMouseEvent) => {
    if (showLinks) {
      const features = mapInstance?.queryRenderedFeatures(event.point, { layers: ["debug-link"] });
      if (features) {
        dispatch(setDebugActiveLink((features[0]?.id as number) || null));
      }
    }

    if (mode !== "shift") return;

    dispatch(
      setGeoLocation({
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng,
        accuracy: 1,
        heading: 0,
        speed: 0,
        timestamp: Date.now(),
        altitude: 0,
        altitudeAccuracy: 0,
      }),
    );
  };

  // 확대/축소 버튼
  const onChangeZoom = useCallback(
    (num: number) => {
      if (!mapInstance) return;

      let newZoom = mapInstance.getZoom() + num;
      if (newZoom < 5) newZoom = 5;
      if (newZoom > 24) newZoom = 24;
      mapInstance?.flyTo({ zoom: newZoom });
    },
    [mapInstance],
  );

  const userActionTimeoutRef = useRef<NodeJS.Timeout>();

  const handleDragStart = () => {
    if (!catchUserAction) return;
    dispatch(setCenterChanged(true));

    if (userActionTimeoutRef.current) {
      clearTimeout(userActionTimeoutRef.current);
    }

    userActionTimeoutRef.current = setTimeout(() => {
      if (autoResetZoom) {
        dispatch(clearUserDragAction());
      } else {
        dispatch(clearUserAction());
      }
    }, USER_ACTION_CLEAR_TIMEOUT);
  };

  const handleZoomStart = () => {
    if (!catchUserAction) return;
    dispatch(setZoomChanged(true));

    if (autoResetZoom) return;

    if (userActionTimeoutRef.current) {
      clearTimeout(userActionTimeoutRef.current);
    }

    userActionTimeoutRef.current = setTimeout(() => {
      dispatch(clearUserAction());
    }, USER_ACTION_CLEAR_TIMEOUT);
  };

  useEffect(() => {
    return () => {
      if (userActionTimeoutRef.current) {
        clearTimeout(userActionTimeoutRef.current);
        dispatch(clearUserAction());
      }
    };
  }, [dispatch]);

  const [contextMenu, setContextMenu] = useState<Position | null>(null);

  const handleMouseDown = (e: MapLayerMouseEvent) => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }
    longPressTimerRef.current = setTimeout(() => {
      setContextMenu([e.lngLat.lng, e.lngLat.lat]);
    }, USER_LONG_PRESS_THRESHOLD * 1.5);
  };

  const handleMouseUp = () => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }
  };

  const handleTouchStart = (e: MapLayerTouchEvent) => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }
    longPressTimerRef.current = setTimeout(() => {
      setContextMenu([e.lngLat.lng, e.lngLat.lat]);
    }, USER_LONG_PRESS_THRESHOLD * 1.5);
  };

  const handleTouchEnd = () => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }
  };

  return (
    <Box position="relative" height="100%">
      <MapLibre
        {...viewState}
        onMove={handleChange}
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
        mapStyle={MAP_STYLES[theme.palette.mode]}
        antialias
        attributionControl={false}
        onClick={handleClick}
        onLoad={handleMapLoad}
        onRemove={() => setMapInstance(null)}
        onResize={handleResize}
        onDragStart={handleDragStart}
        onZoomStart={handleZoomStart}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <DebugLinkLine />
        {contextMenu && <Marker latitude={contextMenu[1]} longitude={contextMenu[0]} />}
        {children}
      </MapLibre>
      {navigationControl && (
        <NavigationControl
          position="absolute"
          right={8}
          zIndex={1}
          bottom="10%"
          onChangeView={onChangeZoom}
        />
      )}
      <Scale />
      <LongPressContextMenu position={contextMenu} onClose={() => setContextMenu(null)} />
    </Box>
  );
};

export default memo(Map);
