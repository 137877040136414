import { Add, FastForward, FastRewind, Pause, PlayArrow, Remove } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Slider, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useAppSelector } from "../app/hooks";
import { selectSimulator } from "../features/debug/simulator/simulatorSlice";

interface Props {
  value: number;
  disabled: boolean;
  marks: { value: number; label: string }[];
  play: boolean;
  speed: number;
  onPlay: () => void;
  onPause: () => void;
  onChange: (value: number) => void;
  onChangeSpeed: (speed: number) => void;
  onFastForward: () => void;
  onFastRewind: () => void;
}

const Player = ({
  value,
  disabled,
  marks,
  play,
  speed,
  onFastForward,
  onFastRewind,
  onChange,
  onChangeSpeed,
  onPause,
  onPlay,
}: Props) => {
  const { timelines } = useAppSelector(selectSimulator);

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      onChange(newValue);
    }
  };

  const handleChangeSpeed = (num: number) => {
    if (speed + num < 1) return;
    onChangeSpeed(speed + num);
  };

  const getValueLablFormat = useCallback(
    (v: number) => {
      let target = timelines.find((timeline) => timeline.value === v);
      if (!target) {
        target = [...timelines].reverse().find((point) => point.value < v);
      }
      return !target ? "" : new Date(target.timestamp).toLocaleTimeString();
    },
    [timelines],
  );

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2} alignItems="center" pt={2}>
        <Box width={40} textAlign="center">
          <Typography noWrap>출발</Typography>
        </Box>
        <Slider
          value={value}
          disabled={disabled}
          marks={marks}
          min={marks[0]?.value}
          max={marks[1]?.value}
          onChange={handleSliderChange}
          valueLabelDisplay={disabled ? "off" : "on"}
          valueLabelFormat={getValueLablFormat}
        />
        <Box width={40} textAlign="center">
          <Typography noWrap>도착</Typography>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={2} alignItems="center">
        <ButtonGroup disabled={disabled} size="small">
          <Button onClick={onFastRewind}>
            <FastRewind />
          </Button>
          {play ? (
            <Button onClick={onPause}>
              <Pause />
            </Button>
          ) : (
            <Button onClick={onPlay}>
              <PlayArrow />
            </Button>
          )}
          <Button onClick={onFastForward}>
            <FastForward />
          </Button>
        </ButtonGroup>
        <Button
          variant="outlined"
          size="small"
          disabled={disabled}
          onClick={() => handleChangeSpeed(1)}
        >
          <Add />
        </Button>
        <Typography>{speed}.0</Typography>
        <Button
          variant="outlined"
          size="small"
          disabled={disabled}
          onClick={() => handleChangeSpeed(-1)}
        >
          <Remove />
        </Button>
      </Stack>
    </Stack>
  );
};

export default Player;
