import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, ButtonGroup, Paper, Stack, Typography } from "@mui/material";
import { NearMe, Home, Apartment } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl/maplibre";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import SwipeableDrawer from "../components/SwipeableDrawer";
import { clearWaypoints, createWaypoint, setWayPoints } from "../features/waypoint/waypointSlice";
import { clearGuide } from "../features/guide/guideSlice";
import ProfileDrawer from "../features/profile/ProfileDrawer";
import Map from "../features/map/Map";
import { selectGeoLocation } from "../features/geolocation/geoLocationSlice";
import ProfileToggleButton from "../components/ProfileToggleButtonProps";
import SearchDialog from "../features/search/SearchDialog";
import { HistoryItem, selectHistory } from "../features/search/history/historySlice";
import SearchListItem from "../features/search/SearchListItem";
import { Place } from "../features/search/searchSlice";
import { useDialog } from "../components/Dialog";
import { clearUserAction, selectMap } from "../features/map/mapSlice";
import { setPartialViewState } from "../features/map/view/viewSlice";
import { GetFavoritesResponse, useGetFavoritesQuery } from "../features/favorites/favoritesApi";
import { selectToken } from "../features/token/tokenSlice";
import { useGetHistoryQuery } from "../features/search/history/historyApi";
import { carImgUrl } from "../app/config/images";
import { selectDebug } from "../features/debug/debugSlice";

const Search = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isCenterChanged, isZoomChanged } = useAppSelector(selectMap);
  const { token } = useAppSelector(selectToken);
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { t } = useTranslation();
  const { histories } = useAppSelector(selectHistory);
  const { data: historiesDB = [] } = useGetHistoryQuery(undefined, {
    skip: Boolean(!token),
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const { autoResetZoom } = useAppSelector(selectDebug);

  const removeDuplicatesAndSort = (local: HistoryItem[], server: HistoryItem[]): HistoryItem[] => {
    // 두 개의 배열을 합침
    const combinedHistories = [...local, ...server];

    const uniqueHistories: HistoryItem[] = [];

    // 중복 제거
    combinedHistories.forEach((history) => {
      const existingIndex = uniqueHistories.findIndex((item) => item.place.id === history.place.id);

      if (existingIndex !== -1) {
        if (
          new Date(history.created_at).getTime() >
          new Date(uniqueHistories[existingIndex].created_at).getTime()
        ) {
          uniqueHistories[existingIndex] = history;
        }
      } else {
        uniqueHistories.push(history);
      }
    });

    // created_at 기준으로 정렬
    uniqueHistories.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

    return uniqueHistories;
  };

  const deduplicatedHistories = useMemo(() => {
    return removeDuplicatesAndSort(histories, historiesDB);
  }, [histories, historiesDB]);

  const { enqueueSnackbar } = useSnackbar();

  // search 페이지 진입 시 안내 관련 상태 전부 초기화
  useEffect(() => {
    dispatch(clearGuide());
    dispatch(clearWaypoints());
  }, [dispatch]);

  const searchDialog = useDialog();
  const [profileDialog, setProfileDialog] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(true);

  const onToggleDrawer = useCallback(() => setSearchDrawer((prev) => !prev), []);
  const onToggleProfile = useCallback(() => setProfileDialog((prev) => !prev), []);

  const onSubmitPlace = useCallback(
    (place: Place) => {
      try {
        if (!geolocation) throw new Error("Cannot find current location");
        const { latitude, longitude } = geolocation;
        dispatch(
          setWayPoints([
            createWaypoint({
              name: "현위치",
              distance: 0,
              point: { lat: latitude, lng: longitude },
              id: "current",
              jibunAddress: "",
              roadAddress: "",
              type: "current",
            }),
            createWaypoint(place),
          ]),
        );
        navigate("/routing");
      } catch (error: any) {
        enqueueSnackbar({ variant: "error", message: error.message });
      }
    },
    [dispatch, enqueueSnackbar, geolocation, navigate],
  );

  useEffect(() => {
    if (geolocation && !isCenterChanged && (!isZoomChanged || autoResetZoom)) {
      const viewState: {
        latitude: number;
        longitude: number;
        pitch: number;
        padding: { top: number; right: number; left: number; bottom: number };
        zoom?: number;
      } = {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
        pitch: 0,
        padding: { top: 0, right: 0, left: 0, bottom: 0 },
      };

      if (!autoResetZoom) {
        viewState.zoom = 16;
      }

      dispatch(setPartialViewState(viewState));
    }
  }, [isCenterChanged, isZoomChanged, geolocation, autoResetZoom, dispatch]);

  // 즐겨찾기 목록
  const { data: favorites } = useGetFavoritesQuery(undefined, {
    skip: Boolean(!token),
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  // result.home 집 , result.company 회사
  const result = useMemo(() => {
    // 즐겨찾기 목록에서 집과 회사만 가져오기
    const findAddressByType = (type: string): GetFavoritesResponse | null => {
      const item = favorites?.primary.find((i) => i.name === type);
      return item ?? null;
    };

    return {
      home: findAddressByType("집"),
      company: findAddressByType("회사"),
    };
  }, [favorites]);

  return (
    <>
      <Box position="absolute" top={8} left={8} zIndex={(theme) => theme.zIndex.drawer + 1}>
        <ProfileToggleButton onToggleProfile={onToggleProfile} />
      </Box>

      {(isCenterChanged || isZoomChanged) && (
        <Box position="absolute" left={8} bottom={120} zIndex={1}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<NearMe />}
            onClick={() => dispatch(clearUserAction())}
          >
            {t("Current")}
          </Button>
        </Box>
      )}

      {/* 검색 다이얼로그 */}
      <SearchDialog
        open={searchDialog.open}
        onClose={searchDialog.onClose}
        onSubmit={onSubmitPlace}
      />

      {/* 하단 드로어 */}
      <SwipeableDrawer
        open={searchDrawer}
        tabSize={100}
        onClose={onToggleDrawer}
        onOpen={onToggleDrawer}
        contentHeight="38vh"
        header={
          <Button
            fullWidth
            size="large"
            variant="outlined"
            onClick={searchDialog.onOpen}
            sx={{ pointerEvents: "initial" }}
          >
            {t("Where to go?")}
          </Button>
        }
      >
        {/* 집/회사/즐겨찾기 버튼 */}
        <ButtonGroup variant="outlined" fullWidth sx={{ p: 1, pt: 0 }}>
          <Button
            startIcon={<Home />}
            onClick={() => {
              if (token) {
                if (result.home) {
                  onSubmitPlace({
                    id: result.home.idx.toString(),
                    distance: 0,
                    jibunAddress: "",
                    name: "집",
                    roadAddress: result.home.addr,
                    point: { lat: result.home.lat, lng: result.home.lon },
                    type: "home",
                  });
                } else {
                  navigate("/bookmark", { state: { editMode: true } });
                }
              } else {
                navigate("/signin");
              }
            }}
          >
            집
          </Button>
          <Button
            startIcon={<Apartment />}
            onClick={() => {
              if (token) {
                if (result.company) {
                  onSubmitPlace({
                    id: result.company.idx.toString(),
                    distance: 0,
                    jibunAddress: "",
                    name: "회사",
                    roadAddress: result.company.addr,
                    point: { lat: result.company.lat, lng: result.company.lon },
                    type: "company",
                  });
                } else {
                  navigate("/bookmark", { state: { editMode: true } });
                }
              } else {
                navigate("/signin");
              }
            }}
          >
            회사
          </Button>
          <Button
            onClick={() => {
              if (token) {
                navigate("/bookmark");
              } else {
                navigate("/signin");
              }
            }}
          >
            즐겨찾기
          </Button>
        </ButtonGroup>
        {/* 최근 목적지 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">{t("Recent results")}</Typography>
        </Box>
        <Stack spacing={0.5}>
          {deduplicatedHistories.map((history) => (
            <Paper key={history.created_at}>
              <SearchListItem place={history.place} type="history" onClick={onSubmitPlace} />
            </Paper>
          ))}
        </Stack>
      </SwipeableDrawer>

      {/* 프로필 드로어 */}
      <ProfileDrawer open={profileDialog} onClose={onToggleProfile} />

      {/* 지도  */}
      <Map catchUserAction navigationControl={false}>
        {geolocation && (
          <Marker latitude={geolocation.latitude} longitude={geolocation.longitude}>
            <img src={carImgUrl} alt="car" width="80" />
          </Marker>
        )}
      </Map>
    </>
  );
};

export default Search;
