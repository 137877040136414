import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { BackCarChecker, GpsData, MAPMATCH_DIR, MAPMATCH_QUALITY, MAPMATCH_ROUTE_STATUS, MapMatchCandidateResult, MapMatchResult, rpoint } from "./MapMatchStructs";

export interface MapMatch2State {
  lastGpsSpeeds: number[];
  lastGpsSpeedIdx: number;
  mmOkCount: number;
  gps: GpsData;
  prevGps: GpsData;
  result:MapMatchResult;
  useTunnelMode: boolean;
  tunnelEnd: number;
  tunnelMode: number;
  tunnelSpeed: number;
  prevMMPos: rpoint;
  tunnelNextLinkIdx: number;
  tunnelEndDalay: number;
  candiNum: number;
  candidates:MapMatchCandidateResult[];
  gpsDist: number;
  competition:boolean;
  competitionDelay:number;
  stableCnt:number;
  forwardDepth:number;
  userChangeLinkIdx:number;
  userChangeScore:number;
  searchRange:boolean;
  uturn:boolean;
  nearTG:boolean;
  tunnelRoadClass:number;
  useTunnelSticky:boolean;
  researchCount:number;
  offroadDelay:number;
  tunnelRTDelay:number;
  offRoadCompetition:boolean;
  offRoadCompetitionCnt:number;
  offRoadCompetitionPos:rpoint;
  dirDelay:number;
  prevTickCount:number;
  lastprevMM_linkIdx:number;
  lastprevMM_dir:MAPMATCH_DIR;
  ucounter:number;
  prevMMlinkIdx:number;
  prevMMdir:MAPMATCH_DIR;
  backCarChecker:BackCarChecker;
  bIgnoreSpeedLimit:boolean;
  pushPoint:number;
}

const initialState: MapMatch2State = {
  lastGpsSpeedIdx:0,
  lastGpsSpeeds: [0,0,0,0,0,0,0,0,0,0],
  mmOkCount: 0,
  gps: {
    latitude: 0,
    longitude: 0,
    altitude: 0,
    speed: 0,
    degree: 0,
    loc_time: {
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      min: 0,
      sec: 0,
      msec: 0
    }
  },
  prevGps: {
    latitude: 0,
    longitude: 0,
    altitude: 0,
    speed: 0,
    degree: 0,
    loc_time: {
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      min: 0,
      sec: 0,
      msec: 0
    }
  },
  result: {
    x: 0,
    y: 0,
    carDegree: 0,
    gpsDegree: 0,
    // mapIdx: 0,
    linkIdx: -1,
    vtxIdx: -1,
    vtxDist: 0,
    dist: 0,
    dir: MAPMATCH_DIR.UNKNOWN,
    quality: MAPMATCH_QUALITY.UNKNOWN,
    routeStatus: MAPMATCH_ROUTE_STATUS.ONGUIDE,
    speed1000: 0,
    roadName: "",
    crossName: "",
    isHighway: false,
    roadType: 0,
    roadType2: 0,
    roadClass2: 0,
    kwt_roadid: 0,
    kwt_kind: 0,
    kwt_rclass: 0,
    kwt_road_speed: 0,
    kwt_is_auto_moving: false,
    Competition: false,
    U_turn: false,
    is_tunnel: false,
    is_underpass: false,
    details: {
      mm_pt: { x: 0, y: 0 },
      pt1: { x: 0, y: 0 },
      pt2: { x: 0, y: 0 },
      angle: 0,
      map_idx: 0,
      link_idx: 0,
      dir: 0,
      vtx_idx: 0,
      vtx_dist: 0,
      road_grade: 0,
      link_type: 0,
      facil: 0,
      zpos: 0,
      pitch: 0
    }
  },
  useTunnelMode: true,
  tunnelEnd: 0,
  tunnelMode: 0,
  tunnelSpeed: 0,
  prevMMPos: {x:0, y:0},
  tunnelNextLinkIdx: -1,
  tunnelEndDalay: 0,
  candiNum: 0,
  gpsDist: 0,
  candidates: [],
  competition:false,
  competitionDelay:0,
  stableCnt:0,
  forwardDepth:0,
  userChangeLinkIdx:-1,
  userChangeScore:0,
  searchRange:false,
  uturn:false,
  nearTG:false,
  useTunnelSticky:false,
  tunnelRoadClass:0,
  researchCount:0,
  offroadDelay:0,
  tunnelRTDelay:5,
  offRoadCompetition:false,
  offRoadCompetitionCnt:0,
  offRoadCompetitionPos:{x:0, y:0},
  dirDelay:0,
  prevTickCount:0,
  lastprevMM_linkIdx:0,
  lastprevMM_dir:MAPMATCH_DIR.UNKNOWN,
  ucounter:0,
  prevMMlinkIdx:0,
  prevMMdir:MAPMATCH_DIR.UNKNOWN,
  backCarChecker: {
    prevX: 0,
    prevY: 0,
    PrevAngle: 0
  },
  bIgnoreSpeedLimit:false,
  pushPoint:0
};

const mapMatch2Slice = createSlice({
  name: "mapMatch2",
  initialState,
  reducers: {
    setMapMatchedData: (state, { payload }: PayloadAction<MapMatch2State>) => {
      state.mmOkCount = payload.mmOkCount;
      state.gps = payload.gps;
      state.result = payload.result;
      state.useTunnelMode = payload.useTunnelMode;
      state.tunnelEnd = payload.tunnelEnd;
      state.tunnelMode = payload.tunnelMode;
      state.tunnelSpeed = payload.tunnelSpeed;
      state.prevMMPos = payload.prevMMPos;
      state.tunnelNextLinkIdx = payload.tunnelNextLinkIdx;
      state.tunnelEndDalay = payload.tunnelEndDalay;
      state.candiNum = payload.candiNum;
      state.candidates = payload.candidates;
      state.gpsDist = payload.gpsDist;
      state.competition = payload.competition;
      state.competitionDelay = payload.competitionDelay;
      state.stableCnt = payload.stableCnt;
      state.forwardDepth = payload.forwardDepth;
      state.userChangeLinkIdx = payload.userChangeLinkIdx;
      state.userChangeScore = payload.userChangeScore;
      state.searchRange = payload.searchRange;
      state.uturn = payload.uturn;
      state.nearTG = payload.nearTG;
      state.useTunnelSticky = payload.useTunnelSticky;
      state.tunnelRoadClass = payload.tunnelRoadClass;
      state.researchCount = payload.researchCount;
      state.offroadDelay = payload.offroadDelay;
      state.tunnelRTDelay = payload.tunnelRTDelay;
      state.offRoadCompetition = payload.offRoadCompetition;
      state.offRoadCompetitionCnt = payload.offRoadCompetitionCnt;
      state.offRoadCompetitionPos = payload.offRoadCompetitionPos;
      state.dirDelay = payload.dirDelay;
      state.prevTickCount = payload.prevTickCount;
      state.lastprevMM_linkIdx = payload.lastprevMM_linkIdx;
      state.lastprevMM_dir = payload.lastprevMM_dir;
      state.ucounter = payload.ucounter;
      state.prevMMlinkIdx = payload.prevMMlinkIdx;
      state.prevMMdir = payload.prevMMdir;
      state.backCarChecker = payload.backCarChecker;
      state.prevGps = payload.prevGps;
      state.bIgnoreSpeedLimit = payload.bIgnoreSpeedLimit;
    },
    setLastSpeed: (state, { payload }: PayloadAction<number>) => {
      state.lastGpsSpeeds[state.lastGpsSpeedIdx] = payload;
      state.lastGpsSpeedIdx = (state.lastGpsSpeedIdx + 1) % 10;
    },
    setPushPoint(state, { payload }: PayloadAction<number>) {
      state.pushPoint = payload;
    },
    changeCandidate:(state) => {
      state.userChangeLinkIdx = state.candidates[0].linkIdx;
    },

  },
});

export const { setMapMatchedData, setLastSpeed, setPushPoint, changeCandidate } = mapMatch2Slice.actions;
export const selectMapMatch2 = (state: RootState) => state.mapMatch2;
export default mapMatch2Slice;
