import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as turf from "@turf/turf";
import { SEARCH_API_URL } from "../../app/config/const";
import { Place } from "./searchSlice";

export interface Coordinate {
  lat: number;
  lon: number;
}

type SearchPlaceByTextResponse = Place[];
type SearchPlaceByCoordsResponse = Place[];

export type SearchType = "distance" | "keyword";
export interface SearchPlaceByTextRequest {
  type: SearchType;
  keyword: string;
  size: number;
  lat?: number;
  lon?: number;
  page: number;
}
interface SearchPlaceByCoordsRequest {
  lat: number;
  lon: number;
}

const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SEARCH_API_URL}/geocode`,
  }),
  endpoints: (builder) => ({
    searchPlacesByText: builder.query<SearchPlaceByTextResponse, SearchPlaceByTextRequest>({
      query: (request) => ({
        url: "",
        params: {
          q: request.keyword,
          size: request.size,
          page: request.page,
          ...(request.type === "distance" && {
            lat: request.lat,
            lon: request.lon,
          }),
        },
      }),
      transformResponse: (response: SearchPlaceByTextResponse, meta, arg) => {
        const filterdOptions: Place[] = [];
        response.forEach((option) => {
          if (
            !filterdOptions.some(
              (o) => o.roadAddress === option.roadAddress && o.name === option.name,
            )
          ) {
            const dist =
              arg.lat && arg.lon
                ? turf.distance([arg.lon, arg.lat], [option.point.lng, option.point.lat], {
                    units: "meters",
                  })
                : 0;
            option.distance = dist;
            filterdOptions.push(option);
          }
        });
        return filterdOptions;
      },
    }),
    searchPlaceByCoords: builder.mutation<Place, SearchPlaceByCoordsRequest>({
      query: (requset) => ({
        url: "reverse",
        params: {
          q: `${requset.lat},${requset.lon}`,
        },
      }),
      transformResponse: (response: SearchPlaceByCoordsResponse) => {
        return response.find((place) => Boolean(place.name)) || response[0];
      },
    }),
  }),
  tagTypes: [],
});

export const { useSearchPlaceByCoordsMutation, useSearchPlacesByTextQuery } = searchApi;

export default searchApi;
