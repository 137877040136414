import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GeoLocation } from "../../geolocation/geoLocationSlice";
import type { RootState } from "../../../app/store";

export interface CarPositionState {
  carPosition: GeoLocation | null; // map-matched car geo-location
  distanceForward: number | null; // distance from the start of the link
  distanceBackward: number | null; // distance from the end of the link
  wayId: number | null; // id of link(way) that the car is on
  direction: boolean | null; // forward direction of the car on the link
  onRoad: boolean; // whether the car is on road(map-matched)
  onRoute: boolean | null; // whether the car is on route
  onReset: boolean | null; // whether the car position is reset, because of init state, changing geolocation provider, or etc.

  distanceInLinkSegment: number | null;
  indexOfLinkSegment: number | null;
}

const initialState: CarPositionState = {
  carPosition: null,
  distanceForward: null,
  distanceBackward: null,
  wayId: null,
  direction: null,
  onRoad: false,
  onRoute: null,
  onReset: null,
  distanceInLinkSegment: null,
  indexOfLinkSegment: null,
};

const carPositionSlice = createSlice({
  name: "carPosition",
  initialState,
  reducers: {
    setCarPosition: (state, { payload }: PayloadAction<CarPositionState>) => {
      state.carPosition = payload.carPosition;
      state.wayId = payload.wayId;
      state.direction = payload.direction === null ? state.direction : payload.direction;
      state.onRoad = payload.onRoad;
      state.onRoute = payload.onRoute;
      state.onReset = payload.onReset;
      state.indexOfLinkSegment = payload.indexOfLinkSegment;
      state.distanceInLinkSegment = payload.distanceInLinkSegment;
      state.distanceForward = payload.distanceForward;
      state.distanceBackward = payload.distanceBackward;
    },
    clearCarPosition: () => {
      return initialState;
    },
  },
});

export const { setCarPosition, clearCarPosition } = carPositionSlice.actions;
export const selectCarPosition = (state: RootState) => state.carPosition;
export default carPositionSlice;
