import React, { useMemo, useState } from "react";
import {
  Box,
  CardActionArea,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAppSelector } from "../../app/hooks";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { selectGuidances } from "./guideSlice";
import { getFormattedDistance, getGuideSignImageUrl } from "./guideUtils";
import { selectWaypoint } from "../waypoint/waypointSlice";

const NextGuide = () => {
  const [expanded, setExpanded] = useState(false);
  const guidances = useAppSelector(selectGuidances);
  const { guidanceIndex } = useAppSelector(selectGuidePosition);
  const { waypoints } = useAppSelector(selectWaypoint);

  const isGuideEnded = useMemo(
    () =>
      guidanceIndex === null ||
      guidanceIndex < 0 ||
      guidanceIndex >= guidances.length ||
      waypoints.length < 2 /* 목적지 터치다운 후 목적지 웨이포인트 없음 */,
    [guidances, guidanceIndex, waypoints],
  );

  const nextGuideDistance: string = useMemo(() => {
    if (guidanceIndex === null) return "";
    const targetGuide = guidances[guidanceIndex + 1];
    if (!targetGuide || isGuideEnded) return "";
    return getFormattedDistance(targetGuide.guidedist);
  }, [guidances, guidanceIndex, isGuideEnded]);

  const isNextGuideExist = useMemo(() => {
    if (guidanceIndex === null || guidances.length === 0) return false;
    return guidances.length > guidanceIndex + 1;
  }, [guidanceIndex, guidances]);

  const theme = useTheme();
  const handleToggleExpaned = () => setExpanded((prev) => !prev);

  if (!isNextGuideExist || !guidanceIndex) return null;

  return (
    <Box position="absolute" width="50%" right={0} top="calc(100% - 56px)">
      <CardActionArea onClick={handleToggleExpaned}>
        <Paper
          sx={{
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderBottomLeftRadius: 0,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
            <Box display="flex" alignItems="center">
              <img
                src={getGuideSignImageUrl(guidances[guidanceIndex + 1])}
                alt="Arrow"
                style={{ aspectRatio: 1, width: 40, opacity: 0.7 }}
              />
              <Box ml={2}>
                <Typography variant="h5" sx={{ opacity: 0.7 }}>
                  {nextGuideDistance}
                </Typography>
              </Box>
            </Box>

            {expanded ? <ExpandLess /> : <ExpandMore />}
          </Box>
          <Collapse in={expanded} unmountOnExit>
            <List
              sx={{
                maxHeight: 400,
                overflowY: "auto",
              }}
              dense
            >
              {guidances.map((guidance, index) => {
                if (index <= guidanceIndex + 2) return null;
                return (
                  <ListItem key={guidance.acctime} divider>
                    <ListItemAvatar>
                      <img
                        src={getGuideSignImageUrl(guidance)}
                        alt="guidance sign"
                        style={{ aspectRatio: 1, width: 40, opacity: 0.65 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={getFormattedDistance(guidance.partdist)}
                      primaryTypographyProps={{ variant: "caption" }}
                      secondary={guidance.text}
                      secondaryTypographyProps={{ color: "inherit", variant: "caption" }}
                      sx={{ opacity: 0.65 }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </Paper>
      </CardActionArea>
    </Box>
  );
};

export default NextGuide;
