import {
  Box,
  SwipeableDrawer as MuiSwipeableDrawer,
  SwipeableDrawerProps,
  styled,
} from "@mui/material";
import React, { ReactNode } from "react";

const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

const StyledDrawer = styled(MuiSwipeableDrawer, {
  shouldForwardProp: (prop) => prop !== "totalHeight",
})<{ totalHeight: string }>(() => ({
  // top: "auto",
  // height: totalHeight,
  "& > .MuiPaper-root": {
    overflow: "visible",
  },
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 70,
  height: 6,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "50%",
  transform: "translate(-50%)",
}));

const TabBox = styled(Box, { shouldForwardProp: (prop) => prop !== "tabSize" })<{
  tabSize: number;
}>(({ theme, tabSize }) => ({
  top: -tabSize + 1,
  width: "100%",
  visibility: "visible",
  position: "absolute",
  background: theme.palette.background.default,
  height: tabSize,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  boxShadow: theme.shadows[3],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ContentBox = styled(Box, { shouldForwardProp: (prop) => prop !== "contentHeight" })<{
  contentHeight: number | string;
}>(({ theme, contentHeight }) => ({
  overflowY: "auto",
  background: theme.palette.background.default,
  position: "relative",
  height: contentHeight,
  // borderTop: `1px solid ${theme.palette.divider}`,
}));

interface Props extends SwipeableDrawerProps {
  header?: ReactNode;
  children: ReactNode;
  tabSize: number;
  contentHeight: number | string;
}

const SwipeableDrawer = ({ children, header, tabSize, contentHeight, ...restProps }: Props) => {
  return (
    <StyledDrawer
      anchor="bottom"
      swipeAreaWidth={tabSize + 10}
      totalHeight={`calc(${tabSize}px + ${contentHeight})`}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      disableSwipeToOpen={false}
      autoFocus={false}
      disableEnforceFocus
      disableAutoFocus
      slotProps={{ backdrop: { sx: { background: "none" } } }}
      {...restProps}
    >
      <TabBox tabSize={tabSize} pt={3}>
        <Puller />
        <Box width="100%" p={2}>
          {header}
        </Box>
      </TabBox>
      <ContentBox contentHeight={contentHeight} p={2}>
        {children}
      </ContentBox>
    </StyledDrawer>
  );
};

export default SwipeableDrawer;
