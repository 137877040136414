import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Collapse,
  Paper,
  Divider,
  CardActionArea,
  ListItem,
  ListItemAvatar,
  Skeleton,
  ListItemText,
  ButtonBase,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos, ExpandLess, ExpandMore, Add } from "@mui/icons-material";
import { Marker } from "react-map-gl/maplibre";
import { useTranslation } from "react-i18next";
import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  clearWaypoints,
  selectWaypoint,
  addWayPoint,
  WaypointType,
  reorderWaypoints,
} from "../features/waypoint/waypointSlice";
import WaypointItem from "../features/waypoint/WaypointItem";
import { fetchRouteByWaypoints, selectRoute } from "../features/route/routeSlice";
import Map, { MapInstance } from "../features/map/Map";
import { clearViewState } from "../features/map/view/viewSlice";
import LineString from "../features/map/source/line/LineString";
import RouteListItem from "../features/route/RouteListItem";
import WaypointIcon from "../features/waypoint/WaypointIcon";
import Droppable from "../components/Droppable";
import Draggable from "../components/Draggable";

const Routing = () => {
  const [map, setMap] = useState<MapInstance>();
  const { waypoints } = useAppSelector(selectWaypoint);
  const { paths, activePath, activePathIndex, status } = useAppSelector(selectRoute);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isShowRouteInput, setShowRouteInput] = useState(false);

  // 경로 요청
  useEffect(() => {
    dispatch(fetchRouteByWaypoints(waypoints));
  }, [waypoints, dispatch]);

  useEffect(() => {
    if (map && activePath) {
      dispatch(clearViewState());
      map.fitBounds(activePath.bbox!, {
        padding: { top: 200, right: 50, bottom: 50, left: 50 },
        pitch: 0,
        animate: false,
      });
    }
  }, [map, activePath, dispatch]);

  const handleClickBack = () => {
    dispatch(clearWaypoints());
    navigate("/", { replace: true });
  };

  const handleToggleShow = () => setShowRouteInput((prev) => !prev);
  const handleMapLoaded = useCallback((newMap: MapInstance) => setMap(newMap), []);

  useEffect(() => {
    if (waypoints.every((waypoint) => waypoint.place === null)) {
      navigate("/", { replace: true });
    }
  }, [dispatch, navigate, waypoints]);

  const handleDragEnd: OnDragEndResponder = async (result) => {
    const { destination, source } = result;

    // 드래그가 완료되지 않거나, 같은 위치로 드래그한 경우
    if (!destination || destination.index === source.index) {
      return;
    }

    dispatch(reorderWaypoints({ fromIndex: source.index, toIndex: destination.index }));
  };

  return (
    <>
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        zIndex={2}
        bgcolor="background.default"
      >
        <Stack px={2.5} pt={4} pb={2.5} spacing={2}>
          <Paper>
            <Box display="flex" alignItems="center" px={1}>
              <IconButton color="inherit" onClick={handleClickBack} edge="end" size="large">
                <ArrowBackIos />
              </IconButton>
              <CardActionArea onClick={handleToggleShow}>
                <Box
                  display="flex"
                  flex={1}
                  alignItems="center"
                  overflow="hidden"
                  justifyContent="center"
                >
                  <Box flex={1} textAlign="right">
                    <Typography noWrap variant="caption" color="text.secondary">
                      {waypoints[0].place?.name || null}
                    </Typography>
                  </Box>
                  <Typography variant="h6" color="text.secondary" px={2} align="center">
                    ▸
                  </Typography>
                  <Box flex={1}>
                    <Typography noWrap variant="caption" color="text.secondary" align="left">
                      {waypoints[waypoints.length - 1].place?.name || null}
                    </Typography>
                  </Box>
                </Box>
              </CardActionArea>
            </Box>
          </Paper>
          <Collapse in={isShowRouteInput}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable id="waypoints">
                <Stack display="flex" spacing={0.5}>
                  {waypoints.map((waypoint, index) =>
                    index === 0 ? (
                      <WaypointItem key={waypoint.id} waypoint={waypoint} index={index} />
                    ) : (
                      <Draggable key={waypoint.id} draggableId={String(waypoint.id)} index={index}>
                        <WaypointItem waypoint={waypoint} index={index} />
                      </Draggable>
                    ),
                  )}
                </Stack>
              </Droppable>
            </DragDropContext>
            <Box textAlign="end" pt={2} sx={{ paddingRight: 4 }}>
              <Button
                variant="outlined"
                onClick={() => dispatch(addWayPoint())}
                disabled={waypoints.length > 5}
                endIcon={<Add />}
              >
                {t("Add as via")}
              </Button>
            </Box>
          </Collapse>
        </Stack>
        <Box
          position="absolute"
          top="100%"
          left="50%"
          zIndex={1}
          bgcolor="background.default"
          sx={{ transform: "translateX(-50%)" }}
          border={(theme_) => `1px solid ${theme_.palette.divider}`}
          borderTop={0}
        >
          <ButtonBase onClick={handleToggleShow} sx={{ p: 0, width: 50 }}>
            {isShowRouteInput ? <ExpandLess /> : <ExpandMore />}
          </ButtonBase>
        </Box>
        <Divider />
      </Box>

      <Map onLoad={handleMapLoaded}>
        {paths.map((path, index) => (
          <LineString
            type="route"
            key={path.time}
            points={path.points}
            lineWidth={15}
            layerId={`layer-${index}`}
            beforeId={index !== activePathIndex ? `layer-${activePathIndex}` : undefined}
            // active={index === activePathIndex}
            selected={index === activePathIndex}
          />
        ))}
        {waypoints.map((waypoint, index) => {
          if (!waypoint.place) return null;

          return (
            <Marker
              key={waypoint.place.id}
              latitude={waypoint.place.point.lat}
              longitude={waypoint.place.point.lng}
              anchor="bottom-left"
              offset={[-3, 3]}
            >
              <WaypointIcon
                type={waypoint.type}
                text={waypoint.type === "waypoint" ? `${index}` : ""}
              />
            </Marker>
          );
        })}
      </Map>
      <Box p={2}>
        <Stack spacing={0.5}>
          {paths.map((path, index) => (
            <RouteListItem key={path.time} path={path} index={index} />
          ))}
          {status === "pending" &&
            [0, 1].map((i) => (
              <Paper key={i}>
                <ListItem>
                  <ListItemAvatar>
                    <Skeleton animation="wave" variant="circular" width={30} height={30} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Skeleton animation="wave" width="80%" />}
                    secondary={<Skeleton animation="wave" width="60%" />}
                    secondaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              </Paper>
            ))}
        </Stack>
        <Stack spacing={1} direction="row" mt={2}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleClickBack}
            size="large"
            color="tertiary"
          >
            {t("Cancel Path")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate("/view", { replace: true })}
            size="large"
            disabled={paths.length === 0}
          >
            {t("Guidance Start")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default Routing;
