import {
  Add,
  Apartment,
  ArrowBackIos,
  DragHandle,
  Home,
  Remove,
  SelectAll,
  Star,
} from "@mui/icons-material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import { BASE_URL, USER_LONG_PRESS_THRESHOLD } from "../app/config/const";
import Droppable from "../components/Droppable";
import Draggable from "../components/Draggable";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectToken } from "../features/token/tokenSlice";
import { useDialog } from "../components/Dialog";
import SearchDialog from "../features/search/SearchDialog";
import {
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetFavoritesQuery,
  useUpdateFavoriteInfoMutation,
  useUpdateFavoriteSeqMutation,
  GetAllFavoritesResponse,
  GetFavoritesResponse,
} from "../features/favorites/favoritesApi";
import { Place } from "../features/search/searchSlice";
import { selectGeoLocation } from "../features/geolocation/geoLocationSlice";
import { useSearchPlaceByCoordsMutation } from "../features/search/searchApi";
import { createWaypoint, setWayPoints } from "../features/waypoint/waypointSlice";

const Bookmark = () => {
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const longPressTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const searchDialog = useDialog();
  const searchHomeCompanyDialog = useDialog();
  const nameEditDialog = useDialog();
  const location = useLocation();
  const { geolocation } = useAppSelector(selectGeoLocation);
  const [searchPlacesByCoords] = useSearchPlaceByCoordsMutation();

  useEffect(() => {
    if (location.state?.editMode !== undefined) {
      setEditMode(location.state.editMode);
    }
  }, [location.state]);

  const handleToggleEditMode = () => {
    setSelectedIds([]);
    setEditMode((prev) => !prev);
  };

  const handleMouseDown = () => {
    if (editMode) return;
    longPressTimerRef.current = setTimeout(() => {
      handleToggleEditMode();
    }, USER_LONG_PRESS_THRESHOLD);
  };

  const handleMouseUp = () => {
    if (editMode) return;
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }
  };

  const handleTouchStart = () => {
    if (editMode) return;
    longPressTimerRef.current = setTimeout(() => {
      handleToggleEditMode();
    }, USER_LONG_PRESS_THRESHOLD);
  };

  const handleTouchEnd = () => {
    if (editMode) return;
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
    }
  };

  const onSubmitPlace = useCallback(
    async (place: Place) => {
      try {
        if (!geolocation) throw new Error("Cannot find current location");
        const { latitude, longitude } = geolocation;
        const response = await searchPlacesByCoords({ lat: latitude, lon: longitude }).unwrap();
        dispatch(setWayPoints([createWaypoint(response), createWaypoint(place)]));
        navigate("/routing");
      } catch (error: any) {
        enqueueSnackbar({ variant: "error", message: error.message });
      }
    },
    [dispatch, geolocation, navigate, searchPlacesByCoords],
  );

  // 현재 로그인 토큰 정보
  const { token } = useAppSelector(selectToken);
  const [createFavorite] = useCreateFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();
  const [updateFavorite] = useUpdateFavoriteInfoMutation();

  // 즐겨찾기 목록
  const { data: favorites = [] } = useGetFavoritesQuery(undefined, {
    skip: Boolean(!token),
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  // primary: 집, 회사  normal: 일반 즐겨찾기
  const { primary = [], normal = [] } = favorites as GetAllFavoritesResponse;

  // 집/회사
  const useAddressByType = (hc: GetFavoritesResponse[]) => {
    const findAddressByType = (type: string): GetFavoritesResponse | null => {
      const item = hc.find((i) => i.name === type);
      return item ?? null;
    };

    return {
      home: findAddressByType("집"),
      company: findAddressByType("회사"),
    };
  };
  const { home, company } = useAddressByType(primary);

  // 체크박스 전체 선택/해제
  const handleSelectAll = () => {
    const allIds = normal.map((item) => item.idx);
    if (selectedIds.length === normal.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(allIds);
    }
  };

  // 집/회사 주소 추가/변경
  const [searchDialogType, setSearchDialogType] = useState<string | undefined>(undefined);
  const openSearchDialog = (addressType: "home" | "company") => {
    setSearchDialogType(addressType);
    searchHomeCompanyDialog.onOpen();
  };

  const handlePrimarySearchSubmit = (place: Place, addressType: string) => {
    if (addressType === "home") {
      if (home) {
        updateFavorite({
          idx: home.idx,
          data: {
            name: "집",
            addr: place.roadAddress || place.jibunAddress,
            lat: place.point.lat,
            lon: place.point.lng,
            default_type: true,
          },
        });
      } else {
        createFavorite({
          name: "집",
          lat: place.point.lat,
          lon: place.point.lng,
          addr: place.roadAddress || place.jibunAddress,
          default_type: true,
        });
      }
    } else if (addressType === "company") {
      if (company) {
        updateFavorite({
          idx: company.idx,
          data: {
            name: "회사",
            addr: place.roadAddress || place.jibunAddress,
            lat: place.point.lat,
            lon: place.point.lng,
            default_type: true,
          },
        });
      } else {
        createFavorite({
          name: "회사",
          lat: place.point.lat,
          lon: place.point.lng,
          addr: place.roadAddress || place.jibunAddress,
          default_type: true,
        });
      }
    }
    navigate("/bookmark");
  };

  // 즐겨찾기 추가
  const handleAddFavorite = (place: Place) => {
    // 중복 여부 확인
    const isDuplicate = normal.some(
      (favorite) => favorite.lat === place.point.lat && favorite.lon === place.point.lng,
    );
    if (isDuplicate) {
      enqueueSnackbar("Favorite already exists!!", {
        variant: "warning",
      });
      return;
    }

    // 중복이 아니면 create
    createFavorite({
      name: place.name,
      lat: place.point.lat,
      lon: place.point.lng,
      addr: place.roadAddress || place.jibunAddress,
      default_type: false,
    });

    navigate("/bookmark");
  };

  // 즐겨찾기 삭제
  const handleCheckboxChange = (id: number) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteFavorite(id)));
      setSelectedIds([]);
      setEditMode((prev) => !prev);
    } catch (error) {
      enqueueSnackbar({ variant: "error", message: "failed to delete" });
    }
  };

  // 즐겨찾기 이름 수정
  const [currentEdit, setCurrentEdit] = useState<GetFavoritesResponse | null>(null);

  const handleNameEditClick = (item: GetFavoritesResponse) => {
    setCurrentEdit(item);
    nameEditDialog.onOpen();
  };

  const handleUpdateFavoriteName = async (name: string) => {
    if (currentEdit !== null) {
      try {
        await updateFavorite({
          idx: currentEdit.idx,
          data: {
            name,
            addr: currentEdit.addr,
            lat: currentEdit.lat,
            lon: currentEdit.lon,
            default_type: currentEdit.default_type,
          },
        }).unwrap();
        nameEditDialog.onClose();
      } catch (error) {
        enqueueSnackbar({ variant: "error", message: "unable to edit" });
      }
    }
  };

  const [updateFavoriteSeq] = useUpdateFavoriteSeqMutation();
  const handleDragEnd: OnDragEndResponder = async (result) => {
    const { destination, source } = result;

    // 드래그가 완료되지 않거나, 같은 위치로 드래그한 경우
    if (!destination || destination.index === source.index) {
      return;
    }

    const draggedItemIndex = source.index;
    const droppedItemIndex = destination.index;

    const draggedItem = normal[draggedItemIndex];
    const droppedItem = normal[droppedItemIndex];

    if (draggedItemIndex < droppedItemIndex) {
      // going down
      await updateFavoriteSeq({ idx: draggedItem.idx, seq: droppedItem.seq + 1 });
    } else if (draggedItemIndex > droppedItemIndex) {
      // going up
      await updateFavoriteSeq({ idx: draggedItem.idx, seq: droppedItem.seq });
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Stack spacing={2}>
          <AppBar position="static" color="inherit">
            <Toolbar>
              <IconButton onClick={() => navigate(BASE_URL)} sx={{ mr: 2 }}>
                <ArrowBackIos />
              </IconButton>
              <Typography variant="h6" flexGrow={1}>
                {editMode ? "즐겨찾기 편집" : "즐겨찾기"}
              </Typography>
              <Button onClick={handleToggleEditMode} color="tertiary">
                {editMode ? "취소" : "편집"}
              </Button>
            </Toolbar>
          </AppBar>
          <Container>
            <Stack spacing={2}>
              <Paper sx={{ overflow: "hidden" }}>
                {/* 집 */}
                <ListItem component="div" divider dense disablePadding>
                  <ListItemButton
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onClick={
                      !editMode && home
                        ? () =>
                            onSubmitPlace({
                              id: home.idx.toString(),
                              distance: 0,
                              jibunAddress: "",
                              name: home.name,
                              roadAddress: home.addr,
                              point: { lat: home.lat, lng: home.lon },
                              type: "home",
                            })
                        : undefined
                    }
                  >
                    <ListItemIcon>
                      <Home />
                    </ListItemIcon>
                    <ListItemText primary="집" secondary={home?.addr ?? "정보 없음"} />
                    {editMode && (
                      <Button size="small" onClick={() => openSearchDialog("home")}>
                        주소변경
                      </Button>
                    )}
                  </ListItemButton>
                </ListItem>
                {/* 회사 */}
                <ListItem component="div" dense disablePadding divider>
                  <ListItemButton
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onClick={
                      !editMode && company
                        ? () =>
                            onSubmitPlace({
                              id: company.idx.toString(),
                              distance: 0,
                              jibunAddress: "",
                              name: company.name,
                              roadAddress: company.addr,
                              point: { lat: company.lat, lng: company.lon },
                              type: "company",
                            })
                        : undefined
                    }
                  >
                    <ListItemIcon>
                      <Apartment />
                    </ListItemIcon>
                    <ListItemText primary="회사" secondary={company?.addr ?? "정보 없음"} />
                    {editMode && (
                      <Button size="small" onClick={() => openSearchDialog("company")}>
                        주소변경
                      </Button>
                    )}
                  </ListItemButton>
                </ListItem>
                {/* 즐겨찾기 목록 */}
                <Droppable id="bookmark">
                  {normal
                    ?.slice()
                    .sort((a, b) => a.seq - b.seq)
                    .map((item, index) => (
                      <Draggable
                        key={item.seq}
                        draggableId={String(index)}
                        index={index}
                        isDragDisabled={!editMode}
                      >
                        <ListItem
                          secondaryAction={
                            editMode && (
                              <Checkbox
                                onChange={() => handleCheckboxChange(item.idx)}
                                checked={selectedIds.includes(item.idx)}
                              />
                            )
                          }
                          component="div"
                          divider
                          dense
                          disablePadding
                          sx={{ bgcolor: "background.paper" }}
                        >
                          <ListItemButton
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                            onClick={
                              !editMode
                                ? () =>
                                    onSubmitPlace({
                                      id: item.idx.toString(),
                                      distance: 0,
                                      jibunAddress: "",
                                      name: item.name,
                                      roadAddress: item.addr,
                                      point: { lat: item.lat, lng: item.lon },
                                      type: "",
                                    })
                                : undefined
                            }
                          >
                            <ListItemIcon>
                              {editMode ? <DragHandle /> : <Star color="warning" />}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  component="div"
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  {item.name}
                                  {editMode && (
                                    <IconButton
                                      onClick={() => {
                                        handleNameEditClick(item);
                                      }}
                                      style={{ marginLeft: 8, padding: 0 }}
                                    >
                                      <EditNoteOutlinedIcon style={{ fontSize: "20px" }} />
                                    </IconButton>
                                  )}
                                </Typography>
                              }
                              secondary={item.addr}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Draggable>
                    ))}
                </Droppable>
              </Paper>
              {/* 하단 버튼 */}
              <Box position="fixed" bottom={0} left={0} right={0} p={2} bgcolor="background.paper">
                <Stack spacing={1} direction="row">
                  {editMode ? (
                    <>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<SelectAll />}
                        size="large"
                        onClick={handleSelectAll}
                      >
                        전체 선택
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        startIcon={<Remove />}
                        size="large"
                        onClick={handleDelete}
                        disabled={!selectedIds.length}
                      >
                        삭제
                      </Button>
                    </>
                  ) : (
                    <Button
                      startIcon={<Add />}
                      color="tertiary"
                      variant="contained"
                      fullWidth
                      size="large"
                      onClick={searchDialog.onOpen}
                      disabled={normal.length > 9}
                    >
                      즐겨찾기 추가
                    </Button>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Container>
        </Stack>
      </DragDropContext>

      {/* 이름 변경 창 */}
      <Dialog open={nameEditDialog.open} onClose={nameEditDialog.onClose}>
        <DialogTitle>이름 변경</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="새 이름"
            fullWidth
            required
            // inputProps={{ maxLength: 11 }}
            value={currentEdit?.name}
            onChange={(e) =>
              setCurrentEdit((prev) => (prev ? { ...prev, name: e.target.value } : null))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={searchDialog.onClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() => handleUpdateFavoriteName(currentEdit?.name!)}
            color="primary"
            disabled={!currentEdit || !currentEdit.name}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* 즐겨찾기 추가용 검색 */}
      <SearchDialog
        open={searchDialog.open}
        onClose={searchDialog.onClose}
        onSubmit={(place) => handleAddFavorite(place)}
        addressType="normal"
      />
      {/* 집, 회사 주소 변경용 검색 */}
      <SearchDialog
        open={searchHomeCompanyDialog.open}
        onClose={searchHomeCompanyDialog.onClose}
        onSubmit={handlePrimarySearchSubmit}
        addressType={searchDialogType}
      />
    </>
  );
};

export default Bookmark;
