import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export enum GuidanceGeneralDistType {
  GUIDE_GENERAL_1_5KM = 0,
  GUIDE_GENERAL_700M,
  GUIDE_GENERAL_300M,
  GUIDE_GENERAL_SOON,
};

export enum GuidanceExpresswayDistType {
  GUIDE_EXPWAY_2_0KM = 0,
  GUIDE_EXPWAY_1_0KM,
  GUIDE_EXPWAY_500M,
  GUIDE_EXPWAY_SOON,
};

export enum SafetyGeneralDistType {
  SAFETY_GENERAL_800M = 0,
  SAFETY_GENERAL_500M,
  SAFETY_GENERAL_300M,
  SAFETY_GENERAL_SOON,
};

export enum SafetyExpresswayDistType {
  SAFETY_EXPWAY_1_5KM = 0,
  SAFETY_EXPWAY_1_0KM,
  SAFETY_EXPWAY_500M,
  SAFETY_EXPWAY_SOON,
};

export const guideGeneralDistance = [1500, 700, 300, 0];
export const guideExpwayDistance = [2000, 1000, 500, 0];
export const safetyGeneralDistance = [800, 500, 300, 100];
export const safetyExpwayDistance = [1500, 1000, 500, 200];
export const safetyGeneralGap = 20;
export const safetyExpwayGap = 70;

interface State {
  guideGeneralDistanceCheck: boolean[];
  guideExpwayDistanceCheck: boolean[];
  safetyGeneralDistanceCheck: boolean[];
  safetyExpwayDistanceCheck: boolean[];
}

const initialState: State = {
  guideGeneralDistanceCheck:[true, true, true, true],
  guideExpwayDistanceCheck:[true, true, true, true],
  safetyGeneralDistanceCheck:[true, true, true, true],
  safetyExpwayDistanceCheck:[true, true, true, true],
};

const guideSettingSlice = createSlice({
  name: "guideSetting",
  initialState,
  reducers: {
    setGuideGeneralDistance: (state, action: PayloadAction<{type: GuidanceGeneralDistType, value:boolean}>) => {
      state.guideGeneralDistanceCheck[action.payload.type] = action.payload.value;
    },
    setGuideExpwayDistance: (state, action: PayloadAction<{type: GuidanceExpresswayDistType, value:boolean}>) => {
      state.guideExpwayDistanceCheck[action.payload.type] = action.payload.value;
    },
    setSafetyGeneralDistance: (state, action: PayloadAction<{type: SafetyGeneralDistType, value:boolean}>) => {
      state.safetyGeneralDistanceCheck[action.payload.type] = action.payload.value;
    },
    setSafetyExpwayDistance: (state, action: PayloadAction<{type: SafetyExpresswayDistType, value:boolean}>) => {
      state.safetyExpwayDistanceCheck[action.payload.type] = action.payload.value;
    },
  },
});

export default guideSettingSlice;
export const { setGuideGeneralDistance, setGuideExpwayDistance, setSafetyGeneralDistance, setSafetyExpwayDistance } = guideSettingSlice.actions;
export const selectGuideSetting = (state: RootState) => state.guideSetting;
