import { Close, FileOpen } from "@mui/icons-material";
import { Button, CardActions, CardContent, Collapse, Divider } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { parseGpxToPoints } from "./gpxUtils";
import parseNmeaToPoints from "./nmeaUtils";
import Simulator from "./Simulator";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectSimulator, setCurrentTimeline, setTimelines } from "./simulatorSlice";
import { GeoLocation, setGeoLocation } from "../../geolocation/geoLocationSlice";
import DebugCard from "../DebugCard";
import DebugCardHeader from "../DebugCardHeader";

interface Props {
  onClose: () => void;
}

const LogSimulator = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { currentTimeline } = useAppSelector(selectSimulator);
  const [expanded, setExpanded] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [points, setPoints] = useState<GeoLocation[]>([]);

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    let newPoints: GeoLocation[] = await parseNmeaToPoints(file);

    if (newPoints.length === 0) {
      // 파일을 열어서 $GP가 있으면 NMEA파일로 간주하고, 없으면 기존처럼 GPX파싱을 시도.
      newPoints = await parseGpxToPoints(file);
    }

    dispatch(
      setTimelines({
        timelines: newPoints.map((point) => ({
          timestamp: point.timestamp,
          value: point.timestamp,
        })),
        keyFrame: 1000,
      }),
    );
    dispatch(setCurrentTimeline(newPoints[0].timestamp));
    setPoints(newPoints);
  };

  const [hidden, setHidden] = useState(false);
  const onToggleExpand = () => setExpanded((prev) => !prev);
  const onToggleHide = () => setHidden((prev) => !prev);

  useEffect(() => {
    if (!points.length) return;
    const targetPoint = [...points].reverse().find((point) => point.timestamp <= currentTimeline);
    if (
      targetPoint &&
      !Number.isNaN(targetPoint.latitude) &&
      !Number.isNaN(targetPoint.longitude)
    ) {
      dispatch(setGeoLocation(targetPoint));
    }
  }, [currentTimeline, points, dispatch]);
  return (
    <DebugCard bottom={4} right={4} left={4} maxWidth="35rem">
      <DebugCardHeader
        title="로그 주행"
        isExpanded={expanded}
        isHidden={hidden}
        onClickExpanded={onToggleExpand}
        onClickHidden={onToggleHide}
      />
      <Collapse in={expanded}>
        <Divider />
        <CardContent>
          <input
            type="file"
            accept=".gpx, .xml, .txt, .log"
            style={{ display: "none" }}
            onChange={handleChangeFile}
            ref={inputRef}
          />
          <Simulator />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            variant="contained"
            onClick={() => inputRef.current?.click()}
            startIcon={<FileOpen />}
            size="small"
          >
            파일 열기
          </Button>
          <Button onClick={onClose} startIcon={<Close />}>
            종료
          </Button>
        </CardActions>
      </Collapse>
    </DebugCard>
  );
};

export default LogSimulator;
