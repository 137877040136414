export const getTTSString = (key: {ko:string, en:string, zhTW:string}, lang: string) => {
  if (lang === 'ko')
    return key.ko;
  else if (lang === 'zhTW')
    return key.zhTW;

  return key.en;
}
/* eslint-disable @typescript-eslint/no-unused-vars */

export const WV_UNIT_KM = {ko:"킬로미터", en:"kilometer", zhTW:"公里"};
export const WV_NUM_1 = {ko:"일", en:"one", zhTW:"一"}; 	
export const WV_NUM_2 = {ko:"이", en:"two", zhTW:"二"}; 	
export const WV_NUM_3 = {ko:"삼", en:"three", zhTW:"三"}; 	
export const WV_NUM_4 = {ko:"사", en:"four", zhTW:"四"}; 	
export const WV_NUM_5 = {ko:"오", en:"five", zhTW:"五"}; 	
export const WV_NUM_6 = {ko:"육", en:"six", zhTW:"六"}; 	
export const WV_NUM_7 = {ko:"칠", en:"seven", zhTW:"七"}; 	
export const WV_NUM_8 = {ko:"팔", en:"eight", zhTW:"八"}; 	
export const WV_NUM_9 = {ko:"구", en:"nine", zhTW:"九"}; 	
export const WV_NUM_10 = {ko:"십", en:"ten", zhTW:"十"}; 	
export const WV_NUM_20 = {ko:"이십", en:"twenty", zhTW:"二十"}; 	
export const WV_NUM_30 = {ko:"삼십", en:"thirty", zhTW:"三十"}; 	
export const WV_NUM_40 = {ko:"사십", en:"forty", zhTW:"四十"}; 	
export const WV_NUM_50 = {ko:"오십", en:"fifty", zhTW:"五十"}; 	
export const WV_NUM_60 = {ko:"육십", en:"sixty", zhTW:"六十"}; 	
export const WV_NUM_70 = {ko:"칠십", en:"seventy", zhTW:"七十"}; 	
export const WV_NUM_80 = {ko:"팔십", en:"eighty", zhTW:"八十"}; 	
export const WV_NUM_90 = {ko:"구십", en:"ninety", zhTW:"九十"}; 	

export const WV_NUM2_1 = {ko:"한개", en:"one", zhTW:"一個"};  
export const WV_NUM2_2 = {ko:"두개", en:"two", zhTW:"兩個"};  
export const WV_NUM2_3 = {ko:"세개", en:"three", zhTW:"三個"};  
export const WV_NUM2_4 = {ko:"네개", en:"four", zhTW:"四個"};  
export const WV_NUM2_5 = {ko:"다섯개", en:"five", zhTW:"五個"};  
export const WV_NUM2_6 = {ko:"여섯개", en:"six", zhTW:"六個"};  
export const WV_NUM2_7 = {ko:"일곱개", en:"seven", zhTW:"七個"};  
export const WV_NUM2_8 = {ko:"여덟개", en:"eight", zhTW:"八個"};  
export const WV_NUM2_9 = {ko:"아홉개", en:"nine", zhTW:"九個"};  
export const WV_NUM2_10 = {ko:"열개", en:"ten", zhTW:"十個"};  

export const WV_NUM_50_WON = {ko:"오십원입니다", en:"fifty won", zhTW:"五十元"}; 	
export const WV_NUM_100  = {ko:"백", en:"hundred", zhTW:"一百"}; 	
export const WV_NUM_200  = {ko:"이백", en:"two hundred", zhTW:"二百"}; 	
export const WV_NUM_300  = {ko:"삼백", en:"three hundred", zhTW:"三百"}; 	
export const WV_NUM_400  = {ko:"사백", en:"four hundred", zhTW:"四百"}; 	
export const WV_NUM_500  = {ko:"오백", en:"five hundred", zhTW:"五百"}; 	
export const WV_NUM_600  = {ko:"육백", en:"six hundred", zhTW:"六百"}; 	
export const WV_NUM_700  = {ko:"칠백", en:"seven hundred", zhTW:"七百"}; 	
export const WV_NUM_800  = {ko:"팔백", en:"eight hundred", zhTW:"八百"}; 	
export const WV_NUM_900  = {ko:"구백", en:"nine hundred", zhTW:"九百"}; 	
export const WV_NUM_1000 = {ko:"천", en:"thousand", zhTW:"一千"}; 	
export const WV_NUM_2000 = {ko:"이천", en:"two thousand", zhTW:"二千"}; 	
export const WV_NUM_3000 = {ko:"삼천", en:"three thousand", zhTW:"三千"}; 	
export const WV_NUM_4000 = {ko:"사천", en:"four thousand", zhTW:"四千"}; 	
export const WV_NUM_5000 = {ko:"오천", en:"five thousand", zhTW:"五千"}; 	
export const WV_NUM_6000 = {ko:"육천", en:"six thousand", zhTW:"六千"}; 	
export const WV_NUM_7000 = {ko:"칠천", en:"seven thousand", zhTW:"七千"}; 	
export const WV_NUM_8000 = {ko:"팔천", en:"eight thousand", zhTW:"八千"}; 	
export const WV_NUM_9000 = {ko:"구천", en:"nine thousand", zhTW:"九千"}; 	
export const WV_NUM_10000 = {ko:"만", en:"ten thousand", zhTW:"一萬"}; 	
export const WV_NUM_20000 = {ko:"이만", en:"twenty thousand", zhTW:"二萬"}; 	
export const WV_NUM_30000 = {ko:"삼만", en:"thirty thousand", zhTW:"三萬"}; 	
export const WV_NUM_40000 = {ko:"사만", en:"forty thousand", zhTW:"四萬"}; 	
export const WV_NUM_50000 = {ko:"오만", en:"fifty thousand", zhTW:"五萬"}; 	
export const WV_NUM_60000 = {ko:"육만", en:"sixty thousand", zhTW:"六萬"}; 	
export const WV_NUM_70000 = {ko:"칠만", en:"seventy thousand", zhTW:"七萬"}; 	
export const WV_NUM_80000 = {ko:"팔만", en:"eighty thousand", zhTW:"八萬"}; 	
export const WV_NUM_90000 = {ko:"구만", en:"ninety thousand", zhTW:"九萬"}; 	

export const WV_FR_SOON = {ko:"그리고 곧", en:"and soon", zhTW:"接下来"}; 	
export const WV_FR_DIR = {ko:"방향입니다", en:"direction", zhTW:"方向"}; 	
export const WV_R_CHANGE_LANE = {ko:"차선으로 이동해 주십시오", en:"change lane", zhTW:"請換車道"}; 	
export const WV_R_ROTARI = {ko:"로타리", en:"rotary", zhTW:"環狀交叉口"}; 	
export const WV_FR_TO = {ko:"방면으로", en:"to", zhTW:"往"}; 	
export const WV_FR_FROM = {ko:"방면", en:"from", zhTW:"來自"}; 	
export const WV_FR_IS = {ko:"입니다", en:"is", zhTW:"是"}; 	
export const WV_FR_TO_DIRECTION = {ko:"방향으로", en:"to direction", zhTW:"往方向"}; 	
export const WV_FR_DIRECTION = {ko:"방향", en:"direction", zhTW:"方向"}; 	
export const WV_FR_ROTARY = {ko:"로터리에서", en:"at rotary", zhTW:"在環狀交叉口"}; 	
export const WV_FR_CROSS = {ko:"교차로에서", en:"at intersection", zhTW:"在交叉路口"}; 	
export const WV_FR_THREE_CORNER = {ko:"삼거리에서", en:"at three-way intersection", zhTW:"在三岔路口"}; 	
export const WV_FR_FOUR_CORNER = {ko:"사거리에서", en:"at four-way intersection", zhTW:"在四岔路口"}; 	
export const WV_FR_FIVE_CORNER = {ko:"오거리에서", en:"at five-way intersection", zhTW:"在五岔路口"}; 	
export const WV_FR_FOUR_CORNER2 = {ko:"네거리에서", en:"at four-way intersection", zhTW:"在四岔路口"}; 	
export const WV_FR_JUNCTION = {ko:"분기점에서", en:"at junction", zhTW:"在交叉點"}; 	
export const WV_FR_INTERCHANGE = {ko:"IC에서", en:"at interchange", zhTW:"在交流道"}; 	
export const WV_FR_NEAR = {ko:"부근입니다", en:"nearby", zhTW:"附近"}; 	
export const WV_FR_H_ROAD = {ko:"고가차도에서", en:"on overpass", zhTW:"在高架道路上"}; 	
export const WV_FR_U_ROAD = {ko:"지하차도에서", en:"on underpass", zhTW:"在地下道上"}; 	
export const WV_USE_LANE = {ko:"차로를 이용하세요", en:"use the lane", zhTW:"請使用車道"};  
export const WV_MOVE_LANE = {ko:"차로를 변경하세요", en:"move to the lane", zhTW:"請換車道"}; 	
export const WV_USE_STRAIGHT = {ko:"직진 차로를 이용하세요", en:"use the straight lane", zhTW:"請使用直行車道"}; 	
export const WV_CARE_TUEN = {ko:"회전지점에 주의하세요", en:"be careful at the turning point", zhTW:"請注意轉彎點"}; 	
export const WV_FR_CROSS2 = {ko:"교차로", en:"intersection", zhTW:"交叉路口"}; 	
export const WV_FR_JC2 = {ko:"분기점", en:"junction", zhTW:"交叉點"}; 	
export const WV_FR_IC2 = {ko:"IC", en:"interchange", zhTW:"交流道"}; 	
export const WV_FR_ROTARY2 = {ko:"로터리", en:"rotary", zhTW:"環狀交叉口"}; 	
export const WV_FR_THREE_CORNER2 = {ko:"삼거리", en:"three-way intersection", zhTW:"三岔路口"}; 	
export const WV_FR_FOUR_CORNER_1 = {ko:"사거리", en:"four-way intersection", zhTW:"四岔路口"}; 	
export const WV_FR_FIVE_CORNER2 = {ko:"오거리", en:"five-way intersection", zhTW:"五岔路口"}; 	
export const WV_FR_FOUR_CORNER2_1 = {ko:"네거리", en:"four-way intersection", zhTW:"四岔路口"}; 	
export const WV_FR_GUIDE_POINT = {ko:"안내", en:"guide", zhTW:"導航"}; 	
export const WV_FR_ROTARY_INSIDE = {ko:"로터리 안쪽 차선으로 진입하십시오", en:"enter the inner lane of the rotary", zhTW:"請進入環狀交叉口的內車道"}; 	
export const WV_FR_ROTARY_OUTSIDE = {ko:"로터리 바깥 차선으로 진입하십시오", en:"enter the outer lane of the rotary", zhTW:"請進入環狀交叉口的外車道"}; 	

export const WV_D_SOON = {ko:"잠시후", en:"in a moment", zhTW:"接下来"}; 	
export const WV_TTS_TO_ROAD_NAME = {ko:"도로명 앞에 붙는것", en:"to road name", zhTW:"到道路名稱"}; 	
export const WV_TTS_TO_DIRECTION = {ko:"방면명 앞에 붙는 것", en:"to direction", zhTW:"到方向"}; 	
export const WV_TTS_END_DIRECTION = {ko:"~~ 방향입니다", en:"direction", zhTW:"方向"}; 	
export const WV_D_FORWARD = {ko:"전방에", en:"ahead", zhTW:"前方"}; 	
export const WV_D_FORWARD_2 = {ko:"전방에 있습니다", en:"ahead", zhTW:"前方"}; 
export const WV_D_FORWARD_3 = {ko:"다음", en:"next", zhTW:"下一個"}; 
export const WV_D_UNTIL = {ko:"까지", en:"until", zhTW:"直到"}; 

export const WV_D_HERE = {ko:"여기부터", en:"from here", zhTW:"從這裡開始"}; 	
export const WV_D_100M = {ko:"100미터 앞에서", en:"100 meters ahead", zhTW:"前方100公尺後"}; 	
export const WV_D_200M = {ko:"200미터 앞에서", en:"200 meters ahead", zhTW:"前方200公尺後"}; 	
export const WV_D_300M = {ko:"300미터 앞에서", en:"300 meters ahead", zhTW:"前方300公尺後"}; 	
export const WV_D3_300M = {ko:"300미터 앞", en:"300 meters ahead", zhTW:"前方300公尺後"}; 	
export const WV_D_400M = {ko:"400미터 앞에서", en:"400 meters ahead", zhTW:"前方400公尺後"}; 	
export const WV_D3_400M = {ko:"400미터 앞", en:"400 meters ahead", zhTW:"前方400公尺後"}; 	
export const WV_D_500M = {ko:"500미터 앞에서", en:"500 meters ahead", zhTW:"前方500公尺後"}; 	
export const WV_D3_500M = {ko:"500미터 앞", en:"500 meters ahead", zhTW:"前方500公尺後"}; 	
export const WV_D_600M = {ko:"600미터 앞에서", en:"600 meters ahead", zhTW:"前方600公尺後"}; 	
export const WV_D_700M = {ko:"700미터 앞에서", en:"700 meters ahead", zhTW:"前方700公尺後"}; 	
export const WV_D3_700M = {ko:"700미터 앞", en:"700 meters ahead", zhTW:"前方700公尺後"}; 	
export const WV_D_800M = {ko:"800미터 앞에서", en:"800 meters ahead", zhTW:"前方800公尺後"}; 	
export const WV_D_900M = {ko:"900미터 앞에서", en:"900 meters ahead", zhTW:"前方900公尺後"}; 	
export const WV_D_1KM = {ko:"1킬로미터 앞에서", en:"1 kilometer ahead", zhTW:"前方1公里前後"}; 	
export const WV_D2_1KM = {ko:"1킬로미터 앞에", en:"1 kilometer ahead", zhTW:"前方1公里前後"}; 	
export const WV_D3_1KM = {ko:"1킬로미터 앞", en:"1 kilometer ahead", zhTW:"前方1公里前後"}; 	
export const WV_D_1_5KM = {ko:"1.5킬로미터 앞에서", en:"1.5 kilometers ahead", zhTW:"前方1.5公里後"}; 	
export const WV_D2_1_5KM = {ko:"1.5킬로미터 앞에", en:"1.5 kilometers ahead", zhTW:"前方1.5公里後"}; 	
export const WV_D3_1_5KM = {ko:"1.5킬로미터 앞", en:"1.5 kilometers ahead", zhTW:"前方1.5公里後"}; 	
export const WV_D_1_9KM = {ko:"1.9킬로미터 앞에서", en:"1.9 kilometers ahead", zhTW:"前方1.9公里後"}; 	
export const WV_D2_1_9KM = {ko:"1.9킬로미터 앞에", en:"1.9 kilometers ahead", zhTW:"前方1.9公里後"}; 	
export const WV_D3_1_9KM = {ko:"1.9킬로미터 앞", en:"1.9 kilometers ahead", zhTW:"前方1.9公里後"}; 	
export const WV_D_2KM = {ko:"2킬로미터 앞에서", en:"2 kilometers ahead", zhTW:"前方2公里後"}; 	
export const WV_D2_2KM = {ko:"2킬로미터 앞에", en:"2 kilometers ahead", zhTW:"前方2公里後"}; 	
export const WV_D3_2KM = {ko:"2킬로미터 앞", en:"2 kilometers ahead", zhTW:"前方2公里後"}; 	
export const WV_D_3KM = {ko:"3킬로미터 앞에서", en:"3 kilometers ahead", zhTW:"前方3公里後"}; 	
export const WV_D2_3KM = {ko:"3킬로미터 앞에", en:"3 kilometers ahead", zhTW:"前方3公里後"}; 	
export const WV_D3_3KM = {ko:"3킬로미터 앞", en:"3 kilometers ahead", zhTW:"前方3公里後"}; 	
export const WV_D_4KM = {ko:"4킬로미터 앞에서", en:"4 kilometers ahead", zhTW:"前方4公里後"}; 	
export const WV_D2_4KM = {ko: "4킬로미터 앞에", en: "4km ahead", zhTW: "前方4公里後"};
export const WV_D3_4KM = {ko: "4킬로미터 앞", en: "4km ahead", zhTW: "前方4公里後"};

// 바로 거리 안내를 위한 2km까지 거리 추가
export const WV_D_10M = {ko: "10미터 앞에서", en: "Proceed about ten meters", zhTW: "前方10公尺後"};
export const WV_D_20M = {ko: "20미터 앞에서", en: "Proceed about twenty meters", zhTW: "前方20公尺後"};
export const WV_D_30M = {ko: "30미터 앞에서", en: "Proceed about thirty meters", zhTW: "前方30公尺後"};
export const WV_D_40M = {ko: "40미터 앞에서", en: "Proceed about forty meters", zhTW: "前方40公尺後"};
export const WV_D_50M = {ko: "50미터 앞에서", en: "Proceed about fifty meters", zhTW: "前方50公尺後"};
export const WV_D_60M = {ko: "60미터 앞에서", en: "Proceed about sixty meters", zhTW: "前方60公尺後"};
export const WV_D_70M = {ko: "70미터 앞에서", en: "Proceed about seventy meters", zhTW: "前方70公尺後"};
export const WV_D_80M = {ko: "80미터 앞에서", en: "Proceed about eighty meters", zhTW: "前方80公尺後"};
export const WV_D_90M = {ko: "90미터 앞에서", en: "Proceed about ninety meters", zhTW: "前方90公尺後"};
export const WV_D_120M = {ko: "120미터 앞에서", en: "Proceed about one hundred and twenty meters", zhTW: "前方120公尺後"};
export const WV_D_140M = {ko: "140미터 앞에서", en: "Proceed about one hundred and forty meters", zhTW: "前方140公尺後"};
export const WV_D_150M = {ko: "150미터 앞에서", en: "Proceed about one hundred and fifty meters", zhTW: "前方150公尺後"};
export const WV_D_160M = {ko: "160미터 앞에서", en: "Proceed about one hundred and sixty meters", zhTW: "前方160公尺後"};
export const WV_D_180M = {ko: "180미터 앞에서", en: "Proceed about one hundred and eighty meters", zhTW: "前方180公尺後"};
export const WV_D_250M = {ko: "250미터 앞에서", en: "Proceed about two hundred and fifty meters", zhTW: "前方250公尺後"};
export const WV_D_350M = {ko: "350미터 앞에서", en: "Proceed about three hundred and fifty meters", zhTW: "前方350公尺後"};
export const WV_D_450M = {ko: "450미터 앞에서", en: "Proceed about four hundred and fifty meters", zhTW: "前方450公尺後"};
export const WV_D_550M = {ko: "550미터 앞에서", en: "Proceed about five hundred and fifty meters", zhTW: "前方550公尺後"};
export const WV_D_650M = {ko: "650미터 앞에서", en: "Proceed about six hundred and fifty meters", zhTW: "前方650公尺後"};
export const WV_D_750M = {ko: "750미터 앞에서", en: "Proceed about seven hundred and fifty meters", zhTW: "前方750公尺後"};
export const WV_D_850M = {ko: "850미터 앞에서", en: "Proceed about eight hundred and fifty meters", zhTW: "前方850公尺後"};
export const WV_D_950M = {ko: "950미터 앞에서", en: "Proceed about nine hundred and fifty meters", zhTW: "前方950公尺後"};
export const WV_D_1_1KM = {ko: "1.1킬로미터 앞에서", en: "Proceed about one point one kilometers", zhTW: "前方1.1公里後"};
export const WV_D_1_2KM = {ko: "1.2킬로미터 앞에서", en: "Proceed about one point two kilometers", zhTW: "前方1.2公里後"};
export const WV_D_1_3KM = {ko: "1.3킬로미터 앞에서", en: "Proceed about one point three kilometers", zhTW: "前方1.3公里後"};
export const WV_D_1_4KM = {ko: "1.4킬로미터 앞에서", en: "Proceed about one point four kilometers", zhTW: "前方1.4公里後"};
export const WV_D_1_6KM = {ko: "1.6킬로미터 앞에서", en: "Proceed about one point six kilometers", zhTW: "前方1.6公里後"};
export const WV_D_1_7KM = {ko: "1.7킬로미터 앞에서", en: "Proceed about one point seven kilometers", zhTW: "前方1.7公里後"};
export const WV_D_1_8KM = {ko: "1.8킬로미터 앞에서", en: "Proceed about one point eight kilometers", zhTW: "前方1.8公里後"};

export const WV_D_OUT_TUNNEL = {ko: "터널 통과 후", en: "After passing through the tunnel", zhTW: "通過隧道後"};
export const WV_R_RIGHT = {ko: "오른쪽", en: "right", zhTW: "右邊"};
export const WV_R_LEFT = {ko: "왼쪽", en: "left", zhTW: "左邊"};
export const WV_R_FRONT = {ko: "앞", en: "front", zhTW: "前方"};
export const WV_R_AT = {ko: "에서", en: "at", zhTW: "在"};
export const WV_R_LEFT2 = {ko: "좌측", en: "left", zhTW: "左邊"};
export const WV_R_RIGHT2 = {ko: "우측", en: "right", zhTW: "右邊"};
export const WV_R_LEFT3 = {ko: "좌측에", en: "on the left", zhTW: "在左邊"};
export const WV_R_RIGHT3 = {ko: "우측에", en: "on the right", zhTW: "在右邊"};
export const WV_R_MIDDLE3 = {ko: "가운데에", en: "in the middle", zhTW: "在中間"};
export const WV_R_MIDDLE2 = {ko: "가운데", en: "in the middle", zhTW: "在中間"};

export const WV_R_R = {ko: "오른쪽 방향입니다", en: "Bear right", zhTW: "請靠右"};
export const WV_R_R01 = {ko: "오른쪽 1시 방향입니다", en: "Turn right at 1 o'clock", zhTW: "在1點鐘方向右轉"};
export const WV_R_R04 = {ko: "오른쪽 4시 방향입니다", en: "Turn right at 4 o'clock", zhTW: "在4點鐘方向右轉"};

export const WV_R_L = {ko: "왼쪽 방향입니다", en: "Bear left", zhTW: "請靠左"};
export const WV_R_L08 = {ko: "왼쪽 8시 방향입니다", en: "Turn left at 8 o'clock", zhTW: "在8點鐘方向左轉"};
export const WV_R_L11 = {ko: "왼쪽 11시 방향입니다", en: "Turn left at 11 o'clock", zhTW: "在11點鐘方向左轉"};
export const WV_R_STRAIGHT = {ko: "직진입니다.", en: "Go straight", zhTW: "直走"};
export const WV_R_U_TURN = {ko: "U턴 입니다", en: "Make a U-turn", zhTW: "掉頭"};

export const WV_R_MUL_LMR = {ko: "좌측과 가운데 그리고 우측에", en: "on the left, in the middle, and on the right", zhTW: "在左邊、中間和右邊"};
export const WV_R_NUL_LM = {ko: "좌측과 가운데에", en: "on the left and in the middle", zhTW: "在左邊和中間"};
export const WV_R_NUL_LR = {ko: "좌측과 우측에", en: "on the left and on the right", zhTW: "在左邊和右邊"};
export const WV_R_NUL_MR = {ko: "가운데와 우측에", en: "in the middle and on the right", zhTW: "在中間和右邊"};

export const WV_TAKE_1_EXIT = {ko: "첫번째 출구입니다.", en: "Take the first exit", zhTW: "走第一個出口"};
export const WV_TAKE_2_EXIT = {ko: "두번째 출구입니다.", en: "Take the second exit", zhTW: "走第二個出口"};
export const WV_TAKE_3_EXIT = {ko: "세번째 출구입니다.", en: "Take the third exit", zhTW: "走第三個出口"};
export const WV_TAKE_4_EXIT = {ko: "네번째 출구입니다.", en: "Take the fourth exit", zhTW: "走第四個出口"};
export const WV_TAKE_5_EXIT = {ko: "다섯번째 출구입니다.", en: "Take the fifth exit", zhTW: "走第五個出口"};
export const WV_TAKE_6_EXIT = {ko: "여섯번째 출구입니다.", en: "Take the sixth exit", zhTW: "走第六個出口"};
export const WV_TAKE_7_EXIT = {ko: "일곱번째 출구입니다.", en: "Take the seventh exit", zhTW: "走第七個出口"};
export const WV_TAKE_8_EXIT = {ko: "여덜번째 출구입니다.", en: "Take the eighth exit", zhTW: "走第八個出口"};
export const WV_TAKE_9_EXIT = {ko: "아홈번째 출구입니다.", en: "Take the ninth exit", zhTW: "走第九個出口"};
export const WV_TAKE_10_EXIT = {ko: "열번째 출구입니다.", en: "Take the tenth exit", zhTW: "走第十個出口"};

export const WV_IC_GO_STRAIGHT = {ko: "다음 안내시까지는 직진입니다.", en: "Go straight until the next instruction", zhTW: "直走到下一個指示"};
export const WV_IC_NOT_TO_OVERPASS = {ko: "고가차도 옆 길입니다.", en: "Next to the overpass", zhTW: "在高架橋旁邊"};
export const WV_IC_TO_OVERPASS = {ko: "고가차도로 진입하십시오.", en: "Enter the overpass", zhTW: "進入高架橋"};
export const WV_IC_NOT_TO_RIGHT_OVERPASS = {ko: "고가차도 옆 왼쪽입니다.", en: "Next to the left side of the overpass", zhTW: "在高架橋左邊"};
export const WV_IC_NOT_TO_UNDERPASS = {ko: "지하차도 옆 길입니다.", en: "Next to the underpass", zhTW: "在地下道旁邊"};
export const WV_IC_TO_UNDERPASS = {ko: "지하차도로 진입하십시오.", en: "Enter the underpass", zhTW: "進入地下道"};
export const WV_IC_NOT_TO_RIGHT_UNDERPASS = {ko: "지하차도 옆 왼쪽입니다.", en: "Next to the left side of the underpass", zhTW: "在地下道左邊"};
export const WV_IC_IN_TUNNEL = {ko: "터널 입니다.", en: "In the tunnel", zhTW: "在隧道內"};
export const WV_IC_NOT_TUNNEL = {ko: "터널 옆 길입니다.", en: "Next to the tunnel", zhTW: "在隧道旁邊"};
export const WV_IC_SPEED_DOWN = {ko: "속도에 주의하십시오.", en: "Watch your speed", zhTW: "請注意速度"};
export const WV_IC_EXIT_HIGHWAY = {ko: "고속도로 출구로 진입하십시오.", en: "Enter the highway exit", zhTW: "進入高速公路出口"};
export const WV_IC_ENTER_HIGHWAY = {ko: "고속도로 입구로 진입하십시오.", en: "Enter the highway entrance", zhTW: "進入高速公路入口"};
export const WV_IC_EXIT_EXPRESS = {ko: "고속화도로 출구로 진입하십시오.", en: "Enter the expressway exit", zhTW: "進入高速公路出口"};
export const WV_IC_ENTER_EXPRESS = {ko: "고속화도로 입구로 진입하십시오.", en: "Enter the expressway entrance", zhTW: "進入高速公路入口"};

export const WV_SLIMIT_UK = {ko: "이 구간은 위험지역 입니다.", en: "This area is a danger zone", zhTW: "此區域是危險區"};
export const WV_SLIMIT_SIGN = {ko: "이 구간은 교통신호 주의 구간입니다.", en: "This area is a traffic signal caution zone", zhTW: "此區域是交通信號注意區"};
export const WV_SLIMIT_CURVE = {ko: "이 구간은 급커브 구간 입니다.", en: "This area is a sharp curve zone", zhTW: "此區域是急彎區"};
export const WV_SLIMIT_FOG = {ko: "이 구간은 안개지역 입니다.", en: "This area is a fog zone", zhTW: "此區域是霧區"};
export const WV_SLIMIT_ACC = {ko: "이 구간은 사고 다발지역 입니다.", en: "This area is an accident-prone zone", zhTW: "此區域是事故多發區"};
export const WV_SLIMIT_SIG2 = {ko: "신호에 주의 하십시오.", en: "Watch for traffic signals", zhTW: "請注意交通信號"};
export const WV_SLIMIT_DOWN2 = {ko: "감속운전하세요.", en: "Slow down", zhTW: "請減速"};
export const WV_SLIMIT_CARE = {ko: "조심하세요.", en: "Be careful", zhTW: "請小心"};
export const WV_SLIMIT_DOWN = {ko: "속도를 줄이세요.", en: "Reduce speed", zhTW: "請減速"};
export const WV_SLIMIT_SAFE = {ko: "안전운전 하십시오.", en: "Drive safely", zhTW: "請安全駕駛"};
export const WV_SLIMIT_020 = {ko: "제한속도 20킬로미터입니다.", en: "Speed limit is 20km per hour", zhTW: "限速20公里"};
export const WV_SLIMIT_030 = {ko: "제한속도 30킬로미터입니다.", en: "Speed limit is 30km per hour", zhTW: "限速30公里"};
export const WV_SLIMIT_040 = {ko: "제한속도 40킬로미터입니다.", en: "Speed limit is 40km per hour", zhTW: "限速40公里"};
export const WV_SLIMIT_050 = {ko: "제한속도 50킬로미터입니다.", en: "Speed limit is 50km per hour", zhTW: "限速50公里"};
export const WV_SLIMIT_060 = {ko: "제한속도 60킬로미터입니다.", en: "Speed limit is 60km per hour", zhTW: "限速60公里"};
export const WV_SLIMIT_070 = {ko: "제한속도 70킬로미터입니다", en: "Speed limit is 70km per hour", zhTW: "限速70公里"};
export const WV_SLIMIT_080 = {ko: "제한속도 80킬로미터입니다", en: "Speed limit is 80km per hour", zhTW: "限速80公里"};
export const WV_SLIMIT_090 = {ko: "제한속도 90킬로미터입니다", en: "Speed limit is 90km per hour", zhTW: "限速90公里"};
export const WV_SLIMIT_100 = {ko: "제한속도 100킬로미터입니다", en: "Speed limit is 100km per hour", zhTW: "限速100公里"};
export const WV_SLIMIT_110 = {ko: "제한속도 110킬로미터입니다", en: "Speed limit is 110km per hour", zhTW: "限速110公里"};
export const WV_SLIMIT_120 = {ko: "제한속도 120킬로미터입니다", en: "Speed limit is 120km per hour", zhTW: "限速120公里"};
export const WV_DANGER_01 = {ko: "주의하십시오 신호위반 단속구간입니다", en: "Caution, signal violation enforcement zone", zhTW: "請注意，信號違規執法區"};
export const WV_DANGER_02 = {ko: "주의하십시오 연속해서 과속위험 구간 입니다", en: "Caution, consecutive speeding danger zone", zhTW: "請注意，連續超速危險區"};
export const WV_DANGER_03 = {ko: "주의하십시오 주정차 위반 단속 지역 입니다", en: "Caution, parking violation enforcement area", zhTW: "請注意，違規停車執法區"};
export const WV_DANGER_04 = {ko: "주의하십시오 어린이 보호구역입니다", en: "Caution, children protection zone", zhTW: "請注意，兒童保護區"};
export const WV_DANGER_05 = {ko: "잠시후 차선이 합류합니다", en: "Lane merging ahead", zhTW: "請稍後，車道將合併"};
export const WV_DANGER_06 = {ko: "주의하십시오 사용자 입력 지점 입니다", en: "Caution, user input point", zhTW: "請注意，使用者輸入點"};
export const WV_DANGER_07 = {ko: "주의하십시오 급경사가 있습니다", en: "Caution, steep slope ahead", zhTW: "請注意，陡坡前方"};
export const WV_DANGER_08 = {ko: "주의하십시오 병목구간이 있습니다", en: "Caution, bottleneck zone", zhTW: "請注意，瓶頸區"};
export const WV_DANGER_09 = {ko: "주의하십시오 강풍지역이 있습니다", en: "Caution, strong wind zone", zhTW: "請注意，強風區"};
export const WV_DANGER_10 = {ko: "주의하십시오 결빙구간이 있습니다", en: "Caution, icy road zone", zhTW: "請注意，結冰路段"};
export const WV_DANGER_11 = {ko: "주의하십시오 과속방지턱이 있습니다", en: "Caution, speed bump ahead", zhTW: "請注意，減速帶前方"};
export const WV_DANGER_12 = {ko: "주의하십시오 낙석지역이 있습니다", en: "Caution, falling rocks zone", zhTW: "請注意，落石區"};
export const WV_DANGER_13 = {ko: "주의하십시오 철도건널목이 있습니다", en: "Caution, railway crossing", zhTW: "請注意，鐵路道口"};
export const WV_DANGER_14 = {ko: "주의하십시오 미끄러운 도로가 있습니다", en: "Caution, slippery road", zhTW: "請注意，路面濕滑"};
export const WV_REGION_01 = {ko: "이구간은 통행량 조사 구간 입니다", en: "This section is a traffic volume survey zone", zhTW: "本區域是交通量調查區"};
export const WV_REGION_02 = {ko: "이구간은 과적 위반 단속 구간입니다", en: "This section is an overloading violation enforcement zone", zhTW: "本區域是超載違規執法區"};
export const WV_REGION_03 = {ko: "이구간은 버스 전용차선구간입니다", en: "This section is a bus-only lane", zhTW: "本區域是專用公車車道"};
export const WV_DANGER_23 = {ko: "전방에 과속 주의 구간이 시작됩니다", en: "Caution, speeding zone ahead", zhTW: "前方即將進入超速區"};
export const WV_DANGER_24 = {ko: "과속 주의 구간이 종료되었습니다", en: "End of speeding zone", zhTW: "超速區結束"};
export const WV_DANGER_25 = {ko: "과속 주의 구간이 종료됩니다", en: "End of speeding zone", zhTW: "超速區結束"};
export const WV_DANGER_15 = {ko: "속도와 신호에 주의하십시오", en: "Caution, speed and signals", zhTW: "請注意，速度和信號"};
export const WV_DANGER_16 = {ko: "신호에 주의하십시오", en: "Caution, signals", zhTW: "請注意，信號"};
export const WV_DANGER_17 = {ko: "갓길 주의 구간이 있습니다", en: "Caution, narrow road", zhTW: "請注意，窄路"};
export const WV_DANGER_18 = {ko: "끼어들기 주의 구간이 있습니다", en: "Caution, merging zone", zhTW: "請注意，合流區"};
export const WV_DANGER_19 = {ko: "제방 주의 구간이 있습니다", en: "Caution, embankment zone", zhTW: "請注意，護坡區"};
export const WV_DANGER_20 = {ko: "좌회전 대기 주의 구간이 있습니다", en: "Caution, left turn waiting zone", zhTW: "請注意，左轉等待區"};
export const WV_CONTI_DOME = {ko: "방지턱이 연속으로 있습니다", en: "Continuous speed bumps", zhTW: "連續減速帶"};
export const WV_DANGER_40 = {ko: "전방에 계측 계근 불응 카메라가 있습니다", en: "There is a speed measurement camera ahead", zhTW: "前方有測速照相機"};
export const WV_DANGER_41 = {ko: "전방에 좌회전 전용차선 위반 단속 카메라가 있습니다", en: "There is a left turn violation enforcement camera ahead", zhTW: "前方有左轉違規執法照相機"};
export const WV_DANGER_42 = {ko: "이구간은 적재불량 위반 단속 구간입니다", en: "This section is a cargo loading violation enforcement zone", zhTW: "本區域是貨物裝載違規執法區"};
export const WV_DANGER_43 = {ko: "전방에 도주 차량 단속 카메라가 있습니다", en: "There is a fleeing vehicle enforcement camera ahead", zhTW: "前方有逃逸車輛執法照相機"};
export const WV_DANGER_44 = {ko: "전방에 차선 변경 단속 카메라가 있습니다", en: "There is a lane change enforcement camera ahead", zhTW: "前方有變換車道執法照相機"};
export const WV_DANGER_45 = {ko: "이구간은 램프미터링 구간입니다", en: "This section is a ramp metering zone", zhTW: "本區域是匝道計量區"};
export const WV_DANGER_46 = {ko: "전방에 꼬리물기 단속 카메라가 있습니다", en: "There is a tailgating enforcement camera ahead", zhTW: "前方有尾隨車輛執法照相機"};
export const WV_DANGER_47 = {ko: "전방에 지정 차로 단속 카메라가 있습니다", en: "There is a designated lane enforcement camera ahead", zhTW: "前方有指定車道執法照相機"};
export const WV_HIGHPASS = {ko: "하이패스 전용차선이 있습니다", en: "There is a high-pass lane", zhTW: "有高速通行專用車道"};
export const WV_POI_REST = {ko: "휴게소가", en: "Rest area", zhTW: "休息站"};
export const WV_POI_TOLL = {ko: "요금소가", en: "Toll booth", zhTW: "收費站"};
export const WV_POI_LPG = {ko: "충전소가", en: "LPG station", zhTW: "加油站"};
export const WV_POI_TG = {ko: "톨게이트", en: "Toll gate", zhTW: "收費門"};
export const WV_POI_FROM_TG = {ko: "톨게이트에서", en: "From toll gate", zhTW: "從收費門"};
export const WV_ETC_WON = {ko: "원입니다", en: "won", zhTW: "韓元"};
export const WV_POI_TG_RATE = {ko: "통행요금은", en: "The toll fee is", zhTW: "通行費用為"};
export const WV_POI_FILLING = {ko: "주유소가", en: "Gas station", zhTW: "加油站"};
export const WV_POI_NEXT_REST = {ko: "다음휴게소는", en: "The next rest area is", zhTW: "下一個休息站是"};
export const WV_POI_FORWARD_REST = {ko: "전방에 휴게소가 있습니다", en: "There is a rest area ahead", zhTW: "前方有休息站"};
export const WV_POI_REST_LPG = {ko: "LPG충전소와", en: "LPG station and", zhTW: "LPG加油站和"};
export const WV_SERVICE_GS = {ko: "GS칼텍스 주유소가 있습니다", en: "GS Caltex gas station is available", zhTW: "GS Caltex加油站可用"};
export const WV_SERVICE_SOIL = {ko: "S-OIL 주유소가 있습니다", en: "S-OIL gas station is available", zhTW: "S-OIL加油站可用"};
export const WV_SERVICE_SK = {ko: "SK 주유소가 있습니다", en: "SK gas station is available", zhTW: "SK加油站可用"};
export const WV_SERVICE_HYUNDAI = {ko: "현대오일뱅크 주유소가 있습니다", en: "Hyundai Oilbank gas station is available", zhTW: "Hyundai Oilbank加油站可用"};
export const WV_SERVICE_ALDDLE = {ko: "알뜰 주유소가 있습니다", en: "Alldle gas station is available", zhTW: "Alldle加油站可用"};
export const WV_NEAR_MYPOI = {ko: "근처에 등록지가 있습니다", en: "There is a registered location nearby", zhTW: "附近有註冊地點"};
export const WV_RTM_ACC = {ko: "전방에 사고 구간입니다", en: "Accident zone ahead", zhTW: "前方是事故區"};
export const WV_RTM_CONST = {ko: "전방에 공사 구간입니다", en: "Construction zone ahead", zhTW: "前方是施工區"};
export const WV_ENTER_PASS_LIMIT = {ko: "통행제한 구역에 진입하였습니다", en: "You have entered a restricted area", zhTW: "您已進入限制區域"};
export const WV_ENTER_WATERWORKS = {ko: "상수도 보호 구역에 진입하였습니다", en: "You have entered a waterworks protection zone", zhTW: "您已進入自來水保護區"};
export const WV_CAUTION = {ko: "주의하십시오", en: "Caution", zhTW: "請注意"};
export const WV_ON_ROUTE = {ko: "경로상에", en: "On the route", zhTW: "在路線上"};
export const WV_HEIGHT_LIMIT = {ko: "높이제한", en: "Height limit", zhTW: "限高"};
export const WV_WEIGHT_LIMIT = {ko: "중량제한", en: "Weight limit", zhTW: "限重"};
export const WV_PASS_LIMIT = {ko: "통행제한", en: "Restricted access", zhTW: "限制通行"};
export const WV_WATERWORKS = {ko: "상수도 보호", en: "Waterworks protection", zhTW: "自來水保護"};
export const WV_NARROW_ROAD = {ko: "좁은길", en: "Narrow road", zhTW: "窄路"};
export const WV_UTURN = {ko: "유턴", en: "U-turn", zhTW: "請迴轉"};
export const WV_HAS_AREA = {ko: "구간이 있습니다", en: "There is a section", zhTW: "有一個區域"};
export const WV_UNIT_METER2 = {ko: "미터", en: "meter", zhTW: "公尺"};
export const WV_UNIT_TON = {ko: "톤", en: "ton", zhTW: "噸"};
export const WV_POI_ONE_TOLL = {ko: "무정차 요금소가", en: "Non-stop toll booth", zhTW: "無停車收費站"};
export const WV_ETC_P_TURN = {ko: "P턴 입니다", en: "P-turn", zhTW: "P型轉彎"};
export const WV_ETC_R_TURN = {ko: "우회전 입니다", en: "Turn Right", zhTW: "請右轉"};
export const WV_ETC_L_TURN = {ko: "좌회전 입니다", en: "Turn Left", zhTW: "請左轉"};
export const WV_ETC_12_DIR = {ko: "12시 방향입니다", en: "12 o'clock direction", zhTW: "12點方向"};
export const WV_ETC_R02_DIR = {ko: "오른쪽 2시 방향입니다", en: "2 o'clock direction on the right", zhTW: "右側2點方向"};
export const WV_ETC_R03_DIR = {ko: "오른쪽 3시 방향입니다", en: "3 o'clock direction on the right", zhTW: "右側3點方向"};
export const WV_ETC_R05_DIR = {ko: "오른쪽 5시 방향입니다", en: "5 o'clock direction on the right", zhTW: "右側5點方向"};
export const WV_ETC_L10_DIR = {ko: "왼쪽 10시 방향입니다", en: "10 o'clock direction on the left", zhTW: "左側10點方向"};
export const WV_ETC_L09_DIR = {ko: "왼쪽 9시 방향입니다", en: "9 o'clock direction on the left", zhTW: "左側9點方向"};
export const WV_ETC_L07_DIR = {ko: "왼쪽 7시 방향입니다", en: "7 o'clock direction on the left", zhTW: "左側7點方向"};
export const WV_ETC_START_GUIDE = {ko: "음성안내를 시작합니다", en: "Start voice guidance", zhTW: "開始語音導航"};
export const WV_ETC_END_GUIDE = {ko: "음성안내를 종료합니다", en: "End voice guidance", zhTW: "結束語音導航"};
export const WV_ETC_CHECK_GPS = {ko: "GPS안테나를 확인하여 주십시오", en: "Please check the GPS antenna", zhTW: "請檢查GPS天線"};
export const WV_ETC_RE_ROUTE = {ko: "경로를 재탐색합니다", en: "Re-routing", zhTW: "重新規劃路線"};
export const WV_ETC_NEAR_DEST = {ko: "목적지 주변에 도착했습니다", en: "Arrived near the destination", zhTW: "到達目的地附近。"};
export const WV_ETC_AT_DEST = {ko: "목적지에 도착했습니다", en: "Arrived at the destination", zhTW: "到達目的地。"};
export const WV_ETC_NEAR_MID = {ko: "경유지 주변에 도착했습니다", en: "Arrived near the waypoint", zhTW: "到達經由地附近。"};
export const WV_ETC_AT_MID = {ko: "경유지에 도착했습니다", en: "Arrived at the waypoint", zhTW: "到達經由地。"};
export const WV_ETC_NEAR_BOOKMARK = {ko: "등록지점이 있습니다", en: "There is a bookmarked location", zhTW: "有一個書籤位置"};
export const WV_ETC_OUT_OF_ROUTE = {ko: "경로에서 벗어나 있습니다", en: "Out of route", zhTW: "偏離路線"};
export const WV_ETC_IS = {ko: "있습니다", en: "is", zhTW: "是"};
export const WV_ETC_BAD_GPS = {ko: "GPS 수신상태가 양호하지 않습니다", en: "GPS reception is poor", zhTW: "GPS接收不良"};
export const WV_ETC_MOVE_CAR_RETRY = {ko: "차량을 다른곳으로 이동 후 사용하십시오", en: "Move the vehicle to another location and try again", zhTW: "將車輛移至其他位置後再試一次"};
export const WV_ETC_PAUSE_GUIDE = {ko: "음성안내를 일시 중지합니다.", en: "Voice guidance paused", zhTW: "語音導航已暫停"};
export const WV_ETC_RESTART_GUIDE = {ko: "음성안내를 다시 시작합니다.", en: "Voice guidance restarted", zhTW: "語音導航已重新開始"};
export const WV_ETC_TO_HOME = {ko: "집으로 경로를 탐색합니다.", en: "Searching for a route to home", zhTW: "尋找回家的路線"};
export const WV_ETC_TO_COMPANY = {ko: "회사로 경로를 탐색합니다.", en: "Searching for a route to the company", zhTW: "尋找前往公司的路線"};
export const WV_ETC_TO_CLIENT = {ko: "거래처로 경로를 탐색합니다.", en: "Searching for a route to the client", zhTW: "尋找前往客戶的路線"};
export const WV_ETC_TO_POI = {ko: "관심지로 경로를 탐색합니다.", en: "Searching for a route to the point of interest", zhTW: "尋找前往興趣點的路線"};
export const WV_ETC_TO_NOT_REGIST = {ko: "위치가 등록되어 있지 않습니다.", en: "The location is not registered", zhTW: "該位置未註冊"};
export const WV_ETC_DAY_MODE = {ko: "주간모드로 전환합니다", en: "Switching to day mode", zhTW: "切換到日間模式"};
export const WV_ETC_NIGHT_MODE = {ko: "야간모드로 전환합니다", en: "Switching to night mode", zhTW: "切換到夜間模式"};
export const WV_ETC_ALONG_REAL_RULE = {ko: "실제의 교통규제에 따라 운전하시기 바랍니다", en: "Please drive according to the actual traffic regulations", zhTW: "請根據實際交通規則行駛"};
export const WV_ETC_FIND_GPS = {ko: "GPS를 찾았습니다", en: "GPS found", zhTW: "找到GPS"};
export const WV_ETC_ENTER_FERRY = {ko: "페리항로를 이용해 주십시요.", en: "Please use the ferry route", zhTW: "請使用渡輪路線"};
export const WV_ETC_200_AT_DEST = {ko: "200미터 앞에 목적지가 있습니다.", en: "There is a destination 200m ahead", zhTW: "200公尺後有目的地"};
export const WV_ETC_HEAD_LIGHT = {ko: "안전운전을 위하여 전조등을 켜세요", en: "Turn on the headlights for safe driving", zhTW: "為了安全駕駛，請打開頭燈"};

export const WV_ETC_TOUCHDOWN_DEST = {ko: "목적지 입니다.", en: "There is a destination", zhTW: "有一個目的地"};
export const WV_ETC_TOUCHDOWN_MID = {ko: "경유지 입니다.", en: "There is a waypoint", zhTW: "有一個經由地"};

export const WV_ETC_LEFT_LANE = {ko: "좌측차선을 유지하십시요", en: "Keep left lane", zhTW: "保持左車道"};
export const WV_ETC_RIGHT_LANE = {ko: "우측차선을 유지하십시요", en: "Keep right lane", zhTW: "保持右車道"};

export const WV_ETC_BAD_GPS2 = {ko: "GPS가 연결되지 않았습니다. GPS가 연결되면 안내를 시작합니다", en: "GPS is not connected. Guidance will start when GPS is connected", zhTW: "GPS未連接。GPS連接後將開始導航"};
export const WV_ETC_FIND_GPS2 = {ko: "길안내가 준비되었습니다. 안내를 시작합니다.", en: "Guidance is ready. Guidance will start", zhTW: "導航已準備就緒。將開始導航"};

export const WV_ETC_COL_TRAFFIC_CAM = {ko: "전방에 수집 카메라가 있습니다.", en: "There is a traffic camera ahead", zhTW: "前方有一個交通攝像機"};

export const WV_ETC_FAST_LANE = {ko: "고속차선으로 진입하십시오.", en: "Enter the fast lane", zhTW: "進入快車道"};
export const WV_ETC_SLOW_LANE = {ko: "저속차선으로 진입하십시오.", en: "Enter the slow lane", zhTW: "進入慢車道"};

export const WV_ETC_IT_IS_DEST = {ko: "목적지가 있습니다.", en: "There is a destination", zhTW: "有一個目的地"};
export const WV_ETC_IT_IS_MID = {ko: "경유지가 있습니다.", en: "There is a waypoint", zhTW: "有一個經由地"};
export const WV_D2_300M = {ko: "300미터 앞에", en: "300m ahead", zhTW: "300公尺後"};
export const WV_D2_500M = {ko: "500미터 앞에", en: "500m ahead", zhTW: "500公尺後"};
export const WV_D2_700M = {ko: "700미터 앞에", en: "700m ahead", zhTW: "700公尺後"};

export const WV_D2_10M = {ko: "10미터 앞에", en: "10m ahead", zhTW: "10公尺後"};
export const WV_D2_20M = {ko: "20미터 앞에", en: "20m ahead", zhTW: "20公尺後"};
export const WV_D2_30M = {ko: "30미터 앞에", en: "30m ahead", zhTW: "30公尺後"};
export const WV_D2_40M = {ko: "40미터 앞에", en: "40m ahead", zhTW: "40公尺後"};
export const WV_D2_50M = {ko: "50미터 앞에", en: "50m ahead", zhTW: "50公尺後"};
export const WV_D2_60M = {ko: "60미터 앞에", en: "60m ahead", zhTW: "60公尺後"};
export const WV_D2_70M = {ko: "70미터 앞에", en: "70m ahead", zhTW: "70公尺後"};
export const WV_D2_80M = {ko: "80미터 앞에", en: "80m ahead", zhTW: "80公尺後"};
export const WV_D2_90M = {ko: "90미터 앞에", en: "90m ahead", zhTW: "90公尺後"};
export const WV_D2_100M = {ko: "100미터 앞에", en: "100m ahead", zhTW: "100公尺後"};
export const WV_D2_120M = {ko: "120미터 앞에", en: "120m ahead", zhTW: "120公尺後"};
export const WV_D2_140M = {ko: "140미터 앞에", en: "140m ahead", zhTW: "140公尺後"};
export const WV_D2_160M = {ko: "160미터 앞에", en: "160m ahead", zhTW: "160公尺後"};
export const WV_D2_180M = {ko: "180미터 앞에", en: "180m ahead", zhTW: "180公尺後"};
export const WV_D2_200M = {ko: "200미터 앞에", en: "200m ahead", zhTW: "200公尺後"};

export const WV_D2_150M = {ko: "150미터 앞에", en: "150m ahead", zhTW: "150公尺後"};
export const WV_D2_250M = {ko: "250미터 앞에", en: "250m ahead", zhTW: "250公尺後"};
export const WV_D2_350M = {ko: "350미터 앞에", en: "350m ahead", zhTW: "350公尺後"};
export const WV_D2_400M = {ko: "400미터 앞에", en: "400m ahead", zhTW: "400公尺後"};
export const WV_D2_450M = {ko: "450미터 앞에", en: "450m ahead", zhTW: "450公尺後"};
export const WV_D2_550M = {ko: "550미터 앞에", en: "550m ahead", zhTW: "550公尺後"};
export const WV_D2_600M = {ko: "600미터 앞에", en: "600m ahead", zhTW: "600公尺後"};
export const WV_D2_650M = {ko: "650미터 앞에", en: "650m ahead", zhTW: "650公尺後"};
export const WV_D2_750M = {ko: "750미터 앞에", en: "750m ahead", zhTW: "750公尺後"};
export const WV_D2_800M = {ko: "800미터 앞에", en: "800m ahead", zhTW: "800公尺後"};
export const WV_D2_850M = {ko: "850미터 앞에", en: "850m ahead", zhTW: "850公尺後"};
export const WV_D2_900M = {ko: "900미터 앞에", en: "900m ahead", zhTW: "900公尺後"};
export const WV_D2_950M = {ko: "950미터 앞에", en: "950m ahead", zhTW: "950公尺後"};

export const WV_D2_1_1KM = {ko: "1.1킬로미터 앞에", en: "1.1km ahead", zhTW: "1.1公里後"};
export const WV_D2_1_2KM = {ko: "1.2킬로미터 앞에", en: "1.2km ahead", zhTW: "1.2公里後"};
export const WV_D2_1_3KM = {ko: "1.3킬로미터 앞에", en: "1.3km ahead", zhTW: "1.3公里後"};
export const WV_D2_1_6KM = {ko: "1.6킬로미터 앞에", en: "1.6km ahead", zhTW: "1.6公里後"};
export const WV_D2_1_7KM = {ko: "1.7킬로미터 앞에", en: "1.7km ahead", zhTW: "1.7公里後"};
export const WV_D2_1_8KM = {ko: "1.8킬로미터 앞에", en: "1.8km ahead", zhTW: "1.8公里後"};

export const WV_ETC_R02_TURN = {ko: "2시방향 우회전입니다.", en: "Turn right at 2 o'clock", zhTW: "在2點鐘方向右轉"};
export const WV_ETC_R03_TURN = {ko: "3시방향 우회전입니다.", en: "Turn right at 3 o'clock", zhTW: "在3點鐘方向右轉"};
export const WV_ETC_R05_TURN = {ko: "5시방향 우회전입니다.", en: "Turn right at 5 o'clock", zhTW: "在5點鐘方向右轉"};
export const WV_ETC_L07_TURN = {ko: "7시방향 좌회전입니다.", en: "Turn left at 7 o'clock", zhTW: "在7點鐘方向左轉"};

export const WV_SR_GUIDE_START = {ko: "안내 시작 버튼을 누르거나 5초후에 자동으로 안내를 시작합니다.", en: "Press the guidance start button or guidance will start automatically after 5 seconds", zhTW: "按下導航開始按鈕或導航將在5秒後自動開始"};

export const WV_CUR_VOLUME = {ko: "이 음량으로 안내합니다.", en: "Guidance with this volume", zhTW: "使用此音量進行導航"};
export const WV_DOT = {ko: "1쩜", en: "1 point", zhTW: "1點"};
export const WV_UNIT_METER = {ko: "미터", en: "meter", zhTW: "公尺"};
export const WV_R_FRONT3 = {ko: "앞에서", en: "in front", zhTW: "後"};
export const WV_L_SIDE_GO12 = {ko: "직진 차선을 유지하십시요.", en: "Keep the straight lane", zhTW: "保持直行車道"};

