import { Box, BoxProps, Paper } from "@mui/material";
import React, { useRef } from "react";
import Draggable from "react-draggable";

interface Props extends BoxProps {
  children: React.ReactNode;
}

const DebugCard = ({ children, ...restProps }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <Draggable nodeRef={divRef} handle=".drag-handle">
      <Box
        zIndex={(theme) => theme.zIndex.drawer + 1}
        position="absolute"
        {...restProps}
        ref={divRef}
      >
        <Paper>{children}</Paper>
      </Box>
    </Draggable>
  );
};

export default DebugCard;
