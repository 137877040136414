import React from "react";
import { Box, Button } from "@mui/material";
import { NearMe } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearUserAction, selectMap } from "./mapSlice";

const ResetViewButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isCenterChanged, isZoomChanged } = useAppSelector(selectMap);

  if (isCenterChanged || isZoomChanged)
    return (
      <Box position="absolute" right={8} bottom={16} zIndex={1}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<NearMe />}
          onClick={() => dispatch(clearUserAction())}
        >
          {t("Current")}
        </Button>
      </Box>
    );
  return null;
};

export default ResetViewButton;
