import { IconButton, styled, useTheme } from "@mui/material";
import React from "react";

interface CircleIconProps {
  children: React.ReactNode; // 원 내부 요소
  color?: string; // 원 배경 색상
  onClick?: () => void; // click event
  size: "small" | "medium" | "large"; // 원 크기
}

const sizeMap = {
  small: 24,
  medium: 37,
  large: 50,
};

const CenteredIconButton = styled(IconButton)<{ color?: string; opacity?: number }>(
  ({ theme, color, opacity }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: color || theme.palette.background.default,
      opacity,
      zIndex: -1,
    },
  }),
);

const CircleIcon = ({ children, color, onClick, size }: CircleIconProps) => {
  const theme = useTheme();
  const opacity = color === theme.palette.background.paper ? 0.3 : 1;
  const buttonSize = sizeMap[size];

  return (
    <CenteredIconButton
      onClick={onClick}
      sx={{
        width: buttonSize,
        height: buttonSize,
      }}
      opacity={opacity}
    >
      {children}
    </CenteredIconButton>
  );
};

export default CircleIcon;
