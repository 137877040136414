import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LineString, Point } from "geojson";
import * as turf from "@turf/turf";
import i18n from "../../app/locales/i18n";
import { Path } from "../route/routeSlice";
import type { RootState } from "../../app/store";
import {
  getFormattedDistance,
  getFormattedTime,
  binarySearchForPointIdx,
  binarySearchForGuideIdxByInterval,
} from "./guideUtils";
import Logger from "../../app/logger";

export interface Guidance {
  guidePoint: Point;
  guideLine: LineString; // 제거 예정
  startInterval: number;
  endInterval: number;
  accdist: number;
  acctime: number;
  guidedist: number;
  guidetime: number;
  partdist: number;
  sign: number;
  subsign: number;
  text: string;
  street: string;
  dirname: string | null;
  jv_day: string | null;
  jv_night: string | null;
  lengthRatio: number; // '서버에서 온 guide거리'/'포인트로 측정한 거리'
  isHighway: boolean;
}

export interface SafeDrive {
  guidePoint: Point;
  // guideLine: LineString;
  point_idx: number;
  type: SafeType;
  accdist: number;
  guidedist: number;
  speed: number;
  isHighway: boolean;
  castFlag: boolean[];
  isPassed: boolean;
}

export interface GuideRemainInfo {
  etaTime: string;
  remainTime: string;
  remainDist: string;
}

export enum SafeType {
  SAFE_INFO_NONE = 0,
  SAFE_INFO_SPEED_FIXED_CAMERA,
  SAFE_INFO_SPEED_MOVABLE_CAMERA,
  SAFE_INFO_TRAFFIC_LIGHT,
  SAFE_INFO_SPEED_TRAFFIC_LIGHT,
}

export interface WayDetailInfo {
  wayId: number;
  pointIdx: number[];
  roadClass: string;
  properties: string[];
  lane: number | null;
  speedLimit: number | null;
  length: number;
  direction: boolean;
  roadName: string;
  lengthRatio: number;
  order: number;
}

interface State {
  // 실제 안내용 데이터
  currentSafeIndex: number;
  guidances: Guidance[];
  safeDrives: SafeDrive[];
  totalRouteTime: number;
  totalRouteDist: number;
  guideRemainInfo: GuideRemainInfo;
  sortedWayIds: WayDetailInfo[];
}

const initialState: State = {
  guidances: [],
  safeDrives: [],
  currentSafeIndex: -1,
  totalRouteTime: 0,
  totalRouteDist: 0,
  guideRemainInfo: {
    etaTime: "",
    remainTime: "",
    remainDist: "경로 정보 없음",
  },
  sortedWayIds: [],
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    // set paths - route 생성후 설정.
    setRoutePath: (state, action: PayloadAction<{ path: Path }>) => {
      if (
        action.payload.path === undefined ||
        action.payload.path === null ||
        action.payload.path.instructions === undefined ||
        action.payload.path.instructions === null
      ) {
        // clear route;
        state.guidances = [];
        state.safeDrives = [];
        return;
      }

      state.totalRouteTime = action.payload.path.time;
      state.totalRouteDist = action.payload.path.distance;

      // wayid 정렬
      // state.sortedWayIds = action.payload.path.details?.osm_way_id.slice().sort((a, b) => a[2] - b[2]) ?? []; // copy array
      let jj = 0;
      let kk = 0;
      for (let ii = 0; ii < (action.payload.path.rousen_details?.way_info.length ?? 0); ii += 1) {
        const item = action.payload.path.rousen_details?.way_info[ii];
        if (item === undefined) break;

        while (
          action.payload.path.rousen_details?.road_details &&
          jj < action.payload.path.rousen_details.road_details.length &&
          action.payload.path.rousen_details.road_details[jj].last_idx < item.end_idx
        ) {
          jj += 1;
        }

        while (
          action.payload.path.rousen_details?.road_names &&
          kk < action.payload.path.rousen_details.road_names.length &&
          action.payload.path.rousen_details.road_names[kk].last_idx < item.end_idx
        ) {
          kk += 1;
        }

        let roadName = "";
        if (
          action.payload.path.rousen_details?.road_names &&
          jj < action.payload.path.rousen_details.road_names.length
        ) {
          roadName = action.payload.path.rousen_details.road_names[kk].text;
        }

        if (
          action.payload.path.rousen_details?.road_details &&
          jj < action.payload.path.rousen_details.road_details.length
        ) {
          const wayDetail: WayDetailInfo = {
            wayId: item.id,
            pointIdx: [item.start_idx, item.end_idx],
            roadClass: action.payload.path.rousen_details?.road_details[jj].road_class ?? "",
            properties: action.payload.path.rousen_details?.road_details[jj].properties ?? [],
            lane: action.payload.path.rousen_details?.road_details[jj].lane ?? 0,
            speedLimit:
              typeof action.payload.path.rousen_details?.road_details[jj].speed_limit === "number"
                ? (action.payload.path.rousen_details?.road_details[jj].speed_limit as number)
                : null,
            length: item.length,
            direction: item.direction,
            roadName,
            lengthRatio: 0,
            order: ii,
          };
          state.sortedWayIds.push(wayDetail);
        } else {
          const wayDetail: WayDetailInfo = {
            wayId: item.id,
            pointIdx: [item.start_idx, item.end_idx],
            roadClass: "",
            properties: [],
            lane: null,
            speedLimit: null,
            length: item.length,
            direction: item.direction,
            roadName,
            lengthRatio: 0,
            order: ii,
          };
          state.sortedWayIds.push(wayDetail);
        }
      }
      // 소트는 lengthratio 계산 뒤로 이동함.

      // dir_Guide 소트
      const sortedDirGuide =
        action.payload.path.rousen_details?.dir_guide
          .slice()
          .sort((a, b) => a.point_idx - b.point_idx) ?? []; // copy array

      // junction_view 소트
      const sortedJV =
        action.payload.path.rousen_details?.junction_view
          .slice()
          .sort((a, b) => a.point_idx - b.point_idx) ?? []; // copy array

      // 총거리 비교()
      // const totaldist1 = turf.length(turf.lineString(action.payload.path.points.coordinates, { units: "kilometers" })) * 1000;
      // const totaldist2 = action.payload.path.distance;
      // Logger.log("distance by turf: ", totaldist1, ", by path", totaldist2);

      if (state.guidances.length === 0) {
        let wayIdx = 0;
        // 출발지 안내는 필요없으므로 패스
        for (let i = 1; i < action.payload.path.instructions.length; i += 1) {
          const guidePoint: Point = {
            type: "Point",
            coordinates:
              action.payload.path.points.coordinates[
                action.payload.path.instructions[i - 1].interval[1]
              ],
          };
          const guideLine: LineString = {
            type: "LineString",
            coordinates: action.payload.path.points.coordinates.slice(
              action.payload.path.instructions[i - 1].interval[0],
              action.payload.path.instructions[i - 1].interval[1] + 1,
            ),
          };

          const guidePointIdx = action.payload.path.instructions[i - 1].interval[1];
          // dir name
          const dirNameIdx = binarySearchForPointIdx(sortedDirGuide, guidePointIdx);
          const jvIdx = binarySearchForPointIdx(sortedJV, guidePointIdx);

          let dirName: string | null = null;
          if (dirNameIdx !== null && dirNameIdx !== -1) {
            let dirFieldCnt = 0;

            // short name
            if (sortedDirGuide[dirNameIdx].short_name.length > 0) {
              dirName = sortedDirGuide[dirNameIdx].short_name;
              dirFieldCnt += 1;
            }

            // middle name
            if (sortedDirGuide[dirNameIdx].mid_name.length > 0) {
              if (dirName != null) {
                dirName += `, ${sortedDirGuide[dirNameIdx].mid_name}`;
              } else {
                dirName = sortedDirGuide[dirNameIdx].mid_name;
              }
              dirFieldCnt += 1;
            }

            // long name
            // short 과 middle 이 존재하면 long name 은 사용하지 않는다.
            if (dirFieldCnt < 2 && sortedDirGuide[dirNameIdx].long_name.length > 0) {
              if (dirName != null) {
                dirName += `, ${sortedDirGuide[dirNameIdx].long_name}`;
              } else {
                dirName = sortedDirGuide[dirNameIdx].long_name;
              }
            }
          }

          let dayImg: string | null = null;
          let nightImg: string | null = null;
          if (jvIdx !== null && jvIdx !== -1) {
            if (sortedJV[jvIdx].day_img != null && sortedJV[jvIdx].day_img.length > 0) {
              dayImg = sortedJV[jvIdx].day_img;
            }

            if (sortedJV[jvIdx].night_img != null && sortedJV[jvIdx].night_img.length > 0) {
              nightImg = sortedJV[jvIdx].night_img;
            }
          }

          const linedist =
            guideLine.coordinates.length < 2
              ? 0
              : turf.length(turf.lineString(guideLine.coordinates), { units: "kilometers" }) * 1000;
          state.guidances.push({
            guidePoint,
            guideLine,
            accdist:
              i === 1
                ? action.payload.path.instructions[i - 1].distance
                : state.guidances[i - 2].accdist + action.payload.path.instructions[i - 1].distance,
            acctime:
              i === 1
                ? action.payload.path.instructions[i - 1].time
                : state.guidances[i - 2].acctime + action.payload.path.instructions[i - 1].time,
            guidedist:
              i === 1
                ? action.payload.path.instructions[i - 1].distance /* linedist */
                : state.guidances[i - 2].accdist +
                  action.payload.path.instructions[i - 1].distance /* linedist */,
            guidetime:
              i === 1
                ? state.totalRouteTime
                : state.totalRouteTime - state.guidances[i - 2].acctime,
            partdist: action.payload.path.instructions[i - 1].distance,
            startInterval: action.payload.path.instructions[i - 1].interval[0],
            endInterval: action.payload.path.instructions[i - 1].interval[1],
            sign: action.payload.path.instructions[i].sign,
            subsign: 0,
            text: action.payload.path.instructions[i].text,
            street: action.payload.path.instructions[i].street_name ?? "",
            jv_day: dayImg,
            jv_night: nightImg,
            dirname: dirName,
            lengthRatio: action.payload.path.instructions[i - 1].distance / linedist, // '서버에서 온 guide거리'/'포인트로 측정한 거리'
            isHighway: false,
          });

          // roundabout 체크
          if (state.guidances[i - 1].sign === 6 /* || state.guidances[i - 1].sign === -6 */) {
            let clockwise: number = 0; // -1 반시계방향, 0, 1 시계방향
            const turnAngle: number = action.payload.path.instructions[i].turn_angle ?? 0;

            if (turnAngle < 0) {
              clockwise = -1;
            } else if (turnAngle > 0) {
              clockwise = 1;
            }

            if (clockwise === 0) {
              // 회전 방향을 알 수 없음. 그냥 임시로 직진처리./
              state.guidances[i - 1].subsign = 0;
            } else {
              // if (clockwise === 1) state.guidances[i - 1].sign = -6;
              // else state.guidances[i - 1].sign = 6;

              let radian: number = 0;
              radian = (turnAngle < 0 ? -Math.PI : Math.PI) - turnAngle;
              const degree: number = (Math.abs(Math.PI * clockwise - radian) * 180.0) / Math.PI;
              // Logger.log("회전각도", degree);

              if (degree < 22.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? -3 : 3;
              } else if (degree < 67.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? -3 : 3;
              } else if (degree < 112.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? -2 : 2;
              } else if (degree < 157.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? -1 : 1;
              } else if (degree < 202.5) {
                state.guidances[i - 1].subsign = 0;
              } else if (degree < 247.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? 1 : -1;
              } else if (degree < 292.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? 2 : -2;
              } else if (degree < 337.5) {
                state.guidances[i - 1].subsign = clockwise === 1 ? 3 : -3;
              } else {
                // 원래 유턴은 아닌데, 진짜 유턴인지 알수 없으니 일단 이거를 유턴으로 표시 시킨다.
                state.guidances[i - 1].subsign = clockwise === 1 ? 98 : -98;
              }
            }
          }

          while (
            wayIdx < state.sortedWayIds.length &&
            state.sortedWayIds[wayIdx].pointIdx[0] >=
              action.payload.path.instructions[i - 1].interval[0] &&
            state.sortedWayIds[wayIdx].pointIdx[1] <=
              action.payload.path.instructions[i - 1].interval[1]
          ) {
            if (
              state.sortedWayIds[wayIdx].pointIdx[1] ===
              action.payload.path.instructions[i - 1].interval[1]
            ) {
              state.guidances[state.guidances.length - 1].isHighway =
                state.sortedWayIds[wayIdx].roadClass === "highway";
            }

            state.sortedWayIds[wayIdx].lengthRatio =
              state.guidances[state.guidances.length - 1].lengthRatio;
            wayIdx += 1;
          }
        }

        state.sortedWayIds.sort((a, b) => {
          const diff = a.wayId - b.wayId;
          if (diff !== 0) return diff;

          if (a.direction > b.direction) {
            return 1;
          }

          if (a.direction < b.direction) {
            return -1;
          }

          return a.order - b.order;
        });

        // 경유지로 인해, 다음 안내가 경유지와 같은 거리로 직진으로 나오는것을 방지하기 위해 경유지 다음 안내를 체크한다.
        // 생성하면서 인덱스 꼬이는걸 방지하기 위해 데이터 생성 후 다시 루프.
        state.guidances.forEach((point, index) => {
          if (index > 0) {
            if (
              point.accdist === state.guidances[index - 1].accdist &&
              state.guidances[index - 1].sign === 5
            ) {
              state.guidances.splice(index, 1);
            }
          }
        });
      }

      // 도착시간/거리 정보가 처음에 안보여서, 미리 만들어 준다.
      // total remain time
      state.guideRemainInfo.remainTime = getFormattedTime(state.totalRouteTime);

      // total remain dist
      state.guideRemainInfo.remainDist = getFormattedDistance(state.totalRouteDist);

      // establish time
      let datelocale = "en-us";
      if (i18n.language === "ko") {
        datelocale = "ko-kr";
      } else if (i18n.language === "zhtw") {
        datelocale = "zh-tw";
      }
      const timeStamp: number = Date.now();
      state.guideRemainInfo.etaTime = new Date(timeStamp + state.totalRouteTime).toLocaleTimeString(
        datelocale,
        { hour: "2-digit", minute: "2-digit" },
      );

      // 카메라 데이터 설정.

      if (
        state.safeDrives.length === 0 &&
        action.payload.path.rousen_details !== undefined &&
        action.payload.path.rousen_details !== null &&
        action.payload.path.rousen_details.safe_road !== undefined &&
        action.payload.path.rousen_details.safe_road !== null &&
        action.payload.path.rousen_details.safe_road.length > 0
      ) {
        state.currentSafeIndex = 0;
        let j = 0;
        for (let i = 0; i < action.payload.path.rousen_details.safe_road.length; i += 1) {
          // 1. 총 누적거리 계산
          while (j < state.guidances.length - 1) {
            if (
              state.guidances[j].startInterval <=
                action.payload.path.rousen_details.safe_road[i].start_point_idx &&
              state.guidances[j].endInterval >=
                action.payload.path.rousen_details.safe_road[i].start_point_idx // end포인트는 instuction범위와 다를 수 있으므로 start로만 체크
            ) {
              let cameraType = SafeType.SAFE_INFO_NONE;
              switch (action.payload.path.rousen_details.safe_road[i].type) {
                case "fix_sign":
                  cameraType = SafeType.SAFE_INFO_SPEED_FIXED_CAMERA;
                  break;
                case "spd_sign":
                  cameraType = SafeType.SAFE_INFO_SPEED_TRAFFIC_LIGHT;
                  break;
                case "sign_sign":
                  cameraType = SafeType.SAFE_INFO_TRAFFIC_LIGHT;
                  break;
                default:
                  cameraType = SafeType.SAFE_INFO_NONE;
              }

              if (cameraType === SafeType.SAFE_INFO_NONE) {
                break;
              }

              let accdistPoint = j === 0 ? 0 : state.guidances[j - 1].accdist;
              /*
              if (
                state.guidances[j].startInterval !==
                action.payload.path.rousen_details.safe_road[i].start_point_idx
              ) {
                const tmpLine = action.payload.path.points.coordinates.slice(
                  state.guidances[j].startInterval,
                  action.payload.path.rousen_details.safe_road[i].start_point_idx + 1,
                );
                accdistPoint += (turf.length(turf.lineString(tmpLine), { units: "kilometers" }) * 1000) * state.guidances[j].lengthRatio;
              }           
              accdistPoint += action.payload.path.rousen_details.safe_road[i].dist * state.guidances[j].lengthRatio;
              */
              const lineStringCamOnGuide = turf.lineString(
                action.payload.path.points.coordinates.slice(
                  state.guidances[j].startInterval,
                  state.guidances[j].endInterval + 1,
                ),
              );
              const camPointOnLine = turf.nearestPointOnLine(
                lineStringCamOnGuide,
                turf.point(action.payload.path.rousen_details.safe_road[i].pos),
                { units: "meters" },
              );
              accdistPoint += camPointOnLine.properties.location * state.guidances[j].lengthRatio;

              state.safeDrives.push({
                guidePoint: {
                  type: "Point",
                  coordinates: action.payload.path.rousen_details.safe_road[i].pos,
                },
                point_idx: action.payload.path.rousen_details.safe_road[i].start_point_idx,
                type: cameraType,
                accdist: accdistPoint,
                guidedist: accdistPoint,
                speed: action.payload.path.rousen_details.safe_road[i].speed,
                isHighway: action.payload.path.rousen_details.safe_road[i].road_type !== "normal",
                castFlag: [false, false, false, false, false],
                isPassed: false,
              });
              break;
            }

            j += 1;
          }
        }
      }
    },

    refreshGuide: (
      state,
      action: PayloadAction<{
        path: Path;
        linkStartIndex: number | null;
        guideDistanceFromStart: number | null;
        guidanceIndex: number | null;
      }>,
    ) => {
      if (action.payload.guideDistanceFromStart === null) return;

      const oldCamIdx = state.currentSafeIndex;
      if (state.guidances.length > 0) {
        for (let i = 0; i < state.guidances.length; i += 1) {
          state.guidances[i].guidedist = Math.round(
            state.guidances[i].accdist - action.payload.guideDistanceFromStart,
          );
        }

        /*
       Logger.log("", (state.guidances[state.currentGuideIndex].guidedist), "m 후", state.guidances[state.currentGuideIndex].text);
       if (state.guidances.length > state.currentGuideIndex + 1) {
        Logger.log("(다음)", (state.guidances[state.currentGuideIndex + 1].guidedist), "m 후", state.guidances[state.currentGuideIndex + 1].text);
      }
        */

        // total remain time
        if (state.guidances.length === 0) {
          state.guideRemainInfo.remainTime = "경로정보 없음";
        } else if (action.payload.guidanceIndex === null || action.payload.guidanceIndex === -1) {
          // 자차 위치 정보가 없을때는 전체 거리 기준 시간 계산
          state.guideRemainInfo.remainTime = getFormattedTime(state.totalRouteTime);
        } else {
          state.guideRemainInfo.remainTime = getFormattedTime(
            state.totalRouteTime - state.guidances[action.payload.guidanceIndex].acctime,
          );
        }

        // total remain dist
        if (action.payload.guideDistanceFromStart === null) {
          // 자차 위치 정보가 없을때는 전체 거리 출력
          state.guideRemainInfo.remainDist = getFormattedDistance(state.totalRouteDist);
        } else {
          state.guideRemainInfo.remainDist = getFormattedDistance(
            state.totalRouteDist - action.payload.guideDistanceFromStart,
          );
        }

        // establish time
        let datelocale = "en-us";
        if (i18n.language === "ko") {
          datelocale = "ko-kr";
        } else if (i18n.language === "zhtw") {
          datelocale = "zh-tw";
        }
        const timeStamp: number = Date.now();
        if (action.payload.guidanceIndex === null || action.payload.guidanceIndex === -1) {
          // 자체 위치 정보가 없을때에는 전체 시간 기준으로 도착시각 계산.
          state.guideRemainInfo.etaTime = new Date(
            timeStamp + state.totalRouteTime,
          ).toLocaleTimeString(datelocale, { hour: "2-digit", minute: "2-digit" });
        } else {
          state.guideRemainInfo.etaTime = new Date(
            timeStamp +
              (state.totalRouteTime - state.guidances[action.payload.guidanceIndex].acctime),
          ).toLocaleTimeString(datelocale, { hour: "2-digit", minute: "2-digit" });
        }
      }

      for (let i = 0; i < state.safeDrives.length; i += 1) {
        state.safeDrives[i].guidedist = Math.round(
          state.safeDrives[i].accdist - action.payload.guideDistanceFromStart,
        );
      }

      if (state.safeDrives.length > 0) {
        while (
          state.currentSafeIndex > 0 &&
          state.safeDrives[state.currentSafeIndex - 1].guidedist > 0
        ) {
          state.currentSafeIndex -= 1;
        }

        while (
          state.currentSafeIndex < state.safeDrives.length - 1 &&
          state.safeDrives[state.currentSafeIndex].guidedist < 0
        ) {
          state.currentSafeIndex += 1;
        }
      }
    },

    // clear guide data - 경로 종료나 경로 취소시. 안내 데이터 모두 삭제.
    clearGuide: (state) => {
      state.guidances = [];
      state.safeDrives = [];
      state.currentSafeIndex = -1;
      state.guideRemainInfo.etaTime = "";
      state.guideRemainInfo.remainDist = "";
      state.guideRemainInfo.remainTime = "";
      state.sortedWayIds = [];
    },
    setCameraCastFlag: (
      state,
      action: PayloadAction<{
        index: number;
        flags: boolean[];
      }>,
    ) => {
      state.safeDrives[action.payload.index].castFlag = action.payload.flags;
    },
    setCameraPassed: (state, action: PayloadAction<number>) => {
      if (state.safeDrives[action.payload]) state.safeDrives[action.payload].isPassed = true;
    },
    clearSafetyInfoFlags: (state) => {
      state.safeDrives.forEach((item) => {
        item.castFlag = [false, false, false, false, false];
        item.isPassed = false;
      });
    },
  },
});

export default guideSlice;
export const {
  refreshGuide,
  setRoutePath,
  clearGuide,
  setCameraCastFlag,
  setCameraPassed,
  clearSafetyInfoFlags,
} = guideSlice.actions;
export const selectGuide = (state: RootState) => state.guide;
export const selectGuidances = (state: RootState) => state.guide.guidances;
export const selectSafeDrives = (state: RootState) => state.guide.safeDrives;
export const selectGuideRemainInfo = (state: RootState) => state.guide.guideRemainInfo;
