import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Place } from "../search/searchSlice";
import type { RootState } from "../../app/store";

export const waypointTypeImages = {
  origin: "/guide_flag/flag_start.webp",
  destination: "/guide_flag/flag_end.webp",
  waypoint: "/guide_flag/flag_mid.webp",
};

export type WaypointType = "origin" | "destination" | "waypoint";

export interface Waypoint {
  id: number;
  place: Place | null;
  type: WaypointType;
  label: string;
  guideIndex: number | null;
  guideDistance: number | null;
}

interface State {
  waypoints: Waypoint[];
}

export const createWaypoint = (place?: Place | null): Waypoint => {
  return {
    id: Date.now() + Math.random() * 100000,
    place: place || null,
    type: "waypoint",
    label: "경유",
    guideIndex: null,
    guideDistance: null,
  };
};

const initialState: State = {
  waypoints: [createWaypoint(), createWaypoint()],
};

const checkWaypointOrder = (waypoints: Waypoint[], clearGuideInfo: boolean): Waypoint[] => {
  return waypoints.map((waypoint, index) => {
    if (clearGuideInfo) {
      waypoint.guideIndex = null;
      waypoint.guideDistance = null;
    }

    if (index === 0) {
      return { ...waypoint, type: "origin", label: "출발" };
    }
    if (index === waypoints.length - 1) {
      return { ...waypoint, type: "destination", label: "도착" };
    }
    return { ...waypoint, type: "waypoint", label: "경유" };
  });
};

const waypointSlice = createSlice({
  name: "waypoint",
  initialState,
  reducers: {
    setWayPoint: (state, action: PayloadAction<{ place: Place | null; index: number }>) => {
      const newWaypoints = [...state.waypoints];
      newWaypoints[action.payload.index].place = action.payload.place;
      state.waypoints = checkWaypointOrder(newWaypoints, true);
    },
    setWayPoints: (state, action: PayloadAction<State["waypoints"]>) => {
      state.waypoints = checkWaypointOrder(action.payload, true);
    },
    addWayPoint: (state) => {
      const newWaypoints = [...state.waypoints];
      const insertIndex = newWaypoints.length - 1;
      newWaypoints.splice(insertIndex, 0, createWaypoint());
      state.waypoints = checkWaypointOrder(newWaypoints, true);
    },
    clearWaypoints: (state) => {
      state.waypoints = checkWaypointOrder([createWaypoint(), createWaypoint()], true);
    },
    removeWayPoint: (state, action: PayloadAction<number>) => {
      const newWaypoints = [...state.waypoints];
      newWaypoints.splice(action.payload, 1);
      state.waypoints = checkWaypointOrder(newWaypoints, true);
    },
    setWayPointGuideInfo: (
      state,
      action: PayloadAction<{ index: number; guideIndex: number; guideDistance: number }>,
    ) => {
      const newWaypoints = [...state.waypoints];
      newWaypoints[action.payload.index].guideIndex = action.payload.guideIndex;
      newWaypoints[action.payload.index].guideDistance = action.payload.guideDistance;
      state.waypoints = checkWaypointOrder(newWaypoints, false);
    },
    reorderWaypoints: (state, action: PayloadAction<{ fromIndex: number; toIndex: number }>) => {
      const newWaypoints = [...state.waypoints];
      const [movedWaypoint] = newWaypoints.splice(action.payload.fromIndex, 1);
      newWaypoints.splice(action.payload.toIndex, 0, movedWaypoint);
      state.waypoints = checkWaypointOrder(newWaypoints, true);
    },
    removeNullPlaces: (state) => {
      const newWaypoints = [...state.waypoints];
      const filteredWaypoints = newWaypoints.filter((waypoint) => waypoint.place !== null);
      state.waypoints = checkWaypointOrder(filteredWaypoints, true);
    },
  },
});
export const {
  addWayPoint,
  clearWaypoints,
  setWayPoint,
  setWayPoints,
  removeWayPoint,
  setWayPointGuideInfo,
  reorderWaypoints,
  removeNullPlaces,
} = waypointSlice.actions;
export const selectWaypoint = (state: RootState) => state.waypoint;
export default waypointSlice;
