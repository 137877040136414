import { enqueueSnackbar } from "notistack";
import baseApi, { ApiResponse, ApiErrorResponse } from "../baseApi";

// 즐겨찾기 정보
export interface GetFavoritesResponse {
  idx: number;
  user_id: number;
  seq: number;
  name: string;
  addr: string;
  lat: number;
  lon: number;
  default_type: boolean;
  created_at: string;
}
export interface CreateFavoriteRequest {
  name: string;
  lat: number;
  lon: number;
  addr: string;
  default_type: boolean;
}

// 즐겨찾기 수정
export interface UpdateFavoriteRequest {
  name: string;
  addr: string;
  lat: number;
  lon: number;
  default_type: boolean;
}

// primary: 집, 회사  normal: 일반 즐겨찾기
export interface GetAllFavoritesResponse {
  primary: GetFavoritesResponse[];
  normal: GetFavoritesResponse[];
}

const favoritesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // 즐겨찾기 불러오기
    getFavorites: builder.query<GetAllFavoritesResponse, void>({
      query: () => ({
        url: "favorites",
        method: "get",
      }),
      providesTags: ["favorites"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
      transformResponse: (response: GetFavoritesResponse[]) => {
        const primary: GetFavoritesResponse[] = [];
        const normal: GetFavoritesResponse[] = [];

        response.forEach((item) => {
          if (item.default_type) {
            primary.push(item);
          } else {
            normal.push(item);
          }
        });

        return { primary, normal };
      },
    }),
    // 즐겨찾기 등록
    createFavorite: builder.mutation<void, CreateFavoriteRequest>({
      query: (favorite) => ({
        url: "favorites",
        method: "post",
        body: favorite,
      }),
      invalidatesTags: ["favorites"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 즐겨찾기 삭제
    deleteFavorite: builder.mutation<void, number>({
      query: (id) => ({
        url: `favorites/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["favorites"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 즐겨찾기 수정
    updateFavoriteInfo: builder.mutation<void, { idx: number; data: UpdateFavoriteRequest }>({
      query: ({ idx, data }) => ({
        url: `favorites/${idx}`,
        method: "put",
        body: {
          name: data.name,
          addr: data.addr,
          lat: data.lat,
          lon: data.lon,
          default_type: data.default_type,
        },
      }),
      invalidatesTags: ["favorites"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 즐겨찾기 순서 변경
    updateFavoriteSeq: builder.mutation<void, { idx: number; seq: number }>({
      query: ({ idx, seq }) => ({
        url: `favorites/${idx}/seq/${seq}`,
        method: "put",
        body: { idx, seq },
      }),
      invalidatesTags: ["favorites"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
  }),
});

export const {
  useGetFavoritesQuery,
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useUpdateFavoriteInfoMutation,
  useUpdateFavoriteSeqMutation,
} = favoritesApi;
export default favoritesApi;
