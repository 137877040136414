import { enqueueSnackbar } from "notistack";
import baseApi, { ApiResponse, ApiErrorResponse } from "../baseApi";

// 유저 토큰
export interface GetUserTokenRequest {
  user_id: string;
  password: string;
}
export interface GetUserTokenResponse {
  token: string;
}

// 로그인
export interface CreateUserRequest {
  user_id: string;
  password: string;
  join_type: string;
}
export interface CreateUserResponse {
  userId: string;
  access_token: string;
  join_type: string;
}

// 유저 정보
export interface GetUserInfoResponse {
  user_id: string;
  nickname: string;
  join_type: string;
  created_at: string;
  role: string;
}

const userApi = baseApi.injectEndpoints({
  // 내정보 보기 추가
  endpoints: (builder) => ({
    // 로그인
    getUserToken: builder.mutation<GetUserTokenResponse, GetUserTokenRequest>({
      query: (request) => ({
        url: "login",
        body: { ...request },
        method: "post",
      }),
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 회원가입
    createUser: builder.mutation<ApiResponse<CreateUserResponse>, CreateUserRequest>({
      query: (request) => ({
        url: "signup",
        body: { ...request, expiredTime: 1000 * 60 * 20 },
        method: "post",
      }),
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 내정보
    getUserInfo: builder.query<GetUserInfoResponse, void>({
      query: () => ({
        url: "me",
        method: "get",
      }),
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
  }),
});

export const { useGetUserTokenMutation, useCreateUserMutation, useGetUserInfoQuery } = userApi;
export default userApi;
