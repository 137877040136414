// 메뉴
import React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";

interface ProfileToggleButtonProps {
  onToggleProfile: () => void;
  disabled?: boolean; // 비활성화 여부
}

const ProfileToggleButton = ({ onToggleProfile, disabled }: ProfileToggleButtonProps) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.background.paper,
      }}
    >
      <IconButton size="large" onClick={onToggleProfile} color="inherit" disabled={disabled}>
        <Menu fontSize="medium" />
      </IconButton>
    </Paper>
  );
};

export default ProfileToggleButton;
