import { Button, CardActions, CardContent, Collapse, Divider } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { along, distance, lineString } from "@turf/turf";
import { Close } from "@mui/icons-material";
import Simulator from "./Simulator";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectRoute } from "../../route/routeSlice";
import { calculateDegreeByPoints } from "../../geolocation/geoLocationUtils";
import { selectSimulator, setTimelines } from "./simulatorSlice";
import { setGeoLocation } from "../../geolocation/geoLocationSlice";
import DebuggerHeader from "../DebugCardHeader";
import DebugCard from "../DebugCard";

interface Props {
  onClose: () => void;
}

// 60km/h
const velocity = 60;

const RouteSimulator = memo(({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(true);
  const {
    speed,
    play,
    currentTimeline: currentDistance,
    timelines,
  } = useAppSelector(selectSimulator);
  const { activePath } = useAppSelector(selectRoute);

  // 경로의 타임라인을 생성합니다.
  useEffect(() => {
    if (!activePath) return;

    let startTime = Date.now();
    const newTimelines: { timestamp: number; value: number }[] = [];
    const meterPerSecond = (velocity * speed * 1000) / 3600;

    const { coordinates } = activePath.points;

    let totalDistance = 0;

    // 경로의 모든 지점을 순회하며 총 거리를 계산합니다.
    coordinates.forEach((point, index) => {
      const nextPoint = coordinates[index + 1];
      if (!nextPoint) return;
      totalDistance += distance(point, nextPoint, { units: "meters" });
    });

    // 1초마다 1m씩 증가하는 타임라인을 생성합니다.
    for (let i = 0; i <= totalDistance; i += meterPerSecond) {
      newTimelines.push({
        timestamp: startTime,
        value: i,
      });
      startTime += 1000;
    }

    newTimelines.push({ timestamp: startTime, value: totalDistance });
    dispatch(setTimelines({ timelines: newTimelines, keyFrame: meterPerSecond }));
  }, [activePath, speed, dispatch]);

  // 경로의 타임라인을 기반으로 차량의 위치를 계산합니다.
  useEffect(() => {
    if (!activePath || !activePath.details) return;
    const { coordinates } = activePath.points;

    let totalDistance = 0;

    let timestamp = timelines.find((timeline) => timeline.value === currentDistance)?.timestamp;
    if (!timestamp) {
      timestamp =
        [...timelines].reverse().find((point) => point.value < currentDistance)?.timestamp || 0;
    }

    // 경로의 모든 지점을 순회하며 차량의 위치를 계산합니다.
    for (let i = 0; i < coordinates.length; i += 1) {
      const currentPoint = coordinates[i];
      const nextPoint = coordinates[i + 1];
      if (!nextPoint) break;
      const lineDistance = distance(currentPoint, nextPoint, { units: "meters" });
      totalDistance += lineDistance;

      // 다음 지점까지의 거리가 현재 거리보다 크거나 같으면 차량의 위치를 계산합니다.
      if (totalDistance >= currentDistance) {
        const line = lineString([nextPoint, currentPoint]);
        const [lon, lat] = along(line, Math.abs(currentDistance - totalDistance), {
          units: "meters",
        }).geometry.coordinates;

        const heading = calculateDegreeByPoints(currentPoint, nextPoint);
        const carPosition = {
          latitude: lat,
          longitude: lon,
          timestamp,
          speed: velocity * speed,
          heading,
          accuracy: 100,
          altitude: 0,
          altitudeAccuracy: 100,
        };

        dispatch(setGeoLocation(carPosition));
        return;
      }
    }
    // 경로의 끝에 도달하면 차량의 위치를 경로의 끝으로 설정합니다.
    const lastPoint = coordinates[coordinates.length - 1];
    const carPosition = {
      latitude: lastPoint[1],
      longitude: lastPoint[0],
      timestamp,
      speed: velocity * speed,
      heading: 0,
      accuracy: 100,
      altitude: 0,
      altitudeAccuracy: 100,
    };
    dispatch(setGeoLocation(carPosition));
  }, [currentDistance, activePath, speed, timelines, dispatch]);

  const [hidden, setHidden] = useState(false);
  const onToggleExpand = () => setExpanded((prev) => !prev);
  const onToggleHide = () => setHidden((prev) => !prev);

  useEffect(() => {
    setHidden(play);
  }, [play, dispatch]);

  return (
    <DebugCard
      bottom={4}
      position="absolute"
      right={4}
      left={4}
      maxWidth="35rem"
      sx={{ opacity: hidden ? 0.3 : 1 }}
    >
      <DebuggerHeader
        title={`경로 모의주행 (${Math.floor(speed * velocity)}km/h)`}
        isExpanded={expanded}
        isHidden={hidden}
        onClickExpanded={onToggleExpand}
        onClickHidden={onToggleHide}
      />
      <Collapse in={expanded}>
        <Divider />
        <CardContent>
          <Simulator />
        </CardContent>
        <Divider />
        <CardActions>
          <Button onClick={onClose} startIcon={<Close />}>
            종료
          </Button>
        </CardActions>
      </Collapse>
    </DebugCard>
  );
});

export default RouteSimulator;
