// useMatcher.ts
import { useEffect, useRef, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GeoLocation, selectGeoLocation } from "../geolocation/geoLocationSlice";
import { setCarPosition, selectCarPosition } from "../map/position/carPositionSlice";
import { selectSimulator } from "../debug/simulator/simulatorSlice";
import { selectGuide } from "../guide/guideSlice";
import { selectRoute } from "../route/routeSlice";
import {
  MapMatch2State,
  selectMapMatch2,
  setLastSpeed,
  setMapMatchedData,
  setPushPoint,
} from "./MapMatch2Slice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { DebugMode, selectDebug } from "../debug/debugSlice";
import { MapMatchCandidateResult, SCORE_TYPE } from "./MapMatchStructs";
import { requestToNative } from "../geolocation/native/nativeUtils";
import Logger from "../../app/logger";

// import { setPrePositions } from "./prePosSlice";

const useMatcher2 = () => {
  const dispatch = useAppDispatch();
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { sortedWayIds } = useAppSelector(selectGuide);
  const { activePath } = useAppSelector(selectRoute);
  const { carPosition } = useAppSelector(selectCarPosition);
  const mapMatchStore = useAppSelector(selectMapMatch2);
  const { play } = useAppSelector(selectSimulator);
  const { wayDetailInfo: currentWayInfo, linkPositionIndex } = useAppSelector(selectGuidePosition);
  const { useMMV4, mode, gpsPush1sec } = useAppSelector(selectDebug);
  const workerRef = useRef<Worker | null>(null);
  const [avgGpsPeriod, setAvgGpsPeriod] = useState<number>(1000);
  const gpsTickCount = useRef<number>(0);
  const mapMatchStore2 = useRef<MapMatch2State | null>(null);
  const isLogPlayOn = useRef<boolean | null>(false);
  const mode2 = useRef<DebugMode>("off");

  useEffect(() => {
    mapMatchStore2.current = mapMatchStore;
  }, [mapMatchStore]);

  useEffect(() => {
    isLogPlayOn.current = play;
  }, [play]);

  useEffect(() => {
    mode2.current = mode;
  }, [mode]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const LoggingMapMatchInfo = (mapMatchStoreResult: MapMatch2State) => {
      Logger.log(
        `MM------(${mapMatchStoreResult.gps.loc_time.hour}:${mapMatchStoreResult.gps.loc_time.min}:${mapMatchStoreResult.gps.loc_time.sec}) (x=${mapMatchStoreResult.gps.longitude},y=${mapMatchStoreResult.gps.latitude},speed=${mapMatchStoreResult.gps.speed})`,
      );
      for (let i = 0; i < mapMatchStoreResult.candiNum; i += 1) {
        // eslint-disable-next-line no-continue
        if (mapMatchStoreResult.candidates[i].isSet === false) continue;
        const result: MapMatchCandidateResult = mapMatchStoreResult.candidates[i];
        Logger.log(
          `[${i}] L(${result.linkIdx},${result.dir}) S={${result.mmScore.totalScore.toFixed(2)}}(d=${result.mmScore.score[SCORE_TYPE.DIST_SCORE].toFixed(2)},a=${result.mmScore.score[SCORE_TYPE.ANGLE_SCORE].toFixed(2)},s=${result.mmScore.score[SCORE_TYPE.SELECTED_SCORE].toFixed(2)},sa=${result.mmScore.score[SCORE_TYPE.SA_SCORE].toFixed(2)},mn=${result.mmScore.score[SCORE_TYPE.MAIN_ROAD_SCORE].toFixed(2)},rt=${result.mmScore.score[SCORE_TYPE.ONROUTE_SCORE].toFixed(2)})`,
        );
      }
    };

    // Worker를 모듈로 생성
    workerRef.current = new Worker(new URL("./matcherWorker2.ts", import.meta.url), {
      type: "module",
    });

    workerRef.current.onmessage = (event) => {
      const { type, ...data } = event.data;
      if (type === "matchResult") {
        dispatch(setMapMatchedData(data.mapMatchStoreRW));
        if (data.newCarPos !== null && data.newCarPos !== undefined) {
          dispatch(setCarPosition(data.newCarPos));
          requestToNative({ type: "carPosition", payload: data.newCarPos });
        }
        // LoggingMapMatchInfo(data.mapMatchStoreRW);
      } else if (type === "error") {
        console.error("Matcher worker error:", data.message);
      } else if (type === "matchIgnore") {
        Logger.log("matchIgnore");
        dispatch(setMapMatchedData(data.mapMatchStoreRW));
      }
    };

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (!useMMV4 || mapMatchStore2.current === null) return () => {};

    if (gpsPush1sec) dispatch(setPushPoint(1));
    else dispatch(setPushPoint(0));

    if ((geolocation?.speed || 0) > 0) dispatch(setLastSpeed(geolocation?.speed || 0));

    if (geolocation && workerRef.current) {
      if (mode2.current === "route") {
        const simulGeo: GeoLocation = {
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
          accuracy: 3,
          speed: geolocation.speed,
          altitude: geolocation.altitude,
          altitudeAccuracy: 0,
          heading: geolocation.heading,
          timestamp: geolocation.timestamp,
        };
        workerRef.current.postMessage({
          geolocation: simulGeo,
          sortedWayIds,
          activePath,
          carPosition,
          mapMatchStore: mapMatchStore2.current,
          currentWayInfo,
          linkPositionIndex,
        });
      } else {
        workerRef.current.postMessage({
          geolocation,
          sortedWayIds,
          activePath,
          carPosition,
          mapMatchStore: mapMatchStore2.current,
          currentWayInfo,
          linkPositionIndex,
        });
      }
    }

    if (
      (mode2.current !== "log" && !isLogPlayOn.current && mode2.current !== "off") ||
      mapMatchStore2.current === null ||
      mapMatchStore2.current.result.x === 0 ||
      (mapMatchStore2.current.result.y === 0 && mapMatchStore2.current.candidates.length === 0)
    )
      // 이전에 맵매칭된 정보가 없으면 더미정보 넣어봐야 의미가 없으므로 무시.
      return () => {};

    const tick = new Date().getTime();
    if (gpsTickCount.current !== 0) {
      let avg = (avgGpsPeriod + (tick - gpsTickCount.current)) / 2;
      if (avg < 1000 || avg > 1500) avg = 1000;

      setAvgGpsPeriod(avg);
    }
    gpsTickCount.current = tick;
    // Logger.log(`gpsTickCount: ${gpsTickCount.current}, avgGpsPeriod: ${avgGpsPeriod}`);

    // 평균 GPS수신 주기보다 1.2배 이상 늦게 수신되면, GPS가 끊겼다고 판단하고(안드로이드에서는 정차중일수도 있지만 신호가 주기적으로 안오기는 똑같으므로) 더미 HDOP가 50인 GPS정보를 보낸다.
    let timerId = setTimeout(function run() {
      if (geolocation !== null) {
        const geodummy: GeoLocation = {
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
          accuracy: 50 * 3,
          speed: geolocation.speed,
          altitude: 0,
          altitudeAccuracy: 0,
          heading: 0,
          timestamp: 0,
        };

        if (workerRef.current !== null)
          workerRef.current.postMessage({
            geolocation: geodummy,
            sortedWayIds,
            activePath,
            carPosition,
            mapMatchStore: mapMatchStore2.current,
            currentWayInfo,
            linkPositionIndex,
          });

        console.error("GPS is disconnected or Car stopped. Send dummy GPS data.");
      }

      if (
        mode2.current === "off" ||
        (mode2.current === "log" && isLogPlayOn.current !== null && isLogPlayOn.current === true)
      )
        timerId = setTimeout(run, avgGpsPeriod); // GPS Offline 감지만 1.2배였고 그 이후는 1배로 동작
    }, avgGpsPeriod * 1.2);

    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePath, geolocation, sortedWayIds]);
};

export default useMatcher2;
