import React, { useMemo } from "react";
import { Paper } from "@mui/material";
import { Source, Layer, Marker } from "react-map-gl/maplibre";
import { featureCollection } from "@turf/turf";

import { useAppSelector } from "../../app/hooks";
import { selectDebug } from "./debugSlice";

const DebugLinkLine = () => {
  const { debugLinks, debugActiveLinkId } = useAppSelector(selectDebug);
  const geojson = useMemo(() => {
    return featureCollection(
      debugLinks.map((link) => {
        return { ...link.feature, id: link.id };
      }),
    );
  }, [debugLinks]);

  const activeLink = useMemo(() => {
    return debugLinks.find((link) => link.id === debugActiveLinkId);
  }, [debugActiveLinkId, debugLinks]);

  return (
    <>
      <Source type="geojson" data={geojson}>
        <Layer type="line" id="debug-link" paint={{ "line-width": 12, "line-color": "skyblue" }} />
      </Source>
      {activeLink?.feature.geometry.coordinates.map((coord, i) => (
        <Marker key={coord.join(",")} latitude={coord[1]} longitude={coord[0]}>
          <Paper
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {i}
          </Paper>
        </Marker>
      ))}
    </>
  );
};

export default DebugLinkLine;
