import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { selectDebug } from "../debug/debugSlice";

/** 디버그, 경로 안내, 안전 운행 */
export type MapStatus = "debug" | "guide" | "safetydrive" | "default";
export type MapClickStatus = "pickStart" | "pickEnd" | "pickWaypoint" | "moveCar" | "none";

interface State {
  isCenterChanged: boolean;
  isZoomChanged: boolean;
  containerHeight: number;
}

const initialState: State = {
  isCenterChanged: false,
  isZoomChanged: false,
  containerHeight: 0,
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setCenterChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isCenterChanged = payload;
    },
    setZoomChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isZoomChanged = payload;
    },
    setContainerHeight: (state, { payload }: PayloadAction<number>) => {
      state.containerHeight = payload;
    },
    clearUserAction: (state) => {
      state.isCenterChanged = false;
      state.isZoomChanged = false;
    },
    clearUserDragAction: (state) => {
      state.isCenterChanged = false;
    },
  },
});

export default mapSlice;
export const selectMap = (state: RootState) => state.map;
export const {
  setCenterChanged,
  setZoomChanged,
  setContainerHeight,
  clearUserAction,
  clearUserDragAction,
} = mapSlice.actions;
