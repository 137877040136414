import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const LogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          d="M10.4,5.9c0,0-1-1.7-1.7-2.6c-2.9,1-5.1,3.5-5.8,6.5c1.2-0.4,4.8-1.2,6.4-1.6C10.6,7.8,10.9,6.9,10.4,5.9
		L10.4,5.9L10.4,5.9z"
        />
        <path
          d="M12.4,5.4c0.7,0.8,1.9,1.1,2.7,0.7c0.6-0.3,1.8-1.1,2.5-1.5c-1.6-1.2-3.6-1.9-5.7-1.9c-0.6,0-1.3,0.1-1.9,0.2
		C10.6,3.4,11.9,4.8,12.4,5.4L12.4,5.4L12.4,5.4z"
        />
        <path
          d="M9.5,10.3c-1.4,0.1-5.4,0.6-6.8,1c0,0.2,0,0.5,0,0.7c0,4.7,3.5,8.7,8.1,9.3c0.4-2.1,1-7,1.2-8.5
		C12,11.5,11.1,10.2,9.5,10.3L9.5,10.3L9.5,10.3z"
        />
        <path
          d="M14.3,13.1C14,15,12.9,20,12.6,21.3c4-0.2,7.4-3.1,8.4-6.8c-1.1-0.8-3.3-2.2-4.2-2.6
		C15.8,11.4,14.5,11.8,14.3,13.1L14.3,13.1L14.3,13.1z"
        />
        <path
          d="M17.5,10c0.9,0.8,2.8,2.2,3.8,3.2c0.1-0.4,0.1-0.8,0.1-1.2c0-2.5-1-4.8-2.6-6.5c-0.6,0.6-1.4,1.4-1.7,1.8
		C16.4,8,16.6,9.3,17.5,10L17.5,10L17.5,10z"
        />
      </g>
    </SvgIcon>
  );
};

export default LogoIcon;
