import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export interface OsmTileState {
  tileId: { x: number, y: number, z: number };
}

const initialState: OsmTileState = {
  tileId: { x: -1, y: -1, z: -1 },
};

const osmTileSlice = createSlice({
  name: "osmTile",
  initialState,
  reducers: {
    setOsmTileId: (state, { payload }: PayloadAction<{ x: number, y: number, z: number }>) => {
      state.tileId = payload;
    }
  },
});

export const { setOsmTileId } = osmTileSlice.actions;
export const selectOsmTile = (state: RootState) => state.osmTile;
export default osmTileSlice;