import { createSlice } from "@reduxjs/toolkit";
import { GeoLocation } from "../../geolocation/geoLocationSlice";
import type { RootState } from "../../../app/store";
import { type WayDetailInfo } from "../../guide/guideSlice";

export interface GuidePositionState {
  position: GeoLocation | null;
  linkPositionIndex: number | null;
  distanceInLink: number | null;
  distanceFromStart: number | null;
  wayDetailInfo: WayDetailInfo | null;  // 현재 도로 정보 제공용
  guidanceIndex : number | null;
}

const initialState: GuidePositionState = {
  position: null,
  linkPositionIndex: null,
  distanceInLink: null,
  distanceFromStart: null,
  wayDetailInfo: null,
  guidanceIndex: null,
};

const guidePositionSlice = createSlice({
  name: "guidePosition",
  initialState,
  reducers: {
    setGuidePosition: (state, { payload }) => {
      state.position = payload.position;
      state.linkPositionIndex = payload.linkPositionIndex;
      state.distanceInLink = payload.distanceInLink;
      state.distanceFromStart = payload.distanceFromStart;
      state.guidanceIndex =  payload.guidanceIndex
      state.wayDetailInfo = payload.wayDetailInfo;
    },
    clearGuidePosition: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export default guidePositionSlice;
export const { setGuidePosition, clearGuidePosition } = guidePositionSlice.actions;
export const selectGuidePosition = (state: RootState) => state.guidePosition;
