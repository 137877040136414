import { Box, BoxProps, Paper, Typography, useTheme } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectGuidances } from "./guideSlice";
import { getFormattedDistance, getGuideSignImageUrl } from "./guideUtils";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { selectWaypoint } from "../waypoint/waypointSlice";
import NextGuide from "./NextGuide";

interface Props extends BoxProps {}

const TurnByTurn = ({ ...restProps }: Props) => {
  const guidances = useAppSelector(selectGuidances);
  const { guidanceIndex } = useAppSelector(selectGuidePosition);
  const { waypoints } = useAppSelector(selectWaypoint);

  const theme = useTheme();
  const currentGudiance = useMemo(() => guidances[guidanceIndex || 0], [guidances, guidanceIndex]);

  const isGuideEnded = useMemo(
    () =>
      guidanceIndex === null ||
      guidanceIndex < 0 ||
      guidanceIndex >= guidances.length ||
      waypoints.length < 2 /* 목적지 터치다운 후 목적지 웨이포인트 없음 */,
    [guidances, guidanceIndex, waypoints],
  );

  const info = useMemo(() => {
    if (!currentGudiance || isGuideEnded) return null;
    return {
      distance: getFormattedDistance(currentGudiance.guidedist),
      text: currentGudiance.dirname || currentGudiance.street || currentGudiance.text,
    };
  }, [currentGudiance, isGuideEnded]);

  if (guidances.length === 0 || isGuideEnded) return null;

  return (
    <Box position="absolute" zIndex={1} width="100%" {...restProps} maxWidth={500}>
      <Paper
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {info?.text}
        </Typography>
        <Box display="flex" alignItems="end">
          <img
            src={getGuideSignImageUrl(currentGudiance)}
            alt="Arrow"
            style={{ aspectRatio: 1 }}
            width={60}
          />
          <Box ml={2}>
            <Typography variant="h4" mb={0.5}>
              {info?.distance}
            </Typography>
          </Box>
        </Box>
      </Paper>

      <NextGuide />
    </Box>
  );
};

export default memo(TurnByTurn);
