import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, memo, useCallback } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import RouteSimulator from "./simulator/RouteSimulator";
import LogSimulator from "./simulator/LogSimulator";
import {
  DebugMode,
  selectDebug,
  setDebugMode,
  setDisableAnimation,
  toggleExpanded1,
  toggleExpanded2,
  toggleExpanded3,
  toggleGpsPush1Sec,
  toggleHidden,
  toggleShowGPSLocation,
  toggleShowLinks,
  toggleShowRoute,
  toggleUseMMV4,
  toggleAutoResetZoom,
  toggleShowLaneTest,
} from "./debugSlice";
import StatusMonitor from "./status/StatusMonitor";
import { selectSetting } from "../setting/settingSlice";
import DebuggerHeader from "./DebugCardHeader";
import DebugCard from "./DebugCard";
import { selectRoute } from "../route/routeSlice";

const Debugger = () => {
  const { activePath } = useAppSelector(selectRoute);
  const { debug_mode: enableDebug } = useAppSelector(selectSetting);
  const dispatch = useAppDispatch();

  const {
    mode,
    disableAnimation,
    isExpanded1,
    isExpanded2,
    isExpanded3,
    isHidden,
    showLinks,
    showGPSLocation,
    showRoute,
    useMMV4,
    gpsPush1sec,
    autoResetZoom,
    showLaneTest,
  } = useAppSelector(selectDebug);

  const onCloseSimulator = useCallback(() => {
    dispatch(setDebugMode("off"));
  }, [dispatch]);

  const handleChangeMode = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as DebugMode;
    dispatch(setDebugMode(value));
  };

  if (!enableDebug) return null;

  return (
    <>
      <DebugCard top={8} right={8} sx={{ opacity: isHidden ? 0.3 : 1 }}>
        <DebuggerHeader
          title="Debugger"
          isExpanded={isExpanded1}
          isHidden={isHidden}
          onClickExpanded={() => dispatch(toggleExpanded1())}
          onClickHidden={() => dispatch(toggleHidden())}
        />
        <Collapse in={isExpanded1} unmountOnExit>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
            <IconButton
              size="small"
              sx={{ p: 0, pr: 1 }}
              onClick={() => dispatch(toggleExpanded2())}
            >
              {isExpanded2 ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={isExpanded2} unmountOnExit>
            <List>
              <ListItem
                secondaryAction={
                  <TextField
                    value={mode}
                    select
                    onChange={handleChangeMode}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem value="off">끄기</MenuItem>
                    <MenuItem value="route" disabled={Boolean(!activePath)}>
                      모의 주행
                    </MenuItem>
                    <MenuItem value="log">로그 주행</MenuItem>
                    <MenuItem value="shift">자차 이동</MenuItem>
                  </TextField>
                }
              >
                <ListItemText primary="디버깅 모드" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch
                    checked={disableAnimation}
                    onChange={(_event, checked) => dispatch(setDisableAnimation(checked))}
                  />
                }
              >
                <ListItemText primary="애니메이션 끄기" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch checked={showLinks} onChange={() => dispatch(toggleShowLinks())} />
                }
              >
                <ListItemText primary="주변 링크 표시" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch
                    checked={showGPSLocation}
                    onChange={() => dispatch(toggleShowGPSLocation())}
                  />
                }
              >
                <ListItemText primary="GPS 위치 표시" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch checked={showRoute} onChange={() => dispatch(toggleShowRoute())} />
                }
              >
                <ListItemText primary="서버 경로 표시" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch checked={useMMV4} onChange={() => dispatch(toggleUseMMV4())} />
                }
              >
                <ListItemText primary="맵매칭 V4사용" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch checked={gpsPush1sec} onChange={() => dispatch(toggleGpsPush1Sec())} />
                }
              >
                <ListItemText primary="GPS 1초 앞으로 밀기" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch
                    checked={autoResetZoom}
                    onChange={() => dispatch(toggleAutoResetZoom())}
                  />
                }
              >
                <ListItemText primary="자동 zoom 초기화 끄기" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch checked={showLaneTest} onChange={() => dispatch(toggleShowLaneTest())} />
                }
              >
                <ListItemText primary="차선정보 출력 테스트" />
              </ListItem>
            </List>
          </Collapse>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
            <IconButton
              size="small"
              sx={{ p: 0, pr: 1 }}
              onClick={() => dispatch(toggleExpanded3())}
            >
              {isExpanded3 ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={isExpanded3} unmountOnExit>
            <StatusMonitor />
          </Collapse>
        </Collapse>
      </DebugCard>
      {mode === "route" && <RouteSimulator onClose={onCloseSimulator} />}
      {mode === "log" && <LogSimulator onClose={onCloseSimulator} />}
    </>
  );
};

export default memo(Debugger);
