// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MuiCreatePalette, { light } from "@mui/material/styles/createPalette";
import { alpha } from "@mui/material";
import { common } from "@mui/material/colors";
import { neutral, error, info, success, warning } from "./colors";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    neutral?: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    tertiary: PaletteColor;
  }
  interface Palette {
    neutral: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    tertiary: PaletteColor;
  }
}

const createPalette = () =>
  MuiCreatePalette({
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: "#efefef",
      paper: common.white,
    },
    divider: alpha("#929292", 0.2),
    error,
    info,
    mode: "light" as const,
    neutral,
    primary: {
      main: "#1174C5",
      light: "#399CFF",
    },
    secondary: {
      main: "#00447c",
    },
    tertiary: {
      main: "#929292",
      light: "#b2b2b2",
      dark: "#6a6a6a",
      contrastText: "#fff",
    },
    success,
    text: {
      primary: neutral[900],
      secondary: "#929292",
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  });

export const createDarkPalette = () =>
  MuiCreatePalette({
    mode: "dark" as const,
    neutral,
    primary: {
      main: "#1174C5",
      light: "#399CFF",
    },
    secondary: {
      main: "#00447c",
    },
    success,
    warning,
    tertiary: {
      main: "#929292",
      light: "#b2b2b2",
      dark: "#6a6a6a",
      contrastText: "#fff",
    },
  });

export default createPalette;
