import React from "react";
import { Marker } from "react-map-gl/maplibre";
import { Box } from "@mui/material";
import { selectDebug } from "../../../debug/debugSlice";
import { useAppSelector } from "../../../../app/hooks";
import { selectGeoLocation } from "../../../geolocation/geoLocationSlice";

const GPSLocationMarker = () => {
  const { showGPSLocation } = useAppSelector(selectDebug);
  const { geolocation } = useAppSelector(selectGeoLocation);

  if (!showGPSLocation || !geolocation) return null;

  return (
    <Marker latitude={geolocation.latitude} longitude={geolocation.longitude} pitchAlignment="map">
      <Box width={20} height={20} borderRadius="50%" bgcolor="red" />
    </Marker>
  );
};

export default GPSLocationMarker;
