// 상단 appBar
import React from "react";
import { Toolbar, useTheme, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircleIconButton from "./CircleIconButton";

interface DialogTitleProps {
  onClose: () => void;
  children: React.ReactNode;
}

const DialogTitle = ({ onClose, children }: DialogTitleProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
      <Toolbar sx={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
        <Box>
          <CircleIconButton size="medium" color={theme.palette.background.paper} onClick={onClose}>
            <CloseIcon sx={{ color: theme.palette.background.paper }} />
          </CircleIconButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>{children}</Box>
        <Box sx={{ visibility: "hidden" }}>
          <CircleIconButton size="medium" color={theme.palette.background.paper} onClick={onClose}>
            <CloseIcon sx={{ color: theme.palette.background.paper }} />
          </CircleIconButton>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default DialogTitle;
