import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const LayoutOutlet = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Outlet />
    </Box>
  );
};

export default LayoutOutlet;
