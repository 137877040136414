import React, { memo, useCallback, useEffect } from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { KeyboardArrowUp, Refresh } from "@mui/icons-material";
import { useDialog } from "../../components/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectGuideRemainInfo } from "./guideSlice";
import SummaryDialog from "../summary/SummaryDialog";
import Confirm from "../../components/Confirm";
import { setWayPoint } from "../waypoint/waypointSlice";
import { getReRouteStartPoint } from "../route/routeUtils";
import { setIsAwaitingWaypointsUpdate, setReRouteType } from "../route/reroute/reRouteSlice";
import { selectCarPosition } from "../map/position/carPositionSlice";
import { RouteType, selectRoute, setRouteStatusOnly } from "../route/routeSlice";

const GuideStatusBar = () => {
  const dispatch = useAppDispatch();
  const reRouteDialog = useDialog();
  const summaryDialog = useDialog();
  const reRouteFailDialog = useDialog();

  const { carPosition } = useAppSelector(selectCarPosition);
  const guideRemainInfo = useAppSelector(selectGuideRemainInfo);
  const { status: routeStatus, error: routeError } = useAppSelector(selectRoute);

  useEffect(() => {
    // 재탐색 실패시 다이얼로그 띄우도록
    if (
      routeStatus === "rejected" &&
      routeError &&
      reRouteFailDialog.open === false &&
      reRouteDialog.open === false
    ) {
      reRouteFailDialog.onOpen();
    }
  }, [routeStatus, routeError, reRouteFailDialog, reRouteDialog.open]);

  const onConfirmReRoute = useCallback(() => {
    dispatch(setWayPoint({ place: getReRouteStartPoint(carPosition), index: 0 }));
    dispatch(setReRouteType(RouteType.ReRoute));
    dispatch(setIsAwaitingWaypointsUpdate(true));
    reRouteDialog.onClose();
  }, [carPosition, dispatch, reRouteDialog]);

  const onConfirmReRouteFail = useCallback(() => {
    dispatch(setRouteStatusOnly("pending")); // 경탐실패 다이얼로그를 그냥 닫기만하면 자꾸 뜨므로 이전처럼 pending상태로 그냥 유지시킨다.
    dispatch(setWayPoint({ place: getReRouteStartPoint(carPosition), index: 0 }));
    dispatch(setReRouteType(RouteType.ReRoute));
    dispatch(setIsAwaitingWaypointsUpdate(true));
    reRouteFailDialog.onClose();
  }, [carPosition, dispatch, reRouteFailDialog]);

  const onCloseReRouteFail = useCallback(() => {
    dispatch(setRouteStatusOnly("pending")); // 경탐실패 다이얼로그를 그냥 닫기만하면 자꾸 뜨므로 이전처럼 pending상태로 그냥 유지시킨다.
    reRouteFailDialog.onClose();
  }, [dispatch, reRouteFailDialog]);

  return (
    <>
      <Confirm
        open={reRouteDialog.open}
        title="경로를 재탐색 하시겠습니까?"
        onClose={reRouteDialog.onClose}
        onConfirm={onConfirmReRoute}
      />
      <Confirm
        open={reRouteFailDialog.open}
        title="경로 재탐색 중 오류가 발생하였습니다. 재시도 하시겠습니까?"
        content={routeError || ""}
        onClose={onCloseReRouteFail}
        onConfirm={onConfirmReRouteFail}
      />

      <Paper>
        <Box
          py={2}
          textAlign="center"
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* Left */}
          <IconButton
            sx={{
              position: "absolute",
              left: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={reRouteDialog.onOpen}
          >
            <Refresh fontSize="large" />
          </IconButton>

          {/* Center */}
          <Box>
            <Typography variant="h6" gutterBottom>
              {guideRemainInfo.etaTime}
            </Typography>

            <Typography variant="body1">
              {guideRemainInfo.remainTime} ({guideRemainInfo.remainDist})
            </Typography>
          </Box>

          {/* Right */}
          <IconButton
            onClick={summaryDialog.onOpen}
            sx={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <KeyboardArrowUp fontSize="large" />
          </IconButton>
        </Box>
      </Paper>
      <SummaryDialog open={summaryDialog.open} onClose={summaryDialog.onClose} />
    </>
  );
};

export default memo(GuideStatusBar);
