import React from "react";
import { Position } from "geojson";
import { Add, Close, LocationOn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import ContextMenu from "../../components/ContextMenu";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createWaypoint, selectWaypoint, setWayPoints } from "../waypoint/waypointSlice";
import { selectGeoLocation } from "../geolocation/geoLocationSlice";
import { fetchRouteByWaypoints } from "../route/routeSlice";

interface Props {
  position: Position | null;
  onClose: () => void;
}

const LongPressContextMenu = ({ position, onClose }: Props) => {
  const { waypoints } = useAppSelector(selectWaypoint);
  const navigate = useNavigate();
  const { geolocation } = useAppSelector(selectGeoLocation);
  const dispatch = useAppDispatch();
  const handleClickDestination = () => {
    if (!geolocation) return;
    dispatch(
      setWayPoints([
        createWaypoint({
          name: "현위치",
          distance: 0,
          point: { lat: geolocation.latitude, lng: geolocation.longitude },
          id: "current",
          jibunAddress: "",
          roadAddress: "",
          type: "current",
        }),
        createWaypoint({
          name: "목적지",
          distance: 0,
          point: { lat: position![1], lng: position![0] },
          id: "destination",
          jibunAddress: "",
          roadAddress: "",
          type: "destination",
        }),
      ]),
    );
    navigate("/routing");
    onClose();
  };

  const handleClickAddWaypoint = () => {
    const newWaypoints = [...waypoints];
    newWaypoints.splice(
      1,
      0,
      createWaypoint({
        name: "경유지",
        distance: 0,
        point: { lat: position![1], lng: position![0] },
        id: Date.now().toString(),
        jibunAddress: "",
        roadAddress: "",
        type: "waypoint",
      }),
    );
    dispatch(setWayPoints(newWaypoints));
    dispatch(fetchRouteByWaypoints(newWaypoints));
    onClose();
  };

  return (
    <ContextMenu open={Boolean(position)}>
      <Stack p={3} spacing={1}>
        <Typography variant="h6" pb={2} textAlign="center">
          {position?.[1]}, {position?.[0]}
        </Typography>
        {waypoints.length > 1 && waypoints[1].place ? (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={handleClickAddWaypoint}
          >
            경유지 추가
          </Button>
        ) : (
          <Button variant="contained" startIcon={<LocationOn />} onClick={handleClickDestination}>
            목적지 설정
          </Button>
        )}
        <Button onClick={onClose} startIcon={<Close />}>
          닫기
        </Button>
      </Stack>
    </ContextMenu>
  );
};

export default LongPressContextMenu;
