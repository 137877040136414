import React, { useCallback, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectSafeDrives } from "../features/guide/guideSlice";
import { selectWaypoint, removeNullPlaces } from "../features/waypoint/waypointSlice";
import Map from "../features/map/Map";
import SpeedText from "../features/map/SpeedText";
import Car from "../features/map/source/marker/Car";
import TurnByTurn from "../features/guide/TurnByTurn";
import Arrivals from "../features/guide/Arrivals";
import Camera from "../features/guide/Camera";
import GuideLine from "../features/map/source/line/GuideLine";
import Confirm from "../components/Confirm";
import GuidePointMarker from "../features/map/source/GuidePointMarker";
import GuideStatusBar from "../features/guide/GuideStatusBar";
import SafeDriveMarker from "../features/map/source/marker/SafeDriveMarker";
import WaypointMarker from "../features/map/source/marker/WaypointMarker";
import GPSLocationMarker from "../features/map/source/marker/GPSLocationMarker";
import GPSPushMarker from "../features/map/source/marker/GPSPushMarker";
import PredictLocationMarker from "../features/map/source/marker/PredictLocationMarker";
import ResetViewButton from "../features/map/ResetViewButton";
import ViewTrasition from "../features/map/view/transition/ViewTrasition";
import LinkRoute from "../features/route/link/LinkRoute";
import Guide from "../features/guide/Guide";
import AutoReRoute from "../features/route/reroute/AutoReRoute";
import PositionCorrectionButton from "../features/map/PositionCorrectionButton";
import BottomLane from "../features/map/BottomLane";
import JunctionView from "../features/guide/JunctionView";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname === "/view" && currentLocation.pathname !== nextLocation.pathname,
  );

  const { waypoints } = useAppSelector(selectWaypoint);
  const safeDrives = useAppSelector(selectSafeDrives);
  const onCloseConfirm = useCallback(() => blocker.reset?.(), [blocker]);
  const onConfirmLeave = useCallback(() => blocker.proceed?.(), [blocker]);

  // waypoints에 place가 null인 항목들을 정리해야 함
  useEffect(() => {
    dispatch(removeNullPlaces());
  }, [dispatch]);

  return (
    <>
      <Guide />
      <ViewTrasition frameRate={30} />
      <LinkRoute />
      <AutoReRoute />
      <Confirm
        open={blocker.state === "blocked"}
        title={
          waypoints[1]?.place
            ? t("The current route will be deleted. Would you like to continue?")
            : "안전 주행을 취소하시겠습니까?"
        }
        onClose={onCloseConfirm}
        onConfirm={onConfirmLeave}
      />

      <Box height="100%" position="relative" overflow="hidden">
        <BottomLane />
        <ResetViewButton />
        <PositionCorrectionButton />
        <Stack spacing={4} position="absolute" bottom="10%" left={16} zIndex={1} textAlign="center">
          <Camera />
          <SpeedText />
        </Stack>
        <TurnByTurn top={0} left={0} />
        <JunctionView />
        <Map catchUserAction>
          {waypoints.map((waypoint, index) => (
            <WaypointMarker
              key={waypoint.id}
              waypoint={waypoint}
              text={waypoint.type === "waypoint" ? `${index}` : ""}
            />
          ))}
          {safeDrives.map((safeDrive, index) => (
            <SafeDriveMarker
              key={safeDrive.accdist}
              latitude={safeDrive.guidePoint.coordinates[1]}
              longitude={safeDrive.guidePoint.coordinates[0]}
              index={index}
            />
          ))}
          <GuidePointMarker />
          <GuideLine />
          <Car />
          <GPSLocationMarker />
          <GPSPushMarker />
          <PredictLocationMarker />
        </Map>
      </Box>
      <Arrivals />
      <GuideStatusBar />
    </>
  );
};

export default View;
