import { useCallback, useEffect, useMemo, useRef } from "react";
import { bboxPolygon, booleanWithin, lineString, point } from "@turf/turf";
import { debounce } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectRoute, WayInfo } from "../routeSlice";
import { setLinkRoute } from "./linkRouteSlice";
import { selectDebug, setDebugLinks } from "../../debug/debugSlice";
import { selectGeoLocation } from "../../geolocation/geoLocationSlice";
import { IWay, OsmCache, osmCacheDB } from "../../db/OsmCache";
import { selectCarPosition } from "../../map/position/carPositionSlice";
import Logger from "../../../app/logger";

export const isSamePosition = (
  coord1: number[] | undefined,
  coord2: number[] | undefined,
): boolean => {
  if (!coord1 || !coord2) return false;
  return coord1[0] === coord2[0] && coord1[1] === coord2[1];
};

const fetchLinksByIds = async (ways: WayInfo[]): Promise<(IWay | undefined)[]> => {
  return osmCacheDB.getWays(ways.map((way) => way.id));
};

const LinkRoute = () => {
  const dispatch = useAppDispatch();
  const { showLinks } = useAppSelector(selectDebug);
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { activePath } = useAppSelector(selectRoute);
  const { carPosition } = useAppSelector(selectCarPosition);

  const prevCoords = useRef<number[]>([0, 0]);
  const prevCarCoords = useRef<number[]>([0, 0]);
  const prevTick = useRef<number>(0);

  useEffect(() => {
    (async () => {
      const tick = new Date().getTime();
      if (prevTick.current !== 0 && tick - prevTick.current < 1000) return; // 1초 미만으로 갱신하려고하면 무시(geopos, carpos둘 다 사용하다보니...)
      prevTick.current = tick;

      if (
        !activePath?.details ||
        !activePath.rousen_details ||
        geolocation === null ||
        geolocation.longitude === 0 ||
        geolocation.latitude === 0
      ) {
        return;
      }

      let searchPos: number[] = [0, 0];
      if (isSamePosition(prevCoords.current, [geolocation.longitude, geolocation.latitude])) {
        if (
          carPosition === null ||
          carPosition.longitude === 0 ||
          carPosition.latitude === 0 ||
          isSamePosition(prevCarCoords.current, [carPosition.longitude, carPosition.latitude])
        )
          return;

        searchPos = [carPosition.longitude, carPosition.latitude];
      } else {
        searchPos = [geolocation.longitude, geolocation.latitude];
      }

      const { points } = activePath;
      const { way_info: wayInfo } = activePath.rousen_details;

      const bbox = OsmCache.BufferedBBoxOf(point(searchPos).geometry, 1000);

      const polygon = bboxPolygon(bbox);

      const targetWays: WayInfo[] = [];

      points.coordinates.forEach((coord, index) => {
        const nextPoint = points.coordinates[index + 1];
        if (!nextPoint) return;
        const line = lineString([coord, nextPoint]);

        if (booleanWithin(line, polygon)) {
          const targetWay = wayInfo.find((way) => way.end_idx > index);
          if (targetWay && targetWays.every((way) => way.id !== targetWay.id))
            targetWays.push(targetWay);
        }
      });

      const links = await fetchLinksByIds(targetWays);

      // links.forEach((link, index) => {
      //   if (link) {
      //     if (targetWays[index].direction === false) {
      // Logger.log(link);
      //       link.feature.geometry.coordinates.reverse();
      //     }
      //   }
      // });

      dispatch(
        setLinkRoute({
          links: links.filter((link) => link?.feature.geometry) as IWay[],
        }),
      );
      prevCoords.current = [geolocation.longitude, geolocation.latitude];
      prevCarCoords.current = [carPosition?.longitude || 0, carPosition?.latitude || 0];
    })();
  }, [activePath, geolocation, carPosition, dispatch, showLinks]);

  useEffect(() => {
    if (!geolocation) return;

    (async () => {
      if (showLinks) {
        const bbox = OsmCache.BufferedBBoxOf(
          point([geolocation.longitude, geolocation.latitude]).geometry,
          200,
        );
        const debugLinks = await osmCacheDB.waysOn(bbox);

        dispatch(setDebugLinks(debugLinks));
      } else {
        dispatch(setDebugLinks([]));
      }
    })();
  }, [showLinks, geolocation, dispatch]);

  return null;
};

export default LinkRoute;
