import { useCallback, useEffect, useMemo, useRef } from "react";
import { ViewState } from "react-map-gl/maplibre";
import { distance } from "@turf/turf";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  TransitionQueueItem,
  selectViewTransition,
  selectViewTransitionStatus,
  setStatus,
  setTransition,
} from "./viewTransitionSlice";
import { GeoLocation } from "../../../geolocation/geoLocationSlice";
import { selectDebug } from "../../../debug/debugSlice";
import { selectCarPosition } from "../../position/carPositionSlice";
import { selectLinkRoute } from "../../../route/link/linkRouteSlice";
import { selectMap } from "../../mapSlice";
import { setPartialViewState } from "../viewSlice";

const worker = new Worker(new URL("./viewTransitionWorker.ts", import.meta.url), {
  type: "module",
});

interface Props {
  frameRate?: number;
}

const isSamelocation = (point1: GeoLocation | undefined, point2: GeoLocation | undefined) => {
  if (!point1 || !point2) return false;
  return point1.latitude === point2.latitude && point1.longitude === point2.longitude;
};

const interpolatePositions = (points: TransitionQueueItem[], frameRate: number) =>
  new Promise<TransitionQueueItem[]>((resolve) => {
    worker.postMessage({ points, frameRate });
    worker.onmessage = (event) => {
      resolve(event.data);
    };
  });

const ViewTrasition = ({ frameRate = 60 }: Props) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectViewTransitionStatus);
  const { carPosition, indexOfLinkSegment, wayId, direction, onRoute } =
    useAppSelector(selectCarPosition);

  const { links } = useAppSelector(selectLinkRoute);

  const queue = useRef<TransitionQueueItem[]>([]);
  const prevPositionRef = useRef<TransitionQueueItem>();
  const intervalId = useRef<NodeJS.Timeout>();
  const { disableAnimation, autoResetZoom } = useAppSelector(selectDebug);

  const linksRef = useRef(links);

  useEffect(() => {
    linksRef.current = links;
  }, [links]);

  const calcuateMidPoints = useCallback((prev: TransitionQueueItem, next: TransitionQueueItem) => {
    const points: TransitionQueueItem[] = [];

    if (linksRef.current.length === 0 || !next.isOnRoute || !prev.isOnRoute) {
      return points;
    }

    // 같은 링크 일 경우
    if (prev.wayId === next.wayId) {
      // 같은 노드위에 있을 경우 제외
      if (prev.index === next.index) {
        return points;
      }

      const prevLink = linksRef.current.find((item) => item.id === prev.wayId);
      const nextLink = linksRef.current.find((item) => item.id === next.wayId);

      if (!prevLink || !nextLink) {
        return points;
      }

      // 경로 선수가 정방향일 경우
      if (prev.direction) {
        for (let i = prev.index + 1; i <= next.index; i += 1) {
          const currentPoint = prevLink.feature.geometry.coordinates[i];
          const nextPoint = prevLink.feature.geometry.coordinates[i + 1];
          points.push({
            index: i,
            location: {
              latitude: currentPoint[1],
              longitude: currentPoint[0],
              timestamp: 0,
              accuracy: 10,
              altitude: 0,
              speed: 60,
              altitudeAccuracy: 0,
              // heading: nextPoint
              //   ? calculateDegreeByPoints(currentPoint, nextPoint)
              //   : calculateDegreeByPoints(currentPoint, [
              //       next.location.longitude,
              //       next.location.latitude,
              //     ]),
              heading: prev.location.heading,
            },
            wayId: prev.wayId,
            isOnRoute: true,
            direction: prev.direction,
          });
        }
      }
      // 경로 순서가 역 방향일 경우
      else {
        // Logger.log(prev, prevLink);
        for (let i = prev.index - 1; i > next.index; i -= 1) {
          const currentPoint = prevLink.feature.geometry.coordinates[i];
          const nextPoint = prevLink.feature.geometry.coordinates[i + 1];
          points.push({
            index: i,
            location: {
              latitude: currentPoint[1],
              longitude: currentPoint[0],
              timestamp: 0,
              accuracy: 10,
              altitude: 0,
              speed: 60,
              altitudeAccuracy: 0,
              // heading: nextPoint
              //   ? calculateDegreeByPoints(currentPoint, nextPoint)
              //   : calculateDegreeByPoints(currentPoint, [
              //       next.location.longitude,
              //       next.location.latitude,
              //     ]),
              heading: prev.location.heading,
            },
            wayId: prev.wayId,
            isOnRoute: true,
            direction: prev.direction,
          });
        }
      }
      return points;
    }

    const prevLinkIndex = linksRef.current.findIndex((item) => item.id === prev.wayId);
    const nextLinkIndex = linksRef.current.findIndex((item) => item.id === next.wayId);

    if (nextLinkIndex === -1 || prevLinkIndex === -1) {
      return points;
    }

    for (let i = prevLinkIndex; i <= nextLinkIndex; i += 1) {
      const currentLink = linksRef.current[i];
      // const nextLink = linksRef.current[i + 1];

      if (currentLink.id === prev.wayId) {
        if (prev.direction) {
          for (
            let j = prev.index + 1;
            j < currentLink.feature.geometry.coordinates.length;
            j += 1
          ) {
            const currentPoint = currentLink.feature.geometry.coordinates[j];
            const nextPoint = currentLink.feature.geometry.coordinates[j + 1];
            points.push({
              index: j,
              location: {
                latitude: currentPoint[1],
                longitude: currentPoint[0],
                timestamp: 0,
                accuracy: 10,
                altitude: 0,
                speed: 60,
                altitudeAccuracy: 0,
                // heading: nextPoint
                //   ? calculateDegreeByPoints(currentPoint, nextPoint)
                //   : calculateDegreeByPoints(currentPoint, [
                //       next.location.longitude,
                //       next.location.latitude,
                //     ]),
                heading: next.location.heading,
              },
              wayId: prev.wayId,
              isOnRoute: true,
              direction: prev.direction,
            });
          }
        } else {
          for (let j = prev.index - 1; j >= 0; j -= 1) {
            const currentPoint = currentLink.feature.geometry.coordinates[j];
            const nextPoint = currentLink.feature.geometry.coordinates[j - 1];
            points.push({
              index: j,
              location: {
                latitude: currentPoint[1],
                longitude: currentPoint[0],
                timestamp: 0,
                accuracy: 10,
                altitude: 0,
                speed: 60,
                altitudeAccuracy: 0,
                // heading: nextPoint
                //   ? calculateDegreeByPoints(currentPoint, nextPoint)
                //   : calculateDegreeByPoints(currentPoint, [
                //       next.location.longitude,
                //       next.location.latitude,
                //     ]),
                heading: next.location.heading,
              },
              wayId: prev.wayId,
              isOnRoute: true,
              direction: prev.direction,
            });
          }
        }
      } else if (currentLink.id === next.wayId) {
        if (next.direction) {
          for (let j = 0; j <= next.index; j += 1) {
            const currentPoint = currentLink.feature.geometry.coordinates[j];
            const nextPoint = currentLink.feature.geometry.coordinates[j + 1];
            points.push({
              index: j,
              location: {
                latitude: currentPoint[1],
                longitude: currentPoint[0],
                timestamp: 0,
                accuracy: 10,
                altitude: 0,
                speed: 60,
                altitudeAccuracy: 0,
                // heading: nextPoint
                //   ? calculateDegreeByPoints(currentPoint, nextPoint)
                //   : calculateDegreeByPoints(currentPoint, [
                //       next.location.longitude,
                //       next.location.latitude,
                //     ]),
                heading: next.location.heading,
              },
              wayId: next.wayId,
              isOnRoute: true,
              direction: next.direction,
            });
          }
        } else {
          // Logger.log(next, currentLink);
          for (
            let j = currentLink.feature.geometry.coordinates.length - 1;
            j > next.index;
            j -= 1
          ) {
            const currentPoint = currentLink.feature.geometry.coordinates[j];
            // const nextPoint = currentLink.feature.geometry.coordinates[j - 1];
            points.push({
              index: j,
              location: {
                latitude: currentPoint[1],
                longitude: currentPoint[0],
                timestamp: 0,
                accuracy: 10,
                altitude: 0,
                speed: 60,
                altitudeAccuracy: 0,
                // heading: nextPoint
                //   ? calculateDegreeByPoints(currentPoint, nextPoint)
                //   : calculateDegreeByPoints(currentPoint, [
                //       next.location.longitude,
                //       next.location.latitude,
                //     ]),
                heading: next.location.heading,
              },
              wayId: next.wayId,
              isOnRoute: true,
              direction: next.direction,
            });
          }
        }
      } else {
        const prevLink = linksRef.current[i - 1];
        let dir = true;
        const currentLinkPoints = [...currentLink.feature.geometry.coordinates];
        if (prevLink) {
          if (
            currentLinkPoints[currentLinkPoints.length - 1].join(",") ===
            prevLink.feature.geometry.coordinates[
              prevLink.feature.geometry.coordinates.length - 1
            ].join(",")
          ) {
            currentLinkPoints.reverse();
            dir = false;
          }

          for (let j = 0; j < currentLinkPoints.length; j += 1) {
            const currentPoint = currentLinkPoints[j];
            // const nextPoint = currentLinkPoints[j + 1];
            if (
              points[points.length - 1]?.location.longitude !== currentPoint[0] &&
              points[points.length - 1]?.location.latitude !== currentPoint[1]
            )
              points.push({
                index: j,
                location: {
                  latitude: currentPoint[1],
                  longitude: currentPoint[0],
                  timestamp: 0,
                  accuracy: 10,
                  altitude: 0,
                  speed: 60,
                  altitudeAccuracy: 0,
                  // heading: nextPoint
                  //   ? calculateDegreeByPoints(currentPoint, nextPoint)
                  //   : calculateDegreeByPoints(currentPoint, [
                  //       next.location.longitude,
                  //       next.location.latitude,
                  //     ]),
                  heading: next.location.heading,
                },
                wayId: currentLink.id,
                isOnRoute: true,
                direction: dir,
              });
          }
        }
      }
    }
    return points;
  }, []);

  const getArc = useCallback(
    (prevPosition: TransitionQueueItem, nextPosition: TransitionQueueItem) => {
      // const timestamp = new Date().getTime();
      const points = [prevPosition, ...calcuateMidPoints(prevPosition, nextPosition), nextPosition];

      // Logger.log(points);
      worker.postMessage({ points, frameRate });
      const result = interpolatePositions(points, frameRate);
      // Logger.log("getArc", new Date().getTime() - timestamp);

      return result;
    },
    [frameRate, calcuateMidPoints],
  );

  const startRender = useCallback(
    async (prev: TransitionQueueItem, next: TransitionQueueItem, queueSize: number) => {
      if (isSamelocation(prev.location, next.location)) return Promise.resolve();
      // const speed = queueSize < 1 ? 1 : queueSize * 1.1;
      const arc = await getArc(prev, next);
      // Logger.log([...arc].map((item) => item.location.heading));

      // const timestamp = new Date().getTime();
      return new Promise<void>((resolve) => {
        const test = () => {
          const target = arc.shift();
          if (
            !target ||
            Number.isNaN(target.location.latitude) ||
            Number.isNaN(target.location.longitude)
          ) {
            resolve();
            clearInterval(intervalId.current);
            intervalId.current = undefined;
            return;
          }
          dispatch(setTransition({ prev, current: target, next }));
          // if (new Date().getTime() - timestamp > 1000)
          // Logger.log("rendering", new Date().getTime() - timestamp);

          if (arc.length === 0) {
            resolve();
            clearInterval(intervalId.current);
            intervalId.current = undefined;
          }
        };
        intervalId.current = setInterval(test, 1000 / frameRate / 1);
        test();
      });
    },
    [frameRate, dispatch, getArc],
  );

  const queueItem: TransitionQueueItem | null = useMemo(() => {
    if (
      indexOfLinkSegment === null ||
      wayId === null ||
      carPosition === null ||
      isSamelocation(queue.current[queue.current.length - 1]?.location, carPosition)
    )
      return null;

    return {
      index: indexOfLinkSegment,
      location: carPosition,
      wayId,
      isOnRoute: Boolean(onRoute),
      direction: Boolean(direction),
    };
  }, [wayId, indexOfLinkSegment, carPosition, onRoute, direction]);

  useEffect(() => {
    if (queueItem === null) return;
    queue.current.push(queueItem);
    // Logger.log("prev", queue.current.length);
    dispatch(setStatus("pending"));
  }, [queueItem, dispatch]);

  useEffect(() => {
    if (status !== "pending") return undefined;

    const animateFrame = async () => {
      const next = queue.current.shift();
      const prev = prevPositionRef.current;

      if (!next || isSamelocation(prev?.location, next.location)) {
        dispatch(setStatus("fulfilled"));
        return;
      }

      if (!prev) {
        prevPositionRef.current = next;
        dispatch(
          setTransition({
            prev: null,
            current: next,
            next: null,
          }),
        );
        dispatch(setStatus("fulfilled"));
        return;
      }

      if (
        distance(
          [prev.location.longitude, prev.location.latitude],
          [next.location.longitude, next.location.latitude],
          {
            units: "meters",
          },
        ) > 1000 ||
        disableAnimation ||
        queue.current.length > 2
      ) {
        dispatch(
          setTransition({
            prev: null,
            current: next,
            next: null,
          }),
        );
        prevPositionRef.current = next;
        queue.current = [];
        dispatch(setStatus("fulfilled"));
        return;
      }

      // const timestamp = new Date().getTime();
      await startRender(prev, next, queue.current.length);
      // Logger.log("animation duration", new Date().getTime() - timestamp);
      prevPositionRef.current = next;
      animateFrame();
    };

    animateFrame();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [status, startRender, dispatch, disableAnimation]);

  const transition = useAppSelector(selectViewTransition);
  const { containerHeight, isCenterChanged, isZoomChanged } = useAppSelector(selectMap);

  useEffect(() => {
    if (!transition) return;

    let newViewState: Partial<ViewState> = {
      pitch: 45,
      padding: {
        top: containerHeight - 300 < 0 ? 0 : containerHeight - 300,
        bottom: 0,
        left: 0,
        right: 0,
      },
    };

    if (isCenterChanged) {
      dispatch(setPartialViewState(newViewState));
      return;
    }

    newViewState = {
      ...newViewState,
      latitude: transition.current.location.latitude,
      longitude: transition.current.location.longitude,
    };

    if (isZoomChanged && !autoResetZoom) {
      dispatch(setPartialViewState(newViewState));
      return;
    }

    newViewState = {
      ...newViewState,
      bearing: Number(transition.current.location.heading.toFixed(2)),
    };

    const updatedViewState = {
      ...newViewState,
      ...(autoResetZoom ? {} : { zoom: 17 }),
    };

    dispatch(setPartialViewState(updatedViewState));
  }, [dispatch, containerHeight, transition, isCenterChanged, isZoomChanged, autoResetZoom]);

  return null;
};

export default ViewTrasition;
