import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import View from "./pages/View";
import LayoutOutlet from "./components/LayoutOutlet";
import Routing from "./pages/Routing";
import Search from "./pages/Search";
import Setting from "./pages/Setting";
import VoiceGuidanceDist from "./pages/settings/VoiceGuidanceDist";
import GeoLocationOutlet from "./features/geolocation/GeoLocationOutlet";
import Bookmark from "./pages/Bookmark";
import { selectToken } from "./features/token/tokenSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useGetUserSettingsMutation } from "./features/setting/settingApi";
import { resetSettings, syncSettings } from "./features/setting/settingSlice";
import { BASE_URL } from "./app/config/const";

const router = createBrowserRouter(
  [
    {
      element: <LayoutOutlet />,
      children: [
        {
          element: <GeoLocationOutlet />,
          children: [
            { index: true, element: <Search /> },
            { path: "routing", element: <Routing /> },
            { path: `view`, element: <View /> },
          ],
        },
        { path: `signin`, element: <SignIn /> },
        { path: `signup`, element: <SignUp /> },
      ],
    },
    { path: `setting`, element: <Setting /> },
    { path: `setting/voice-guidance-dist`, element: <VoiceGuidanceDist /> },
    { path: `bookmark`, element: <Bookmark /> },
  ],
  { basename: BASE_URL },
);

const App = () => {
  const { token } = useAppSelector(selectToken);
  const [getUserSettings, { data: userSettings, isLoading, isError }] =
    useGetUserSettingsMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      getUserSettings();
    }
  }, [token, getUserSettings]);

  useEffect(() => {
    if (token && userSettings) {
      dispatch(syncSettings(userSettings));
    } else if (token && !isLoading && isError) {
      dispatch(resetSettings());
    }
  }, [userSettings, token, isLoading, isError, dispatch]);

  return <RouterProvider router={router} />;
};

export default App;
