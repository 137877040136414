// 상세 경로 리스트
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  DialogContent,
  Divider,
  Slide,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import { Path } from "../route/routeSlice";
import DialogTitle from "../../components/DialogTitle";
import { getFormattedDistance } from "../guide/guideUtils";
import { selectWaypoint } from "../waypoint/waypointSlice";
import { useAppSelector } from "../../app/hooks";
import Dialog from "../../components/Dialog";
import { BASE_URL } from "../../app/config/const";

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement }>(
  (props, ref) => <Slide direction="up" ref={ref} {...props} />,
);

interface SummaryGuideListDialogProps {
  open: boolean;
  onClose: () => void;
  moveToCoordinate: (longitude: number, latitude: number) => void;
  selectedPath: Path | null;
}

const SummaryGuideListDialog = ({
  open,
  onClose,
  moveToCoordinate,
  selectedPath,
}: SummaryGuideListDialogProps) => {
  const { waypoints } = useAppSelector(selectWaypoint);
  const theme = useTheme();
  const { t } = useTranslation();

  // sign 에 따라서 대응되는 이미지를 가져옴
  const getSignImage = (instruction: { sign: number; exit_number?: number }, isNext: boolean) => {
    if (instruction === undefined) return "";
    if (instruction.sign === 6) {
      return `/guide_arrow/sign_${instruction.sign}_${instruction.exit_number}.png`;
    }
    return `/guide_arrow/sign_${instruction.sign}.png`;
  };

  const getGuideDist = useMemo(() => {
    if (!selectedPath || !selectedPath.instructions) return [];
    return selectedPath.instructions.map((instruction) =>
      getFormattedDistance(instruction.distance),
    );
  }, [selectedPath]);

  const getGuideText = useMemo(() => {
    if (!selectedPath || !selectedPath.instructions) return [];
    return selectedPath.instructions.map((instruction, guideIndex) => {
      if (guideIndex < 0 || guideIndex >= selectedPath.instructions.length) return "";
      if (instruction.text !== undefined && instruction.text.length) return instruction.text;

      return instruction.text;
    });
  }, [selectedPath]);

  // 현재 index 에서 다음 index 까지의 거리를 계산
  // const getGuideDist = (index: number) => {
  //   const instructions = selectedPath?.instructions;
  //   if (!instructions || instructions.length === 0) return "";
  //   if (index < 0 || index >= instructions.length) return "";

  //   const currentDistance = instructions[index]?.distance;
  //   const nextDistance = instructions[index + 1]?.distance;

  //   if (index === 0) {
  //     return currentDistance !== undefined ? getFormattedDistance(currentDistance) : "";
  //   }

  //   if (index + 1 < instructions.length) {
  //     return nextDistance !== undefined ? getFormattedDistance(nextDistance) : "";
  //   }

  //   // 마지막 요소
  //   return "";
  // };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle onClose={onClose}>
        <Typography sx={{ flexGrow: 1, textAlign: "center", py: 3 }} variant="h6" component="div">
          {t("All Guides")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <List sx={{ padding: 0 }}>
          {selectedPath &&
            selectedPath.instructions.map((instruction, index) => {
              if (index === 0) {
                return (
                  <ListItem sx={{ padding: 0 }} key={waypoints[0].id}>
                    <Box
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        display: "flex",
                        alignItems: "center",
                        padding: 1,
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          padding: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/guide_arrow/sign_start.svg"
                          alt="origin sign"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </ListItemAvatar>
                    </Box>
                    <Box
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemText
                        sx={{ paddingLeft: 2 }}
                        secondary={`${getGuideDist[0]}`}
                        primary={`${waypoints[0].place?.name}`}
                        secondaryTypographyProps={{ style: { fontSize: "1.0rem" } }}
                      />
                    </Box>
                  </ListItem>
                );
              }

              return (
                <ListItem
                  key={instruction.time}
                  sx={{
                    padding: 0,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      height: "1px",
                      bgcolor: "black",
                      opacity: 0.2,
                    },
                  }}
                  onClick={() => {
                    if (index + 1 < selectedPath.instructions.length) {
                      moveToCoordinate(
                        selectedPath.points.coordinates[instruction.interval[0]][0],
                        selectedPath.points.coordinates[instruction.interval[0]][1],
                      );
                    }
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      padding: 1,
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        padding: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={getSignImage(instruction, false)}
                        alt="guidance sign"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </ListItemAvatar>
                  </Box>
                  <Box
                    sx={{
                      bgcolor: theme.palette.background.paper,
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      sx={{ paddingLeft: 2 }}
                      secondary={`${getGuideDist[index]}`}
                      primary={getGuideText[index]}
                      secondaryTypographyProps={{ style: { fontSize: "1.0rem" } }}
                    />
                  </Box>
                </ListItem>
              );
            })}
        </List>
        <Divider />
      </DialogContent>
    </Dialog>
  );
};

export default SummaryGuideListDialog;
