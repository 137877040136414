import React, { memo, useMemo } from "react";
import { lineString } from "@turf/turf";
import { Position } from "geojson";
import { useAppSelector } from "../../../../app/hooks";
import { selectRoute } from "../../../route/routeSlice";
import { selectLinkRoute } from "../../../route/link/linkRouteSlice";
import { selectViewTransition } from "../../view/transition/viewTransitionSlice";
import LineString from "./LineString";

const GuideLine = () => {
  const { activePath } = useAppSelector(selectRoute);
  const { links } = useAppSelector(selectLinkRoute);
  const transition = useAppSelector(selectViewTransition);

  const geojson = useMemo(() => {
    if (!activePath?.rousen_details) return null;

    const points: Position[] = [];
    if (transition === null) {
      activePath.rousen_details.way_info.forEach(({ end_idx, start_idx, id, direction }, index) => {
        const targetLink = links.find((link) => link.id === id);
        if (!targetLink) {
          points.push(...activePath.points.coordinates.slice(start_idx, end_idx + 1));
          return;
        }

        // way정보가 조각으로 나누어져 올때가 있음.
        // 위 같이 link정보를 못 가져 왔으면 상관 없지만, 링크 정보를 통째로 가져와 써야한다면
        // 이전 way와 dir이 같으면 추가하지 않는다.
        if (
          index > 0 &&
          activePath.rousen_details?.way_info[index - 1].id === id &&
          activePath.rousen_details.way_info[index - 1].direction === direction
        ) {
          return;
        }

        const targetLinkPoints = [...targetLink.feature.geometry.coordinates];
        if (!direction) targetLinkPoints.reverse();
        points.push(...targetLinkPoints);
      });
    } else {
      const { current } = transition;

      let currentIndex = activePath.rousen_details.way_info.findIndex(
        (info) => info.id === current.wayId,
      );

      // way정보가 조각으로 나누어져 있을때가 있기때문에, 선이 반복되지 않게 하려면 index를 같은 wayid/dir인것의 마지막것을 취해야한다.
      while (
        activePath.rousen_details.way_info.length > currentIndex + 1 &&
        activePath.rousen_details.way_info[currentIndex + 1].id === current.wayId &&
        activePath.rousen_details.way_info[currentIndex + 1].direction === current.direction
      ) {
        currentIndex += 1;
      }

      if (current.isOnRoute) {
        points.push([current.location.longitude, current.location.latitude]);
      }

      const currentWay = links.find((link) => link.id === current.wayId);
      if (currentWay) {
        if (current.direction) {
          for (
            let i = current.index + 1;
            i < currentWay.feature.geometry.coordinates.length;
            i += 1
          ) {
            points.push(currentWay.feature.geometry.coordinates[i]);
          }
        } else {
          for (let i = current.index - 1; i > 0; i -= 1) {
            points.push(currentWay.feature.geometry.coordinates[i]);
          }
        }
      }

      const filteredWayInfo = activePath.rousen_details.way_info.slice(currentIndex + 1);

      filteredWayInfo.forEach(({ end_idx, start_idx, id, direction }, index) => {
        const targetLink = links.find((link) => link.id === id);

        if (!targetLink) {
          points.push(...activePath.points.coordinates.slice(start_idx, end_idx + 2));
          return;
        }

        // way정보가 조각으로 나누어져 올때가 있음.
        // 위 같이 link정보를 못 가져 왔으면 상관 없지만, 링크 정보를 통째로 가져와 써야한다면
        // 이전 way와 dir이 같으면 추가하지 않는다.
        if (
          index > 0 &&
          filteredWayInfo &&
          filteredWayInfo[index - 1].id === id &&
          filteredWayInfo[index - 1].direction === direction
        ) {
          return;
        }

        const targetLinkPoints = [...targetLink.feature.geometry.coordinates];
        if (!direction) targetLinkPoints.reverse();
        points.push(...targetLinkPoints);
      });
    }
    return points.length < 2 ? null : lineString(points);
  }, [activePath, links, transition]);

  if (geojson === null) return null;

  return <LineString type="guide" lineWidth={18} data={geojson} />;
};

export default memo(GuideLine);
