import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Checkbox,
  Divider,
  Card
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setGuideGeneralDistance,
  setGuideExpwayDistance,
  setSafetyExpwayDistance,
  setSafetyGeneralDistance,
  selectGuideSetting,
  GuidanceGeneralDistType,
  GuidanceExpresswayDistType,
  SafetyGeneralDistType,
  SafetyExpresswayDistType
} from "../../features/guide/guideSettingSlice"

/* 음성 안내 거리 설정 화면 */
const VoiceGuidanceDist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { guideGeneralDistanceCheck, guideExpwayDistanceCheck, safetyGeneralDistanceCheck, safetyExpwayDistanceCheck } = useAppSelector(selectGuideSetting);

  return (
    <Stack spacing={2}>
      <Box p={2}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIos fontSize="small" />
        </IconButton>
      </Box>

      <Card style={{ maxHeight: '85vh', maxWidth: '100vw', overflow: 'auto' }}>
        <List subheader={<ListSubheader disableSticky>{t("General Guide")}({t("Surface Street")})</ListSubheader>}>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setGuideGeneralDistance({ type: GuidanceGeneralDistType.GUIDE_GENERAL_1_5KM, value: !guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_1_5KM] }))}>
              <Checkbox checked={guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_1_5KM]} />
              <ListItemText primary={ `1.5 ${t("km")}`} />
            </ListItemButton>
            <ListItemButton onClick={() => dispatch(setGuideGeneralDistance({ type: GuidanceGeneralDistType.GUIDE_GENERAL_700M, value: !guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_700M] }))}>
              <Checkbox checked={guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_700M]} />
              <ListItemText primary={ `700 ${t("m")}`} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setGuideGeneralDistance({ type: GuidanceGeneralDistType.GUIDE_GENERAL_300M, value: !guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_300M] }))}>
              <Checkbox checked={guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_300M]} />
              <ListItemText primary={ `300 ${t("m")}`} />
            </ListItemButton>
            <ListItemButton onClick={() => dispatch(setGuideGeneralDistance({ type: GuidanceGeneralDistType.GUIDE_GENERAL_SOON, value: !guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_SOON] }))}>
              <Checkbox checked={guideGeneralDistanceCheck[GuidanceGeneralDistType.GUIDE_GENERAL_SOON]} />
              <ListItemText primary={t("Soon")} />
            </ListItemButton>
          </ListItem>
        </List>

        <List subheader={<ListSubheader disableSticky>{t("General Guide")}({t("Expressway")})</ListSubheader>}>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setGuideExpwayDistance({ type: GuidanceExpresswayDistType.GUIDE_EXPWAY_2_0KM, value: !guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_2_0KM] }))}>
              <Checkbox checked={guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_2_0KM]} />
              <ListItemText primary={ `2.0 ${t("km")}`} />
            </ListItemButton>
            <ListItemButton onClick={() => dispatch(setGuideExpwayDistance({ type: GuidanceExpresswayDistType.GUIDE_EXPWAY_1_0KM, value: !guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_1_0KM] }))}>
              <Checkbox checked={guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_1_0KM]} />
              <ListItemText primary={ `1.0 ${t("km")}`} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setGuideExpwayDistance({ type: GuidanceExpresswayDistType.GUIDE_EXPWAY_500M, value: !guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_500M] }))}>
              <Checkbox checked={guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_500M]} />
              <ListItemText primary={ `500 ${t("m")}`} />
            </ListItemButton>
            <ListItemButton onClick={() => dispatch(setGuideExpwayDistance({ type: GuidanceExpresswayDistType.GUIDE_EXPWAY_SOON, value: !guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_SOON] }))}>
              <Checkbox checked={guideExpwayDistanceCheck[GuidanceExpresswayDistType.GUIDE_EXPWAY_SOON]} />
              <ListItemText primary={t("Soon")} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader disableSticky>{t("Safety Info")}({t("Surface Street")})</ListSubheader>}>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setSafetyGeneralDistance({ type: SafetyGeneralDistType.SAFETY_GENERAL_800M, value: !safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_800M] }))}>
              <Checkbox checked={safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_800M]} />
              <ListItemText primary={ `800 ${t("m")}`} />
            </ListItemButton>
            <ListItemButton onClick={() => dispatch(setSafetyGeneralDistance({ type: SafetyGeneralDistType.SAFETY_GENERAL_500M, value: !safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_500M] }))}>
              <Checkbox checked={safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_500M]} />
              <ListItemText primary={ `500 ${t("m")}`} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setSafetyGeneralDistance({ type: SafetyGeneralDistType.SAFETY_GENERAL_300M, value: !safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_300M] }))}>
              <Checkbox checked={safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_300M]} />
              <ListItemText primary={ `300 ${t("m")}`} />
            </ListItemButton>
          </ListItem>
        </List>

        <List subheader={<ListSubheader disableSticky>{t("Safety Info")}({t("Expressway")})</ListSubheader>}>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setSafetyExpwayDistance({ type: SafetyExpresswayDistType.SAFETY_EXPWAY_1_5KM, value: !safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_5KM] }))}>
              <Checkbox checked={safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_5KM]} />
              <ListItemText primary={ `1.5 ${t("km")}`} />
            </ListItemButton>
            <ListItemButton onClick={() => dispatch(setSafetyExpwayDistance({ type: SafetyExpresswayDistType.SAFETY_EXPWAY_1_0KM, value: !safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_0KM] }))}>
              <Checkbox checked={safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_0KM]} />
              <ListItemText primary={ `1.0 ${t("km")}`} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => dispatch(setSafetyExpwayDistance({ type: SafetyExpresswayDistType.SAFETY_EXPWAY_500M, value: !safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_500M] }))}>
              <Checkbox checked={safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_500M]} />
              <ListItemText primary={ `500 ${t("m")}`} />
            </ListItemButton>
          </ListItem>
        </List>
      </Card>
    </Stack>
  );
};

export default VoiceGuidanceDist;
