import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface State {
  laneInfo: number[] | null;
  extraDistance: number;
  wayIds: number[];
}

const initialState: State = {
  laneInfo: null,
  extraDistance: 0,
  wayIds: [],
}

const laneOsmSlice = createSlice({
  name: "laneOsm",
  initialState,
  reducers: {
    setLaneInfo: (state, action: PayloadAction<{laneInfo:number[] | null, extraDistance:number, wayIds:number[]}>) => {
      state.laneInfo = action.payload.laneInfo;
      state.extraDistance = action.payload.extraDistance;
      state.wayIds = action.payload.wayIds;
    },
  }
});

export default laneOsmSlice;
export const { setLaneInfo } = laneOsmSlice.actions;
export const selectLaneOsm = (state: RootState) => state.laneOsm;