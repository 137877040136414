import { Box, Paper, Slide } from "@mui/material";
import React, { memo, ReactNode } from "react";

interface Props {
  open: boolean;
  children: ReactNode;
}

const ContextMenu = ({ open, children }: Props) => {
  return (
    <Slide in={open} direction="up" unmountOnExit>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        zIndex={(theme) => theme.zIndex.drawer + 2}
      >
        <Paper sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>{children}</Paper>
      </Box>
    </Slide>
  );
};

export default memo(ContextMenu);
