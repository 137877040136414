import {
  toMercator,
  toWgs84
} from "@turf/turf";
import { MAPMATCH_DIR, MAPMATCH_QUALITY, MAPMATCH_ROUTE_STATUS, MapMatchResult, rpoint } from "./MapMatchStructs";

export const reset = (): MapMatchResult => {
  const res: MapMatchResult = {
    x: 0,
    y: 0,
    carDegree: 0,
    gpsDegree: 0,
    // mapIdx: -1,
    linkIdx: -1,
    vtxIdx: -1,
    dist: 0,
    dir: MAPMATCH_DIR.UNKNOWN,
    quality: MAPMATCH_QUALITY.UNKNOWN,
    routeStatus: MAPMATCH_ROUTE_STATUS.DELAY,
    speed1000: 0,
    isHighway: false,
    roadName: "",
    crossName: "",
    roadType2: -1,
    roadClass2: -1,
    kwt_roadid: 0,
    kwt_rclass: 0,
    kwt_kind: 0,
    kwt_road_speed: 0,
    kwt_is_auto_moving: false,
    Competition: false,
    U_turn: false,
    is_tunnel: false,
    details: {
      mm_pt: { x: 0, y: 0 },
      pt1: { x: 0, y: 0 },
      pt2: { x: 0, y: 0 },
      angle: 0,
      map_idx: 0,
      link_idx: 0,
      dir: 0,
      vtx_idx: 0,
      vtx_dist: 0,
      road_grade: 0,
      link_type: 0,
      facil: 0,
      zpos: 0,
      pitch: 0
    },
    vtxDist: 0,
    roadType: 0,
    is_underpass: false
  };

  return res;
}

export const calPosOnEdge = (pt1wgs: rpoint, pt2wgs: rpoint, ptwgs: rpoint): { dista: number, result: rpoint, factor: number } => {

  const pt1 = toMercator([pt1wgs.x, pt1wgs.y]);
  const pt2 = toMercator([pt2wgs.x, pt2wgs.y]);
  const pt = toMercator([ptwgs.x, ptwgs.y]);

  let resultmercator: rpoint = { x: 0, y: 0 };
  let factor = 0;

  const sVa: rpoint = { x: 0, y: 0 };
  const sVb: rpoint = { x: 0, y: 0 };

  sVa.x = pt[0] - pt1[0];
  sVa.y = pt[1] - pt1[1];

  sVb.x = pt2[0] - pt1[0];
  sVb.y = pt2[1] - pt1[1];

  if ((sVb.x === 0) && (sVb.y === 0)) {
    factor = 0;
  }
  else {
    factor = ((sVa.x * sVb.x) + (sVa.y * sVb.y)) /
      ((sVb.x * sVb.x) + (sVb.y * sVb.y));
  }

  if (factor < 0.) {
    resultmercator = { x: pt1[0], y: pt1[1] };
  }
  else if (factor > 1.) {
    resultmercator = { x: pt2[0], y: pt2[1] };

    sVa.x = (pt[0] - resultmercator.x);
    sVa.y = (pt[1] - resultmercator.y);
  }
  else {
    resultmercator.y = (factor * sVb.y + pt1[1]);
    resultmercator.x = (factor * sVb.x + pt1[0]);

    sVa.x = (pt[0] - resultmercator.x);
    sVa.y = (pt[1] - resultmercator.y);
  }

  const resultwgs = toWgs84([resultmercator.x, resultmercator.y]);
  const result: rpoint = { x: resultwgs[0], y: resultwgs[1] };

  return { dista: Math.sqrt(sVa.x * sVa.x + sVa.y * sVa.y), result, factor };
}

export const vRotate0 = (r: number, ang: number): rpoint => {
  const rot = { x: 0, y: 0 };

  // 기존 루센에선 정동 기준이었고, 온라인은 정북 기준이므로 x,y가 반대.
  rot.y = Math.cos(ang) * r;
  rot.x = Math.sin(ang) * r;
  return rot;
}

export const rad2deg = (rad: number): number => {
  return rad * 180 / Math.PI;
}

export const deg2rad = (deg: number): number => {
  return deg * Math.PI / 180;
}


// get vector length
const vMag = (_a: rpoint, _b: rpoint): number => {
  const cx: number = _a.x - _b.x;
  const cy: number = _a.y - _b.y;
  return Math.sqrt(cx * cx + cy * cy);
}

// make unit vector and mag
const vUnit = (_a: rpoint, _b: rpoint) : {mag:number, uv12:rpoint} =>
{
  const uv12: rpoint = { x: 0, y: 0 };
  let mag: number = vMag(_a, _b);
  if (mag <= 1e-06) {
    mag = 0;
    uv12.x = 0;
    uv12.y = 0.;
  }
  else {
    uv12.x = (_b.x - _a.x) / mag;
    uv12.y = (_b.y - _a.y) / mag;
  }
  return { mag, uv12 };
}

const vUnitAngle = ( uv:rpoint ) : number => {
	return ( (uv.y < 0.) ? (2*Math.PI - Math.acos(uv.x)) : Math.acos(uv.x) ) ;
}

// get vector angle
export const vAngle = (_1: rpoint, _2: rpoint): number => {
  const { uv12 } = vUnit(_1, _2);
  return vUnitAngle(uv12);
}
