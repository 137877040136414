/* eslint-disable import/prefer-default-export */
import * as turf from "@turf/turf";
import { LineString } from "geojson";
import type WayPosition from "./WayPosition";
import { GEOLOCATION_DIRECTION_ANGLE_THRESHOLD } from "../../app/config/geolocation";

// enum type을 사용하여 oneway 속성을 정의
export enum WayOrder {
  forward = 3,
  backward = 4,
  both = 0,
  blocked = 1,
}

export function isWayForward(current: WayPosition, prev: WayPosition): boolean {
  const wayCoords = (current.way.feature.geometry as LineString).coordinates;
  const currentPoint = turf.point(current.position.coordinates);
  const prevPoint = turf.point(prev.position.coordinates);

  // 현재 위치에서 가장 가까운 way의 점 찾기
  const nearestPoint = turf.nearestPointOnLine(turf.lineString(wayCoords), currentPoint);
  const nearestIndex = nearestPoint.properties.index;

  // way의 진행 방향 결정을 위한 두 점 선택
  const startIndex = Math.max(0, nearestIndex - 1);
  const endIndex = Math.min(wayCoords.length - 1, nearestIndex + 1);

  const segmentStart = turf.point(wayCoords[startIndex]);
  const segmentEnd = turf.point(wayCoords[endIndex]);

  // 이동 방향과 way 세그먼트 방향 계산
  const movementBearing = turf.bearing(prevPoint, currentPoint);
  const segmentBearing = turf.bearing(segmentStart, segmentEnd);

  // 두 방향 사이의 각도 차이 계산
  const angleDifference = Math.abs(movementBearing - segmentBearing);

  // 각도 차이가 90도 미만이면 forward, 그렇지 않으면 backward
  return angleDifference < 90 || angleDifference > 270;
}

// 중복 로직을 처리하는 함수 정의
export function calculateBearing(
  link: GeoJSON.LineString,
  startIndex: number,
  endIndex: number,
): number {
  const start = turf.point(link.coordinates[startIndex]);
  const end = turf.point(link.coordinates[endIndex]);
  const bearing = turf.bearing(start, end);
  return bearing < 0 ? bearing + 360 : bearing;
}

export function isMovableDirection(
  heading: number,
  onewayInfo: number | string | null,
  link: GeoJSON.LineString,
): { movable: boolean; wayOrder: WayOrder } {
  // ref: http://redmine.rarara.com/redmine/projects/citus-rp/wiki/Rousen_OSM_PBF_format_
  // eslint-disable-next-line consistent-return
  const oneway: number | undefined = (() => {
    if (typeof onewayInfo === "string") {
      switch (onewayInfo) {
        case "yes":
          return 3;
        case "-1":
          return 4;
        default:
          return 0;
      }
    }
  })();

  // 통행 불가능한 경우 바로 반환. 2는 reserved.
  if (oneway === 1 || oneway === 2) {
    return { movable: false, wayOrder: WayOrder.blocked };
  }

  // 방향과 이동 가능 여부를 결정하는 로직
  let wayOrder;
  let movable = true;
  let wayBearing;

  switch (oneway) {
    case 3: // 정방향 link
      wayOrder = WayOrder.forward;
      wayBearing = calculateBearing(link, 0, link.coordinates.length - 1);
      movable = Math.abs(wayBearing - heading) < GEOLOCATION_DIRECTION_ANGLE_THRESHOLD;
      break;
    case 4: // 역방향 link
      wayOrder = WayOrder.backward;
      wayBearing = calculateBearing(link, link.coordinates.length - 1, 0);
      movable = Math.abs(wayBearing - heading) < GEOLOCATION_DIRECTION_ANGLE_THRESHOLD;
      break;
    default: // 양방향 가능 link: 0 or undefined
      wayOrder = WayOrder.both;
      wayBearing = calculateBearing(link, 0, link.coordinates.length - 1);
      movable = true;
      break;
  }

  return { movable, wayOrder };
}
