import React, { useCallback, useMemo } from "react";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  useTheme,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { removeWayPoint, setWayPoint, Waypoint } from "./waypointSlice";
import SearchDialog from "../search/SearchDialog";
import { Place, setValue } from "../search/searchSlice";
import { useAppDispatch } from "../../app/hooks";
import CircleIconButton from "../../components/CircleIconButton";
import { useDialog } from "../../components/Dialog";
import { locationImgUrl } from "../../app/config/images";

interface Props {
  waypoint: Waypoint;
  index: number;
}

const WaypointItem = ({ waypoint, index }: Props) => {
  const dispatch = useAppDispatch();
  const searchDialog = useDialog();

  const theme = useTheme();

  const onSumbit = useCallback(
    (place: Place) => {
      dispatch(setWayPoint({ index, place }));
      searchDialog.onClose();
    },
    [dispatch, index, searchDialog],
  );

  const handleClickRemove = () => {
    dispatch(removeWayPoint(index));
  };

  const handleClickWaypoint = () => {
    dispatch(setValue(waypoint.place?.name || ""));
    searchDialog.onOpen();
  };

  const waypointText = useMemo(() => {
    if (waypoint.place?.name) {
      return waypoint.place.name;
    }
    if (waypoint.type === "waypoint") {
      return "경유지";
    }
    if (waypoint.type === "destination") {
      return "도착지";
    }
    return "출발지";
  }, [waypoint.place, waypoint.type]);

  return (
    <Paper
      sx={{
        display: "flex",
      }}
    >
      <ListItem
        disablePadding
        component="div"
        secondaryAction={
          waypoint.type === "waypoint" ? (
            <CircleIconButton
              size="medium"
              color={theme.palette.background.default}
              onClick={handleClickRemove}
            >
              <DeleteOutline sx={{ color: theme.palette.text.primary }} fontSize="small" />
            </CircleIconButton>
          ) : null
        }
      >
        <ListItemButton onClick={waypoint.type !== "origin" ? handleClickWaypoint : undefined}>
          <ListItemAvatar>
            <img src={locationImgUrl} alt="Location Icon" style={{ width: 37, height: 37 }} />
          </ListItemAvatar>
          <ListItemText
            primary={waypointText}
            primaryTypographyProps={{ color: waypoint.place ? "text.primary" : "text.secondary" }}
          />
        </ListItemButton>
      </ListItem>
      {waypoint.type !== "origin" && (
        <IconButton>
          <MenuIcon />
        </IconButton>
      )}
      <SearchDialog open={searchDialog.open} onClose={searchDialog.onClose} onSubmit={onSumbit} />
    </Paper>
  );
};

export default WaypointItem;
