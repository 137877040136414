import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface State {
  token: string | null;
}

const initialState: State = {
  token: null,
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    clearUserToken: (state) => {
      state.token = null;
      sessionStorage.removeItem("persist:session"); // session 전체를 clear?
    },
  },
});

export const selectToken = (state: RootState) => state.token;

export const { setUserToken, clearUserToken } = tokenSlice.actions;
export default tokenSlice;
