import React, { memo } from "react";
import { Marker } from "react-map-gl/maplibre";
import { Waypoint } from "../../../waypoint/waypointSlice";
import WaypointIcon from "../../../waypoint/WaypointIcon";

interface Props {
  waypoint: Waypoint;
  text?: string | number;
}

const WaypointMarker = ({ waypoint, text }: Props) => {
  if (!waypoint.place) return null;
  return (
    <Marker
      key={waypoint.place.id}
      latitude={waypoint.place.point.lat}
      longitude={waypoint.place.point.lng}
      anchor="center"
      offset={[-3, 3]}
    >
      <WaypointIcon type={waypoint.type} text={text} />
    </Marker>
  );
};

export default memo(WaypointMarker);
