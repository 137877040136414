import WebGeoLocationProvider from "./WebGeoLocationProvider";
import { GeoLocation } from "./geoLocationSlice";
import IGeoLocationProvider from "./interface/IGeoLocationProvider";

export default class GeoLocationManager {
  static locationManager: GeoLocationManager | null = null;

  private provider!: IGeoLocationProvider;

  public timer?: any = null;

  static getInstance(): GeoLocationManager {
    if (GeoLocationManager.locationManager === null) {
      GeoLocationManager.locationManager = new GeoLocationManager();
    }
    return GeoLocationManager.locationManager;
  }

  private constructor(provider: IGeoLocationProvider = new WebGeoLocationProvider()) {
    this.setProvider(provider);
  }

  public setProvider(provider: IGeoLocationProvider): void {
    this.provider = provider;
  }

  public async getLocation(): Promise<GeoLocation> {
    return this.provider.getLocation();
  }
}

export const geoLocationManager = GeoLocationManager.getInstance();
