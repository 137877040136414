import { useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { SafeDrive, selectGuide, SafeType, setCameraCastFlag, clearSafetyInfoFlags, setCameraPassed } from "../guide/guideSlice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";
import { selectDebug } from "../debug/debugSlice";
import { selectSimulator } from "../debug/simulator/simulatorSlice";  
import { selectGuideSetting, SafetyGeneralDistType, SafetyExpresswayDistType } from "../guide/guideSettingSlice";
import { type SoundData, WavType, playSound, SoundType, SoundPriority, isPlayingSound } from "./soundUtils";
import * as TTSText from "./TTSText";

const useCameraSoundWithRoute = () => {
  const TTS_LANG = 'ko';  // 일단은 한국어만 지원하도록 처리.

  const CPF_FILE_KIND_NO_INFO = (-1);
  const CPF_FILE_KIND_NONE = (0);
  const CPF_FILE_KIND_LINE = (1);
  const CPF_FILE_KIND_POINT = (2);
  const CPF_FILE_KIND_NOTICE = (3);
  const CPF_FILE_KIND_TPEG = (4);
  const CPF_FILE_KIND_CAM_POINT = (5);
  const CPF_FILE_KIND_RTM = (6);
  const CPF_FILE_KIND_LINK_POINT = (7);
  const CPF_FILE_KIND_USER = (11);

  const MIN_DIST_FOR_DD_SOUND = 60;

  const DistH1 = 500;
  const DistH2 = 1000;
  const DistL1 = 300;
  const DistL2 = 500;
  const CK_TIME1 = 1;
  const CK_TIME2 = 2;

  // const PLAY_SND_DIST_ONLYONE = 0x0040; // type의  6 번째 비트 (0 번째 부터 시작)
  const PLAY_SND_DIST_4KM = 0; // 0x0200; // type의  9 번째 비트 (0 번째 부터 시작)
  const PLAY_SND_DIST_1KM = 1; // 0x0400; // type의 10 번째 비트 (0 번째 부터 시작)
  const PLAY_SND_DIST_500M = 2; // 0x0800; // type의 11 번째 비트 (0 번째 부터 시작)
  const PLAY_SND_DIST_300M = 3; // 0x0100; // 
  const PLAY_SND_DIST_SOON = 4; // 0x1000; // type의 12 번째 비트 (0 번째 부터 시작)
  // const PLAY_SND_DIST_ALL = 0x1C00; //

  const dispatch = useAppDispatch();
  const { position } = useAppSelector(selectGuidePosition);
  const { safeDrives, currentSafeIndex } = useAppSelector(selectGuide);
  const { mode } = useAppSelector(selectDebug);
  const { safetyGeneralDistanceCheck, safetyExpwayDistanceCheck } = useAppSelector(selectGuideSetting);
  const currentCamIndex = useRef<number | null>(null);
  const currentCamPoint = useRef<number[] | null>(null);
  const overFirst = useRef<number>(0);
  const tickcount = useRef<number>(0);
  const remainDist = useRef<number>(0);
  const prevSimulTimeLine = useRef<number|null>(null);
  const { currentTimeline } = useAppSelector(selectSimulator)

  const isMuted: boolean = false;

  useEffect(() => {
    const getDdDistance = (speed: number): number => {
      // calc_dist = speed * 1000/3600 * second
      // 기준 second = 3s delay
      // GMK 에서 위에서 계산된 값을 0.5 해서 사용하였음.
      // double dd_dist = _speed * 1000. / 3600 * 3 * 0.5;

      // #5525 현대 장비는 GMK LVM 장비보다 훨씬 빠르다고 가정하고 기준 초를 0.5초 정도로 줄인다.
      // (2019-02-13) shinguru
      const ddDist = speed * 1000.0 / 3600 * 0.5;

      return ddDist;
    }

    const IsPassSound = (cpfType: number): boolean => {
      switch (cpfType) {
        case SafeType.SAFE_INFO_SPEED_FIXED_CAMERA:
        case SafeType.SAFE_INFO_SPEED_MOVABLE_CAMERA:
        case SafeType.SAFE_INFO_TRAFFIC_LIGHT:
        case SafeType.SAFE_INFO_SPEED_TRAFFIC_LIGHT:
          return true;
          break;
        default:
      }
      return false;
    }

    const IsFixedSpeedCameraType = (cpfType: number): boolean => {
      return (cpfType === SafeType.SAFE_INFO_SPEED_FIXED_CAMERA || cpfType === SafeType.SAFE_INFO_SPEED_TRAFFIC_LIGHT);

    }

    const IsMovingSpeedCameraType = (cpfType: number): boolean => {
      return cpfType === SafeType.SAFE_INFO_SPEED_MOVABLE_CAMERA;
    }

    const IsSpeedCameraGuide = (cpfType: number): boolean => {
      const fixed = IsFixedSpeedCameraType(cpfType);
      const moving = IsMovingSpeedCameraType(cpfType);
      return fixed || moving;
    }

    const PlaySpeedCameraPassSound = (currGuideCam: SafeDrive, index:number): void => {
      const cpfType = currGuideCam.type;
      if ( /* m_guide_options.pass_sound && */ IsPassSound(cpfType)  && !currGuideCam.isPassed ) {
        // fixme : 원래 지나간 카메라는 플래그를 셋팅해줘야하는데, 현재는 안함. 나중에 처리할것.
        const snd: SoundData = { wav: "speedover2", tts: [] }
        playSound(SoundType.STS_SOUND_EFFECT_END_OF_SPEED_CAMERA, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
      }

      dispatch(setCameraPassed(index));
    }

    const PlayPassSound = (remainDist0: number, minDistForDdSound: number, currGuideCam: SafeDrive, index:number): boolean => {
      if (remainDist0 < minDistForDdSound) {
        PlaySpeedCameraPassSound(currGuideCam, index);
        return true;
      }
      return false;
    }

    const IsSignCameraGuide = (cpfType: number): boolean => {
      return (cpfType === SafeType.SAFE_INFO_TRAFFIC_LIGHT) /* && SYS_GetSafeGuideFlag(cpf_type) */;
    }


    const PlaySpeedOverWaring = (speed: number, limit: number): boolean => {
      const soundType = SoundType.STS_SOUND_EFFECT_SPEED_OVER;
      // if (m_use_voice_guidance[0] == 0 || m_use_voice_guidance[sound_type] == 0)
      //  return FALSE; // off
      if (isPlayingSound()) return false;
      const snd: SoundData = { wav: WavType.WV_EFF_SPEED_OVER_ALM, tts: [] }
      playSound(soundType, snd, SoundPriority.STS_PRIORITY_EFFECT_SOUND);
      return true;
    }
    const PlaySpeedCameraSpeedOverAlarm = (_speed: number, _isHighway: boolean, currGuideCam: SafeDrive, tc: number): boolean => {
      const cpfType = currGuideCam.type;
      const limit = currGuideCam.speed;

      if ((tc === 0) && limit !== 0 && (_speed > limit) && (mode !== "route")) {
        let isOk = false;
        if ((IsFixedSpeedCameraType(cpfType) /* && m_guide_options.speed_over_fixed */) ||
          (IsMovingSpeedCameraType(cpfType) /* && m_guide_options.speed_over_moving */)) {
          isOk = true;
        }

        if (isOk) {
          let ldist = _isHighway ? 1000 : 500;
          if (_isHighway)// && ((dist - MIN_DIST_FOR_DD_SOUND()) <= sysOpts->m_speedOverExpress) )
          {
            if (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_5KM])
              ldist = 1500;
            else if (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_0KM])
              ldist = 1000;
            else if (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_500M])
              ldist = 500;
            // if(SYS_GetRGType() == RG_TYPE_SIMPLE) ldist = 900;
          }
          else // if( (dist - MIN_DIST_FOR_DD_SOUND()) <= sysOpts->m_speedOverNormal)
          {
            // eslint-disable-next-line no-lonely-if
            if (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_800M])
              ldist = 800;
            else if (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_500M])
              ldist = 500;
            else if (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_300M])
              ldist = 300;
          }

          if (remainDist.current <= ldist) {
            PlaySpeedOverWaring(_speed, limit);
            // 					sts_sound	snd;
            // 					snd.add( WV_EFF_SPEED_OVER_ALM );
            // 					sts().play_sound(STS_SOUND_CAMERA, snd, STS_PRIORITY_GUIDANCE);
            overFirst.current += 1;
          }
        }
      }

      return false;
    }

    const PlaySpeedCameraDistanceGuide = (isSectionEndGuide: boolean, _speed: number, _isHighway: boolean, currGuideCam: SafeDrive, tc: number): boolean => {
      const cpfType = currGuideCam.type;
      const limit = currGuideCam.speed;

      const isMoving = IsMovingSpeedCameraType(cpfType); // IsMovingSpeedCameraGuide(cpfType);
      const lCastTag = JSON.parse(JSON.stringify(currGuideCam.castFlag))


      // #2626 좌측 방향 안내를, 300m 안내는 4~50m 부근에서 하고 잠시 후 안내는 지나서 안내함
      // 카메라 안내 때문에 방향 음성 안내가 정상적으로 나오지 않던 문제로 인해
      // 송도IC 근처 카메라 (280381, 534901) 의 500m 거리 안내 제거함.
      // 땜빵 코드이므로 이런 경우가 더 나온다면, 근본적인 수정을 해야 함.
      // (2017-02-02) shinguru
      if (Math.abs(currGuideCam.guidePoint.coordinates[0] - 280381) < 5 && Math.abs(currGuideCam.guidePoint.coordinates[0] - 534901) < 5) {
        // PLAY_SND_DIST_300M --> 일반도로 300m 거리 안내 MASK
        // PLAY_SND_DIST_1KM  --> 일반도로 500m 거리 안내 MASK
        // PLAY_SND_DIST_4KM  --> 일반도로 800m 거리 안내 MASK
        lCastTag[PLAY_SND_DIST_1KM] = true;
      }


      const dist = remainDist.current;
      // dist -= DEF_SND_DIST_DIFF;

      let isOK = false;
      let distsnd = "";
      let distchk = 0;

      if ((dist >= 1480) && (dist <= 1570)) {
        distchk = 1500;
        distsnd = TTSText.getTTSString(TTSText.WV_D_1_5KM, TTS_LANG);
      }
      else if ((dist >= 900) && (dist <= 1100)) {
        distchk = 1000;
        distsnd = TTSText.getTTSString(TTSText.WV_D_1KM, TTS_LANG);
      }
      else if ((dist >= 850) && (dist < 980)) {
        distchk = 900;
        distsnd = TTSText.getTTSString(TTSText.WV_D_900M, TTS_LANG);
      }
      else if ((dist >= 770) && (dist < 870)) {
        distchk = 800;
        distsnd = TTSText.getTTSString(TTSText.WV_D_800M, TTS_LANG);
      }
      else if ((dist >= 670) && (dist < 770)) {
        distchk = 700;
        distsnd = TTSText.getTTSString(TTSText.WV_D_700M, TTS_LANG);
      }
      else if ((dist >= 570) && (dist < 670)) {
        distchk = 600;
        distsnd = TTSText.getTTSString(TTSText.WV_D_600M, TTS_LANG);
      }
      else if ((dist >= 480) && (dist < 570)) {
        distchk = 500;
        distsnd = TTSText.getTTSString(TTSText.WV_D_500M, TTS_LANG);
      }
      else if ((dist >= 380) && (dist < 470)) {
        distchk = 400;
        distsnd = TTSText.getTTSString(TTSText.WV_D_400M, TTS_LANG);
      }
      else if ((dist >= 280) && (dist < 370)) {
        distchk = 300;
        distsnd = TTSText.getTTSString(TTSText.WV_D_300M, TTS_LANG);
      }
      else if ((dist >= 200) && (dist < 270)) {
        distchk = 200;
        distsnd = TTSText.getTTSString(TTSText.WV_D_200M, TTS_LANG);
      }
      else if ((dist >= 100) && (dist < 170)) {
        distchk = 100;
        distsnd = TTSText.getTTSString(TTSText.WV_D_100M, TTS_LANG);
      }
      else if (dist < 100 && dist > 40) {
        distchk = 90;
        distsnd = TTSText.getTTSString(TTSText.WV_D_SOON, TTS_LANG);
      }

      const oldVal = JSON.parse(JSON.stringify(lCastTag));

      if (_isHighway) {
        if (!isPlayingSound() && (distchk >= 90 && distchk <= 200) && !(lCastTag[PLAY_SND_DIST_SOON]) && (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_SOON])) // soon 
        {
          isOK = true;
          distsnd = TTSText.getTTSString(TTSText.WV_D_SOON, TTS_LANG);
          lCastTag[PLAY_SND_DIST_4KM] = true;
          lCastTag[PLAY_SND_DIST_1KM] = true;
          lCastTag[PLAY_SND_DIST_500M] = true;
          lCastTag[PLAY_SND_DIST_300M] = true;
          lCastTag[PLAY_SND_DIST_SOON] = true;
        }
        else if ((DistH1 === distchk) && !(lCastTag[PLAY_SND_DIST_500M]) && (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_500M])) // 고속도로 500m
        {
          // 500m
          isOK = true;
          lCastTag[PLAY_SND_DIST_4KM] = true;
          lCastTag[PLAY_SND_DIST_1KM] = true;
          lCastTag[PLAY_SND_DIST_500M] = true;
          lCastTag[PLAY_SND_DIST_300M] = true;

        }
        else if ((DistH2 === distchk) && !(lCastTag[PLAY_SND_DIST_1KM]) && (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_0KM])) // 고속도로 1KM
        {
          isOK = true;
          lCastTag[PLAY_SND_DIST_4KM] = true;
          lCastTag[PLAY_SND_DIST_1KM] = true;
        }
        else if ((distchk === 1500) && !(lCastTag[PLAY_SND_DIST_4KM]) && (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_5KM])) // 고속도로 1.5KM
        {
          isOK = true;
          lCastTag[PLAY_SND_DIST_4KM] = true;
        }
      }
      else {
        // eslint-disable-next-line no-lonely-if
        if (!isPlayingSound() && (distchk >= 90 && distchk <= 100) && !(lCastTag[PLAY_SND_DIST_SOON]) && (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_SOON])) // soon 
        {
          isOK = true;
          distsnd = TTSText.getTTSString(TTSText.WV_D_SOON, TTS_LANG);
          lCastTag[PLAY_SND_DIST_4KM] = true;
          lCastTag[PLAY_SND_DIST_1KM] = true;
          lCastTag[PLAY_SND_DIST_500M] = true;
          lCastTag[PLAY_SND_DIST_300M] = true;
          lCastTag[PLAY_SND_DIST_SOON] = true;
        }
        else if ((DistL1 === distchk) && !(lCastTag[PLAY_SND_DIST_300M]) && (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_300M])) // 일반 300m
        {
          isOK = true;
          lCastTag[PLAY_SND_DIST_4KM] = true;
          lCastTag[PLAY_SND_DIST_1KM] = true;
          lCastTag[PLAY_SND_DIST_500M] = true;
          lCastTag[PLAY_SND_DIST_300M] = true;

        }
        else if ((DistL2 === distchk) && !(lCastTag[PLAY_SND_DIST_1KM]) && (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_500M])) // 일반 500m
        {
          isOK = true;
          lCastTag[PLAY_SND_DIST_4KM] = true;
          lCastTag[PLAY_SND_DIST_1KM] = true;
        }
        else if ((distchk === 800) && !(lCastTag[PLAY_SND_DIST_4KM]) && (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_800M])) // 일반 800m
        {
          isOK = true;
          lCastTag[PLAY_SND_DIST_4KM] = true;
        }
      }

      if (JSON.stringify(oldVal) !== JSON.stringify(lCastTag)) {
        dispatch(setCameraCastFlag({ index: currentSafeIndex, flags: lCastTag }));
      }

      if (isOK) {
        const snd: SoundData = { wav: WavType.WV_EFF_DDINGDDAENG, tts: [] };
        if (isMoving && !isSectionEndGuide) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_IC_SPEED_DOWN, TTS_LANG));
        }
        else {
          snd.tts.push(distsnd);
        }

        if (isSectionEndGuide) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_DANGER_25, TTS_LANG));
        }

        if (limit === 0 && !isMoving) snd.tts.push(TTSText.getTTSString(TTSText.WV_IC_SPEED_DOWN, TTS_LANG));
        if (limit === 20) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_020, TTS_LANG));
        else if (limit === 30) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_030, TTS_LANG));
        else if (limit === 40) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_040, TTS_LANG));
        else if (limit === 50) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_050, TTS_LANG));
        else if (limit === 60) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_060, TTS_LANG));
        else if (limit === 70) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_070, TTS_LANG));
        else if (limit === 80) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_080, TTS_LANG));
        else if (limit === 90) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_090, TTS_LANG));
        else if (limit === 100) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_100, TTS_LANG));
        else if (limit === 110) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_110, TTS_LANG));
        else if (limit === 120) snd.tts.push(TTSText.getTTSString(TTSText.WV_SLIMIT_120, TTS_LANG));

        if (cpfType === SafeType.SAFE_INFO_SPEED_TRAFFIC_LIGHT && limit >= 15) snd.tts.push(TTSText.getTTSString(TTSText.WV_DANGER_15, TTS_LANG));

        playSound(SoundType.STS_SOUND_CAMERA, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
      }

      return false;
    }

    const PlaySignCamera = (_speed: number, _isHighway: boolean, currGuideCam: SafeDrive, tc: number): boolean => {
      const cpfType = currGuideCam.type;

      const dist = remainDist.current;
      // dist -= DEF_SND_DIST_DIFF;

      const snd: SoundData = { wav: WavType.WV_EFF_DDINGDDAENG, tts: [] };

      const lCastTag = JSON.parse(JSON.stringify(currGuideCam.castFlag));
      const oldVal = JSON.parse(JSON.stringify(lCastTag));

      if (_isHighway) {
        if ((dist < 250) && !(lCastTag[PLAY_SND_DIST_500M])) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_DANGER_16, TTS_LANG));
          playSound(SoundType.STS_SOUND_CAMERA, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
          lCastTag[PLAY_SND_DIST_1KM] = true;
          lCastTag[PLAY_SND_DIST_500M] = true;
        }
        else if ((dist < 800) && !(lCastTag[PLAY_SND_DIST_1KM])) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_DANGER_16, TTS_LANG));
          playSound(SoundType.STS_SOUND_CAMERA, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
          lCastTag[PLAY_SND_DIST_1KM] = true;
        }
      }
      else {
        // eslint-disable-next-line no-lonely-if
        if ((dist < 150) && !(lCastTag[PLAY_SND_DIST_500M])) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_DANGER_16, TTS_LANG));
          playSound(SoundType.STS_SOUND_CAMERA, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
          lCastTag[PLAY_SND_DIST_1KM] = true;
          lCastTag[PLAY_SND_DIST_500M] = true;
        }
        else if ((dist < 600) && !(lCastTag[PLAY_SND_DIST_1KM])) {
          snd.tts.push(TTSText.getTTSString(TTSText.WV_DANGER_16, TTS_LANG));
          playSound(SoundType.STS_SOUND_CAMERA, snd, SoundPriority.STS_PRIORITY_GUIDANCE);
          lCastTag[PLAY_SND_DIST_1KM] = true;
        }
      }

      if (JSON.stringify(oldVal) !== JSON.stringify(lCastTag)) {
        dispatch(setCameraCastFlag({ index: currentSafeIndex, flags: lCastTag }));
      }

      return false;
    }


     // 모의주행이나, 로그주행시 시간을 뒤로 돌리면 플래그 초기화.
     if (prevSimulTimeLine.current !== null && prevSimulTimeLine.current > currentTimeline) {
      dispatch(clearSafetyInfoFlags())
     }
     prevSimulTimeLine.current = currentTimeline;
 
    // play function
    // @ts-ignore
    if (isMuted === true) {
      return;
    }

    // 구간단속 구간중 - 속도 초과음 (현재 카메라 안내에 관계없이 속도 초과음이 나와야 함)
    /* TODO 현재는 구간단손을 고정식으로 처리하므로 사용 안함. 나중에 구간단속타입이 추가되면 평균속도 계산이 먼저 되어야 함.
    if (Play_SectionCamera_SpeedOverAlarm())
      return;
    */

    if (currentCamIndex.current != null
      && currentCamIndex.current !== -1
      && currentCamIndex.current !== currentSafeIndex) {
      // fixme : CameraSoundGIM::IsCurrentPlayable() 로 체크 먼저 필요.

      if (currentCamIndex.current >= 0 &&
        safeDrives.length > currentCamIndex.current &&
        currentCamPoint.current !== null && // 재경탐되서 카메라 인덱스가 바뀔수도 있으니 정말 같은 카메라인지 좌표 비교 추가.
        currentCamPoint.current.length === 2 &&
        currentCamPoint.current[0] === safeDrives[currentCamIndex.current].guidePoint.coordinates[0] &&
        currentCamPoint.current[1] === safeDrives[currentCamIndex.current].guidePoint.coordinates[1]
      )
        PlaySpeedCameraPassSound(safeDrives[currentCamIndex.current], currentCamIndex.current);
      overFirst.current = 0;
    }

    currentCamIndex.current = currentSafeIndex;
    currentCamPoint.current = (currentSafeIndex >= 0 && safeDrives.length > currentSafeIndex) ? [safeDrives[currentSafeIndex].guidePoint.coordinates[0], safeDrives[currentSafeIndex].guidePoint.coordinates[1]] : null;


    // 현재 카메라 안내가 없으면 넘김
    if (currentSafeIndex < 0) { return; };
    const currGuideCam = safeDrives[currentSafeIndex];
    if (currGuideCam === undefined || currGuideCam === null) { return; };

    const speed: number = (position === null ? 0 : (position.speed || 0));
    // let lCastTag = currGuideCam.castFlag;

    const cpfType = currGuideCam.type;

    const curDist = currGuideCam.guidedist;
    const remainDist0: number = Math.round(curDist + .5);

    let minDistForDdSound: number = 0;
    if (mode !== "route")
      minDistForDdSound = Math.round((getDdDistance(Math.round(speed + .5))) + 0.5);

    remainDist.current = Math.round(curDist + .5) - minDistForDdSound;


    // tc = 0 일때 속도 초과음을 낸다.
    let tc: number = 0;
    const d2: number = remainDist.current;
    const dist1 = currGuideCam.isHighway ? 500 : 300;
    let dist2 = 0;

    // 카메라 안내 시점 옵션에 따라 속도 초과음을 내는 거리값을 변경
    if (currGuideCam.isHighway) {
      if (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_5KM])
        dist2 = 1500;
      else if (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_1_0KM])
        dist2 = 1000;
      else if (safetyExpwayDistanceCheck[SafetyExpresswayDistType.SAFETY_EXPWAY_500M])
        dist2 = 500;
      else
        dist2 = 1000;
    }
    else {
      // eslint-disable-next-line no-lonely-if
      if (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_800M])
        dist2 = 800;
      else if (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_500M])
        dist2 = 500;
      else if (safetyGeneralDistanceCheck[SafetyGeneralDistType.SAFETY_GENERAL_300M])
        dist2 = 300;
      else
        dist2 = 500;
    }

    if (d2 <= dist1)
      tc = (new Date().getTime() * 1000) % CK_TIME1;
    else if (d2 <= dist2)
      tc = (new Date().getTime() * 1000) % CK_TIME2;
    else
      tc = -1;

    if (overFirst.current < 2) {
      tc = 0;
    }

    tickcount.current = tc;

    // 속도 카메라 - 카메라 통과음 (구간단속 포함)
    // 또는 일반정보 안내 (휴게소, LPG 충전소, 요금소, 시경계)
    if (PlayPassSound(remainDist0, minDistForDdSound, currGuideCam, currentCamIndex.current))
      return;

    // 저속주행시 안내 안함 옵션이 켜져 있고, 속도 조건을 만족할때 종료
    // if (m_guide_options.guide_only_high_speed && _speed < m_guide_options.guide_only_high_speed_limit) {
    //   return;
    // }

    if (IsSpeedCameraGuide(cpfType)) {
      // 속도 카메라 - 속도 초과음 처리
      if (PlaySpeedCameraSpeedOverAlarm(speed, currGuideCam.isHighway, currGuideCam, tickcount.current))
        return;

      // 속도 카메라 - 남은 거리 음성 안내
      if (PlaySpeedCameraDistanceGuide(false, speed, currGuideCam.isHighway, currGuideCam, tickcount.current)) {
        // eslint-disable-next-line no-useless-return
        return;
      }
    }
    /* TODO : 아래 항목들은 현재 서버에서 오지 않음. 현재는 구간 단속도 고정식으로 처리. 서버에서 타입이 추가된 후 처리 바람.
    else if (IsDangerAreaGuide(cpf_type)) {
      // 위험구간 [주의구간안내로 보임]
      if (Play_DangerArea())
        return;
    }
    */
    else if (IsSignCameraGuide(cpfType)) {
      // 신호위반 단속 카메라
      if (PlaySignCamera(speed, currGuideCam.isHighway, currGuideCam, tickcount.current))
      {
        // eslint-disable-next-line no-useless-return
        return;
      }
    }
    /*
    else if (IsCurvedAreaGuide(cpf_type)) {
      // 급커브구간 [주의구간안내로 보임]
      if (Play_CurvedArea())
        return;
    }
    else if (IsSpeedBumpGuide(cpf_type)) {
      // 과속방지턱 [주의구간안내로 보임]
      if (Play_SpeedBump())
        return;
    }
    else if (IsOverloadGuide(cpf_type)) {
      // 과적단속카메라
      if (Play_Overload())
        return;
    }
    else if (IsSideRoadGuide(cpf_type)) {
      // 갓길단속카메라
      if (Play_SideRoad())
        return;
    }
    else if (IsTrafficInfoCollectorGuide(cpf_type)) {
      // 교통정보 수집 카메라
      if (Play_TrafficInfoCollector())
        return;
    }
    else if (IsBusLaneGuide(cpf_type)) {
      // 버스전용차선 카메라
      if (Play_BusLane())
        return;
    }
    else if (IsCCTVGuide(cpf_type)) {
      // CCTV
      if (Play_CCTV())
        return;
    }
    else if (IsParkGuide(cpf_type)) {
      // CCTV
      if (Play_Park())
        return;
    }
    else if (IsHeightLimitGuide(cpf_type)) {
      // 높이제한
      if (Play_HeightLimit())
        return;
    }
    else if (IsWeightLimitGuide(cpf_type)) {
      // 중량제한
      if (Play_WeightLimit())
        return;
    }
    else if (IsSectionCameraStartGuide(cpf_type)) {
      // 구간단속 카메라 시작점
      if (Play_SectionCameraStart())
        return;
    }
    else if (IsSectionCameraEndGuide(cpf_type)) {
      // 구간단속 카메라 종료점 남은 거리 
      if (Play_SpeedCamera_DistanceGuide(true))
        return;
    }
    else if (IsCautionGuide(cpf_type)) {
      // 주의구간안내
      if (Play_CautionGuide(cpf_type))
        return;
    }
    else if (IsWeightMonitorGuide(cpf_type)) {
      // 계측,계근불응카메라
      if (Play_WeightMonitorGuide())
        return;
    }
    else if (IsLeftSignGuide(cpf_type)) {
      // 좌회전전용차선위반단속
      if (Play_LeftSignGuide())
        return;
    }
    else if (IsPoorLoadGuide(cpf_type)) {
      // 적재불량
      if (Play_PoorLoadGuide())
        return;
    }
    else if (IsEscapeCarGuide(cpf_type)) {
      // 도주차량카메라
      if (Play_EscapeCarGuide())
        return;
    }
    else if (IsLaneChangeGuide(cpf_type)) {
      // 차선변경단속카메라
      if (Play_LaneChangeGuide())
        return;
    }
    else if (IsRampMeteringGuide(cpf_type)) {
      // 램프미터링
      if (Play_RampMeteringGuide())
        return;
    }
    else if (IsTailgatingGuide(cpf_type)) {
      // 꼬리물기카메라
      if (Play_TailgatingGuide())
        return;
    }
    else if (IsLaneCheckGuide(cpf_type)) {
      // 지정차로단속
      if (Play_LaneCheckGuide())
        return;
    }
    */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSafeIndex, safeDrives]);
}
export default useCameraSoundWithRoute;

