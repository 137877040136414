import { createApi, fetchBaseQuery, FetchArgs, BaseQueryApi } from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/store";
import { SERVER_API_URL } from "../app/config/const";
import { clearUserToken } from "./token/tokenSlice";

export interface ApiResponse<T> {
  code: string;
  message: string;
  response: T;
}

export interface ApiErrorResponse {
  data: { timestamp: string; status: number; error: string; path: string };
  status: number;
}

// const baseQuery = fetchBaseQuery({
//   baseUrl: SERVER_API_URL,
//   prepareHeaders: (headers, { getState }) => {
//     const { token } = (getState() as RootState).token;
//     if (token) {
//       headers.set("X-AUTH-TOKEN", token);
//     }
//   },
// });
// s: string | FetchArgs, api: BaseQueryApi, extraOptions: {}

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, any>,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: SERVER_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).token;
      if (token) {
        headers.set("X-AUTH-TOKEN", token);
      }
    },
  });

  const result = await baseQuery(args, api, extraOptions);

  // 401
  if (result.error?.status === 401) {
    api.dispatch(clearUserToken());
  }

  return result;
};

const baseApi = createApi({
  reducerPath: "baseApi",
  // baseQuery,
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ["token", "users", "settings", "favorites", "history"],
});

export default baseApi;
