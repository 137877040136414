/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import Dexie from "dexie";
import GeoJSON from "geojson";
import * as turf from "@turf/turf";
import LRUCache from "./LRUCache";
import { OSM_PBF_TILE_LEVEL } from "../../app/config/const";
import {
  GEOLOCATION_DISTANCE_STAY_THRESHOLD,
  SNAPPING_DISTANCE_THRESHOLD,
} from "../../app/config/geolocation";
import { tileKey, tilesOnBBox } from "../osm/osmUtils";
import { isMovableDirection } from "../match/wayDirectionUtils";
import { GeoLocation } from "../geolocation/geoLocationSlice";
import Logger from "../../app/logger";

const OSM_CACHE_DB_VERSION = 6; // must be integer
export const NODES = "nodes";
export const WAYS = "ways";
export const RELATIONS = "relations";
export const TILES = "tiles";

// 인터페이스 정의
export interface IWay {
  id: number;
  feature: GeoJSON.Feature<GeoJSON.LineString>;
}

export interface WayProperties {
  snode_idx: string;
  guideInfo2: string;
  maxspeed: string;
  guideInfo1: string;
  length: string;
  idxname: string;
  link_idx: string;
  dir: string;
  enode_idx: string;
  link_cate: string;
  link_facil: string;
  name: string;
  lanes: string;
  road_level: string;
  highway: string;
}

export interface OSMNode {
  id: number;
  tile: string;
  lat: number;
  lon: number;
  tags?: { [key: string]: string };
}

export interface OSMWay {
  id: number;
  tiles: string[];
  minLon: number;
  minLat: number;
  maxLon: number;
  maxLat: number;
  nodes: number[];
  tags: { [key: string]: string }; // 선택적 속성 제거
  geometry?: GeoJSON.LineString;
}

export interface OSMRelation {
  id: number;
  tile: string;
  members: Array<{ type: "node" | "way" | "relation"; ref: number; role: string }>;
  tags: { [key: string]: string }; // 선택적 속성 제거
}

export interface OSMTile {
  id: string; // 예: "15/27782/12711"  tileKey(x, y, zoom)로 생성
  x: number;
  y: number;
  zoom: number;
  lastUpdated: number; // 타일이 마지막으로 업데이트된 시간
}

export interface WayTile {
  ways: IWay[];
  lastAccessed: number;
}

export class OsmCache extends Dexie {
  nodes!: Dexie.Table<OSMNode, number>;

  ways!: Dexie.Table<OSMWay, number>;

  relations!: Dexie.Table<OSMRelation, number>;

  tiles!: Dexie.Table<OSMTile, string>;

  private osmWayCache: LRUCache<number, OSMWay> = new LRUCache(50000);

  private wayTileCache: LRUCache<string, WayTile> = new LRUCache(1000);

  constructor() {
    super("OsmCache");
    this.version(OSM_CACHE_DB_VERSION).stores({
      [NODES]: "id, tile, lat, lon",
      [WAYS]: "id, *tiles, minLon, minLat, maxLon, maxLat, *nodes",
      [RELATIONS]: "id, tile, *members",
      [TILES]: "id, x, y, zoom, lastUpdated",
    });
  }

  async initializeDatabase() {
    try {
      await this.transaction("rw", this[NODES], this[WAYS], this[RELATIONS], async () => {
        await this[NODES].clear();
        await this[WAYS].clear();
        await this[RELATIONS].clear();
        await this[TILES].clear();
      });
      Logger.info("Database initialized successfully");
    } catch (error) {
      Logger.error("Failed to initialize database:", error);
      throw error;
    }
  }

  async updateTileAccessDate(tileId: string): Promise<void> {
    await this[TILES].update(tileId, { lastUpdated: Date.now() });
  }

  async cleanupOldTiles(limit: number = 1000): Promise<void> {
    const startTime = performance.now();
    // const thirtyDaysAgo = Date.now() - 30 * 24 * 60 * 60 * 1000; // 30일 전의 타임스탬프
    const thirtyDaysAgo = Date.now() - 3 * 24 * 60 * 60 * 1000; // 3일 전의 타임스탬프
    const allTiles = await this[TILES].where("lastUpdated").below(thirtyDaysAgo).toArray();

    if (allTiles.length > limit) {
      const tilesToDelete = allTiles.slice(limit);

      await this.transaction(
        "rw",
        this[TILES],
        this[WAYS],
        this[NODES],
        this[RELATIONS],
        async () => {
          for (const tile of tilesToDelete) {
            const tileId = tile.id;

            // 타일 삭제
            await this[TILES].delete(tileId);

            // 해당 타일에 속한 way 중 하나의 타일에만 속한 way 삭제
            const waysToDelete = await this[WAYS].where("tiles")
              .equals(tileId)
              .filter((way) => way.tiles.length === 1)
              .toArray();

            if (waysToDelete.length > 0) {
              await this[WAYS].bulkDelete(waysToDelete.map((way) => way.id));
            }

            // 해당 타일에 속한 node 삭제
            await this[NODES].where("tile").equals(tileId).delete();

            // 해당 타일에 속한 relation 삭제
            await this[RELATIONS].where("tile").equals(tileId).delete();
          }
        },
      );
    }

    const elapsedTime = performance.now() - startTime;
    if (elapsedTime > 100) console.warn(`cleanupOldTiles() took ${Math.round(elapsedTime)} ms`);
  }

  async bulkWaysPut(tileId: string, ways: OSMWay[]): Promise<void> {
    try {
      // 1. 모든 way의 ID를 추출
      const wayIds = ways.map((way) => way.id);

      // 2. 기존에 존재하는 way들을 가져옴
      const existingWays = await osmCacheDB.ways.bulkGet(wayIds);

      // 3. 새로운 way와 기존 way를 처리
      const waysToUpdate = ways.map((way, index) => {
        const existingWay = existingWays[index];
        if (existingWay) {
          // 기존 way가 존재하면 tiles 업데이트
          return {
            ...existingWay,
            tiles: Array.isArray(existingWay.tiles)
              ? [...new Set([...existingWay.tiles, tileId])]
              : [tileId],
          };
        }
        // 새로운 way면 tileId만 포함한 tiles 배열 추가
        return {
          ...way,
          tiles: [tileId],
        };
      });

      // 4. 모든 way를 bulkPut으로 저장 또는 업데이트
      await osmCacheDB.ways.bulkPut(waysToUpdate);
    } catch (error) {
      console.error(`Error in bulkWaysAdd for tile ${tileId}:`, error);
      throw error;
    }
  }

  private async cacheWaysOn(tileId: string): Promise<WayTile | undefined> {
    const isTileIn = await this[TILES].get(tileId);
    if (isTileIn) {
      const ways = await this.loadWayTileOn(tileId);
      this.updateTileAccessDate(tileId).catch(console.error); // async하게 처리하기 위해 catch 사용
      return {
        ways,
        lastAccessed: Date.now(),
      };
    }
    return undefined;
  }

  /**
   * @brief 주어진 조건에 따라 가장 가까운 way를 찾습니다.
   * @param { GeoJSON.Point } point 기준점
   * @param { number } radius 검색 반경 (미터 단위)
   * @param { (way: IWay) => boolean } filterFn way를 필터링하는 함수
   * @returns { Promise<IWay | undefined> } 가장 가까운 way 또는 undefined
   */
  private async findNearestWay(
    point: GeoJSON.Point,
    radius: number,
    filterFn: (way: IWay) => boolean,
  ): Promise<IWay | undefined> {
    const bbox = OsmCache.BufferedBBoxOf(point, radius);
    const ways = await this.waysOn(bbox);

    let minDistance = Infinity;
    let minWay: IWay | undefined;

    for (let i = 0; i < ways.length; i++) {
      const way = ways[i];
      if (!way || !filterFn(way)) continue;

      const pointOnLine = turf.nearestPointOnLine(way.feature.geometry, point, { units: "meters" });
      const distance = pointOnLine.properties!.dist!;
      if (distance < minDistance) {
        minDistance = distance;
        minWay = way;
      }
    }

    return minWay;
  }

  /**
   * @brief 주어진 점에서 가장 가까운 way를 찾습니다.
   * @param { GeoJSON.Point } point 기준점
   * @param { number } radius 검색 반경 (미터 단위)
   * @returns { Promise<IWay | undefined> } 가장 가까운 way 또는 undefined
   */
  async nearestLinkOnPoint(point: GeoJSON.Point, radius: number): Promise<IWay | undefined> {
    return this.findNearestWay(point, radius, () => true);
  }

  /**
   * @brief 주어진 점에서 가장 가까운, 같은 방향의 way를 찾습니다.
   * @param { GeoJSON.Point } point 기준점
   * @param { number } heading 진행 방향 (도 단위)
   * @param { number } radius 검색 반경 (미터 단위)
   * @returns { Promise<IWay | undefined> } 가장 가까운 way 또는 undefined
   */
  public async nearestSameDirLinkOnPoint(
    point: GeoJSON.Point,
    heading: number,
    radius: number,
  ): Promise<IWay | undefined> {
    return this.findNearestWay(point, radius, (way) => {
      const { movable } = isMovableDirection(
        heading,
        way.feature.properties?.oneway,
        way.feature.geometry,
      );
      return movable;
    });
  }

  async getWay(wayId: number): Promise<IWay | undefined> {
    const way = await this.getOsmWay(wayId);
    if (way) {
      return this.convertToIWay(way);
    }
    return undefined;
  }

  public async getOsmWay(wayId: number): Promise<OSMWay | undefined> {
    const cachedWay = this.osmWayCache.get(wayId);
    if (cachedWay) {
      return cachedWay;
    }

    // way ID로 way 데이터 가져오기
    const way = await this[WAYS].get(wayId);

    if (!way) {
      return undefined; // way가 존재하지 않으면 undefined 반환
    }

    // geometry가 없는 경우 생성
    if (!way.geometry) {
      const nodes = await this[NODES].bulkGet(way.nodes);
      const coordinates = nodes
        .filter((node): node is NonNullable<typeof node> => node !== undefined)
        .map((node) => [node.lon, node.lat]);

      if (coordinates.length >= 2) {
        way.geometry = {
          type: "LineString",
          coordinates,
        };
      } else {
        return undefined;
      }
      await this[WAYS].update(wayId, { geometry: way.geometry });
    }

    this.osmWayCache.put(wayId, way);
    return way;
  }

  /**
   * @brief 중심점(point)을 기준으로 distance 만큼의 bbox를 계산합니다.
   * @param { GeoJSON.Point } point 중심점
   * @param { number } distance 중심점으로부터의 meter 거리
   * @returns { GeoJSON.BBox } 계산된 bounding box
   */
  public static BufferedBBoxOf(point: GeoJSON.Point, distance: number): GeoJSON.BBox {
    const [lon, lat] = point.coordinates;

    // 지구의 반경 (미터)
    const R = 6378137;

    // 위도 1도의 미터 거리
    const metersPerLatDegree = 111319.9;

    // 경도 방향으로의 거리 계산
    const latDelta = distance / metersPerLatDegree;

    // 경도 방향으로의 거리 계산 (위도에 따라 달라짐)
    const lonDelta = distance / ((R * Math.cos((lat * Math.PI) / 180) * Math.PI) / 180);

    // bbox 계산
    const minLon = lon - lonDelta;
    const minLat = lat - latDelta;
    const maxLon = lon + lonDelta;
    const maxLat = lat + latDelta;

    return [minLon, minLat, maxLon, maxLat];
  }

  public async loadWayTileOn(tileId: string): Promise<IWay[]> {
    const startTime = performance.now();
    // tileId의 ways를 가져오기
    const ways = await this[WAYS].where("tiles").equals(tileId).toArray();

    return ways
      .filter((way) => way !== undefined)
      .map((way) => {
        this.osmWayCache.put(way.id, way); // Add to cache
        return this.convertToIWay(way!);
      });
  }

  /**
   * @brief bbox 안과 걸쳐져(intersect) 있는 link 정보를 모두 가져옵니다.
   * @param { GeoJSON.BBox } bbox bound box
   * @returns { Promise<IWay[]> } bbox와 걸쳐 있는 way들
   */
  public async waysOn(bbox: GeoJSON.BBox): Promise<IWay[]> {
    const startTime = performance.now();
    let isCached = true;

    const tiles = tilesOnBBox(bbox, OSM_PBF_TILE_LEVEL);
    const bboxPolygon = turf.bboxPolygon(bbox);

    const wayPromises = tiles.map(async (tile) => {
      const tileId = tileKey(tile.x, tile.y, tile.z);
      let tileData = this.wayTileCache.get(tileId);

      if (!tileData) {
        tileData = await this.cacheWaysOn(tileId);
        if (!tileData) return [];
        this.wayTileCache.put(tileId, tileData);
        isCached = false;
      } else {
        tileData.lastAccessed = Date.now();
      }

      return tileData.ways.filter((way) => turf.booleanIntersects(way.feature, bboxPolygon));
    });

    const waysArray = await Promise.all(wayPromises);
    const result = waysArray.flat();

    const elapsedTime = performance.now() - startTime;
    if (elapsedTime > 100)
      console.warn(
        `waysOn() took ${Math.round(elapsedTime)} ms with ${result.length} ways in ${isCached ? "cached" : "uncached"} mode`,
      );

    return result;
  }

  public async fetchWayAroundOn(
    point: GeoJSON.Point,
    geolocation: GeoLocation,
    radius: number = SNAPPING_DISTANCE_THRESHOLD,
  ): Promise<IWay | undefined> {
    let way: IWay | undefined;
    if (!geolocation.speed || geolocation.speed < GEOLOCATION_DISTANCE_STAY_THRESHOLD) {
      way = await this.nearestLinkOnPoint(point, radius);
    } else {
      way = await this.nearestSameDirLinkOnPoint(point, geolocation.heading, radius);
    }
    return way;
  }

  public async getWays(wayIds: number[]): Promise<(IWay | undefined)[]> {
    const cachedWays = wayIds.map((id) => this.osmWayCache.get(id));
    const missingWayIds = wayIds.filter((_, index) => !cachedWays[index]);

    if (missingWayIds.length > 0) {
      const fetchedWays = await this[WAYS].bulkGet(missingWayIds);
      const processedWays = fetchedWays
        .filter((way) => way !== undefined)
        .map((way) => {
          const iway: IWay = this.convertToIWay(way!);
          this.osmWayCache.put(way!.id, way!);
          return iway;
        });

      let processedIndex = 0;
      return wayIds.map((id) => {
        const cachedWay = this.osmWayCache.get(id);
        if (cachedWay) return this.convertToIWay(cachedWay);
        return processedWays[processedIndex++];
      });
    }
    const result = cachedWays.map((way) => (way ? this.convertToIWay(way) : undefined));
    return result;
  }

  private convertToIWay(way: OSMWay): IWay {
    return {
      id: way.id,
      feature: {
        type: "Feature",
        properties: way.tags || {},
        geometry: way.geometry as GeoJSON.LineString,
      },
    };
  }
}

export const osmCacheDB = new OsmCache();
