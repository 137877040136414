import React from "react";
import * as turf from "@turf/turf";
import { useTheme } from "@mui/material";
import { Source, Layer, useMap } from "react-map-gl/maplibre";
import { Feature, LineString as GeoJsonLineString } from "geojson";
import { Path } from "../../../route/routeSlice";

type LineStringType = "guide" | "summary" | "route";

interface Props {
  type: LineStringType;
  points?: Path["points"];
  lineWidth: number;
  layerId?: string;
  beforeId?: string;
  selected?: boolean;
  color?: string;
  data?: Feature<GeoJsonLineString>;
}

const LineString = ({
  type,
  points,
  lineWidth,
  layerId,
  beforeId,
  selected,
  color,
  data,
}: Props) => {
  const geojson = turf.feature(points || null);
  const theme = useTheme();
  const map = useMap();
  const layerExists = beforeId ? !!map.current?.getLayer(beforeId) : false;

  let lineColor;
  switch (type) {
    case "summary":
    case "route":
      lineColor = selected ? theme.palette.error.main : theme.palette.grey[500];
      break;
    case "guide":
      return (
        <Source type="geojson" data={data}>
          <Layer
            type="line"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": theme.palette.primary.main,
              "line-width": lineWidth,
            }}
            beforeId="River labels"
          />
          {/* <Layer
            beforeId="River labels"
            type="line"
            layout={{
              "line-join": "round",
            }}
            paint={{
              "line-color": theme.palette.background.paper,
              "line-width": 3,
              "line-dasharray": [2, 2],
            }}
          /> */}
        </Source>
      );
    default:
      break;
  }

  if (color) {
    lineColor = color;
  }

  return (
    <Source type="geojson" data={geojson}>
      <Layer
        id={layerId}
        beforeId={layerExists ? beforeId : undefined}
        type="line"
        layout={{ "line-join": "round" }}
        paint={{
          "line-color": lineColor,
          "line-width": selected ? lineWidth : lineWidth - 5,
          "line-opacity": selected ? 1 : 0.8,
        }}
      />
    </Source>
  );
};

export default LineString;
