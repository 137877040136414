import { GeoLocation } from "../../geolocation/geoLocationSlice";

const gpsAngleToDegree = (gpsVal: string[]): number => {
  const gpsVal1 = parseFloat(gpsVal[0]);
  const gpsVal2 = parseFloat(`0.${gpsVal[1]}`);

  const deg = Math.floor(gpsVal1 / 100);
  const min = gpsVal1 - deg * 100;
  // const sec = Math.floor(gpsVal2 * 60.);
  // const msec = Math.floor((gpsVal2 * 60. - sec) * 1000 + 0.5);

  return deg + min / 60.0 + gpsVal2 / 60.0;
};
// Geolocation에서 사용되는 정보만 가져오기 위해 RMC와 GSA만 사용함.
export default async function parseNmeaToPoints(nmeaFile: File): Promise<GeoLocation[]> {
  const checkGP = nmeaFile.slice(0, 86); // NMEA 한주길이는 최대 83바이트이고 $GP 체크를 위해 +3, 마지막으로 85번째 인덱스 데이터를 포함하기 위해 또 +1
  const checkHeader = (await checkGP.text()).toString();
  const isGPIncluded = checkHeader.includes("$GP") || checkHeader.includes("$GN");
  if (!isGPIncluded) {
    return Promise.resolve([]);
  }

  return new Promise((resolve) => {
    const KNOT2KM = 1.8519998;

    const reader = new FileReader();
    reader.readAsText(nmeaFile);
    reader.onload = () => {
      const lines = reader.result?.toString().split("\n");
      if (!lines) {
        resolve([]);
        return;
      }

      // Process each line of the NMEA file
      let geoLocatinItem: GeoLocation | null = null;
      const geoLocations: GeoLocation[] = [];
      lines.forEach((line) => {
        if (line.includes("$GPGSA") || line.includes("$GNGSA")) {
          const parts = line.split(",");
          if (parts.length < 18 || (parts[0] !== "$GPGSA" && parts[0] !== "$GNGSA")) {
            return;
          }
          const hdop = parseFloat(parts[16]);
          const vdop = parseFloat(parts[17]);
          // const pdop = parseFloat(parts[15]);

          geoLocatinItem = {
            latitude: 0,
            longitude: 0,
            speed: 0,
            heading: 0,
            timestamp: 0,
            accuracy: Number.isNaN(hdop)?0:hdop * 3,
            altitude: 0,
            altitudeAccuracy: Number.isNaN(vdop)?0:vdop,
          };
        } else if ((line.includes("$GPRMC") || line.includes("$GNRMC")) && geoLocatinItem != null) {
          const parts = line.split(",");
          if (parts.length < 10 || (parts[0] !== "$GPRMC" && parts[0] !== "$GNRMC")) {
            return;
          }

          const latitude = gpsAngleToDegree(parts[3].split("."));
          const longitude = gpsAngleToDegree(parts[5].split("."));
          const speed = parseFloat(parts[7]) * KNOT2KM;
          const heading = parseFloat(parts[8]) || 0;

          const rawDate = parts[9];
          const rawTime = parts[1];
          const day = parseInt(rawDate.slice(0, 2), 10);
          const month = parseInt(rawDate.slice(2, 4), 10);
          const year = parseInt(rawDate.slice(4, 6), 10) + 2000;
          const hour = parseInt(rawTime.slice(0, 2), 10);
          const minute = parseInt(rawTime.slice(2, 4), 10);
          const second = parseInt(rawTime.slice(4, 6), 10);
          const timestamp = new Date(Date.UTC(year, month - 1, day, hour, minute, second)).getTime();

          geoLocatinItem.latitude = Number.isNaN(latitude) ? 0 : latitude;
          geoLocatinItem.longitude = Number.isNaN(longitude) ? 0 : longitude;
          geoLocatinItem.speed = Number.isNaN(speed) ? 0 : speed;
          geoLocatinItem.heading = Number.isNaN(heading) ? 0 : heading;
          geoLocatinItem.timestamp = Number.isNaN(timestamp) ? 0 : timestamp;
          geoLocatinItem.altitude = 0;
          
          if (geoLocatinItem.latitude === 0 && geoLocatinItem.longitude === 0 && geoLocatinItem.timestamp === 0) {
            geoLocatinItem = null;
            return;
          }
          geoLocations.push(geoLocatinItem);
          geoLocatinItem = null;
        }
      });

      // Return the array of GeoLocation objects
      resolve(geoLocations);
    };
  });
}
