import React from "react";
import { Bookmark, ExitToApp, Settings } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clearUserToken, selectToken } from "../token/tokenSlice";
import { useGetUserInfoQuery } from "../token/userApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ProfileDrawer = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // 현재 로그인 토큰 정보
  const { token } = useAppSelector(selectToken);
  // {created_at, join_type, nickname, role, user_id}
  const { data } = useGetUserInfoQuery(undefined, {
    skip: Boolean(!token),
    refetchOnMountOrArgChange: true,
  });

  // 로그아웃
  const handleClickSignOut = () => {
    navigate("/signin");
    dispatch(clearUserToken());
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={180}
      >
        <Avatar />
        {data?.nickname ? (
          <>
            <Typography variant="h6" component="span">
              {data.nickname}
            </Typography>
            <Box mt={2}>
              <Typography onClick={handleClickSignOut}>{t("Log out")}</Typography>
            </Box>
          </>
        ) : (
          <Typography
            variant="h6"
            component="span"
            onClick={() => navigate("/signin")}
            style={{ textDecoration: "underline" }}
          >
            로그인 하기
          </Typography>
        )}
      </Box>
      <Divider />
      <List>
        <ListItem divider disableGutters disablePadding>
          <ListItemButton onClick={() => navigate("/setting")}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary={t("Settings")} />
          </ListItemButton>
        </ListItem>
        {/* <ListItem divider disableGutters disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Comment />
            </ListItemIcon>
            <ListItemText primary={t("Help & Feeback")} />
          </ListItemButton>
        </ListItem> */}
        <ListItem divider disableGutters disablePadding>
          {/* <ListItemButton onClick={() => navigate("/bookmark")}> */}
          <ListItemButton
            onClick={() => {
              if (token) {
                navigate("/bookmark");
              } else {
                navigate("/signin");
              }
            }}
          >
            <ListItemIcon>
              <Bookmark />
            </ListItemIcon>
            <ListItemText primary="즐겨찾기" />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary={t("Exit")} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default ProfileDrawer;
