import React, { ReactNode } from "react";
import { Draggable as HDraggable } from "@hello-pangea/dnd";

interface Props {
  draggableId: string;
  children: ReactNode;
  index: number;
  isDragDisabled?: boolean;
}

const Draggable = ({ draggableId, children, index, isDragDisabled }: Props) => {
  return (
    <HDraggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {children}
        </div>
      )}
    </HDraggable>
  );
};

export default Draggable;
