// 경로 번호용 아이콘
import React, { ReactNode } from "react";
import { ListItemAvatar, styled } from "@mui/material";

const StyledAvatar = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  zIndex: 1,
  width: 32,
  height: 32,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.error.main,
  textTransform: "uppercase",
  fontSize: theme.typography.caption.fontSize,
  fontWeight: "bold",
  userSelect: "none",
  cursor: "pointer",
  boxShadow: theme.shadows[2],
  background: theme.palette.background.paper,
  borderStyle: "solid",
  borderWidth: 2,
  transition: "0.3s all",
  ...(active && {
    border: "none",
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  }),
}));

interface ItemIndexProps {
  active: boolean; // 버튼 활성화 플래그
  children: ReactNode;
}

const ItemIndexAvatar = ({ active, children }: ItemIndexProps) => (
  <ListItemAvatar>
    <StyledAvatar active={active}>{children}</StyledAvatar>
  </ListItemAvatar>
);

export default ItemIndexAvatar;
