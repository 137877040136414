import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { LineString, Position } from "geojson";
import { ROUTE_SERVER_URL } from "../../app/config/const";
import i18n from "../../app/locales/i18n";
import type { RootState } from "../../app/store";
import { Place } from "../search/searchSlice";
import { Waypoint } from "../waypoint/waypointSlice";

export enum RouteType {
  Normal = 0,
  AutoReRoute = 1,
  ReRoute = 2,
}

export type Bbox = [number, number, number, number];

export interface Path extends BasePath {
  readonly points: LineString;
  readonly snapped_waypoints: LineString;
}

export interface Instruction {
  readonly distance: number;
  readonly interval: [number, number];
  readonly points: number[][];
  readonly sign: number;
  readonly text: string;
  readonly time: number;
  readonly street_name?: string;
  readonly exit_number?: number; // dckim 2024-05-24 - 회전교차로용.
  readonly turn_angle?: number; // dckim 2024-05-24 - 회전교차로용.
}

export interface Details {
  readonly street_name: [number, number, string][];
  readonly toll: [number, number, string][];
  readonly max_speed: [number, number, number][];
  readonly road_class: [number, number, string][];
  readonly road_access: [number, number, string][];
  readonly road_environment: [number, number, string][];
  readonly track_type: [number, number, string][];
  readonly country: [number, number, string][];
  readonly get_off_bike: [number, number, boolean][];
  readonly osm_way_id: number[][];
}

interface SafeRoad {
  readonly sk_spt_id: number;
  readonly start_point_idx: number;
  readonly end_point_idx: number;
  readonly pos: number[];
  readonly dist: number;
  readonly angle: number;
  readonly speed: number;
  readonly type: string;
  readonly road_type: string;
}

interface RoadDetails {
  readonly last_idx: number;
  readonly road_class: string;
  readonly properties: string[];
  readonly lane: number;
  readonly speed_limit: number | string; // 숫자형아니면 "Infinity"문자열로 날아옴.
}

export interface PointIdxForGuide {
  readonly point_idx: number;
}

export interface DirGuide extends PointIdxForGuide {
  // point_idx순으로 정렬 후 bsearch를 용이하게 하기위해 point_idx는 공통 형식으로 뺌.
  readonly short_name: string;
  readonly mid_name: string;
  readonly long_name: string;
}

export interface JunctiovView extends PointIdxForGuide {
  // point_idx순으로 정렬 후 bsearch를 용이하게 하기위해 point_idx는 공통 형식으로 뺌.
  readonly day_img: string;
  readonly night_img: string;
}

export interface WayInfo {
  readonly id: number;
  readonly start_idx: number;
  readonly end_idx: number;
  readonly length: number;
  readonly direction: boolean;
}

export interface RoadNames {
  readonly last_idx: number;
  readonly text: string;
}

interface RousenDetail {
  readonly way_info: WayInfo[];
  readonly road_names: RoadNames[];
  readonly dir_guide: DirGuide[];
  // readonly junctiov_view: JunctiovView[];
  readonly junction_view: JunctiovView[];
  readonly safe_road?: SafeRoad[];
  readonly road_details?: RoadDetails[];
}

export interface BasePath {
  readonly distance: number;
  readonly time: number;
  readonly ascend: number;
  readonly descend: number;
  readonly points_encoded: boolean;
  readonly bbox?: Bbox;
  readonly instructions: Instruction[];
  readonly details?: Details;
  readonly points_order: number[];
  readonly description: string;
  readonly weight: number;
  readonly rousen_details?: RousenDetail;
}

export interface RoutingResult {
  readonly info: { copyright: string[]; took: number };
  readonly paths: Path[];
}

export interface ReRouteArgs {
  waypoints: Waypoint[];
  startHeading: number | null; // 출발지 방향
  destHeading: number | null; // 목적지 방향
}

interface State {
  paths: Path[];
  status: "pending" | "fulfilled" | "rejected";
  activePath: Path | null;
  activePathIndex: number;
  isReRoute: RouteType; // 0: 일반 경로 탐색, 1: 경로 이탈 재탐색, 2: 사용자 경로 재탐색
  error: string | null;
}

const initialState: State = {
  paths: [],
  status: "fulfilled",
  activePathIndex: 0,
  activePath: null,
  isReRoute: RouteType.Normal,
  error: null,
};

const fetchRoute = (points: number[][]) =>
  new Promise<Path[]>((resolve, reject) => {
    (async () => {
      try {
        let locale = "ko";
        if (i18n.language === "zhtw") locale = "zh-TW";
        else if (i18n.language === "en") locale = "en";

        const response: AxiosResponse<RoutingResult> = await axios({
          url: `${ROUTE_SERVER_URL}/route`,
          method: "post",
          data: {
            points,
            points_encoded: false,
            locale,
            ...(points.length < 3 && {
              algorithm: "alternative_route",
            }),
          },
        });
        resolve(response.data.paths);
      } catch (error) {
        reject(error);
      }
    })();
  });

// 재탐색의 경우 경로가 하나만 탐색되도록 하기 위해 alternative_route 알고리즘을 사용하지 않도록 함
const fetchReRoute = (
  points: number[][],
  startHeading: number | null,
  destHeading: number | null,
) =>
  new Promise<Path[]>((resolve, reject) => {
    (async () => {
      try {
        let locale = "ko";
        if (i18n.language === "zhtw") locale = "zh-TW";
        else if (i18n.language === "en") locale = "en";

        // TODO: 현재 경유지, 목적지의 방향성은 고려할 수 없음. 추후 고려가 가능하도록 수정이 필요함
        // 경유지, 목적지에 붙어있는 링크의 정보를 가지고 올 수 있어야 함

        const response: AxiosResponse<RoutingResult> = await axios({
          url: `${ROUTE_SERVER_URL}/route`,
          method: "post",
          data: {
            points,
            points_encoded: false,
            locale,
            ...(startHeading !== null && { vehicle_direction: startHeading }),
          },
          headers: {
            // "x-api-key": API_KEY,
          },
        });
        resolve(response.data.paths);
      } catch (error) {
        reject(error);
      }
    })();
  });

// 이탈 재탐색의 경우 경로가 하나만 탐색되도록 하기 위해 alternative_route 알고리즘을 사용하지 않도록 함
const fetchAutoReRoute = (
  points: number[][],
  startHeading: number | null,
  destHeading: number | null,
) => fetchReRoute(points, startHeading, destHeading);

export const fetchRouteByWaypoints = createAsyncThunk<Path[], Waypoint[]>(
  "route/fetchByPoints",
  async (waypoints, { rejectWithValue }) => {
    try {
      const filteredPoints = waypoints
        .filter((waypoint) => waypoint.place)
        .map((waypoint) => [waypoint.place!.point.lng, waypoint.place!.point.lat]) as Position[];

      if (filteredPoints.length < 2) {
        return [];
      }

      const response = await fetchRoute(filteredPoints);

      return response;
    } catch (error) {
      let errMsg: string = "Failed to fetch route";
      if (error instanceof AxiosError) {
        errMsg = `(Code ${error.response?.data.code}) ${error.response?.data.message}`;
      }
      return rejectWithValue(errMsg);
    }
  },
);

export const fetchReRouteByWaypoints = createAsyncThunk<Path[], ReRouteArgs>(
  "route/fetchReByPoints",
  async (args, { rejectWithValue }) => {
    try {
      const promiseArr: Promise<Path[]>[] = [];
      const filteredPoints = args.waypoints
        .filter((waypoint) => waypoint.place)
        .map((waypoint) => waypoint.place?.point) as Place["point"][];

      if (filteredPoints.length < 2) {
        return [];
      }

      // 포인트별로 경로탐색을 따로할 이유가 없으므로 한번에 처리.
      promiseArr.push(
        fetchReRoute(
          filteredPoints.map((point) => [point.lng, point.lat]),
          args.startHeading,
          args.destHeading,
        ),
      );
      const responses = await Promise.all(promiseArr);
      return responses[0];
    } catch (error) {
      let errMsg: string = "Failed to fetch route";
      if (error instanceof AxiosError) {
        errMsg = `(Code ${error.response?.data.code}) ${error.response?.data.message}`;
      }
      return rejectWithValue(errMsg);
    }
  },
);

export const fetchAutoReRouteByWaypoints = createAsyncThunk<Path[], ReRouteArgs>(
  "route/fetchAutoReByPoints",
  async (args, { rejectWithValue }) => {
    try {
      const promiseArr: Promise<Path[]>[] = [];
      const filteredPoints = args.waypoints
        .filter((waypoint) => waypoint.place)
        .map((waypoint) => waypoint.place?.point) as Place["point"][];

      if (filteredPoints.length < 2) {
        return [];
      }

      // 포인트별로 경로탐색을 따로할 이유가 없으므로 한번에 처리.
      promiseArr.push(
        fetchAutoReRoute(
          filteredPoints.map((point) => [point.lng, point.lat]),
          args.startHeading,
          args.destHeading,
        ),
      );
      const responses = await Promise.all(promiseArr);
      return responses[0];
    } catch (error) {
      let errMsg: string = "Failed to fetch route";
      if (error instanceof AxiosError) {
        errMsg = `(Code ${error.response?.data.code}) ${error.response?.data.message}`;
      }
      return rejectWithValue(errMsg);
    }
  },
);

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setPaths: (state, action: PayloadAction<Path[]>) => {
      state.paths = action.payload;
      [state.activePath] = action.payload || null;
      state.activePathIndex = 0;
    },
    clearPaths: (state) => {
      state.paths = [];
      state.status = "fulfilled";
      state.activePathIndex = 0;
      state.activePath = null;
    },
    setActivePathIndex: (state, action: PayloadAction<number>) => {
      state.activePathIndex = action.payload;
      state.activePath = state.paths[action.payload];
    },
    setRouteStatusOnly: (state, action: PayloadAction<"pending" | "fulfilled" | "rejected">) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRouteByWaypoints.pending, (state) => {
      state.status = "pending";
      state.isReRoute = RouteType.Normal;
      state.error = null;
    });
    builder.addCase(fetchRouteByWaypoints.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.paths = action.payload;
      [state.activePath] = action.payload || null;
      state.activePathIndex = 0;
      state.isReRoute = RouteType.Normal;
      state.error = null;
    });
    builder.addCase(fetchRouteByWaypoints.rejected, (state, action) => {
      state.status = "rejected";
      state.isReRoute = RouteType.Normal;
      if (typeof action.payload ==="string") {
        state.error = action.payload;
      } else {
        state.error = "Unknown error";
      }
    });
    builder.addCase(fetchAutoReRouteByWaypoints.pending, (state) => {
      state.status = "pending";
      state.isReRoute = RouteType.AutoReRoute;
      state.error = null;
    });
    builder.addCase(fetchAutoReRouteByWaypoints.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.paths = action.payload;
      [state.activePath] = action.payload || null;
      state.activePathIndex = 0;
      state.isReRoute = RouteType.AutoReRoute;
      state.error = null;
    });
    builder.addCase(fetchAutoReRouteByWaypoints.rejected, (state, action) => {
      state.status = "rejected";
      state.isReRoute = RouteType.AutoReRoute;
      if (typeof action.payload ==="string") {
        state.error = action.payload;
      } else {
        state.error = "Unknown error";
      }
    });
    builder.addCase(fetchReRouteByWaypoints.pending, (state) => {
      state.status = "pending";
      state.isReRoute = RouteType.ReRoute;
      state.error = null;
    });
    builder.addCase(fetchReRouteByWaypoints.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.paths = action.payload;
      [state.activePath] = action.payload || null;
      state.activePathIndex = 0;
      state.isReRoute = RouteType.ReRoute;
      state.error = null;
    });
    builder.addCase(fetchReRouteByWaypoints.rejected, (state, action) => {
      state.status = "rejected";
      state.isReRoute = RouteType.ReRoute;
      if (typeof action.payload ==="string") {
        state.error = action.payload;
      } else {
        state.error = "Unknown error";
      }
    });
  },
});

export const selectRoute = (state: RootState) => state.route;
export const { setPaths, clearPaths, setActivePathIndex, setRouteStatusOnly } = routeSlice.actions;
export default routeSlice;
