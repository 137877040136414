import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { DeleteOutline } from "@mui/icons-material";
import { Place } from "./searchSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addHistory, removeHistory } from "./history/historySlice";
import { getFormattedDistance } from "../guide/guideUtils";
import CircleIconButton from "../../components/CircleIconButton";
import { selectToken } from "../token/tokenSlice";
import { useDeleteHistoryMutation, usePostHistoryMutation } from "./history/historyApi";
import { locationImgUrl, timerImgUrl } from "../../app/config/images";

interface Props {
  type: "history" | "search";
  place: Place;
  divider?: boolean;
  addressType?: string;
  disabled?: boolean;
  onClick: (place: Place, addressType: string) => void;
}

const SearchListItem = ({
  type,
  place,
  divider = false,
  addressType,
  disabled = false,
  onClick,
}: Props) => {
  const { name, roadAddress, jibunAddress, distance } = place;
  const theme = useTheme();

  const { token } = useAppSelector(selectToken);
  const [createHistory] = usePostHistoryMutation();
  const [deleteHistory] = useDeleteHistoryMutation();

  const dispatch = useAppDispatch();
  const handleClickRemove = () => {
    dispatch(removeHistory(place));
    if (token) {
      deleteHistory(place.id);
    }
  };

  // 검색 결과/이력 클릭 시
  const handleClickPlace = () => {
    if (!addressType) {
      if (token) {
        createHistory({
          geocode_id: place.id,
          name: place.name,
          lat: place.point.lat,
          lon: place.point.lng,
          address: place.roadAddress || place.jibunAddress,
        });
      } else {
        dispatch(addHistory(place));
      }
    }
    onClick(place, addressType || "");
  };

  if (type === "history")
    return (
      <ListItem
        disablePadding
        secondaryAction={
          <CircleIconButton
            size="medium"
            color={theme.palette.background.default}
            onClick={handleClickRemove}
          >
            <DeleteOutline sx={{ color: theme.palette.text.primary }} fontSize="small" />
          </CircleIconButton>
        }
        divider={divider}
      >
        <ListItemButton onClick={handleClickPlace} disabled={disabled}>
          <ListItemAvatar>
            <img src={timerImgUrl} alt="Timer Icon" style={{ width: 37, height: 37 }} />
          </ListItemAvatar>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ variant: "button" }}
            secondary={roadAddress || jibunAddress}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </ListItemButton>
      </ListItem>
    );

  return (
    <ListItem
      disablePadding
      secondaryAction={<Typography variant="caption">{getFormattedDistance(distance)}</Typography>}
      divider={divider}
    >
      <ListItemButton onClick={handleClickPlace} disabled={disabled}>
        <ListItemAvatar>
          <img src={locationImgUrl} alt="Location Icon" style={{ width: 37, height: 37 }} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: "button" }}
          secondary={roadAddress || jibunAddress}
          secondaryTypographyProps={{ variant: "caption" }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SearchListItem;
