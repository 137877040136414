import { Button, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { forwardRef, memo } from "react";
import Dialog from "./Dialog";

// eslint-disable-next-line prefer-arrow-callback
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

interface Props {
  title: string;
  content?: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const Confirm = ({ onClose, onConfirm, open, title, content }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
      }}
      PaperProps={{
        sx: { m: 0, width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 },
      }}
    >
      <DialogTitle align="center">{title}</DialogTitle>
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Button onClick={onClose} fullWidth variant="contained" color="tertiary" size="large">
          취소
        </Button>
        <Button onClick={onConfirm} fullWidth variant="contained" size="large">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(Confirm);
