import { enqueueSnackbar } from "notistack";
import baseApi, { ApiResponse, ApiErrorResponse } from "../../baseApi";
import { HistoryItem } from "./historySlice";

// history
export interface HistoryItemApi {
  idx: number;
  user_id: number;
  geocode_id: string;
  name: string;
  lat: number;
  lon: number;
  address: string;
  created_at: string;
}

export interface PostHistoryRequest {
  geocode_id: string;
  name: string;
  lat: number;
  lon: number;
  address: string;
}

export interface PostHistoryResponse extends HistoryItemApi {}
export type GetHistoryResponse = HistoryItemApi[];

const transformApiResponseToSlice = (apiResponse: GetHistoryResponse): HistoryItem[] => {
  return apiResponse.map((item) => ({
    place: {
      id: item.geocode_id,
      name: item.name,
      point: {
        lng: item.lon,
        lat: item.lat,
      },
      type: "",
      roadAddress: item.address,
      jibunAddress: "",
      distance: 0,
    },
    created_at: item.created_at,
  }));
};

const historyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // 검색 이력 가져오기
    getHistory: builder.query<HistoryItem[], void>({
      query: () => ({
        url: "recently",
        method: "get",
      }),
      providesTags: ["history"],
      transformResponse: (response: GetHistoryResponse) => transformApiResponseToSlice(response),
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 검색 이력 저장하기
    postHistory: builder.mutation<PostHistoryResponse, PostHistoryRequest>({
      query: (historyItem) => ({
        url: "recently",
        method: "post",
        body: historyItem,
      }),
      invalidatesTags: ["history"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
    // 검색 이력 삭제하기
    deleteHistory: builder.mutation<void, string>({
      query: (geocodeId) => ({
        url: `recently/${geocodeId}`,
        method: "delete",
      }),
      invalidatesTags: ["history"],
      transformErrorResponse: (response: ApiErrorResponse) => {
        enqueueSnackbar({ variant: "error", message: response.data.error });
      },
    }),
  }),
});

export const { useGetHistoryQuery, usePostHistoryMutation, useDeleteHistoryMutation } = historyApi;
export default historyApi;
