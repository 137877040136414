// 하단 메뉴 팝업 - 경로 리스트
import React from "react";
import { Dialog, Typography, Box, Slide, DialogContent, useTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { selectRoute } from "../route/routeSlice";
import DialogTitle from "../../components/DialogTitle";
import SummaryRouteCard from "./SummaryRouteCard";

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement }>(
  (props, ref) => <Slide direction="up" ref={ref} {...props} />,
);

interface SummaryRouteListDialogProps {
  open: boolean;
  onClose: () => void;
}

const SummaryRouteListDialog = ({ open, onClose }: SummaryRouteListDialogProps) => {
  const { paths } = useAppSelector(selectRoute);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle onClose={onClose}>
        <Typography
          sx={{ ml: 2, flexGrow: 1, textAlign: "center", py: 3 }}
          variant="h6"
          component="div"
        >
          {t("Route")}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          bgcolor: theme.palette.background.default,
        }}
      >
        {paths.map((path, index) => (
          <Box key={path.time}>
            <SummaryRouteCard path={path} index={index} />
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default SummaryRouteListDialog;
