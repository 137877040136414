import { DragHandle, ExpandLess, ExpandMore, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

interface Props {
  title: string;
  isHidden: boolean;
  isExpanded: boolean;
  onClickHidden: () => void;
  onClickExpanded: () => void;
}

const DebugCardHeader = ({
  onClickExpanded,
  onClickHidden,
  title,
  isExpanded,
  isHidden,
}: Props) => {
  return (
    <Box display="flex" alignItems="center" p={1}>
      <DragHandle color="action" sx={{ m: 1 }} className="drag-handle" />
      <Typography flex={1} variant="h6" sx={{ userSelect: "none" }} className="drag-handle">
        {title}
      </Typography>
      <IconButton onClick={onClickHidden}>
        {isHidden ? <VisibilityOff /> : <Visibility />}
      </IconButton>
      <IconButton onClick={onClickExpanded}>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    </Box>
  );
};

export default DebugCardHeader;
