import React, { memo, useCallback, useMemo, useState } from "react";
import { Marker } from "react-map-gl/maplibre";
import { useAppSelector } from "../../../../app/hooks";
import { selectBearing } from "../../view/viewSlice";
import { selectViewTransition } from "../../view/transition/viewTransitionSlice";
import ReportPopover from "../../../debug/ReportPopover";
import { selectMapMatch2 } from "../../../match2/MapMatch2Slice";
import * as mapMatchUtils from "../../../match2/MapMatch2Utils";
import { carImgUrl, carTunnelImgUrl } from "../../../../app/config/images";

const Competition = () => {
  const bearing = useAppSelector(selectBearing);
  const { candidates, competition } = useAppSelector(selectMapMatch2);
  if (competition && candidates.length > 1)
    return (
      <Marker
        latitude={candidates[1].y}
        longitude={candidates[1].x}
        pitchAlignment="map"
        rotation={mapMatchUtils.rad2deg(candidates[1].carDegree) - bearing}
        opacity="0.3"
      >
        <img src={carImgUrl} alt="car" width="120" />
      </Marker>
    );
  return null;
};

const Car = () => {
  const bearing = useAppSelector(selectBearing);
  const transition = useAppSelector(selectViewTransition);

  const [showButtons, setShowButtons] = useState<HTMLElement | null>(null);

  const onClosePopover = useCallback(() => setShowButtons(null), []);

  const { tunnelMode } = useAppSelector(selectMapMatch2);
  const imageSrc = useMemo(() => {
    return tunnelMode ? carTunnelImgUrl : carImgUrl;
  }, [tunnelMode]);

  if (!transition) return null;

  const handleClickMarker = (e: any) => {
    setShowButtons(e.target.getElement());
  };

  return (
    <>
      <Competition />
      <Marker
        latitude={transition.current.location.latitude}
        longitude={transition.current.location.longitude}
        pitchAlignment="map"
        rotation={transition.current.location.heading - bearing}
        onClick={handleClickMarker}
      >
        <img src={imageSrc} alt="car" width="120" />
      </Marker>
      <ReportPopover anchorEl={showButtons} onClose={onClosePopover} />
    </>
  );
};

export default memo(Car);
