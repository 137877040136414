import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GeoLocation, setGeoLocation } from "./geoLocationSlice";
import { getCurrentGeolocation } from "./geoLocationUtils";
import { GEOLOCATION_UPDATE_INTERVAL } from "../../app/config/geolocation";
import { isAndroid, requestToNative } from "./native/nativeUtils";
import { selectDebug } from "../debug/debugSlice";
import Debugger from "../debug/Debugger";
import useOsm from "../osm/useOsm";
import useMatcher from "../match/useMatcher";
import useMatcher2 from "../match2/useMatcher2";

type DataType = "geolocation";

declare global {
  interface Window {
    AndroidToWeb: (type: DataType, json: string) => void;
  }
}

interface AndroidGeolocation {
  latitude: number;
  longitude: number;
  accuracy: number;
  speed: number;
  altitude: number;
  bearing: number;
  time: number;
}

const MapMatcher = () => {
  useOsm();
  useMatcher();
  useMatcher2();
  return null;
};

type GPSLocationProviderType = "web" | "native";

const GeoLocationOutlet = () => {
  const [provider, setProvider] = useState<GPSLocationProviderType>("web");
  const { mode } = useAppSelector(selectDebug);

  useEffect(() => {
    setProvider(isAndroid() ? "native" : "web");
  }, []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (provider === "native") {
      window.AndroidToWeb = (t, json) => {
        const response: AndroidGeolocation = JSON.parse(json);
        if (t === "geolocation" && mode === "off") {
          dispatch(
            setGeoLocation({
              latitude: response.latitude,
              longitude: response.longitude,
              accuracy: response.accuracy,
              speed: response.speed * 3.6, // m/s to km/h
              altitude: response.altitude,
              altitudeAccuracy: 0,
              heading: response.bearing,
              timestamp: response.time,
            }),
          );
        }
      };
      requestToNative({ type: "geolocation", payload: undefined });
    }
  }, [dispatch, mode, provider]);

  useEffect(() => {
    const fetchGPSLocation = async () => {
      const currentLocation = await getCurrentGeolocation();
      dispatch(setGeoLocation(currentLocation as GeoLocation));
    };

    const intervalId = setInterval(fetchGPSLocation, GEOLOCATION_UPDATE_INTERVAL);

    // 로그, 모의주행시 위치 정보를 요청하지 않습니다.
    if (mode !== "off" || provider === "native") {
      clearInterval(intervalId);
      return undefined;
    }

    fetchGPSLocation();
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, mode, provider]);

  return (
    <>
      <Debugger />
      <MapMatcher />
      <Outlet />
    </>
  );
};

export default GeoLocationOutlet;
