// 현재 속도 출력
import { Box, Typography, useTheme } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCarPosition } from "./position/carPositionSlice";
import { selectGuidePosition } from "./position/guidePositionSlice";
import {
  safetyGeneralDistance,
  safetyExpwayDistance,
  safetyGeneralGap,
  safetyExpwayGap,
  selectGuideSetting,
} from "../guide/guideSettingSlice";
import { selectGuide } from "../guide/guideSlice";

const SpeedText = () => {
  const { carPosition } = useAppSelector(selectCarPosition);
  const { safeDrives, currentSafeIndex } = useAppSelector(selectGuide);
  const { safetyGeneralDistanceCheck, safetyExpwayDistanceCheck } =
    useAppSelector(selectGuideSetting);
  const { wayDetailInfo } = useAppSelector(selectGuidePosition);
  const tunnelMode = useAppSelector((state) => state.mapMatch2.tunnelMode);

  const theme = useTheme();
  const speed: string = useMemo(() => {
    return `${(carPosition?.speed || 0).toFixed(0)}`;
  }, [carPosition?.speed]);

  const color: string = useMemo(() => {
    if (safeDrives?.length && carPosition?.speed !== null && wayDetailInfo) {
      const isHiway =
        safeDrives[currentSafeIndex].isHighway &&
        wayDetailInfo?.roadClass ===
          "highway"; /* || wayDetailInfo?.roadClass === "national_highway" */ // national_highway가 실제 고속도로인지 확인이 먼저 필요.
      let checkDistance = 0;
      if (isHiway) {
        for (let ii = 0; ii < safetyExpwayDistanceCheck.length; ii += 1) {
          if (safetyExpwayDistanceCheck[ii]) {
            checkDistance = safetyExpwayDistance[ii];
            break;
          }
        }
        if (checkDistance === 0)
          checkDistance = safetyExpwayDistance[safetyExpwayDistance.length - 1];

        checkDistance += safetyExpwayGap;
      } else {
        for (let ii = 0; ii < safetyGeneralDistanceCheck.length; ii += 1) {
          if (safetyGeneralDistanceCheck[ii]) {
            checkDistance = safetyGeneralDistance[ii];
            break;
          }
        }

        if (checkDistance === 0)
          checkDistance = safetyGeneralDistance[safetyGeneralDistance.length - 1];

        checkDistance += safetyGeneralGap;
      }

      if (
        safeDrives[currentSafeIndex].guidedist <= checkDistance &&
        safeDrives[currentSafeIndex].guidedist > 0
      )
        return theme.palette.error.light;
    }

    if (tunnelMode) {
      return theme.palette.grey[400];
    }

    return theme.palette.background.paper;
  }, [
    safeDrives,
    carPosition,
    theme,
    currentSafeIndex,
    safetyExpwayDistanceCheck,
    safetyGeneralDistanceCheck,
    wayDetailInfo,
    tunnelMode,
  ]);

  return (
    <Box>
      <Typography
        color={color}
        variant="h1"
        sx={{
          textShadow:
            "2px 2px black, -2px -2px black, 2px -2px black, -2px 2px black, -2px 0px black, 2px 0px black, 0px -2px black, 0px 2px black",
        }}
      >
        {speed}
      </Typography>
    </Box>
  );
};

export default memo(SpeedText);
