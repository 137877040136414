import { ArrowBackIos, Close } from "@mui/icons-material";
import {
  Box,
  debounce,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { SearchType, useSearchPlacesByTextQuery } from "./searchApi";
import SearchListItem from "./SearchListItem";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectGeoLocation } from "../geolocation/geoLocationSlice";
import { HistoryItem, selectHistory } from "./history/historySlice";
import { Place, selectSearch, setValue } from "./searchSlice";
import Dialog from "../../components/Dialog";
import { useGetHistoryQuery } from "./history/historyApi";
import { selectToken } from "../token/tokenSlice";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (place: Place, addressType: string) => void;
  addressType?: string;
}

const SearchDialog = ({ open, onClose, onSubmit, addressType }: Props) => {
  const { t } = useTranslation();
  const { value } = useAppSelector(selectSearch);
  const [type, setType] = useState<SearchType>("distance");
  const [places, setPlaces] = useState<Place[]>([]);
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();

  const { token } = useAppSelector(selectToken);
  const { histories } = useAppSelector(selectHistory);
  const { data: historiesDB = [] } = useGetHistoryQuery(undefined, {
    skip: Boolean(!token),
  });
  const { geolocation } = useAppSelector(selectGeoLocation);
  const isNextPageExistRef = useRef(true);

  const {
    data: response = [],
    isLoading,
    isFetching,
  } = useSearchPlacesByTextQuery({
    keyword: value,
    size: 20,
    lat: geolocation?.latitude,
    lon: geolocation?.longitude,
    type,
    page,
  });

  useEffect(() => {
    setPlaces([]);
    setPage(0);
    isNextPageExistRef.current = true;
  }, [value]);

  useEffect(() => {
    if (response.length) {
      setPlaces((prev) => {
        const newPlaces = [...prev];
        response.forEach((place) => {
          if (newPlaces.every((p) => p.id !== place.id)) {
            newPlaces.push(place);
          }
        });
        return newPlaces;
      });
    } else isNextPageExistRef.current = false;
  }, [response]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setValue(event.target.value));
  };

  const handleChangeType = (event: React.MouseEvent<HTMLElement>, newType: SearchType | null) => {
    if (newType) setType(newType);
  };

  const delaySetPage = useMemo(
    () =>
      debounce(() => {
        setPage((prev) => prev + 1);
      }, 300),
    [],
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (
      target.scrollHeight <= target.scrollTop + target.clientHeight + 100 &&
      isNextPageExistRef.current
    ) {
      delaySetPage();
    }
  };

  const removeDuplicatesAndSort = (local: HistoryItem[], server: HistoryItem[]): HistoryItem[] => {
    // 두 개의 배열을 합침
    const combinedHistories = [...local, ...server];

    const uniqueHistories: HistoryItem[] = [];

    // 중복 제거
    combinedHistories.forEach((history) => {
      const existingIndex = uniqueHistories.findIndex((item) => item.place.id === history.place.id);

      if (existingIndex !== -1) {
        if (
          new Date(history.created_at).getTime() >
          new Date(uniqueHistories[existingIndex].created_at).getTime()
        ) {
          uniqueHistories[existingIndex] = history;
        }
      } else {
        uniqueHistories.push(history);
      }
    });

    // created_at 기준으로 정렬
    uniqueHistories.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

    return uniqueHistories;
  };

  const deduplicatedHistories = useMemo(() => {
    return removeDuplicatesAndSort(histories, historiesDB);
  }, [histories, historiesDB]);

  const [textFieldRendered, setTextFieldRendered] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (textFieldRendered && open) {
      textFieldRendered.focus();
    }
  }, [textFieldRendered, open]);

  const handleClose = () => {
    onClose();
  };

  const clearTextField = () => {
    dispatch(setValue(""));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} onAnimationEnd={clearTextField}>
      <Stack px={2.5} pb={2.5} pt={4} spacing={3}>
        <TextField
          variant="outlined"
          value={value}
          onChange={handleChange}
          inputRef={(ref) => {
            if (ref) {
              setTextFieldRendered(ref);
            }
          }}
          // placeholder={t("Where to go?")}
          placeholder={addressType === undefined ? t("Where to go?") : "장소, 주소 검색"}
          fullWidth
          name="place"
          InputProps={{
            startAdornment: (
              <IconButton onClick={handleClose}>
                <ArrowBackIos />
              </IconButton>
            ),
            endAdornment: value ? (
              <IconButton onClick={() => dispatch(setValue(""))}>
                <Close />
              </IconButton>
            ) : null,
          }}
        />
        <Box textAlign="end">
          <ToggleButtonGroup
            value={type}
            onChange={handleChangeType}
            exclusive
            disabled={isLoading || isFetching}
          >
            <ToggleButton value="distance" disabled={geolocation === null}>
              거리순
            </ToggleButton>
            <ToggleButton value="clarity">정확순</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>
      <Divider />
      <Box overflow="auto" onScroll={handleScroll}>
        <List>
          {places.length > 0 ? (
            <>
              {places.map((place) => (
                <SearchListItem
                  key={place.id}
                  type="search"
                  place={place}
                  divider
                  addressType={addressType}
                  onClick={onSubmit}
                />
              ))}
            </>
          ) : (
            deduplicatedHistories.map((history) => (
              <SearchListItem
                key={history.place.id}
                type="history"
                place={history.place}
                divider
                addressType={addressType}
                onClick={onSubmit}
              />
            ))
          )}
          {(isLoading || isFetching) &&
            [0, 1, 2].map((i) => (
              <ListItem divider key={i}>
                <ListItemAvatar>
                  <Skeleton animation="wave" variant="circular" width={30} height={30} />
                </ListItemAvatar>
                <ListItemText
                  primary={<Skeleton animation="wave" width="80%" />}
                  secondary={<Skeleton animation="wave" width="60%" />}
                />
              </ListItem>
            ))}
        </List>
      </Box>
    </Dialog>
  );
};

export default SearchDialog;
