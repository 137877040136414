import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearSimulator,
  selectSimulator,
  setCurrentTimeline,
  setNextTimeline,
  setPlay,
  setSpeed,
} from "./simulatorSlice";
import Player from "../../../components/Player";

const Simulator = () => {
  const dispatch = useAppDispatch();
  const { marks, timelines, currentTimeline, play, speed, keyFrame } =
    useAppSelector(selectSimulator);

  useEffect(() => {
    if (marks[1]?.value <= currentTimeline) {
      dispatch(setPlay(false));
    }
  }, [marks, currentTimeline, dispatch]);

  const onChangeTimeline = useCallback(
    (newValue: number) => {
      dispatch(setCurrentTimeline(newValue));
    },
    [dispatch],
  );
  const onChangeSpeed = useCallback(
    (newSpeed: number) => {
      dispatch(setSpeed(newSpeed));
    },
    [dispatch],
  );

  const handleChangeInterval = useCallback(() => {
    dispatch(setNextTimeline());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(handleChangeInterval, 1000);
    if (!play) clearInterval(interval);
    else handleChangeInterval();
    return () => {
      clearInterval(interval);
    };
  }, [play, speed, handleChangeInterval]);

  const onFastForward = useCallback(() => {
    dispatch(setCurrentTimeline(currentTimeline + keyFrame * 1 * speed));
  }, [dispatch, currentTimeline, keyFrame, speed]);

  const onFastRewind = useCallback(() => {
    dispatch(setCurrentTimeline(currentTimeline - keyFrame * 1 * speed));
  }, [dispatch, currentTimeline, keyFrame, speed]);

  const onPaused = useCallback(() => {
    dispatch(setPlay(false));
  }, [dispatch]);

  const onPlay = useCallback(() => {
    dispatch(setPlay(true));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearSimulator());
    };
  }, [dispatch]);

  const disabled = useMemo(() => timelines.length === 0, [timelines]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") onFastForward();
      if (e.key === "ArrowLeft") onFastRewind();
      if (e.key === " ") (play ? onPaused : onPlay)();
      if (e.key === "ArrowUp") onChangeSpeed(speed + 1);
      if (e.key === "ArrowDown") onChangeSpeed(speed - 1);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onFastForward, onFastRewind, play, onPaused, onPlay, onChangeSpeed, speed]);

  return (
    <Player
      disabled={disabled}
      speed={speed}
      play={play}
      value={currentTimeline}
      marks={marks}
      onChange={onChangeTimeline}
      onChangeSpeed={onChangeSpeed}
      onPause={onPaused}
      onPlay={onPlay}
      onFastForward={onFastForward}
      onFastRewind={onFastRewind}
    />
  );
};

export default Simulator;
