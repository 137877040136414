import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearPaths, Path, selectRoute } from "../route/routeSlice";
import { selectGuide, refreshGuide, setRoutePath, clearGuide, Guidance } from "./guideSlice";
import {
  selectWaypoint,
  setWayPointGuideInfo,
  Waypoint,
  clearWaypoints,
} from "../waypoint/waypointSlice";
import { selectCarPosition } from "../map/position/carPositionSlice";
import {
  selectGuidePosition,
  setGuidePosition,
  clearGuidePosition,
} from "../map/position/guidePositionSlice";
import { DebugMode, selectDebug } from "../debug/debugSlice";
import { getGuidePosFromCarpos } from "./guideUtils";
import { clearAutoReRoute } from "../route/reroute/autoReRouteSlice";
import useGuideSound from "../sound/useGuideSound";
import useCameraSoundWithRoute from "../sound/useCameraSoundWithRoute";
import Logger from "../../app/logger";

/* MEMO
  터치다운 반경은 : 약 40m??? ---.> waypoint 정보 수정이 ghideSlice에서는 힘들어서 useGuide로 옮김.
*/
const touchDownRadius: number = 40; // 터치다운 체크 40m

const Guide = () => {
  const dispatch = useAppDispatch();
  const { paths, status, isReRoute, activePath, activePathIndex } = useAppSelector(selectRoute);
  const carPositionInterface = useAppSelector(selectCarPosition);
  const guidePositionInterface = useAppSelector(selectGuidePosition);
  const { guidances, sortedWayIds } = useAppSelector(selectGuide);
  const { waypoints } = useAppSelector(selectWaypoint);
  const { mode } = useAppSelector(selectDebug);
  const waypointsRef = useRef<Waypoint[]>([]);
  const pathsRef = useRef<Path[]>([]);
  const guidancesRef = useRef<Guidance[]>([]);
  const activePathRef = useRef<Path | null>(null);
  const modeRef = useRef<DebugMode>();
  const carSpeedRef = useRef<number>(0);
  const navigate = useNavigate();
  // useGuideVoice();

  useCameraSoundWithRoute();
  useGuideSound();

  // waypoints 참조용
  useEffect(() => {
    waypointsRef.current = waypoints;
  }, [waypoints]);

  // paths 참조용
  useEffect(() => {
    pathsRef.current = paths;
  }, [paths]);

  // guidances 참조용
  useEffect(() => {
    guidancesRef.current = guidances;
  }, [guidances]);

  // activePath 참조용
  useEffect(() => {
    activePathRef.current = activePath;
  }, [activePath]);

  // mode 참조용
  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  // carPosition.speed 참조용
  useEffect(() => {
    carSpeedRef.current = carPositionInterface.carPosition?.speed || 0;
  }, [carPositionInterface.carPosition?.speed]);

  // Guide Postion 갱신
  useEffect(() => {
    const newGuidePos = getGuidePosFromCarpos(
      activePath,
      guidances,
      sortedWayIds,
      carPositionInterface,
      guidePositionInterface.linkPositionIndex || 0,
      guidePositionInterface.guidanceIndex, // 이전 가이드 인덱스
    );

    if (newGuidePos !== null) {
      dispatch(setGuidePosition(newGuidePos));
    }
  }, [
    dispatch,
    carPositionInterface,
    activePath,
    sortedWayIds,
    guidances,
    guidePositionInterface.guidanceIndex,
    guidePositionInterface.linkPositionIndex,
  ]);

  // 맵매칭 정보가 바뀌었으면 안내 거리 갱신
  useEffect(() => {
    if (activePathRef.current) {
      const speed = carSpeedRef.current; // 맵매칭된 속도값이 없으니 임시로 999로 설정

      if (
        guidancesRef.current.length !== 0 &&
        waypointsRef.current.length > 1 &&
        waypointsRef.current[1].guideIndex === null
      ) {
        // waypoint 터치다운용 데이터 생성
        let wayPointIndex: number = 1;
        guidancesRef.current.forEach((point, index) => {
          if (point.sign === 5 || point.sign === 4) {
            dispatch(
              setWayPointGuideInfo({
                index: wayPointIndex,
                guideIndex: index,
                guideDistance: guidancesRef.current[index].accdist,
              }),
            );
            wayPointIndex += 1;
          }
        });
      }

      dispatch(
        refreshGuide({
          path: activePathRef.current,
          linkStartIndex: guidePositionInterface.linkPositionIndex,
          guideDistanceFromStart: guidePositionInterface.distanceFromStart,
          guidanceIndex: guidePositionInterface.guidanceIndex,
        }),
      );
      // 터치다운 처리. - 실 주행이나 GPX로그 주행일때만 처리. 경로 모의 주행일때에는 처리하지 않음.
      // 1. 다다음 안내에 목적지/경유지가 있는데 40m 이내일때
      // 2. 다음 안내가 목적지/경유지이고 40m 이내일때
      // 3. 1-2조건에서 터치다운되지 못하고 지나쳤지만 40m이내일때

      // 현재 실주행/모의주행/로그주행이 하나도 구분이 안되기 때문에 일단 막아둠. 지우지 말것.

      if (modeRef.current !== "route") {
        if (waypointsRef.current.length > 1) {
          if (
            waypointsRef.current[1].guideIndex !== null &&
            waypointsRef.current[1].guideDistance !== null &&
            guidePositionInterface.distanceFromStart !== null &&
            guidancesRef.current.length > waypointsRef.current[1].guideIndex
          ) {
            if (
              Math.abs(
                guidancesRef.current[waypointsRef.current[1].guideIndex].accdist -
                  guidePositionInterface.distanceFromStart,
              ) <= touchDownRadius
            ) {
              // 터치다운 처리.
              // Logger.log( "터치다운", waypoint.label, "지점에 도착하였습니다.");
              if (waypointsRef.current.length <= 2) {
                // 목적지까지 터치다운되면 안내 종료.

                dispatch(clearPaths());
                dispatch(clearWaypoints());
                dispatch(clearGuide());
                // navigate("/", { replace: true });
              }

              // dispatch(removeWayPoint(1)); // 지점 제거.(경로 모의 주행일때에는 유지해야하는데 일단 테스트용)
            }
          }
        }
      }

      // 4. 3에서도 만족못하고 지나가 버리면 지나간 경유지/목적지 유지한채 경로 재탐색이 필요하다.
      //
    }
  }, [
    dispatch,
    navigate,
    guidePositionInterface.linkPositionIndex,
    guidePositionInterface.distanceFromStart,
    guidePositionInterface.guidanceIndex,
  ]);

  // 경로 정보가 통째로 바뀌었으면(재탐이나 기타요인으로) 안내 정보 새로 빌딩
  useEffect(() => {
    // 안내 정보 clear 먼저
    // 안내정보 생성
    Logger.log(
      "routing status: %s, paths.length: %d, activePathIndex: %d, isReRoute: %d",
      status,
      paths.length,
      activePathIndex,
      isReRoute,
    );

    if (status === "fulfilled" && activePath) {
      // 경로 탐색이 완료된 때에만 안내정보를 생성하도록 함
      // 이탈재탐색을 포함하여 모든 경로탐색이 끝났을 경우에는 이탈재탐색 카운트는 초기화한다.
      dispatch(clearAutoReRoute());
      dispatch(clearGuide());
      dispatch(clearGuidePosition());
      dispatch(setRoutePath({ path: activePath }));
    }
  }, [dispatch, paths, activePath, status, isReRoute, activePathIndex]);

  // 언마운트시 안내정보 clear
  useEffect(() => {
    return () => {
      dispatch(clearGuide());
    };
  }, [dispatch]);

  return null;
};

export default Guide;
