/* eslint-disable @typescript-eslint/naming-convention */
export enum MAPMATCH_QUALITY {
  UNKNOWN = 0,

  ONROAD = 1,				// 온 로드
  OFFROAD = 2,				// 오프 로드

  PREV = 3,					// 이전 데이타
  DISABLE = 4				// 맵매칭하지 않음
}

export enum MAPMATCH_ROUTE_STATUS {
  ONGUIDE = 0,			// 경로 주행중
  OFFGUIDE = 1,			// 경로 이탈
  DELAY = 2,
  OFFGUIDE_IN_SA = 3	// 경로 이탈 - 휴게소 
};

export enum MAPMATCH_DIR {
  UNKNOWN = 0,
  SE = 1,
  ES = 2
};

export interface rpoint {
  x: number;
  y: number;
};

export interface MapMatchDetails {
  mm_pt: rpoint;
  pt1: rpoint;
  pt2: rpoint;
  angle: number; // degree
  map_idx: number;
  link_idx: number;
  dir: number;
  vtx_idx: number;
  vtx_dist: number;
  road_grade: number;
  link_type: number;
  facil: number;
  zpos: number;
  pitch: number;
}

export interface MapMatchResult {
  x: number;
  y: number;
  carDegree: number; // Heading angle (radian, 0<= degree < 2PI, angle in catesian system, east is 0, positive value is ccw)
  gpsDegree: number; // Heading angle (degree, 0<= degree < 360, angle in catesian system, east is 0 degree, positive value is ccw)

  // mapIdx: number; // 온라인은 mesh데이터 사용 안함.
  linkIdx: number;
  vtxIdx: number;
  vtxDist: number;
  dist: number; // 노드부터 거리

  dir: MAPMATCH_DIR;
  quality: MAPMATCH_QUALITY;
  routeStatus: MAPMATCH_ROUTE_STATUS; // 경로 상태

  speed1000: number;
  roadName: string;
  crossName: string;
  isHighway: boolean; // 고속도로 여부
  roadType: number;
  roadType2: number; // cns에서 사용 JOLEE
  roadClass2: number;
  kwt_roadid: number;
  kwt_kind: number;
  kwt_rclass: number;
  kwt_road_speed: number;
  kwt_is_auto_moving: boolean; // tunnel과 underpass에서 동작.
  Competition: boolean;
  U_turn: boolean;
  is_tunnel: boolean;
  is_underpass: boolean;
  details: MapMatchDetails;
}

export interface GPS_DATETIME {
  year: number;		/// <연
  month: number;		/// <월
  day: number;		/// <일

  hour: number;		/// <시간
  min: number;		/// <분
  sec: number;		/// <초
  msec: number;		/// <1/1000초
}

export interface GpsData {
  latitude: number;
  longitude: number;
  altitude: number;

  speed: number;
  degree: number;						// 정동 기준

  loc_time: GPS_DATETIME;
}
/*
export interface MapMatchResult {
  x:number;
  y:number;

  carDegree:number;			// 현재 차량의 각도	--> 정동 (도)
  gpsDegree:number;			// gps 각도 --> 정동 (도)

  mapIdx:number;
  linkIdx:number;
  vtxIdx:number;
  vtxDist:number;
  dist:number;				// 노드부터 거리
                // 진행방향에 대한 거리(노드 부터)
  dir:MAPMATCH_DIR;
  quality:MAPMATCH_QUALITY;
  routeStatus:MAPMATCH_ROUTE_STATUS;			// 경로 상태

  speed1000:number;

  roadName:string;
  crossName:string;

  isHighway:boolean;             // 고속도로 여부
  roadType:number;
  roadType2:number;				// cns에서 사용 JOLEE
  roadClass2:number;
    
  kwt_roadid:number;
  kwt_kind:number;
  kwt_rclass:number;
  kwt_road_speed:number;
  kwt_is_auto_moving:boolean; // tunnel과 underpass에서 동작.

  Competition:boolean;
  U_turn:boolean;

  is_tunnel:boolean;

  details:MapMatchDetails;
}
  */
export enum SCORE_TYPE {
  DIST_SCORE = 0,
  ANGLE_SCORE,
  SELECTED_SCORE,
  SA_SCORE,
  MAIN_ROAD_SCORE,
  SUBWAY_SCORE,
  ONROUTE_SCORE,
  CHOSEN_SCORE,
  JUMPING_SCORE,
  STRAGGLE_SCORE,
  NAVI_TYPE_SCORE,
  TUNNEL_STICKY_SCORE,
  MAX_SCORE_TYPE,
}

export interface MapMatchScore {
  totalScore: number;
  score: number[];
  diffScore: number;
  lastChosenPt: rpoint;
}
export interface MapMatchCandidateResult {
  isSet: boolean;
  x: number;					// global x
  y: number;					// global y
  carDegree: number;			// 차량의 각도 (도)

  // 맵관련 정보
  // int mapIdx;
  linkIdx: number;
  vtxIdx: number;
  vtxDist: number;

  dir: MAPMATCH_DIR;


  score: number;					// gps와의 거리
  // 각도
  // 경로가 설정시 경로
  // 를 반영한 점수
  // int sc_dist;
  // int sc_angle;

  mmScore: MapMatchScore;

  onRoute: number;
  selected: boolean;
  isHighway: boolean;             // 고속도로 여부

  details: MapMatchDetails;

  ipc_cand_id: number;
}

export interface BackCarChecker {
  prevX:number;		// x map coordinate
	prevY:number;		// y map coordinate
	PrevAngle:number;	// east based ccw+ radian
}