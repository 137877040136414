import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../../app/store";

export interface Timeline {
  value: number;
  timestamp: number;
}

interface State {
  timelines: Timeline[];
  currentTimeline: number;
  play: boolean;
  speed: number;
  marks: { value: number; label: string }[];
  keyFrame: number;
}

const initialState: State = {
  timelines: [],
  keyFrame: 1000,
  currentTimeline: 0,
  play: false,
  speed: 1,
  marks: [],
};

const simulatorSlice = createSlice({
  name: "simulator",
  initialState,
  reducers: {
    setTimelines: (
      state,
      { payload }: PayloadAction<{ timelines: Timeline[]; keyFrame: number }>,
    ) => {
      const start = payload.timelines[0];
      const end = payload.timelines[payload.timelines.length - 1];
      state.keyFrame = payload.keyFrame;
      state.marks = [
        { value: start.value, label: new Date(start.timestamp).toLocaleTimeString() },
        { value: end.value, label: new Date(end.timestamp).toLocaleTimeString() },
      ];
      state.timelines = payload.timelines;
    },
    setMarks: (state, { payload }: PayloadAction<{ value: number; label: string }[]>) => {
      state.marks = payload;
    },
    setPlay: (state, { payload }: PayloadAction<boolean>) => {
      state.play = payload;
    },
    setSpeed: (state, { payload }: PayloadAction<number>) => {
      state.speed = payload;
    },
    setCurrentTimeline: (state, { payload }: PayloadAction<number>) => {
      if (payload < 0) {
        state.currentTimeline = 0;
        return;
      }
      if (payload > state.timelines[state.timelines.length - 1].value) {
        state.currentTimeline = state.timelines[state.timelines.length - 1].value;
        return;
      }
      state.currentTimeline = payload;
    },
    setNextTimeline: (state) => {
      const newCurrentTimeline = state.currentTimeline + state.keyFrame * state.speed;
      if (newCurrentTimeline > state.timelines[state.timelines.length - 1].value) {
        state.play = false;
        state.currentTimeline = state.timelines[state.timelines.length - 1].value;
        return;
      }

      if (newCurrentTimeline < 0) {
        state.currentTimeline = 0;
        return;
      }
      state.currentTimeline += state.keyFrame * state.speed;
    },
    clearSimulator: (state) => {
      state.timelines = [];
      state.currentTimeline = 0;
      state.play = false;
      state.speed = 1;
      state.marks = [];
    },
  },
});

export default simulatorSlice;
export const {
  setTimelines,
  setMarks,
  setPlay,
  setSpeed,
  setCurrentTimeline,
  setNextTimeline,
  clearSimulator,
} = simulatorSlice.actions;
export const selectSimulator = (state: RootState) => state.simulator;
