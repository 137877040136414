import React, { memo } from "react";
import { Marker } from "react-map-gl/maplibre";
import { useAppSelector } from "../../../../app/hooks";
import { cautionIconImgUrl } from "../../../../app/config/images";

interface Props {
  index: number;
  latitude: number;
  longitude: number;
}

const SafeDriveMarker = ({ latitude, longitude, index }: Props) => {
  const currentSafeIndex = useAppSelector((state) => state.guide.currentSafeIndex);
  if (index < currentSafeIndex) return null;
  return (
    <Marker latitude={latitude} longitude={longitude}>
      <img src={cautionIconImgUrl} alt="카메라" />
    </Marker>
  );
};

export default memo(SafeDriveMarker);
