import React, { memo } from "react";
import { Paper, Stack, Divider, IconButton, styled, BoxProps, Box } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import ResetBearingButton from "../features/map/ResetNorthButton";

interface NavigationControlProps extends BoxProps {
  onChangeView: (change: number) => void;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "51px",
  borderRadius: "6px",
  color: theme.palette.text.primary,
  ...(theme.palette.mode === "dark" && {
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  }),
}));

const NavigationControl = ({ onChangeView, ...restProps }: NavigationControlProps) => {
  return (
    <Box {...restProps}>
      <StyledPaper variant="outlined">
        <Stack>
          <ResetBearingButton />
          <Divider />
          <IconButton size="large" onClick={() => onChangeView(0.5)} color="inherit">
            <Add />
          </IconButton>
          <Divider flexItem />
          <IconButton size="large" onClick={() => onChangeView(-0.5)} color="inherit">
            <Remove />
          </IconButton>
        </Stack>
      </StyledPaper>
    </Box>
  );
};

export default memo(NavigationControl);
