class Logger {
  private static isDev = process.env.NODE_ENV === "development";

  public static log(...message: any[]) {
    if (Logger.isDev) {
      console.log(...message);
    }
  }

  public static info(...message: any[]) {
    if (Logger.isDev) {
      console.info("[INFO]:", ...message);
    }
  }

  public static warn(...message: any[]) {
    if (Logger.isDev) {
      console.warn("[WARN]:", ...message);
    }
  }

  public static error(...message: any[]) {
    if (Logger.isDev) {
      console.error("[ERROR]:", ...message);
    }
  }
}

export default Logger;
