import { XMLParser } from "fast-xml-parser";
import { GeoLocation } from "../../geolocation/geoLocationSlice";

export interface SimulationGpxData {
  gpx: {
    trk: {
      trkseg: {
        trkpt: {
          "@_lat": string;
          "@_lon": string;
          time: string;
          hdop: number;
          ele: number;
          pdop: number;
          extensions: { "tpx:bearing": number; "tpx:speed": number };
        }[];
      };
    }[];
  };
}

const xmlParser = new XMLParser({
  ignoreAttributes: false,
  allowBooleanAttributes: true,
});

export const parseGpxToPoints = async (gpxFile: File): Promise<GeoLocation[]> => {
  const gpxString = await gpxFile.text();
  const gpxData: SimulationGpxData = xmlParser.parse(gpxString);

  return gpxData.gpx.trk
    .map((trk) =>
      trk.trkseg.trkpt.map((trkpt) => ({
        latitude: parseFloat(trkpt["@_lat"]),
        longitude: parseFloat(trkpt["@_lon"]),
        timestamp: new Date(trkpt.time).getTime(),
        accuracy: (trkpt.hdop * 3) / 100,
        altitude: trkpt.ele,
        heading: trkpt.extensions["tpx:bearing"],
        altitudeAccuracy: trkpt.hdop,
        speed: Math.floor((trkpt.extensions["tpx:speed"] / (100 * 1000)) * (60 * 60)),
      })),
    )
    .flat();
};
