import { Dialog as MuiDialog, DialogProps } from "@mui/material";
import React, { ReactNode, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface Props extends DialogProps {
  children: ReactNode;
}

export const useDialog = () => {
  const [id] = useState(Math.random().toString());
  const [searchParams, setSearchParams] = useSearchParams();
  const open = searchParams.has(id);
  const navigate = useNavigate();

  const onOpen = useCallback(() => {
    setSearchParams((prev) => {
      prev.set(id, "1");
      return prev;
    });
  }, [setSearchParams, id]);

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    open,
    onOpen,
    onClose,
  };
};

const Dialog = ({ open, children, onClose, ...restProps }: Props) => {
  return (
    <MuiDialog open={open} onClose={onClose} {...restProps}>
      {children}
    </MuiDialog>
  );
};

export default Dialog;
