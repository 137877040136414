// useMatcher.ts
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectGeoLocation } from "../geolocation/geoLocationSlice";
import { setCarPosition } from "../map/position/carPositionSlice";
import { selectRoute } from "../route/routeSlice";
import { setPrePositions } from "./prePosSlice";
import { selectDebug } from "../debug/debugSlice";

const useMatcher = () => {
  const dispatch = useAppDispatch();
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { activePath } = useAppSelector(selectRoute);
  const workerRef = useRef<Worker | null>(null);
  const { useMMV4 } = useAppSelector(selectDebug);

  useEffect(() => {
    // Worker를 모듈로 생성
    workerRef.current = new Worker(new URL("./matcherWorker.ts", import.meta.url), {
      type: "module",
    });

    workerRef.current.onmessage = (event) => {
      const { type, ...data } = event.data;
      if (type === "matchResult") {
        dispatch(setCarPosition(data));
      } else if (type === "prePos") {
        dispatch(setPrePositions(data))
      } else if (type === "error") {
        console.error("Matcher worker error:", data.message);
      }
    };

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (geolocation && workerRef.current && !useMMV4 ) {
      workerRef.current.postMessage({ geolocation, activePath });
    }
  }, [geolocation, activePath, useMMV4]);
};

export default useMatcher;
