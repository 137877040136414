import React from "react";
import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectBearing, setBearing } from "./view/viewSlice";
import { compassImgUrl } from "../../app/config/images";

const Compass = () => {
  const bearing = useAppSelector(selectBearing);
  return <img src={compassImgUrl} alt="Compass" style={{ transform: `rotate(${-bearing}deg)` }} />;
};

const ResetBearingButton = () => {
  const dispatch = useAppDispatch();

  const handleCompassClick = () => {
    dispatch(setBearing(0));
  };

  return (
    <IconButton onClick={handleCompassClick}>
      <Compass />
    </IconButton>
  );
};

export default ResetBearingButton;
