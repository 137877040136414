import React, { ReactNode } from "react";
import { Droppable as HDroppable } from "@hello-pangea/dnd";

interface Props {
  id: string;
  children: ReactNode;
}

const Droppable = ({ id, children }: Props) => {
  return (
    <HDroppable droppableId={id}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </HDroppable>
  );
};

export default Droppable;
