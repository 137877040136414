import { Box, CardActionArea, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectGuidances } from "./guideSlice";
import { selectGuidePosition } from "../map/position/guidePositionSlice";

const JunctionView = () => {
  const guidances = useAppSelector(selectGuidances);
  const { guidanceIndex } = useAppSelector(selectGuidePosition);
  const [hide, setHide] = useState(false);
  const theme = useTheme();

  const imageUrl = useMemo(() => {
    if (!guidanceIndex) return null;
    setHide(false);
    const currentGuidance = guidances[guidanceIndex];
    if (!currentGuidance || guidanceIndex < 0 || guidanceIndex >= guidances.length) return null;
    if (currentGuidance.isHighway && currentGuidance.guidedist > 1000) return null;
    if (!currentGuidance.isHighway && currentGuidance.guidedist > 300) return null;
    return theme.palette.mode === "dark" ? currentGuidance.jv_night : currentGuidance.jv_day;
  }, [guidances, guidanceIndex, theme]);

  if (!imageUrl || hide) {
    return null;
  }

  return (
    <Box position="absolute" top={48} right={0} width="50%" maxWidth={250} zIndex={1}>
      <CardActionArea onClick={() => setHide(true)}>
        <img src={imageUrl} alt="junction view" width="100%" />
      </CardActionArea>
    </Box>
  );
};

export default JunctionView;
