import { useCallback, useEffect, useRef } from "react";
import * as turf from "@turf/turf";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  ReRouteArgs,
  RouteType,
  fetchAutoReRouteByWaypoints,
  fetchReRouteByWaypoints,
  selectRoute,
  setActivePathIndex,
} from "../routeSlice";
import { GeoLocation } from "../../geolocation/geoLocationSlice";
import {
  clearAutoReRouteCounting,
  clearAutoReRouteDelayDist,
  plusAutoReRouteCounting,
  plusAutoReRouteDelayDist,
  selectAutoReRoute,
  setIsAutoReRouteEnabled,
} from "./autoReRouteSlice";
import { selectCarPosition } from "../../map/position/carPositionSlice";
import { selectGuide } from "../../guide/guideSlice";
import { getReRouteStartPoint } from "../routeUtils";
import { selectWaypoint, setWayPoint } from "../../waypoint/waypointSlice";
import { selectReRoute, setIsAwaitingWaypointsUpdate, setReRouteType } from "./reRouteSlice";
import { Place } from "../../search/searchSlice";
import { DebugMode, selectDebug } from "../../debug/debugSlice";
import { selectLinkRoute } from "../link/linkRouteSlice";
import { IWay } from "../../db/OsmCache";
import Logger from "../../../app/logger";

const AutoReRoute = () => {
  const dispatch = useAppDispatch();
  const { carPosition, wayId, direction, onRoute } = useAppSelector(selectCarPosition);
  const { waypoints } = useAppSelector(selectWaypoint);
  const { isAutoReRouteEnabled, autoReRouteCounting, autoReRouteDelayDist } =
    useAppSelector(selectAutoReRoute);
  const { status } = useAppSelector(selectRoute);
  const { sortedWayIds } = useAppSelector(selectGuide);
  const { isAwaitingWaypointsUpdate, reRouteType } = useAppSelector(selectReRoute);
  const { mode } = useAppSelector(selectDebug);
  const { links } = useAppSelector(selectLinkRoute);

  const currentCarPosition = useRef<GeoLocation | null>(null);
  const onRouteCheckTimerRef = useRef<NodeJS.Timeout | null>(null);
  const autoReRoutePrevCoordsRef = useRef<{ prevLng: number; prevLat: number } | null>(null);
  const modeRef = useRef<DebugMode>("off");
  const wayIdRef = useRef<number | null>(null);
  const linksRef = useRef<IWay[]>([]);
  const headingRef = useRef<number>(0);

  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  useEffect(() => {
    wayIdRef.current = wayId;
  }, [wayId]);

  useEffect(() => {
    linksRef.current = links;
  }, [links]);

  useEffect(() => {
    headingRef.current = carPosition ? carPosition.heading : 0;
  }, [carPosition]);

  useEffect(() => {
    // waypoints 가 업데이트 되었을 때, reRouteWaypoints 가 2개 이상이면 경로 재탐색 요청
    const args: ReRouteArgs = { waypoints, startHeading: null, destHeading: null };
    if (isAwaitingWaypointsUpdate && waypoints.length >= 2) {
      dispatch(setIsAwaitingWaypointsUpdate(false));

      // 경로 재탐색의 경우 경로가 한개만 있기 때문에 activeRouteIndex 를 0으로 설정
      // 주요도로3 및 그 상급 도로이면서, 편도 2차선 이상 도로일 때 출발지 방향성을 고려하여 경로 재탐색
      // --> 2024.08.08(dckim) 재탐색일때에는 무조건 방향성 고려하도록 수정. 위 조건은 사용자가 출발지를 직접 지정하였을때에만이고, 재탐색일때에는 무조건 방향 고려.
      /*
      const link = linksRef.current.find((l) => l.id === wayIdRef.current);
      let roadLevel: number = -1;
      let lanes: number = -1;
      if (link !== undefined) {
        roadLevel = Number(link.feature.properties?.road_level);
        lanes = link.feature.properties?.oneway !== undefined ? Number(link.feature.properties?.lanes) : Number(link.feature.properties?.lanes) / 2;
        args.startHeading = (roadLevel <= 7 && lanes >= 2) ? headingRef.current : null;
      }
     Logger.log("re-routing...(type: %d, roadLevel: %d, lanes: %d, startHeading: %f)", reRouteType, roadLevel, lanes, args.startHeading);
      */
      args.startHeading = headingRef.current;
      if (args.startHeading !== null) {
        // fix "vehicle_direction must be between 0 and 360"
        while (args.startHeading < 0) {
          args.startHeading += 360;
        }

        while (args.startHeading >= 360) {
          args.startHeading -= 360;
        }
      }
      Logger.log("re-routing...(type: %d, startHeading: %f)", reRouteType, args.startHeading);

      if (reRouteType === 1) {
        dispatch(fetchAutoReRouteByWaypoints(args));
      } else if (reRouteType === 2) {
        dispatch(fetchReRouteByWaypoints(args));
      }
      dispatch(setActivePathIndex(0));
    }
  }, [dispatch, isAwaitingWaypointsUpdate, reRouteType, waypoints]);

  const checkAutoReRoute = useCallback(() => {
    if (!currentCarPosition.current) return;

    const carSpeed = currentCarPosition.current.speed || 0;
    if (carSpeed > 5) {
      dispatch(plusAutoReRouteCounting());
    }

    if (autoReRoutePrevCoordsRef.current) {
      const { prevLng, prevLat } = autoReRoutePrevCoordsRef.current;
      const currentLng = currentCarPosition.current.longitude;
      const currentLat = currentCarPosition.current.latitude;
      const distance = turf.distance([prevLng, prevLat], [currentLng, currentLat], {
        units: "meters",
      });

      dispatch(plusAutoReRouteDelayDist(distance));
    }

    autoReRoutePrevCoordsRef.current = {
      prevLng: currentCarPosition.current.longitude,
      prevLat: currentCarPosition.current.latitude,
    };
  }, [dispatch]);

  useEffect(() => {
    // 기존 경로상에 맵매칭되다가, 경로상이 아닌 곳으로 맵매칭되기 시작후 시간상으로 2초후, 거리상으로 20m 이상 경과할때 이탈재탐색을 실행한다.
    // 시간상으로 2초 산정시, 현재 자차 속도가 5km/h 초과일때만 카운트한다.
    // 단, 이탈재탐색을 실행하려고 할때, 이미 경로탐색중이면 이탈재탐색을 하지 않고 넘긴다.
    // 이탈재탐색을 포함하여 모든 경로탐색이 끝났을 경우에는 이탈재탐색 카운트는 초기화한다.
    currentCarPosition.current = carPosition;

    // 경로가 없는 상태에서는 이탈재탐색을 하지 않는다.
    // 모의주행에서는 경로 이탈 재탐색이 발생하지 않도록 한다.
    if (modeRef.current === "log" || modeRef.current === "off") {
      if (carPosition && wayId && sortedWayIds.length > 0) {
        if (!onRoute) {
          // 맵매칭된 위치가 경로상에 없을 때(경로 이탈)
          // 2초 산정 시 현재 자차 속도가 5km/h 초과일 때만 카운트 함
          if (onRouteCheckTimerRef.current === null) {
            onRouteCheckTimerRef.current = setInterval(checkAutoReRoute, 1000);
          }
        } else {
          // 맵매칭된 위치가 경로상에 있을 때, 이탈재탐색 카운트 및 타이머 초기화
          if (onRouteCheckTimerRef.current) {
            clearInterval(onRouteCheckTimerRef.current);
            onRouteCheckTimerRef.current = null;
          }
          dispatch(clearAutoReRouteCounting());
          dispatch(clearAutoReRouteDelayDist());
          autoReRoutePrevCoordsRef.current = null;
        }
      }
    } else {
      if (onRouteCheckTimerRef.current) {
        clearInterval(onRouteCheckTimerRef.current);
        onRouteCheckTimerRef.current = null;
      }
      dispatch(clearAutoReRouteCounting());
      dispatch(clearAutoReRouteDelayDist());
      autoReRoutePrevCoordsRef.current = null;
    }
  }, [carPosition, checkAutoReRoute, direction, dispatch, sortedWayIds, wayId, onRoute]);

  useEffect(() => {
    // 경로상이 아닌 곳으로 맵매칭되기 시작후 시간상으로 2초후, 거리상으로 20m 이상 경과할때 이탈재탐색을 실행할 수 있도록 isAutoReRouteEnabled 를 true 로 변경
    // Logger.log("autoReRouteCounting: %d, autoReRouteDelayDist: %d", autoReRouteCounting, autoReRouteDelayDist);
    if (autoReRouteCounting >= 2 && autoReRouteDelayDist >= 20) {
      dispatch(setIsAutoReRouteEnabled(true));
    }
  }, [autoReRouteDelayDist, dispatch, autoReRouteCounting]);

  const setStartPoint = useCallback(
    (startPlace: Place | null) => {
      if (status === "fulfilled") {
        dispatch(setWayPoint({ place: startPlace, index: 0 }));
        dispatch(setReRouteType(RouteType.AutoReRoute));
        dispatch(setIsAwaitingWaypointsUpdate(true));
      }
    },
    [dispatch, status],
  );

  useEffect(() => {
    if (isAutoReRouteEnabled) {
      const startPlace = getReRouteStartPoint(currentCarPosition.current);
      setStartPoint(startPlace);
    }
  }, [isAutoReRouteEnabled, setStartPoint]);
  return null;
};

export default AutoReRoute;
