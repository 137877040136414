/* eslint-disable import/no-absolute-path */
import carMediumSvg from "/images/car_medium.svg";
import carMediumTunnelSvg from "/images/car_medium_tunnel.svg";
import timerSvg from "/images/timer.svg";
import locationSvg from "/images/location.svg";
import compassSvg from "/images/compass.svg";
import mainLogoSvg from "/images/main_logo.svg";

import mapCamera01Png from "/guide_safe/map_camera01.png";
import mapCamera03Png from "/guide_safe/map_camera03.png";
import cautionIconPng from "/guide_safe/caution_icon.png";

import directionTargetWebp from "/guide_flag/guide_dir_target.webp";

export const carImgUrl = carMediumSvg;
export const carTunnelImgUrl = carMediumTunnelSvg;
export const timerImgUrl = timerSvg;
export const locationImgUrl = locationSvg;
export const compassImgUrl = compassSvg;

export const mainLogoImgUrl = mainLogoSvg;
export const mapCamera01ImgUrl = mapCamera01Png;
export const mapCamera03ImgUrl = mapCamera03Png;
export const cautionIconImgUrl = cautionIconPng;

export const directionTargetImgUrl = directionTargetWebp;

// export const
