import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/react";
import App from "./App";
import { store } from "./app/store";
import "./app/locales/i18n";
import { ColorThemeProvider } from "./features/theme/useColorTheme";

Sentry.init({
  dsn: "https://9cbb1c8d8b01a8c2179d1cfbbf0bd3e7@o4507649605173248.ingest.us.sentry.io/4507649609891840",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://rousen-navi-web.dev2citus.com/",
    "https://rousen-navi-web.rousen.com/",
  ],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: window.location.hostname !== "localhost",
});

const container = document.getElementById("root");
const persistor = persistStore(store);

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <ColorThemeProvider>
              <CssBaseline />
              <App />
            </ColorThemeProvider>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  );
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  );
}
