import React, { memo } from "react";
import { Button, Popover } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useAppSelector } from "../../app/hooks";
import { selectToken } from "../token/tokenSlice";
import { useGetUserInfoQuery } from "../token/userApi";
import { selectGeoLocation } from "../geolocation/geoLocationSlice";
import { selectCarPosition } from "../map/position/carPositionSlice";

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ReportPopover = ({ anchorEl, onClose }: Props) => {
  const { token } = useAppSelector(selectToken);
  const { data: user } = useGetUserInfoQuery(undefined, { skip: Boolean(!token) });
  const { geolocation } = useAppSelector(selectGeoLocation);
  const { carPosition, wayId } = useAppSelector(selectCarPosition);

  const trackPosition = (type: "camera" | "route" | "etc") => {
    Sentry.captureException({ type, geolocation, carPosition, wayId, user }, { level: "info" });
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Button
        size="large"
        onClick={() => {
          trackPosition("camera");
        }}
      >
        카메라
      </Button>
      <Button
        size="large"
        color="error"
        onClick={() => {
          trackPosition("route");
        }}
      >
        경로
      </Button>
      <Button
        size="large"
        color="info"
        onClick={() => {
          trackPosition("etc");
        }}
      >
        기타
      </Button>
    </Popover>
  );
};

export default memo(ReportPopover);
